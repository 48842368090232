import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as ManufacturerActions from './manufacturer.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ManufacturerService } from './manufacturer.service';
import * as CategoryActions from '../categories/category.action';

@Injectable()
export class ManufacturerEffects {
  constructor(
    private actions$: Actions,
    private manufacturerService: ManufacturerService,
    private store: Store<AppState>
  ) {}

  ManufacturersListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManufacturerActions.ManufacturersListRequested),
      switchMap((action) =>
        this.manufacturerService.getAll().pipe(
          map((manufacturersList) =>
            ManufacturerActions.ManufacturersListRequestedSuccessfully({
              manufacturers: manufacturersList,
            })
          ),
          catchError((error) =>
            of(ManufacturerActions.ManufacturersListRequestFailed({ error }))
          )
        )
      )
    )
  );

  ManufacturersPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManufacturerActions.ManufacturersPageRequested),
      switchMap((action) =>
        this.manufacturerService
          .getManufactures(
            action.page.filter ?? {},
            action.page.pageNumber,
            action.page.pageSize
          )
          .pipe(
            map((manufacturePage) =>
              ManufacturerActions.ManufacturersPageLoadedSuccessfully({
                manufacturer: manufacturePage.content,
                page: manufacturePage.totalElements,
                totalCount: manufacturePage.totalElements,
              })
            ),
            catchError((error) =>
              of(CategoryActions.CategoriesPageLoadFailed({ error }))
            )
          )
      )
    )
  );

  UpdateManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManufacturerActions.UpdateManufacturer),
      switchMap((action) =>
        this.manufacturerService.updateManufacturer(action.manufacturer).pipe(
          map((manufacturer) =>
            ManufacturerActions.ManufacturerUpdatedSuccessfully({
              manufacturer: manufacturer,
            })
          ),
          catchError((error) =>
            of(ManufacturerActions.ManufacturerUpdateFailed({ error }))
          )
        )
      )
    )
  );

  DeleteManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManufacturerActions.DeleteManufacturer),
      switchMap((action) =>
        this.manufacturerService.deleteManufacturer(action.manufacturerId).pipe(
          map((manufacturer) =>
            ManufacturerActions.ManufacturerDeletedSuccessfully({
              manufacturerId: action.manufacturerId,
            })
          ),
          catchError((error) =>
            of(ManufacturerActions.ManufacturerDeletionFailed({ error }))
          )
        )
      )
    )
  );

  CreateManufacturer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManufacturerActions.CreateManufacturer),
      switchMap((action) =>
        this.manufacturerService.addManufacturer(action.manufacturer).pipe(
          map((manufacturer) =>
            ManufacturerActions.ManufacturerCreatedSuccessfully({
              manufacturer: manufacturer,
            })
          ),
          catchError((error) =>
            of(ManufacturerActions.ManufacturerCreationFailed({ error }))
          )
        )
      )
    )
  );
}
