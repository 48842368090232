import { ProductResource } from './product-resources/product-resource';

export class ProductModel {
  id: number;
  categoryId: number;
  manufacturerId: number;
  name: string;
  designation: string;
  shortDescription: string;
  description: string;
  code: string;
  manufacturerReference: string;
  productResources: ProductResource[];
  status: string;
  replenishmentDate: Date;
  eancode: string;
  visible?: boolean;
  physical: boolean;
  link: string;
  price?: number;
  substitutionProduct: ProductModel;
  quantity: number;
  remainder?: boolean;
  location?: string;
  customsCode?: string;
  origin?: string;
  weight?: number;
  source?: string;
  updateDate?: Date;
  updater: string;
}
