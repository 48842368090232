import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import { Response } from '../../_base/crud/models/response';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { ProductLicensesModel } from './product-licenses.model';
// Actions
import * as LicensesActions from './product-licenses.actions';
import * as CharacteristicActions from '../product-characteristic/product-characteristic.actions';


export interface ProductLicensesState
  extends EntityState<ProductLicensesModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
}

export const adapter: EntityAdapter<ProductLicensesModel> =
  createEntityAdapter<ProductLicensesModel>();

export const initialProductLicensesState: ProductLicensesState =
  adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    errorMessage: '',
  });

const productLicensesReducers = createReducer(
  initialProductLicensesState,
  // Request
  on(LicensesActions.ProductLicensesListRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    progress: 0,
  })),
  on(
    LicensesActions.ProductLicensesListRequestedSuccessfully,
    (state, action) =>
      adapter.addAll(action.productLicenses, {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
      })
  ),
  on(LicensesActions.ProductLicensesListRequestFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // Import
  on(LicensesActions.ProductLicensesImport, (state) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    progress: 0,
  })),
  on(LicensesActions.ProductLicensesImportedSuccessfully, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: null,
    })
  ),
  on(
    LicensesActions.ProductLicensesImportFailed,
    (state, action) => ({
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      error: action.error,
    })
  ),
  on(
    CharacteristicActions.ProductCharacteristicCreationFailed,
    (state, action) => ({
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      error: action.error,
    })
  )
);

export function productLicensesReducer(
  state: ProductLicensesState | undefined,
  action: Action
) {
  return productLicensesReducers(state, action);
}
