import {createFeatureSelector, createSelector} from '@ngrx/store';
import { DiscountCompanyState } from './company-discount.reducers';
import * as fromDiscountCompanies from './company-discount.reducers';
import * as _ from "lodash";



export const selectDiscountCompaniesState = 
    createFeatureSelector<DiscountCompanyState>("companyDiscounts");


export const selectAllDiscountCompanies = createSelector(
    selectDiscountCompaniesState,
    fromDiscountCompanies.selectAllCompaniesDiscounts
);

export const selectDiscountCompaniesIds = createSelector(
    selectDiscountCompaniesState,
    fromDiscountCompanies.selectCompanniesDiscounstIds
);

export const selectDiscountCompaniesTotal = createSelector(
    selectDiscountCompaniesState,
    fromDiscountCompanies.selectCompaniesDiscountsTotal
);


export const selectDiscountsForSelectedCompany = createSelector(
    selectDiscountCompaniesState,
    selectAllDiscountCompanies,
    (companyDiscountsState, discountsEntities) => {
        if (discountsEntities)
            {
                return discountsEntities.filter(discount => discount.companyId == companyDiscountsState.currentCompanyId)
            }
        return null;
    }
        
);

export const selectCurrentCompanyId = createSelector(
    selectDiscountCompaniesState,
    (companiesDiscounts => companiesDiscounts.currentCompanyId)
);

export const selectDiscountCompanyErrorMessage = createSelector(
    selectDiscountCompaniesState,
    (companiesDiscounts => companiesDiscounts.errorMessage)
);
export const selectDiscountCompanyError = createSelector(
    selectDiscountCompaniesState,
    (companiesDiscounts => companiesDiscounts.error)
);

