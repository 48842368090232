import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SupplierModel } from './supplier.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as SuppliersActions from './supplier.action';
import { Response } from '../../_base/crud/models/response';

export interface SupplierState extends EntityState<SupplierModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<SupplierModel> =
  createEntityAdapter<SupplierModel>();

export const initialSupplierState: SupplierState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const SupplierReducer = createReducer(
  initialSupplierState,
  on(SuppliersActions.SuppliersListRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(SuppliersActions.SuppliersListRequestedSuccessfully, (state, action) =>
    adapter.addAll(action.suppliers, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(SuppliersActions.SuppliersListRequestFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // Update
  on(SuppliersActions.UpdateSupplier, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(SuppliersActions.SupplierUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(
      { id: action.supplier.id, changes: action.supplier },
      {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
      }
    )
  ),
  on(SuppliersActions.SupplierUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  /*Deletion*/
  on(SuppliersActions.DeleteSupplier, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(SuppliersActions.SupplierDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.supplierId, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(SuppliersActions.SupplierDeletionFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  /*Creation*/
  on(SuppliersActions.CreateSupplier, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(SuppliersActions.SupplierCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.supplier, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(SuppliersActions.SupplierCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),

  // page
  on(SuppliersActions.SuppliersPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
    lastQuery: action.page,
  })),
  on(SuppliersActions.SuppliersPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.supplier, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      totalCount: action.totalCount,
      showInitWaitingMessage: false,
    })
  ),
  on(SuppliersActions.SuppliersPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  }))
);

export function suppliersReducer(
  state: SupplierState | undefined,
  action: Action
) {
  return SupplierReducer(state, action);
}
