import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import * as FAQActions from './faq.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LayoutUtilsService, MessageType } from '../../_base/crud';
import { QuestionAnswerService } from './question-answer.service';

@Injectable()
export class FaqEffects {
  constructor(
    private actions$: Actions,
    private questionAnswerService: QuestionAnswerService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) {}

  FAQPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.FAQPageRequested),
      switchMap((action) =>
        this.questionAnswerService.GetAllFAQ(action.page).pipe(
          map((faqPage) => {
            return FAQActions.FAQPageLoadedSuccessfully({
              faqs: faqPage.content,
              page: faqPage.totalElements,
              totalCount: faqPage.totalElements,
            });
          }),
          catchError((error) => of(FAQActions.FAQPageLoadFailed({ error })))
        )
      )
    )
  );
  FAQCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.FAQCreated),
      switchMap((action) =>
        this.questionAnswerService.registerFaq(action.faq).pipe(
          map((data) => FAQActions.FAQCreatedSuccessfully({ faq: data })),
          catchError((error) => of(FAQActions.FAQCreationFailed({ error })))
        )
      )
    )
  );
  FAQUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.FAQUpdated),
      switchMap((action) =>
        this.questionAnswerService.updateFaq(action.faq, action.faq.id).pipe(
          map((faq) =>
            FAQActions.FAQUpdatedSuccessfully({
              faq,
              partialFAQ: action.partialFAQ,
            })
          ),
          catchError((error) => of(FAQActions.FAQUpdateFailed({ error })))
        )
      )
    )
  );

  FAQDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.FAQDeleted),
      switchMap((action) =>
        this.questionAnswerService.delete(action.FAQId).pipe(
          map(() => FAQActions.FAQDeletedSuccessfully({ FAQId: action.FAQId })),
          catchError((error) => of(FAQActions.FAQDeleteFailed({ error })))
        )
      )
    )
  );
}
