import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import {
  selectAboutsInitWaitingMessage,
  selectAboutsInStore,
  selectAboutsPageLoading,
} from './about.selector';
import { BaseDataSource, QueryResultsModel } from '../_base/crud';
import { AboutService } from './about.service';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { DataSource } from '@angular/cdk/collections';
import { QuestionAnswerModel } from '../service-client/questionanswer/questionanswer.model';
import { AboutModel } from './about.model';

/**
 * Datasource that doesnt use NGRX
 * check this course https://blog.angular-university.io/angular-material-data-table/
 */

export class AboutDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectAboutsPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectAboutsInitWaitingMessage)
    );

    this.store
      .pipe(select(selectAboutsInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
