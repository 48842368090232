import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on, Store } from '@ngrx/store';
import { QueryParamsModel } from '../_base/crud';
// actions
import * as BlogActions from './blog.action';
import { BlogModel } from './blog.model';
import { BlogCreationFailed } from './blog.action';

export interface BlogState extends EntityState<BlogModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  lastCreatedBlogId: number;
  lastQuery: QueryParamsModel;
  totalCount: number;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<BlogModel> =
  createEntityAdapter<BlogModel>();

export const initialBlogState: BlogState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  lastCreatedBlogId: undefined,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const BlogReducer = createReducer(
  initialBlogState,
  on(BlogActions.BlogsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),

  on(BlogActions.BlogsPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.blogs, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(BlogActions.BlogsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // creation
  on(BlogActions.BlogCreated, (state) => ({
    ...state,
    actionsLoading: false,
    lastAction: '',
  })),
  on(BlogActions.BlogCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.Blog, {
      ...state,
      actionsLoading: true,
      lastCreatedBlogId: action.Blog.id,
      lastAction: 'EDIT.ADD_MESSAGE',
      progress: 0,
    })
  ),
  on(BlogCreationFailed, (state: BlogState, action) => {
    let errorMessage: string;
    switch (action.error.status) {
      case 500:
        errorMessage = 'EDIT.ERROR.SERVER';
        break;
      case 403:
        errorMessage = 'AUTH.VALIDATION.NOT_AUTHORIZED';
        break;
      case 404:
        errorMessage = 'EDIT.ERROR.NOT_FOUND';
        break;
      default:
        errorMessage = 'EDIT.ERROR.UNKNOWN';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage: errorMessage,
      error: action.error,
    };
  }),
  //Update

  //Delete
  on(BlogActions.BlogDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(BlogActions.BlogDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.BlogId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(BlogActions.BlogDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  })),
  //Blog Resource Delete
  on(BlogActions.BlogDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(BlogActions.BlogDeletedSuccessfully, (state, action) => ({
    ...state,
    actionsLoading: false,
    lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
  })),
  on(BlogActions.BlogDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  }))
);

export function BlogsReducer(state: BlogState | undefined, action: Action) {
  return BlogReducer(state, action);
}
