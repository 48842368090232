import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OdooStockModel } from '../../../../../../../../core/e-commerce/OdooStocks/OdooStockModel';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { SupplierModel } from '../../../../../../../../core/e-commerce/suppliers/supplier.model';
import { Observable, Subscription } from 'rxjs';
import { ProductModel } from '../../../../../../../../core/e-commerce/products/product.model';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../../../../../core/_base/layout';
import { LayoutUtilsService } from '../../../../../../../../core/_base/crud';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../core/reducers';
import { ProductsService } from '../../../../../../../../core/e-commerce/products/products.service';
import { Actions } from '@ngrx/effects';

@Component({
  selector: 'kt-achat-stock',
  templateUrl: './achat-stock.component.html',
  styleUrls: ['./achat-stock.component.scss']
})
export class AchatStockComponent implements OnInit {
  dataSource: MatTableDataSource<OdooStockModel> = new MatTableDataSource([]);

  displayedColumns = [
    'id',
    'supplier',
    'quantity',
    'price',
  ];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  filterStatus = '';
  error: any = null;


  productId: number;
  stockForm: FormGroup;

  editStockLine = -1; // 0 if new stock
  loadingAfterSubmit = false;
  productPhysique = true;

  customCurrencyMaskConfig = {
    align: 'left',
    prefix: '',
    suffix: ' €',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };
  data: any[] = [];
  fileInput: ElementRef;



  selectedSupplier: SupplierModel;
  suppliers: SupplierModel[];
  suggestedSuppliers: SupplierModel[] = [];
  importError: Observable<any>;
  loadingKeys: Observable<boolean>;
  product: ProductModel;



  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private productService: ProductsService,
    private actions$: Actions,
  ) {}

  ngOnInit(): void {
    const routesubscription = this.activatedRoute.params.subscribe((res) => {
      this.productId = res.id;
      this.productService.getACHATStocks(this.productId).subscribe((stocks) => {
        this.dataSource.data = stocks;
      });
    });
    this.productService.getById(this.productId).subscribe(value => {
      if (!value.physical) {
        this.productPhysique = false;
      }
      this.product = value;
    });
    this.subscriptions.push(routesubscription);
    this.stockForm = this.fb.group({
      id: [null],
      supplier: [null, Validators.required],
      quantity: [null, Validators.required],
      price: [null, Validators.required],
    });
  }
  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'IN_STOCK':
        return 'success';
      case 'OUT_OF_STOCK':
        return 'danger';
      case 'ON_COMMAND':
        return 'metal';
    }
    return '';
  }
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

}
