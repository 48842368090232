import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { CompanyModel } from '../../../../../../../core/e-commerce/companies/company.model';
import { AddressModel } from '../../../../../../../core/e-commerce/companies/address.model';
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../../core/_base/crud';
import { AppState } from '../../../../../../../core/reducers';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import {
  selectCompaniesActionLoading,
  selectCompanyById,
  selectLastCreatedCompanyId,
} from '../../../../../../../core/e-commerce/companies/company.selectors';
import * as CompanyActions from '../../../../../../../core/e-commerce/companies/company.actions';
import { CompaniesService } from '../../../../../../../core/e-commerce/companies/companies.service';
import { DiscountCompanyApiActions, DiscountCompanyPageActions } from '../../../../../../../core/e-commerce/company-promotions/actions';
import { selectDiscountCompaniesTotal } from '../../../../../../../core/e-commerce/company-promotions/company-discount.selectors';
import { map, tap } from 'lodash';


@Component({
  selector: 'kt-user-edit',
  templateUrl: './company-edit.component.html',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
  styles: [
    `
      mat-horizontal-stepper::ng-deep .mat-horizontal-stepper-header-container {
        max-width: 75%;
        margin: auto;
      }
      .odoo-message{
          font-size: 15px ;
          background-color: red ;
          color: white;
          width: 100%;
          margin-right: 90px;
      }
    `,
  ],
})
export class CompanyEditComponent implements OnInit, OnDestroy {
  company: CompanyModel;
  company$: Observable<CompanyModel>;
  oldCompany: CompanyModel;
  selectedTab = 0;
  loading$: Observable<boolean>;
  addressesSubject$ = new BehaviorSubject<AddressModel[]>([]);
  companyForm: FormGroup;
  hasFormErrors = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userFB: FormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private layoutConfigService: LayoutConfigService,
    public companyService: CompaniesService
  ) {}

  ngOnInit() {
    this.loading$ = this.store.pipe(select(selectCompaniesActionLoading));

    const selection$ = this.store.select(selectDiscountCompaniesTotal);
    selection$.subscribe(
      total => {
        if (total === 0) {
          this.store.dispatch(DiscountCompanyPageActions.loadCompaniesDiscounts());
        }
        return total;
      }
    );

    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      if (id && id > 0) {
        /* Load store data if it's empty */

        // Update the Current Selected Company
        this.store.dispatch(DiscountCompanyPageActions.getDiscountsForSingleCompany({companyId: id}));

        this.company$ = this.store.select(selectCompanyById(id));
        this.store.pipe(select(selectCompanyById(id))).subscribe((res) => {
          if (res) {
            console.log('hey', res);
            this.company = res;
            this.oldCompany = res;
            this.addressesSubject$.next(this.company.addresses);
            this.oldCompany = Object.assign({}, this.company);
            this.initUser();
          } else {
            this.companyService.getById(id).subscribe((value) => {
              this.company = value;
              this.oldCompany = value;
              this.addressesSubject$.next(this.company.addresses);
              this.oldCompany = Object.assign({}, this.company);
              this.initUser();
            });
          }
        });
      } else {
        this.company = new CompanyModel();
        this.addressesSubject$.next(this.company.addresses);
        this.oldCompany = Object.assign({}, this.company);
        this.initUser();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  /**
   * Init user
   */
  initUser() {
    this.createForm();
    if (!this.company.id) {
      this.subheaderService.setTitle('Create user');
      this.subheaderService.setBreadcrumbs([
        { title: 'Company Management', page: `company-management` },
        { title: 'Companies', page: `company-management/companies` },
        { title: 'Create company', page: `company-management/companies/add` },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit company');
    this.subheaderService.setBreadcrumbs([
      { title: 'Company Management', page: `company-management` },
      { title: 'Companies', page: `user-management/companies` },
      {
        title: 'Edit company',
        page: `companies-management/companies/edit`,
        queryParams: { id: this.company.id },
      },
    ]);
  }

  /**
   * Create form   "((?=(.*\\d){11})[a-zA-Z0-9]{13,})"
   */
  createForm() {
    this.companyForm = this.userFB.group({
      name: [this.company.name, Validators.required],
      accountNumber: [this.company.accountNumber],
      siret: [
        this.company.siret,
        [
          Validators.minLength(14),
          Validators.maxLength(14),
          Validators.required,
        ],
      ],
      siren: [
        this.company.siren,
        [Validators.minLength(9), Validators.maxLength(9), Validators.required],
      ],
      tvanumber: [
        this.company.tvanumber,
        [
          Validators.pattern('(?=(.*\\d){11})[a-zA-Z0-9]{13}'),
          Validators.required,
        ],
      ],
      nafcode: [
        this.company.nafcode,
        [
          Validators.pattern('(?=(.*\\d){4})[a-zA-Z0-9]{5}'),
          Validators.required,
        ],
      ],
      activationStatus: [this.company.activationStatus, Validators.required],
      phone: [this.company.phone, Validators.required],
      globalDiscount: [this.company.id ? this.company.globalDiscount : 0],
      globalTurnover: ['null'],
    });
  }

  /**
   * Redirect to list
   *
   */
  goBackWithId() {
    const url = `/ecommerce/companies`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  goBackWithoutId() {
    this.router.navigateByUrl(
      '/ecommerce/companies',
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  /**
   * Refresh user
   *
   * @param isNew: boolean
   * @param id: number
   */
  refreshUser(isNew: boolean = false, id = 0) {
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `/ecommerce/companies/edit/${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  /**
   * Reset
   */
  reset() {
    this.company = Object.assign({}, this.oldCompany);
    this.createForm();
    this.hasFormErrors = false;
    this.companyForm.markAsPristine();
    this.companyForm.markAsUntouched();
    this.companyForm.updateValueAndValidity();
  }

  /**
   * Save data
   *
   * @param withBack: boolean
   */
  onSumbit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.companyForm.controls;
    /** check form */
    if (this.companyForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    const editedUser = this.prepareCompany();

    if (editedUser.id > 0) {
      this.updateUser(editedUser, withBack);
      return;
    }

    this.addUser(editedUser, withBack);
  }

  /**
   * Returns prepared data for save
   */
  prepareCompany(): CompanyModel {
    const controls = this.companyForm.controls;
    const _company = new CompanyModel();
    _company.addresses = this.addressesSubject$.value;
    _company.id = this.company.id;
    _company.name = controls.name.value;
    _company.activationStatus = controls.activationStatus.value;
    _company.nafcode = controls.nafcode.value;
    _company.tvanumber = controls.tvanumber.value;
    _company.accountNumber = undefined;
    _company.globalDiscount = controls.globalDiscount.value;
    _company.globalTurnover = controls.globalTurnover.value;
    _company.siren = controls.siren.value;
    _company.siret = controls.siret.value;
    _company.phone = controls.phone.value;
    _company.status = this.company.status;
    return _company;
  }

  /**
   * Add User
   *
   * @param _company: CompanyModel
   * @param withBack: boolean
   */
  addUser(_company: CompanyModel, withBack: boolean = false) {
    _company.id = undefined;
    _company.status = 'prospect';
    _company.client = this.companyService.client;
    this.store.dispatch(CompanyActions.CompanyCreated({ company: _company }));

    const addSubscription = this.store
      .pipe(select(selectLastCreatedCompanyId))
      .subscribe((newId) => {
        this.company.id = newId;
        const message = `La nouvelle société a été créée avec succès`;
        this.layoutUtilsService.showActionNotification(
          message,
          MessageType.Create,
          5000,
          true,
          true
        );
        if (newId) {
          if (withBack) {
            this.goBackWithId();
          } else {
            this.refreshUser(true, newId);
          }
        }
      });
    this.subscriptions.push(addSubscription);
  }

  /**
   * Update user
   *
   * @param _company: Company
   * @param withBack: boolean
   */
  updateUser(_company: CompanyModel, withBack: boolean = false) {
    // Update Company
    const updatedCompany: Update<CompanyModel> = {
      id: _company.id,
      changes: _company,
    };
    this.store.dispatch(
      CompanyActions.CompanyUpdated({
        company: _company,
        partialCompany: updatedCompany,
      })
    );
    const message = `Les informations de la société sont mises à jour`;
    this.layoutUtilsService.showActionNotification(
      message,
      MessageType.Update,
      5000,
      true,
      true
    );
    if (withBack) {
      this.goBackWithId();
      this.refreshUser(false);
    } else {
      this.refreshUser(false);
    }
  }

  /**
   * Returns component title
   */
  getComponentTitle() {
    let result = 'Créer une nouvelle société';
    if (!this.company || !this.company.id) {
      return result;
    }

    result = `Société > ${this.company.name}` + ' > liste des collaborateurs';
    return result;
  }

  /**
   * Close Alert
   *
   * @param $event: Event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  goToOdoo() {
    window.open(`https://imtech.dev.odoo.wissal-group.com/showcompany?tva=${this.company.tvanumber}`, '_blank');
  }
}
