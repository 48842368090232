import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuotationItem, QuotationModel } from './quotation.model';
import { Page } from '../../_base/crud/models/page';
import { QueryParamsModel } from '../../_base/crud';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class QuotationService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}quotation`;
  }

  public getAllQuotations(
    queryParams: QueryParamsModel
  ): Observable<Page<QuotationModel>> {
    let params = new HttpParams();
    params = params.append('page', queryParams.pageNumber.toString());
    params = params.append('size', queryParams.pageSize.toString());
    params = params.append(
      'search',
      queryParams.filter.query ? queryParams.filter.query : ''
    );
    params = queryParams.filter.status
      ? params.append('status', queryParams.filter.status)
      : params;

    const options = { params };
    const url = `${this.url}/page`;
    return this.http.get<Page<QuotationModel>>(url, options);
  }

  public getById(id: number): Observable<QuotationModel> {
    const url = `${this.url}/${id}`;
    return this.http.get<QuotationModel>(url);
  }

  public deleteQuotationItemById(id: number): Observable<void> {
    const url = `${this.url}/item/${id}`;
    return this.http.delete<void>(url);
  }

  public updateQuotationItem(
    id: number,
    payload: { price: number; quantity: number }
  ): Observable<QuotationItem> {
    const url = `${this.url}/item/${id}`;
    return this.http.put<QuotationItem>(url, payload);
  }

  public addQuotationItem(
    quotationId: number,
    payload: any
  ): Observable<QuotationItem> {
    const url = `${this.url}/${quotationId}/item`;
    return this.http.post<QuotationItem>(url, payload);
  }

  public sendQuotationViaEmail(id: number, mail: string): Observable<any> {
    const url = `${this.url}/pdf/${id}?mail=${mail}`;
    return this.http.get<any>(url);
  }

  public archiveQuotation(id: number): Observable<QuotationModel> {
    const url = `${this.url}/archive/${id}`;
    return this.http.post<QuotationModel>(url, null);
  }

  public getArchivedQuotations(
    queryParams: QueryParamsModel
  ): Observable<Page<QuotationModel>> {
    let params = new HttpParams();
    params = params.append('page', queryParams.pageNumber.toString());
    params = params.append('size', queryParams.pageSize.toString());
    params = params.append(
      'search',
      queryParams.filter.query ? queryParams.filter.query : ''
    );
    params = queryParams.filter.status
      ? params.append('status', queryParams.filter.status)
      : params;

    const options = { params };
    const url = `${this.url}/archived`;
    return this.http.get<Page<QuotationModel>>(url, options);
  }

  unarchiveQuotation(id: number) {
    const url = `${this.url}/unarchive/${id}`;
    return this.http.post<QuotationModel>(url, null);
  }
  updateQuotation(payload: any) {
    const url = `${this.url}`;
    return this.http.put<QuotationModel>(url, payload);
  }
  addNewQuotation(payload: any) {
    const url = `${this.url}/add`;
    return this.http.post<QuotationModel>(url, payload);
  }
  downloadQuotation(id: number): Observable<Blob>{
    const url = `${this.url}/download/pdf/${id}`;
    return this.http.get(url, { responseType: 'blob'});
  }
}
