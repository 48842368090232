import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { DiscountModel } from '../../../../../../core/e-commerce/promotions/discount.model';
import { PromotionModel } from '../../../../../../core/e-commerce/promotions/promotion.model';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { PromotionsService } from '../../../../../../core/e-commerce/promotions/promotions.service';
import { LayoutUtilsService } from '../../../../../../core/_base/crud';
import { finalize } from 'rxjs/operators';
import { DiscountsService } from '../../../../../../core/e-commerce/promotions/discounts.service';
import { ProductCharacteristicsEditDialogComponent } from '../../products/product-edit/subs/product-characteristics/product-characteristics-edit-dialog/product-characteristics-edit-dialog.component';
import { DiscountProductDialogComponent } from './discount-product-dialog/discount-product-dialog.component';
import { ProductModel } from '../../../../../../core/e-commerce/products/product.model';
import { CurrentPromotionComponent } from '../../promo-code/current-promotion/current-promotion.component';

@Component({
  selector: 'kt-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss'],
})
export class DiscountsComponent implements OnInit, AfterViewInit {
  discountForm: FormGroup;
  dataSource: MatTableDataSource<DiscountModel> = new MatTableDataSource([]);
  displayedDiscountColumns = [
    'select',
    'id',
    'type',
    'value',
    'startDate',
    'endDate',
    'actions',
  ];
  editDiscountLine = -1;
  loadingAfterSubmit = false;
  loadingDiscounts = false;
  max = 100;
  min = 1;
  private subscriptions: Subscription[] = [];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private discountService: DiscountsService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.discountForm = this.fb.group({
      id: [null],
      discountType: ['', Validators.required],
      value: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
    });

    this.getDiscounts();

    this.discountForm.get('value').valueChanges.subscribe((value) => {
      if (value < this.min || value > this.max) {
        const clampedValue = Math.min(this.max, Math.max(this.min, value));
        this.discountForm.get('value').setValue(clampedValue);
      }
    });
  }

  onChange: any = () => {};

  getDiscounts() {
    this.loadingDiscounts = true;
    this.discountService
      .getAllDiscounts()
      .pipe(finalize(() => (this.loadingDiscounts = false)))
      .subscribe((rep) => {        
        this.dataSource.data = rep.content;
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  createDiscount() {
    this.editDiscountLine = 0;
    this.discountForm.reset();
  }

  saveNewDiscount() {
    this.loadingAfterSubmit = true;
    const payload: DiscountModel = {
       id: null,
       discountType : this.discountForm.get('discountType').value,
       value: this.discountForm.get('value').value,
       startDate: this.discountForm.get('startDate').value,
       endDate:this.discountForm.get('endDate').value,
       products : null,
    };
    this.discountService.saveDescounts(payload.id , 
                                      payload.discountType,
                                      payload.startDate,
                                      payload.endDate,
                                      payload.value,
                                      payload.products)
                    .pipe(finalize(() => this.loadingAfterSubmit = false))
                    .subscribe(
                      r => {                               
                        this.cancelEditDiscount();
                        this.getDiscounts();                      
                      },
                      e => {
                        if(e.error){
                          this.dialog.open(CurrentPromotionComponent, {
                            data: e.error,
                            height : 'auto',
                          });
                        }
                        this.cancelEditDiscount();
                      }
                    )   
                    // this.discountService.save                 
  }

  editDiscount(item: DiscountModel) {
    this.editDiscountLine = item.id;
    this.discountForm.get('id').setValue(item.id);
    this.discountForm.get('discountType').setValue(item.discountType);
    this.discountForm.get('value').setValue(item.value);
    this.discountForm.get('startDate').setValue(new Date(item.startDate));
    this.discountForm.get('endDate').setValue(new Date(item.endDate));
  }

  deleteDiscount(item: DiscountModel) {
    const _title = 'Supprimer le discount';
    const _description = 'Voulez-vous vraiment supprimer la remise ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.discountService.delete(item.id).subscribe(() => {
        this.dataSource.data = this.dataSource.data.filter(
          (p) => p.id != item.id
        );
      });
    });
  }

  saveEditedDiscount(item: DiscountModel) {
    this.loadingAfterSubmit = true;
    const discount: DiscountModel = {
      id: this.discountForm.value.id,
      discountType: this.discountForm.value.discountType,
      value: this.discountForm.value.value,
      startDate: this.discountForm.value.startDate,
      endDate: this.discountForm.value.endDate,
    };
    this.discountService
      .updateDiscount(discount)
      .pipe(finalize(() => (this.loadingAfterSubmit = false)))
      .subscribe((rep) => {
        this.dataSource.data.forEach((d) => {
          if (d.id == item.id) {
            d.discountType = this.discountForm.value.discountType;
            d.value = this.discountForm.value.value;
            d.startDate = this.discountForm.value.startDate;
            d.endDate = this.discountForm.value.endDate;
          }
        });

        this.cancelEditDiscount();
      });
  }

  cancelEditDiscount() {
    this.editDiscountLine = -1;
    this.discountForm.reset();
  }

  showDiscountProducts(item: DiscountModel) {
    this.dialog.open(DiscountProductDialogComponent, {
      data: item,
    });
  }
}
