import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { fromEvent, merge, observable, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import {
  LayoutUtilsService, MessageType,
  QueryParamsModel
} from '../../../../../core/_base/crud';
import {
  CreateSupplier,
  DeleteSupplier,
  UpdateSupplier,
} from '../../../../../core/e-commerce/suppliers/supplier.action';
import {
  selectSuppliersInStore,
  selectSuppliersPageLastQuery
} from '../../../../../core/e-commerce/suppliers/supplier.selector';
import { SupplierModel } from '../../../../../core/e-commerce/suppliers/supplier.model';
import { SuppliersDataSource } from '../../../../../core/e-commerce/suppliers/suppliers.datasource';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  skip,
  startWith,
  tap,
} from 'rxjs/operators';
import { SupplierActions } from '../../../../../core/e-commerce';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { Update } from '@ngrx/entity';
import { selectCategoriesInStore } from '../../../../../core/e-commerce/categories/category.selector';

@Component({
  selector: 'kt-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss'],
})
export class SuppliersComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('sort2', { static: true }) sort2: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  supplierForm: FormGroup;
  dataSource: SuppliersDataSource;

  myControl = new FormControl();
  options: string[] = ['ACTIVE', 'NOT_ACTIVE'];
  filteredOptions: Observable<string[]>;
  suppliers: SupplierModel[];
  name: string[];

  displayedColumns = [
    'select',
    'id',
    'supplier',
    'email',
    'website',
    'phone',
    'shippingCost',
    'accountNumber',
    'outstanding',
    'actions',
  ];

  editSupplierLine = -1;
  loadingAfterSubmit = false;

  loading$: Observable<boolean>;

  suppliersResult: SupplierModel[] = [];

  lastQuery: QueryParamsModel;

  selection = new SelectionModel<SupplierModel>(true, []);

  private subscriptions: Subscription[] = [];
  oneOrZero = '';

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    this.supplierForm = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      email: ['', Validators.required],
      status: ['', Validators.required],
      website: ['', Validators.required],
      phone: ['', Validators.required],
      outstanding: ['', Validators.required],
      accountNumber: ['', Validators.required],
      shippingCost: ['', Validators.required],
    });

    this.paginator._changePageSize(10);

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadSuppliersList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    /* search*/
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadSuppliersList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    /**/
    this.loadSuppliersList();
    // Init DataSource
    this.dataSource = new SuppliersDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.suppliersResult = res;
      });
    this.subscriptions.push(entitiesSubscription);
    /**/

    const lastQuerySubscription = this.store
      .pipe(select(selectSuppliersPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    this.loadSuppliersList(this.lastQuery);
    // Read from URL itemId, for restore previous state

    const routeSubscription = this.activatedRoute.queryParams.subscribe(() => {
      const pageSize = this.lastQuery.pageSize;
      const pageNumber = this.lastQuery.pageNumber;
      this.paginator._changePageSize(pageSize);
      this.paginator.pageIndex = pageNumber;
      this.loadSuppliersList(this.lastQuery);
    });

    this.subscriptions.push(
      lastQuerySubscription,
      entitiesSubscription,
      routeSubscription,
      sortSubscription,
      searchSubscription
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  createSupplier() {
    this.editSupplierLine = 0;
  }

  loadSuppliersList(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }
    queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.store.dispatch(
      SupplierActions.SuppliersPageRequested({
        page: queryParams,
      })
    );
    const suppliers$ = this.store
      .select(selectSuppliersInStore)
      .subscribe((results) => {
        this.suppliers = results.items;
      });
  }
  verifySupplier(value) {
    this.name = this.suppliers.map((c) => c.name );
    for ( let i = 0 ; i < this.name.length; i++) {
      if (value.target.value === this.name[i]) {
      const message = `Ce fournisseur existe déjà`;
      this.layoutUtilsService.showActionNotification(
          message,
          MessageType.Create,
          10000,
          true,
          true
        );
    }
  }}

  filterConfiguration(): string {
    const filter: any = {};
    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  saveNewSupplier() {
    this.loadingAfterSubmit = true;
    const supplier: SupplierModel = {
      id: this.supplierForm.value.id,
      name: this.supplierForm.value.name,
      email: this.supplierForm.value.email,
      status: this.supplierForm.value.status,
      website: this.supplierForm.value.website,
      phone: this.supplierForm.value.phone,
      shippingCost: this.supplierForm.value.shippingCost,
      accountNumber: this.supplierForm.value.accountNumber,
      outstanding: this.supplierForm.value.outstanding,
    };
    this.store.dispatch(CreateSupplier({ supplier }));
    setTimeout(() => {
      this.loadingAfterSubmit = false;
      this.cancelEditSupplier();
    }, 1000); // TODO use Store State
  }

  editSupplier(item: SupplierModel) {
    this.editSupplierLine = item.id;
    this.supplierForm.get('id').setValue(item.id);
    this.supplierForm.get('name').setValue(item.name);
    this.supplierForm.get('email').setValue(item.email);
    this.supplierForm.get('status').setValue(item.status);
    this.supplierForm.get('website').setValue(item.website);
    this.supplierForm.get('phone').setValue(item.phone);
    this.supplierForm.get('shippingCost').setValue(item.shippingCost);
    this.supplierForm.get('accountNumber').setValue(item.accountNumber);
    this.supplierForm.get('outstanding').setValue(item.outstanding);
  }

  deleteSupplier(item: SupplierModel) {
    const _title = 'Supprimer la frounisseur';
    const _description = 'Voulez-vous vraiment supprimer le fournisseur ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(DeleteSupplier({ supplierId: item.id }));
    });
  }

  saveEditedSupplier() {
    this.loadingAfterSubmit = true;
    const supplier: SupplierModel = {
      id: this.supplierForm.value.id,
      name: this.supplierForm.value.name,
      email: this.supplierForm.value.email,
      status: this.supplierForm.value.status,
      website: this.supplierForm.value.website,
      phone: this.supplierForm.value.phone,
      shippingCost: this.supplierForm.value.shippingCost,
      accountNumber: this.supplierForm.value.accountNumber,
      outstanding: this.supplierForm.value.outstanding,
    };
    this.store.dispatch(UpdateSupplier({ supplier }));
    setTimeout(() => {
      this.loadingAfterSubmit = false;
      this.cancelEditSupplier();
    }, 1000); // TODO use Store State
  }

  cancelEditSupplier() {
    this.editSupplierLine = -1;
    this.supplierForm.reset();
  }
  changeSupplierStatus(value, supplier) {
    const updatedSup = Object.assign({}, supplier);
    updatedSup.status = value.checked;
    const updateSupplier: Update<SupplierModel> = {
      id: supplier.id,
      changes: updatedSup,
    };
    this.store.dispatch(
      SupplierActions.ChangeSupplierStatus(
        {
          partialSupplier: updateSupplier,
          supplier: updatedSup,
        }
      )
    );
  }

}
