import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AddressModel } from '../../../../../../../../core/e-commerce/companies/address.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { AppState } from '../../../../../../../../core/reducers';
import { Store } from '@ngrx/store';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../../../core/_base/crud';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../../../../../../../core/e-commerce/clients/client.service';
import { ClientAddressEditDialogComponent } from '../client-address-edit-dialog/client-address-edit-dialog.component';

@Component({
  selector: 'kt-client-address-list',
  templateUrl: './client-address-list.component.html',
  styleUrls: ['./client-address-list.component.scss'],
})
export class ClientAddressListComponent implements OnInit, OnDestroy {
  @Input() addresses$: BehaviorSubject<AddressModel[]>;

  dataSource = [];

  displayedColumns = [
    'id',
    'country',
    'city',
    'streetName',
    'postalCode',
    'type',
  ];

  private subscriptions: Subscription[] = [];
  private clientId: number;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute,
    private clientService: ClientService
  ) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.params.id;
    const addressesSub = this.addresses$.subscribe((res) => {
      if(res){
        this.dataSource = res ? res : [];
      }
    });

    this.subscriptions.push(addressesSub);
  }

  deleteAddress(_ITEM: AddressModel) {
    const _TITLE = 'Supprimer adresse';
    const _DESCRIPTION =
      'Êtes-vous sûr de supprimer définitivement cette adresse?';
    const _WAIT_DESCRIPTION = 'Suppression...';
    const _DELETE_MESSAGE = `Adresse supprimée avec succès`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _TITLE,
      _DESCRIPTION,
      _WAIT_DESCRIPTION
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.clientService
          .deleteAddressBook(this.clientId, _ITEM.id)
          .subscribe((_) => {
            const afterDelete = this.addresses$.value.filter(
              (elem) => elem !== _ITEM
            );
            this.addresses$.next(afterDelete);
            this.layoutUtilsService.showActionNotification(
              _DELETE_MESSAGE,
              MessageType.Delete
            );
          });
      }
    });
  }

  editAddress(item?: AddressModel) {
    const _ITEM = Object.assign({}, item);
    const dialogRef = this.dialog.open(ClientAddressEditDialogComponent, {
      data: { address: _ITEM, clientId: this.clientId },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let newAddressList;
        if (item) {
          newAddressList = this.dataSource.map(
            (obj) => [res].find((o) => o.id === obj.id) || obj
          );
          const saveMessage = `Adresse mise à jour avec succès`;
          this.layoutUtilsService.showActionNotification(
            saveMessage,
            MessageType.Update,
            3000,
            true,
            true
          );
        } else {
          newAddressList = this.dataSource.concat([res]);
          const saveMessage = `Adresse créée avec succès`;
          this.layoutUtilsService.showActionNotification(
            saveMessage,
            MessageType.Create,
            3000,
            true,
            true
          );
        }
        this.addresses$.next(newAddressList);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
  }
}
