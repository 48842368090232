import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AddressModel } from '../../../../../../../../../core/e-commerce/companies/address.model';
import { CompaniesService } from '../../../../../../../../../core/e-commerce/companies/companies.service';
import { LayoutUtilsService } from '../../../../../../../../../core/_base/crud';
import { CountryModel } from '../../../../../../../../../core/_models/country.model';
import { CountryService } from '../../../../../../../../../core/services/country.service';
import { map, startWith, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'kt-address-edit-dialog',
  templateUrl: './address-edit-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressEditDialogComponent implements OnInit {
  addressEditForm: FormGroup;
  viewLoading = true;
  loadingAfterSubmit = false;
  address: AddressModel;
  newAddress: AddressModel;

  countries: CountryModel[];
  countries$: Observable<CountryModel[]> = this.countryService
    .countries()
    .pipe(take(1));

  constructor(
    public dialogRef: MatDialogRef<AddressEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { address: AddressModel; companyId: number; bill: boolean },
    private fb: FormBuilder,
    private companiesService: CompaniesService,
    private countryService: CountryService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit() {
    this.countries$.subscribe((countries) => {
      this.countries = countries;
      this.countries$ = this.addressEditForm.get('country').valueChanges.pipe(
        startWith(''),
        map((value) => {
          return this.countries.filter((country) => {
            return (
              country.name.toLowerCase().includes(value.toLowerCase()) &&
              country.name !== value
            );
          });
        })
      );
    });
    this.initAddressForm();
  }

  initAddressForm() {
    this.addressEditForm = this.fb.group({
      contactName: [this.data.address ? this.data.address.contactName : ''],
      contactPhone: [this.data.address ? this.data.address.contactPhone : ''],
      country: [
        this.data.address
          ? typeof this.data.address.country !== 'string'
            ? this.data.address.country.name
            : this.data.address.country
          : 'France',
      ],
      city: [
        this.data.address ? this.data.address.city : '',
        [Validators.required],
      ],
      streetName: [
        this.data.address ? this.data.address.streetName : '',
        [Validators.required],
      ],
      additionalInfo: [
        this.data.address ? this.data.address.additionalInfo : '',
      ],
      cedex: [this.data.address ? this.data.address.cedex : '', []],
      postalCode: [
        this.data.address ? this.data.address.postalCode : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6),
        ]),
      ],
    });
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Save address
   */
  save() {
    this.newAddress = new AddressModel();
    const controls = this.addressEditForm.controls;
    /** check form */
    if (this.addressEditForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.layoutUtilsService.showActionNotification(
        'Veuillez remplir les champs correctement!'
      );
      return;
    }

    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    this.newAddress.country = this.countries.find(
      (c) => c.name === controls.country.value
    );
    this.newAddress.city = controls.city.value;
    this.newAddress.streetName = controls.streetName.value;
    this.newAddress.postalCode = controls.postalCode.value;
    this.newAddress.additionalInfo = controls.additionalInfo.value;
    this.newAddress.cedex = controls.cedex.value;
    this.newAddress.type = 'delivryAddress';
    this.viewLoading = false;
    this.newAddress.contactName = controls.contactName.value;
    this.newAddress.contactPhone = controls.contactPhone.value;
    if (this.data.bill || !this.data.companyId) {
      this.closeDialog(this.newAddress);
      return;
    }
    if (!this.data.address) {
      this.companiesService
        .addNewAddress(this.data.companyId, this.newAddress)
        .subscribe((res) => {
          this.closeDialog(res);
        });
    } else {
      this.newAddress.id = this.data.address.id;
      this.companiesService.updateAddress(this.newAddress).subscribe((res) => {
        this.closeDialog(res);
      });
    }
  }

  /**
   * Close dialog
   *
   * @param specId: any
   */
  closeDialog(address: AddressModel) {
    this.dialogRef.close(address);
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addressEditForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
