import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { ResourcesService } from '../../../../../../core/services/resources.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
// Actions
import * as LogoActions from '../../../../../../core/home-page/logos/logo.action';
import { LogoModel } from '../../../../../../core/home-page/logos/logo.model';
import { selectLogos } from '../../../../../../core/home-page/logos/logo.selectors';
import {LogosService} from '../../../../../../core/home-page/logos/logos.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'kt-logo-images',
  templateUrl: './partenaires-logo.component.html',
  styleUrls: ['./partenaires-logo.component.scss'],
})
export class PartenairesLogoComponent implements OnInit, OnDestroy {
  // Subscriptions
  private subscriptions: Subscription[] = [];

  private logoToAdd = new LogoModel();
  private positions: number ;
  private subscription: Subscription;
  uploading = false;
  // Progress
  progress = new BehaviorSubject<number>(0);
  logos$: Observable<LogoModel[]>;
  numbers: number[] = [];

  constructor(
    private store: Store<AppState>,
    private resourcesService: ResourcesService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private logosService: LogosService
  ) {}

  ngOnInit() {
    this.store.dispatch(LogoActions.LogosPageRequested());
    this.logos$ = this.store.select(selectLogos).pipe(
        map(logos => this.sortLogosByPosition(logos))
    );

    this.subscription = this.logos$.subscribe(count => {
      this.positions = count.length;
      this.generateNumbers();
    });
  }

  generateNumbers() {
    this.numbers;
    for (let i = 1; i <= this.positions; i++) {
      this.numbers.push(i);
    }
  }

  sortLogosByPosition(logo: LogoModel[]): LogoModel[] {
    return logo.sort((a, b) => a.position - b.position);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  updateLogo(logo: LogoModel, link: string, position: number, visible:boolean) {
    const updatedLogo: LogoModel = {
      id: logo.id,
      title: logo.title,
      imageUrl: logo.imageUrl,
      isActive: visible,
      file: logo.file,
      link,
      position
    };
    this.logosService.update(updatedLogo).subscribe(
        (Logo) => {
          console.log('Logo updated successfully:', Logo);
        },
        (error) => {
          console.error('Error updating logo:', error);
        }
    );
  }

   onSelectedFile(files: FileList) {
    this.logoToAdd.file = files.item(0);
    const resourceSub = this.resourcesService
      .pushFileToStorage(this.logoToAdd.file)
      .subscribe(
        (res) => {
          this.uploading = true;

          // the upload finished
          if (res instanceof HttpResponse) {
            // let newResource = new ProductResource();
            const body = JSON.parse(res.body);
            // preparing the resource infos
            this.logoToAdd.imageUrl = body.url;
            // todo make it dynamic
            this.logoToAdd.isActive = true;
            this.logoToAdd.title = this.logoToAdd.file.name;

            this.store.dispatch(
              LogoActions.LogoCreated({
                logo: this.logoToAdd,
              })
            );
            this.uploading = false;
            const message = `Logo has been added Successfully.`;
            this.layoutUtilsService.showActionNotification(
              message,
              MessageType.Update,
              5000,
              true,
              false
            );
            this.progress.next(0);
            // the upload is in progress
          } else if (res.type === HttpEventType.UploadProgress) {
            this.progress.next(Math.round((100 * res.loaded) / res.total));
          }
        },
        (error) => {
          this.uploading = false;
        }
      );

    this.subscriptions.push(resourceSub);
  }

  onDelete(logo: LogoModel) {
    const _title: string = this.translate.instant(
      'ECOMMERCE.HOME_PAGE.DELETE.IMAGE.TITLE'
    );
    const _description: string = this.translate.instant(
      'ECOMMERCE.HOME_PAGE.DELETE.IMAGE.DESCRIPTION'
    );
    const _waitDesciption: string = this.translate.instant(
      'ECOMMERCE.HOME_PAGE.DELETE.IMAGE.WAIT_DESCRIPTION'
    );

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    const subdialog = dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.resourcesService.delete(logo.imageUrl).subscribe((res) => {
        this.store.dispatch(
          LogoActions.LogoDeleted({
            logoId: logo.id,
          })
        );
        const message = `Image has been deleted Successfully.`;
        this.layoutUtilsService.showActionNotification(
          message,
          MessageType.Update,
          5000,
          true,
          false
        );
      });
    });
    this.subscriptions.push(subdialog);
  }
}
