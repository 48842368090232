import { createAction, props } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { OrderModel } from './order.model';
import { Update } from '@ngrx/entity';

export const OrdersPageRequested = createAction(
  '[Order List Page] Orders Page Requested ',
  props<{ page: QueryParamsModel }>()
);
export const OrdersPageLoadedSuccessfully = createAction(
  '[Order API] Orders Page Loaded Successfully ',
  props<{ orders: OrderModel[]; totalCount: number }>()
);
export const OrdersPageLoadFailed = createAction(
  '[Order API] Orders Page Request Failed ',
  props<{ error: any }>()
);

export const OrderCreated = createAction(
  '[Order List Page] Order Created ',
  props<{ order: OrderModel }>()
);
export const OrderCreatedSuccessfully = createAction(
  '[Order List Page] Order Created Successfully ',
  props<{ order: OrderModel }>()
);
export const OrderCreationFailed = createAction(
  '[Order List Page] Order Creation Failed ',
  props<{ error: any }>()
);

export const OrderUpdated = createAction(
  '[Order List Page] Order Updated ',
  props<{ order: OrderModel; partialOrder: Update<OrderModel> }>()
);
export const OrderUpdatedSuccessfully = createAction(
  '[Order List Page] Order Updated Successfully ',
  props<{ order: OrderModel; partialOrder: Update<OrderModel> }>()
);
export const OrderUpdateFailed = createAction(
  '[Order List Page] Order Update Failed ',
  props<{ error: any }>()
);

export const OrderDeleted = createAction(
  '[Order List Page] Order Deleted ',
  props<{ orderId: number }>()
);
export const OrderDeletedSuccessfully = createAction(
  '[Order List Page] Order deleted Successfully ',
  props<{ orderId: number }>()
);
export const OrderDeleteFailed = createAction(
  '[Order List Page] Order delete Failed ',
  props<{ error: any }>()
);

// export const OrderItemUpdated = createAction('[Order Item] Order Item Updated ', props<{ orderItem: OrderItemModel }>());
// export const OrderItemUpdatedSuccessfully = createAction('[Order Item] Order Item Updated Successfully ', props<{ order: OrderModel }>());
// export const OrderItemUpdateFailed = createAction('[Order Item] Order Item Update Failed ', props<{ error: any }>());
