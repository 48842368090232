import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrderItemsService } from './orderItems.service';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
//Actions
import * as OrderItemActions from './orderItems.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class OrderItemsEffects {
  constructor(
    private actions$: Actions,
    private orderItemService: OrderItemsService,
    private store: Store<AppState>
  ) {}

  OrderItemPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderItemActions.OrderItemsPageRequested),
      switchMap((action) =>
        this.orderItemService
          .getAllPaged(action.page.pageNumber, action.page.pageSize)
          .pipe(
            map((orderItemPage) =>
              OrderItemActions.OrderItemsPageLoadedSuccessfully({
                orderItems: orderItemPage.content,
                page: orderItemPage.totalPages,
                totalCount: orderItemPage.totalPages,
              })
            ),
            catchError((error) =>
              of(OrderItemActions.OrderItemsPageLoadFailed({ error }))
            )
          )
      )
    )
  );

  OrderItemCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderItemActions.OrderItemCreated),
      switchMap((action) =>
        this.orderItemService.save(action.orderItem).pipe(
          map((data) =>
            OrderItemActions.OrderItemCreatedSuccessfully({ orderItem: data })
          ),
          catchError((error) =>
            of(OrderItemActions.OrderItemCreationFailed({ error }))
          )
        )
      )
    )
  );

  OrderItemUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderItemActions.OrderItemUpdated),
      switchMap((action) =>
        this.orderItemService.update(action.orderItem).pipe(
          map((orderItem) =>
            OrderItemActions.OrderItemUpdatedSuccessfully({
              orderItem,
              partialOrderItem: action.partialOrderItem,
            })
          ),
          catchError((error) =>
            of(OrderItemActions.OrderItemUpdateFailed({ error }))
          )
        )
      )
    )
  );

  OrderItemDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderItemActions.OrderItemDeleted),
      switchMap((action) =>
        this.orderItemService.delete(action.orderItemId).pipe(
          map(() =>
            OrderItemActions.OrderItemDeletedSuccessfully({
              orderItemId: action.orderItemId,
            })
          ),
          catchError((error) =>
            of(OrderItemActions.OrderItemDeleteFailed({ error }))
          )
        )
      )
    )
  );
}
