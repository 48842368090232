import {NgModule} from '@angular/core';
import {CommonModule , DatePipe } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {PartialsModule} from '../../../partials/partials.module';
import {ModuleGuard} from '../../../../core/auth';
import {HttpUtilsService, LayoutUtilsService, TypesUtilsService} from '../../../../core/_base/crud';
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
} from '../../../partials/content/crud';
import { MatMomentDateModule,
         MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {ECommerceComponent} from './e-commerce.component';
import {OrdersListComponent} from './orders/orders-list/orders-list.component';
import {OrderEditComponent} from './orders/order-edit/order-edit.component';
import {
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatFormFieldModule,
} from '@angular/material';
import { NgbDatepickerModule, NgbDropdownModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxPermissionsModule} from 'ngx-permissions';
import {CategoriesComponent} from './categories/categories-list/categories.component';
import {categoriesReducer} from '../../../../core/e-commerce/categories/category.reducers';
import {CategoryEffects} from '../../../../core/e-commerce/categories/category.effects';
import {CategoryEditDialogComponent} from './categories/categories-edit/category-edit-dialog.component';
import {ProductsListComponent} from './products/products-list/products-list.component';
import {productsReducer} from '../../../../core/e-commerce/products/product.reducers';
import {ProductEffects} from '../../../../core/e-commerce/products/product.effects';
import {ProductEditComponent} from './products/product-edit/product-edit.component';
import {MatDividerModule} from '@angular/material/divider';
import {
    CategoryCharacteristicListComponent
} from './categories/categories-edit/category-characteristics-list/category-characteristic-list.component';
import {
    categoryCharacteristicsReducer
} from '../../../../core/e-commerce/category-characteristic/category-characteristic.reducer';
import {
    CategoryCharacteristicEffects
} from '../../../../core/e-commerce/category-characteristic/category-characteristic.effects';
import {
    CategoryCharacteristicEditComponent
} from './categories/categories-edit/subs/category-chatacteristics-edit/category-characteristic-edit.component';
import {
    ProductCharacteristicListComponent
} from './products/product-edit/subs/product-characteristics/product-characteristics-list/product-characteristic-list.component';
import {
    productCharacteristicsReducer
} from '../../../../core/e-commerce/product-characteristic/product-characteristic.reducer';
import {
    ProductCharacteristicEditComponent
} from './products/product-edit/subs/product-characteristics/product-chatacteristics-edit/product-characteristic-edit.component';
import {ProductLicensesListComponent} from './products/product-edit/subs/product-licenses/product-licenses-list/product-licenses-list.component';
import {productLicensesReducer} from '../../../../core/e-commerce/product-licenses/product-licenses.reducer';
import {ProductLicensesEffects} from '../../../../core/e-commerce/product-licenses/product-licenses.effects';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {ResourcesEditComponent} from './products/product-edit/subs/resources-edit/resources-edit.component';
import {CarouselImagesComponent} from './home-page/carousel-images/carousel-images.component';
import {carouselsReducer} from '../../../../core/home-page/carousel/carousel.reducers';
import {CarouselEffects} from '../../../../core/home-page/carousel/carousel.effects';
import {PartenairesLogoComponent} from './home-page/partenaires-logo/partenaires-logo.component';
import {logosReducer} from '../../../../core/home-page/logos/logo.reducers';
import {LogoEffects} from '../../../../core/home-page/logos/logo.effects';
import {CompaniesListComponent} from './companies/companies-list/companies-list.component';
import {companiesReducer} from '../../../../core/e-commerce/companies/company.reducers';
import {CompanyEffects} from '../../../../core/e-commerce/companies/company.effects';
import {CompanyEditComponent} from './companies/companies-list/companies-edit/company-edit.component';

import {MatStepperModule} from '@angular/material/stepper';
import {AddressesListComponent} from './companies/companies-list/_subs/addresses/address-list/addresses-list.component';
import {
    AddressEditDialogComponent
} from './companies/companies-list/_subs/addresses/address-edit/address-edit-dialog.component';
import {ClientfunctionListComponent} from './clientfunctions/clientfunctions-list/clientfunction-list.component';
import {clientfunctionsReducer} from '../../../../core/e-commerce/clientfunctions/clientfunction.reducers';
import {ClientfunctionEffects} from '../../../../core/e-commerce/clientfunctions/clientfunction.effects';
import {ordersReducer} from '../../../../core/e-commerce/orders/order.reducers';
import {OrdersEffects} from '../../../../core/e-commerce/orders/orders.effects';
import {manufacturersReducer} from '../../../../core/e-commerce/manufacturers/manufacturer.reducers';
import {ManufacturerEffects} from '../../../../core/e-commerce/manufacturers/manufacturer.effects';
import {StockListComponent} from './products/product-edit/subs/stocks/stocks-list/stock-list.component';
import {OrderlinesComponent} from './orders/order-edit/subs/orderlines.component';
import {orderItemsReducer} from '../../../../core/e-commerce/OrderItems/orderItem.reducers';
import {OrderItemsEffects} from '../../../../core/e-commerce/OrderItems/orderItems.effects';
import {ClientListComponent} from './companies/companies-list/_subs/clients/client-list.component';
import {ClientEditComponent} from './companies/companies-list/_subs/client-update/client-edit.component';
import {MainClientListComponent} from './mainclient/main-client-list/main-client-list.component';
import {
    ProductCharacteristicsEditDialogComponent
// tslint:disable-next-line:max-line-length
} from './products/product-edit/subs/product-characteristics/product-characteristics-edit-dialog/product-characteristics-edit-dialog.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ManufacturersComponent} from './manufacturers/manufacturers.component';
import {QuotationListComponent} from './quotations/quotation-list/quotation-list.component';
import {QuotationDetailComponent} from './quotations/quotation-detail/quotation-detail.component';
import {QuotationEditComponent} from './quotations/quotation-edit/quotation-edit.component';
import {FlexModule} from '@angular/flex-layout';
import {
    QuotationItemListComponent
} from './quotations/quotation-edit/subs/quotation-item-list/quotation-item-list.component';
import {NgxCurrencyModule} from 'ngx-currency';
import {OrderDetailComponent} from './orders/order-detail/order-detail.component';
import {MatTableExporterModule} from 'mat-table-exporter';
import {PromotionsComponent} from './promotions/promotions.component';
import {DiscountsComponent} from './promotions/discounts/discounts.component';
import {
    DiscountProductDialogComponent
} from './promotions/discounts/discount-product-dialog/discount-product-dialog.component';
import {
    PromotionProductDialogComponent
} from './promotions/promotion-product-dialog/promotion-product-dialog.component';
import {
    ProductSearchDropdownComponent
} from './products/product-edit/scrapping/product-search-dropdown/product-search-dropdown.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SendPdfMailComponent} from './send-pdf-mail/send-pdf-mail.component';
import {SuppliersComponent} from './suppliers/suppliers.component';
import {ContactsComponent} from './companies/companies-list/_subs/contacts/contacts.component';
import {
    ClientAddressListComponent
} from './mainclient/main-client-list/addresses/client-address-list/client-address-list.component';
import {
    ClientAddressEditDialogComponent
} from './mainclient/main-client-list/addresses/client-address-edit-dialog/client-address-edit-dialog.component';
import {ContactsSupplierComponent} from './suppliers/contacts-supplier/contacts-supplier.component';
import {AddressesSupplierListComponent} from './suppliers/addresses-supplier/address-list/addresses-list.component';
import {
    AddressSupplierEditDialogComponent
} from './suppliers/addresses-supplier/address-edit/address-edit-dialog.component';
import {
    OutstandingListComponent
} from './companies/companies-list/_subs/outstanding/outstanding-list/outstanding-list.component';
import {
    OutstandingEditDialogComponent
} from './companies/companies-list/_subs/outstanding/oustanding-edit/outstanding-edit-dialog.component';
import {
    ProductSearchComponent
} from './products/product-edit/scrapping/product-search-component/product-search.component';
import {MatListModule} from '@angular/material/list';
import {InlineSVGModule} from 'ng-inline-svg';
import { OdooStocksComponent } from './products/product-edit/subs/edistocks/odoo-stocks.component';
import { AchatStockComponent } from './products/product-edit/subs/achat-stock/achat-stock.component';
import { LogsListComponent } from './odoo/logs-list/logs-list.component';
import {LogsEffects} from '../../../../core/e-commerce/odoo/logs.effects';
import {logsReducer} from '../../../../core/e-commerce/odoo/logs.reducers';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { PromoCodeEditComponent } from './promo-code/promo-code-edit/promo-code-edit.component';
import { PromoProductsListComponent } from './promo-code/promo-products-list/promo-products-list.component';
import { PromoCategoriesListComponent } from './promo-code/promo-categories-list/promo-categories-list.component';
import { CurrentPromotionComponent } from './promo-code/current-promotion/current-promotion.component';
import { LogsEditComponent } from './odoo/logs-edit/logs-edit.component';
import { AccessoireCategorieComponent } from './categories/categories-edit/accessoire-categorie/accessoire-categorie.component';
import {accessoriesReducer } from '../../../../core/e-commerce/categories/accessories/accessories.reducers';
import { AccessorieEffects } from '../../../../core/e-commerce/categories/accessories/accessories.effects';
import { DiscountsCompaniesComponent } from './companies/companies-list/_subs/discounts-companies/discounts-companies.component';
import { DiscountCompaniesEffects } from '../../../../core/e-commerce/company-promotions/company-discount.effects';
import { discountCompaniesReducer } from '../../../../core/e-commerce/company-promotions/company-discount.reducers';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {categoriesOptReducer} from '../../../../core/e-commerce/categories/categoryOpt.reducers';
import {productsReducerOpt} from '../../../../core/e-commerce/products/productOpt.reducers';

// tslint:disable-next-line:class-name
const routes: Routes = [
    {
        path: '',
        component: ECommerceComponent,
        // canActivate: [ModuleGuard],
        // data: { moduleName: 'ecommerce' },
        children: [
            {
                path: '',
                redirectTo: 'customers',
                pathMatch: 'full',
            },
            {
                path: 'carousel',
                component: CarouselImagesComponent,
            },
            {
                path: 'logo',
                component: PartenairesLogoComponent,
            },
            {
                path: 'companies',
                component: CompaniesListComponent,
            },
            {
                path: 'client-functions',
                component: ClientfunctionListComponent,
            },
            {
                path: 'companies/add',
                component: CompanyEditComponent,
            },
            {
                path: 'companies/edit',
                component: CompanyEditComponent,
            },
            {
                path: 'companies/edit/:id',
                component: CompanyEditComponent,
            },
            {
                path: 'companies/:id/clients',
                component: ClientListComponent,
            },
            {
                path: 'companies/:companyid/clients/add',
                component: ClientEditComponent,
            },
            {
                path: 'companies/:companyid/clients/edit',
                component: ClientEditComponent,
            },
            {
                path: 'companies/:companyid/clients/edit/:id',
                component: ClientEditComponent,
            },
            {
                path: 'categories',
                component: CategoriesComponent,
            },
            {
                path: 'mainclient',
                component: MainClientListComponent,
            },
            {
                path: 'mainclient/edit/:id',
                component: ClientEditComponent,
            },
            {
                path: 'mainclient/add',
                component: ClientEditComponent,
            },

            {
                path: 'categories/add',
                component: CategoryEditDialogComponent,
            },
            {
                path: 'categories/edit',
                component: CategoryEditDialogComponent,
            },
            {
                path: 'categories/edit/:id',
                component: CategoryEditDialogComponent,
            },

            {
                path: 'categories/edit/:categoryid/characteristics/add',
                component: CategoryCharacteristicEditComponent,
            },
            {
                path: 'categories/edit/:categoryid/characteristics/edit',
                component: CategoryCharacteristicEditComponent,
            },
            {
                path: 'categories/edit/:categoryid/characteristics/edit/:id',
                component: CategoryCharacteristicEditComponent,
            },
            {
                path: 'products',
                component: ProductsListComponent,
            },
            {
                path: 'products/add',
                component: ProductEditComponent,
            },
            {
                path: 'products/edit',
                component: ProductEditComponent,
            },
            {
                path: 'products/edit/:id',
                component: ProductEditComponent,
            },
            {
                path: 'promotions',
                component: PromotionsComponent,
            },
            {
                path: 'products/edit/:productid/characteristics/add',
                component: ProductCharacteristicEditComponent,
            },
            {
                path: 'products/edit/:productid/characteristics/edit',
                component: ProductCharacteristicEditComponent,
            },
            {
                path: 'products/edit/:productid/characteristics/edit/:id',
                component: ProductCharacteristicEditComponent,
            },
            {
                path: 'manufacturers',
                component: ManufacturersComponent,
            },
            {
                path: 'products/suppliers',
                component: SuppliersComponent,
            },
            {
                path: 'products/suppliers/contacts',
                component: ContactsSupplierComponent,
            },
            {
                path: 'products/suppliers/addresses',
                component: AddressesSupplierListComponent,
            },
            {
                path: 'products/suppliers/addresses/edit',
                component: AddressSupplierEditDialogComponent,
            },
            {
                path: 'promo-code',
                component: PromoCodeComponent,
            },
            {
                path: 'promo-code/add',
                component: PromoCodeEditComponent,
            },
            {
                path: 'promo-code/edit',
                component: PromoCodeEditComponent,
            },
            {
                path: 'promo-code/edit/:id',
                component: PromoCodeEditComponent,
            },
            {
                path: 'orders',
                component: OrdersListComponent,
            },
            {
                path: 'orders/add',
                component: OrderEditComponent,
            },
            {
                path: 'orders/edit',
                component: OrderEditComponent,
            },
            {
                path: 'orders/edit/:id',
                component: OrderEditComponent,
            },
            {
                path: 'quotations',
                component: QuotationListComponent,
            },
            {
                path: 'quotations/edit/:id',
                component: QuotationEditComponent,
            },
            {
                path: 'quotations/add',
                component: QuotationEditComponent,
            },
            {
                path: 'odoo/logs',
                component: LogsListComponent,
            },
            {
                path: 'odoo/logs/edit/:id',
                component: LogsEditComponent,
            },
            {
                path: 'odoo/logs/add',
                component: LogsEditComponent,
            },
        ],
    },
];

@NgModule({
    imports: [
        MatFormFieldModule,
        MatInputModule,
        GalleryModule,
        LightboxModule,
        GallerizeModule,
        MatDialogModule,
        CommonModule,
        HttpClientModule,
        PartialsModule,
        NgxPermissionsModule.forChild(),
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        NgbProgressbarModule,
        StoreModule.forFeature('categories', categoriesReducer),
        StoreModule.forFeature('categories', categoriesOptReducer),
        StoreModule.forFeature('accessories', accessoriesReducer),
        EffectsModule.forFeature([CategoryEffects]),
        EffectsModule.forFeature([AccessorieEffects]),
        StoreModule.forFeature('manufacturers', manufacturersReducer),
        EffectsModule.forFeature([ManufacturerEffects]),
        StoreModule.forFeature(
            'categoryCharacteristics',
            categoryCharacteristicsReducer
        ),
        EffectsModule.forFeature([CategoryCharacteristicEffects]),
        // Producs
        StoreModule.forFeature('products', productsReducer),
        StoreModule.forFeature('products', productsReducerOpt),
        EffectsModule.forFeature([ProductEffects]),
        StoreModule.forFeature(
            'productCharacteristics',
            productCharacteristicsReducer
        ),
        EffectsModule.forFeature([ProductLicensesEffects]),
        StoreModule.forFeature('productLicenses', productLicensesReducer),
        EffectsModule.forFeature([ProductLicensesEffects]),
        // Companies
        StoreModule.forFeature('companies', companiesReducer),
        EffectsModule.forFeature([CompanyEffects]),

        // Companies Discounts
        StoreModule.forFeature('companyDiscounts', discountCompaniesReducer),
        EffectsModule.forFeature([DiscountCompaniesEffects]),
        // Orders
        StoreModule.forFeature('orders', ordersReducer),
        EffectsModule.forFeature([OrdersEffects]),
        // Orders
        StoreModule.forFeature('orderItems', orderItemsReducer),
        EffectsModule.forFeature([OrderItemsEffects]),
        // ClientFunctions
        StoreModule.forFeature('clientfunctions', clientfunctionsReducer),
        EffectsModule.forFeature([ClientfunctionEffects]),
        // Carousels
        StoreModule.forFeature('carousels', carouselsReducer),
        EffectsModule.forFeature([CarouselEffects]),
        // Logos
        StoreModule.forFeature('logos', logosReducer),
        EffectsModule.forFeature([LogoEffects]),
        // Logs
        StoreModule.forFeature('logs', logsReducer),
        EffectsModule.forFeature([LogsEffects]),
        MatDividerModule,
        MatSlideToggleModule,
        MatStepperModule,
        DragDropModule,
        FlexModule,
        NgxCurrencyModule,
        MatTableExporterModule,
        PerfectScrollbarModule,
        NgbDropdownModule,
        MatListModule,
        InlineSVGModule,
        NgbDatepickerModule,
        Ng2SearchPipeModule
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        ModuleGuard,
        // InterceptService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: InterceptService,
        //     multi: true
        // },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px',
            },
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
        DatePipe,
    ],
    entryComponents: [
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
    ],
    declarations: [
        ECommerceComponent,
        // Companies
        CompaniesListComponent,
        CompanyEditComponent,
        AddressesListComponent,
        AddressEditDialogComponent,
        ClientListComponent,
        ClientEditComponent,
        OutstandingListComponent,
        OutstandingEditDialogComponent,
        // Discount Companies
        DiscountsCompaniesComponent,
        // ClientFunctions
        ClientfunctionListComponent,

        // Orders
        OrdersListComponent,
        OrderEditComponent,
        OrderlinesComponent,
        OrderDetailComponent,
        // Products
        ProductsListComponent,
        ProductEditComponent,
        ProductCharacteristicListComponent,
        ProductCharacteristicEditComponent,
        ProductLicensesListComponent,
        ResourcesEditComponent,

        // Stocks
        StockListComponent,

        // Categories
        CategoriesComponent,
        CategoryEditDialogComponent,
        CategoryCharacteristicListComponent,
        CategoryCharacteristicEditComponent,
        // home page
        CarouselImagesComponent,
        PartenairesLogoComponent,
        // MainClient
        MainClientListComponent,
        ProductCharacteristicsEditDialogComponent,
        ManufacturersComponent,
        QuotationListComponent,
        QuotationDetailComponent,
        QuotationEditComponent,
        QuotationItemListComponent,
        PromotionsComponent,
        DiscountsComponent,
        DiscountProductDialogComponent,
        PromotionProductDialogComponent,
        ProductSearchDropdownComponent,
        SendPdfMailComponent,
        SuppliersComponent,
        ContactsSupplierComponent,
        AddressesSupplierListComponent,
        AddressSupplierEditDialogComponent,
        SendPdfMailComponent,
        ContactsComponent,
        ClientAddressListComponent,
        ClientAddressEditDialogComponent,
        ProductSearchComponent,
        OdooStocksComponent,
        AchatStockComponent,

        // odoo
        LogsListComponent,
        // promocode
        PromoCodeComponent,
        PromoCodeEditComponent,
        PromoProductsListComponent,
        PromoCategoriesListComponent,
        CurrentPromotionComponent,
        LogsEditComponent,
        ProductLicensesListComponent,
        AccessoireCategorieComponent,

    ],
})
export class ECommerceModule {
}
