// France
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'choisissez votre langue',
    },
    MENU: {
      NEW: 'Nouveau',
      ACTIONS: 'Actes',
      CREATE_POST: 'Créer un nouveau Post',
      PAGES: 'Pages',
      FEATURES: 'Fonctionnalités',
      APPS: 'Applications',
      DASHBOARD: 'Tableau de Bord',
      USER_MANAGEMENT: 'Gestion d\'utilisateurs',
      USERS: 'Utilisateurs',
      USERS_LIST: 'Liste des utilisateurs',
      NEW_USER: 'Nouvel utilisateur',
      CUSTOMERS: 'Clients',
      PRODUCTS: 'Produits',
      NAME: 'Nom',
      COMPANIES: 'Entreprises',
      CLIENTS: 'Clients',
      FUNCTIONS: 'Fonctions client',
      ORDERS: 'Commandes',
      HOME_PAGE: 'Page d\'acceuil',
      LOGOS: 'Logos',
      CAROUSEL: 'Carousels',
      CATEGORIES: 'Catégories',
      SERVICE_CLIENT: 'Service Client ',
      RECLAMATION: 'Reclamations',
    },
    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Soumettre',
        NO_ACCOUNT: 'Ne pas avoir de compte?',
        SIGNUP_BUTTON: 'Registre',
        FORGOT_BUTTON: 'Mot de passe oublié',
        BACK_BUTTON: 'Retour',
        PRIVACY: 'Privé',
        LEGAL: 'Légale',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Se connecter',
        BUTTON: 'Se connecter',
        WELCOME: 'Bienvenue chez Imtech Admin',
        GREETING: 'Salut,',
      },
      FORGOT: {
        TITLE: 'Mot de passe oublié ?',
        DESC: 'Entrez votre email pour récupérer le mot de pass',
        SUCCESS: 'Votre compte a été réinitialisé avec succès.',
      },
      REGISTER: {
        TITLE: 'Créer un compte',
        DESC: 'Entrez vos informations pour créer votre compte',
        SUCCESS: 'Votre compte a été enregistré avec succès.',
      },
      INPUT: {
        EMAIL: 'Adresse Email',
        FULLNAME: 'Nom et Prénom',
        PASSWORD: 'Mot de passe',
        CONFIRM_PASSWORD: 'Confirmer le mot de passe',
        USERNAME: 'Nom d\'utilisateur',
      },
      VALIDATION: {
        INVALID: '{{name}} n\'est pas valide',
        REQUIRED: '{{name}} est requis',
        MIN_LENGTH: '{{name}} la langueur minimal est {{min}}',
        AGREEMENT_REQUIRED:
          'L\'acceptation des termes et conditions est requise',
        NOT_FOUND: 'Le {{name}} demandé est introuvable',
        INVALID_LOGIN: 'Mot de passe ou adresse Email incorrect',
        ACCOUNT_SUSPENDED:
          'Beaucoup de tentatives! , Votre compte est bloqué essayez plustard !',
        NO_CONNECTION:
          'Une erreur est survenue lors de la connexion, Verifiez votre internet !',
        REQUIRED_FIELD: 'Champs requis',
        MIN_LENGTH_FIELD: 'Longueur minimale du champ:',
        MAX_LENGTH_FIELD: 'Longueur maximale du champ:',
        INVALID_FIELD: 'Le champ n\'est pas valide',
      },
    },
    ECOMMERCE: {
      HOME_PAGE: {
        DELETE: {
          IMAGE: {
            TITLE: 'Supprimer Image',
            DESCRIPTION: 'Voulez-vous supprimer définitivement cette image ?',
            WAIT_DESCRIPTION: 'Supression en cours ...',
          },
        },
      },

      ORDERS: {
        NEW_ORDER: 'Nouvelle Commande',
        ORDERS_LIST: 'Liste des commandes',
        STATUS: {
          WAITING_FOR_PAYMENT: 'En attente de paiement',
          PAID: 'Payé',
          IN_DELIVERY: 'En livraison',
          DELIVERED: 'Livré',
          CANCELED: 'Annulé',
        },
      },

      COMMON: {
        SELECTED_RECORDS_COUNT: 'Nombre d\'enregistrements sélectionnés: ',
        ALL: 'Tout',
        SELLING: 'Vente',
        SUSPENDED: 'Suspendu',
        ACTIVE: 'Active',
        FILTER: 'Filtre',
        BY_STATUS: 'par statut',
        BY_TYPE: 'Par Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Chercher',
        IN_ALL_FIELDS: 'dans tous les champs',
        SOLD: 'Vendu',
        BACK: 'Retour',
        SAVE: 'Enregistrer',
        RESET: 'Réinitialiser',
        NO_RECORDS_FOUND: 'Aucun enregistrement trouvé',
        PLEASE_WAIT: 'S\'il vous plaît, attendez',
      },
      CATEGORIES: {
        EDIT: {
          ADD_MESSAGE: 'Catégorie ajoutée avec succès',
          UPDATE_MESSAGE: 'Catégorie mise à jour avec succès',
          ERROR: {
            SERVER: 'BACK-END ERROR ¯\\_(ツ)_/¯ ',
            NOT_FOUND:
              'Il semble que le backend ne trouve pas cette catégorie ¯\\_(ツ)_/¯',
          },
        },
        CATEGORIES_LIST: 'List des Catégories',
        NEW_CATEGORY: 'Nouvelle Catégorie',
        CREATE_NEW_CATEGORY: 'Créer une nouvelle Catégorie',
        PARENT_ID: 'Catégorie Mère',
        DELETE_CATEGORY: 'Supprimer la Catégorie',
        UPDATE_CATEGORIES: 'Modifier les Catégories',
        UPDATE_CATEGORY: 'Modifier la Categorie',

        DELETE_CATEGORY_SIMPLE: {
          TITLE: 'Supprimer Categorie',
          DESCRIPTION: 'Voulez-vous supprimer définitivement cette catégorie?',
          WAIT_DESCRIPTION: 'Supression en cours ...',
          MESSAGE: 'Categorie supprimée avec success',
          FOREIGN_KEY_ERROR:
            'Cette Catégorie est liée avec d\'autres entitées, Suppression Impossible !',
        },
      },
      ECOMMERCE: 'éCommerce',

      COMPANIES: {
        COMPANIES_LIST: 'Liste des clients',
        NEW_COMPANY: 'Nouvelle entreprise ',
        STATUS: {
          prospect: 'Prospect',
          client: 'Client',
        },
        ACTIVATION_STATUS: {
          waiting: 'En attente de validation',
          activate: 'Active',
          incomplete_information: 'Information incomplete',
        },
      },

      PRODUCTS: {
        PRODUCTS_LIST: 'Liste des produits',
        NEW_PRODUCT: 'Nouveau produit',
        STATUS: {
          IN_STOCK: 'En Stock',
          OUT_OF_STOCK: 'Rupture de stock',
          ON_COMMAND: 'Sur Commande',
          OBSOLETE: 'obsolète',
        },
      },

      CLIENT_FUNCTION: {
        CLIENT_FUNCTION_LIST: 'Liste des fonctions',
        NEW_FUNCTION: 'Nouvelle fonction',
      },

      CUSTOMERS: {
        CUSTOMERS: 'Les clients',
        CUSTOMERS_LIST: 'Liste des contacts',
        NEW_CUSTOMER: 'Nouveau client',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Suppression du client',
          DESCRIPTION: 'Êtes-vous sûr de supprimer définitivement ce client?',
          WAIT_DESCRIPTION: 'Le client est en train de supprimer ...',
          MESSAGE: 'Le client a été supprimé',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Supprimer les clients',
          DESCRIPTION:
            'Êtes-vous sûr de supprimer définitivement les clients sélectionnés?',
          WAIT_DESCRIPTION: 'Les clients suppriment ...',
          MESSAGE: 'Les clients sélectionnés ont été supprimés',
        },
        UPDATE_STATUS: {
          TITLE: 'Le statut a été mis à jour pour les clients sélectionnés',
          MESSAGE:
            'Le statut des clients sélectionnés a été mis à jour avec succès',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Le client a été mis à jour',
          ADD_MESSAGE: 'Le client a été créé',
        },
      },
    },
    CLIENT_SERVICE: {
      Q_A: {
        TYPE: {
          SHIPPING: 'Livraison',
          GENERAL: 'Général',
          ORDER: 'Commandes',
          PAYMENT: 'Paiement',
        },
        CREATE_NEW_FAQ: 'Créer un nouveau FAQ',
        FAQ_LIST: 'Liste des FAQs',
        QUESTIONS: 'Questions',
        ANSWERS: 'Reponses',
        FILL_FIELDS: 'Remplissez les champs',
        Q_FILL: 'Posez une Question',
        A_FILL: 'Entrez la Reponse',
        T_FILL: 'Entrez le Type',
        Q_REQUIRED: 'la Question est requise',
        A_REQUIRED: 'la Reponse est requise',
      },
      C_Q: {
        TYPE: {
          OPEN: 'Ouvert',
          CLOSED: 'Fermé',
          IN_PROGRESS: 'En Cours',
        },
      },
    },
    BLOG: {
      BLOG_LIST: 'Liste des articles',
      CREATE_NEW_BLOG: 'Créer un nouveau article',
      TITLE: 'Titres',
      BODY: 'Contenu',
      NO_RECORDS_FOUND: 'Aucun Article trouvé',
      FILL_FIELDS: 'Remplissez les champs',
      FILL_TITLE: 'Entrez le titre de l\'article',
      TITLE_REQUIRED: 'le titre est requise',
      IMAGE_REQUIRED: 'L\'image est requise',
      ARTICLE_MANAGEMENT: 'Gestion des articles',
      STATUS_FILL: 'Entrez le Status de l\'article',
      IMAGE_FILL: 'Choisissez une image',
      CREATE_ARTICLE: 'Crée un article',
    },
    ABOUT: {
      ABOUT_LIST: 'Liste des liens utiles',
      CREATE_NEW_ABOUT: 'Nouveau contenu',
      TYPE: {
        cookies: 'Cookies',
        legal: 'Mentions légales',
        general_condition: 'Conditions générales',
        security: 'Sécurité et confidentialité',
      },
    },
  },
};
