import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import {
  selectPromoCodesInitWaitingMessage,
  selectPromoCodesInStore,
  selectPromoCodesPageLoading
} from './promo-code.selectors';


export class PromoCodeDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(select(selectPromoCodesPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectPromoCodesInitWaitingMessage)
    );

    this.store
      .pipe(select(selectPromoCodesInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
