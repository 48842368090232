import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../../../../core/_base/crud';
import { AddressModel } from '../../../../../../../../../core/e-commerce/companies/address.model';
import { AddressEditDialogComponent } from '../address-edit/address-edit-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from '../../../../../../../../../core/e-commerce/companies/companies.service';

@Component({
  selector: 'kt-address-list',
  templateUrl: './addresses-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressesListComponent implements OnInit, OnDestroy {
  // Incoming data
  dataSource = [];

  @Input() addresse$: BehaviorSubject<AddressModel[]>;

  displayedColumns = [
    'id',
    'country',
    'city',
    'streetName',
    'postalCode',
    'type',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  private componentSubscriptions: Subscription[] = [];
  private companyId: number;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private route: ActivatedRoute,
    private companiesService: CompaniesService
  ) {}

  ngOnInit() {
    this.companyId = this.route.snapshot.params.id;
    const addressesSub = this.addresse$.subscribe((res) => {
      if (res) {
        this.dataSource = res;
        // console.log('Addreses : '+JSON.stringify(res));
      } else {
        this.dataSource = [];
      }
    });
    this.componentSubscriptions.push(addressesSub);
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
    }
  }

  /**
   * Load Specs List
   */
  loadSpecsList() {}

  /** ACTIONS */
  /**
   * Delete spec
   *
   * @param _item: AddressModel
   */
  deleteAddress(_item: AddressModel) {
    const _title = 'Address Delete';
    const _description = 'Are you sure to permanently delete this address?';
    const _waitDesciption = 'Address is deleting...';
    const _deleteMessage = `Address has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.companiesService
        .deleteAddress(this.companyId, _item.id)
        .subscribe((res) => {
          const afterDelete = this.addresse$.value.filter(
            (elem) => elem != _item
          );
          this.addresse$.next(afterDelete);
          this.layoutUtilsService.showActionNotification(
            _deleteMessage,
            MessageType.Delete
          );
          this.loadSpecsList();
        });
    });
  }

  addAdress() {
    const newAddress = new AddressModel();
    const dialogRef = this.dialog.open(AddressEditDialogComponent, {
      data: { address: null, companyId: this.companyId },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        newAddress.country = res.country;
        newAddress.city = res.city;
        newAddress.streetName = res.streetName;
        newAddress.postalCode = res.postalCode;
        newAddress.type = res.type;
        newAddress.cedex = res.cedex;
        newAddress.id = res.id;

        const newAddrList = this.dataSource.concat([res]);
        this.addresse$.next(newAddrList);
        const saveMessage = `Address has been created`;
        this.layoutUtilsService.showActionNotification(
          saveMessage,
          MessageType.Create,
          10000,
          true,
          true
        );
      }
    });
  }

  /**
   * Edit add spec dialog ans save data
   *
   * @param item: ProductAddressModel
   */
  editAddress(item: AddressModel) {
    const _item = Object.assign({}, item);
    const dialogRef = this.dialog.open(AddressEditDialogComponent, {
      data: { address: _item, companyId: this.companyId },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const newAddrList = this.dataSource.map(
          (obj) => [res].find((o) => o.id === obj.id) || obj
        );
        this.addresse$.next(newAddrList);

        const saveMessage = `Address has been updated`;
        this.layoutUtilsService.showActionNotification(
          saveMessage,
          MessageType.Update,
          10000,
          true,
          true
        );
      }
    });
  }
}
