// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      USERS: 'Users',
      NEW_USER: 'New User',
      CUSTOMERS: 'Customers',
      PRODUCTS: 'Products',
      NAME: 'Name',
      CATEGORIES: 'Categories',
      COMPANIES: 'Companies',
      CLIENTS: 'Clients',
      FUNCTIONS: 'Client Functions',
      ORDERS: 'Orders',
      HOME_PAGE: 'Home Page',
      USER_MANAGEMENT: 'User Management',
      USERS_LIST: 'Users List',
      LOGOS: 'Logos',
      CAROUSEL: 'Carousels',
      SERVICE_CLIENT: 'Client Service',
      RECLAMATION: 'Claims',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
        WELCOME: 'Welcome to Imtech Administrator !',
        GREETING: 'Hi, ',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        ACCOUNT_SUSPENDED:
          'Too many attempts, This account Suspended for now try later',
        NO_CONNECTION: "Can't access the login service, Check your Internet !",
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
    },
    ECOMMERCE: {
      HOME_PAGE: {
        DELETE: {
          IMAGE: {
            TITLE: 'DELETE IMAGE',
            DESCRIPTION: 'Are you sure to permanently delete this image ?',
            WAIT_DESCRIPTION: 'Deleting Image...',
          },
        },
      },

      ORDERS: {
        NEW_ORDER: 'New Order',
        ORDERS_LIST: 'Orders List',
        STATUS: {
          WAITING_FOR_PAYMENT: 'Waiting for payment',
          PAID: 'Paid',
          IN_DELIVERY: 'In delivery',
          DELIVERED: 'Delivered',
          CANCELED: 'Canceled',
        },
      },
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SELLING: 'Selling',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
        SOLD: 'Sold',
        BACK: 'Back',
        SAVE: 'Save',
        RESET: 'Reset',
        NO_RECORDS_FOUND: 'No records found',
        PLEASE_WAIT: 'Please wait',
      },
      CATEGORIES: {
        DELETE: {
          ERROR: {
            SERVER: 'SERVER ERROR ¯\\_(ツ)_/¯ ',
            NOT_FOUND:
              "It seems the Backend can't find this category ¯\\_(ツ)_/¯",
          },
        },
        EDIT: {
          ADD_MESSAGE: 'Category added Successfully',
          UPDATE_MESSAGE: 'Category Updated Successfully',
          ERROR: {
            SERVER: 'BACK-END ERROR ¯\\_(ツ)_/¯ ',
            NOT_FOUND:
              "It seems the Backend can't find this category ¯\\_(ツ)_/¯",
          },
        },
        CATEGORIES_LIST: 'Categories List',
        NEW_CATEGORY: 'New Category',
        CREATE_NEW_CATEGORY: 'Create new Category',
        PARENT_ID: 'Parent Category',
        DELETE_CATEGORY: 'Delete Category',
        UPDATE_CATEGORIES: 'Edit Categories',
        UPDATE_CATEGORY: 'Edit Category',
        DELETE_CATEGORY_SIMPLE: {
          TITLE: 'Category Delete',
          DESCRIPTION: 'Are you sure to permanently delete this category?',
          WAIT_DESCRIPTION: ' Deleting Category...',
          MESSAGE: 'Category has been deleted',
          FOREIGN_KEY_ERROR: "Can't delete this Category Try empty it first ",
        },
      },
      ECOMMERCE: 'eCommerce',
      COMPANIES: {
        COMPANIES_LIST: 'Companies List',
        NEW_COMPANY: 'New Company',
        STATUS: {
          prospect: 'Prospect',
          client: 'Client',
        },
        ACTIVATION_STATUS: {
          waiting: 'Waiting for validation',
          activate: 'Activate',
          incomplete_information: 'Incomplete information',
        },
        NEW_CLIENT: 'New Client',
        CLIENTS_LIST: 'Clients List',
      },
      PRODUCTS: {
        PRODUCTS_LIST: 'Products List',
        NEW_PRODUCT: 'New Product',
        STATUS: {
          IN_STOCK: 'In Stock',
          OUT_OF_STOCK: 'Out Of Stock',
          ON_COMMAND: 'On Command',
          OBSOLETE: 'Obsolete',
        },
      },
      CLIENT_FUNCTION: {
        CLIENT_FUNCTION_LIST: 'Client Function List',
        NEW_FUNCTION: 'New Function',
      },
      CUSTOMERS: {
        CUSTOMERS: 'Clients',
        CUSTOMERS_LIST: 'Clients list',
        NEW_CUSTOMER: 'New Client',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Client Delete',
          DESCRIPTION: 'Are you sure to permanently delete this client?',
          WAIT_DESCRIPTION: 'Client is deleting...',
          MESSAGE: 'Client has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Clients Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected client?',
          WAIT_DESCRIPTION: 'Clients are deleting...',
          MESSAGE: 'Selected clients have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Client has been updated',
          ADD_MESSAGE: 'Client has been created',
        },
      },
    },
    CLIENT_SERVICE: {
      Q_A: {
        TYPE: {
          SHIPPING: 'Shipping',
          GENERAL: 'General',
          ORDER: 'Order',
          PAYMENT: 'Payment',
        },
        CREATE_NEW_FAQ: 'Create a new FAQ',
        FAQ_LIST: 'FAQ List',
        QUESTIONS: 'Questions',
        ANSWERS: 'Answers',
        FILL_FIELDS: 'Fill in the fields',
        Q_FILL: 'Ask a question',
        A_FILL: 'Enter the Answer',
        T_FILL: 'Enter the Type',
        Q_REQUIRED: 'The Question is required',
        A_REQUIRED: 'The Answer is required',
      },
    },
  },
};
