import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as CharacteristicActions from './category-characteristic.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CategoryCharacteristicsService } from './category-characteristics.service';

@Injectable()
export class CategoryCharacteristicEffects {
  constructor(
    private actions$: Actions,
    private characteristicService: CategoryCharacteristicsService,
    private store: Store<AppState>
  ) {}

  CharacteristicListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CharacteristicActions.CategoryCharacteristicListRequested),
      switchMap((action) =>
        this.characteristicService.getByCategoryId(action.categoryId).pipe(
          map((categoryList) =>
            CharacteristicActions.CategoryCharacteristicListRequestedSuccessfully(
              {
                categoryCharacteristics: categoryList,
              }
            )
          ),
          catchError((error) =>
            of(
              CharacteristicActions.CategoryCharacteristicListRequestFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  CharacteristicDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CharacteristicActions.CategoryCharacteristicDeleted),
      switchMap((action) =>
        this.characteristicService.delete(action.id, action.categoryId).pipe(
          map(() =>
            CharacteristicActions.CategoryCharacteristicDeletedSuccessfully({
              id: action.id,
            })
          ),
          catchError((error) =>
            of(
              CharacteristicActions.CategoryCharacteristicDeleteFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  CharacteristicCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CharacteristicActions.CategoryCharacteristicCreated),
      switchMap((action) =>
        this.characteristicService
          .save(action.categoryCharacteristic, action.categoryId)
          .pipe(
            map((categoryCharacteristic) =>
              CharacteristicActions.CategoryCharacteristicCreatedSuccessfully({
                categoryCharacteristic,
              })
            ),
            catchError((error) =>
              of(
                CharacteristicActions.CategoryCharacteristicCreationFailed({
                  error,
                })
              )
            )
          )
      )
    )
  );

  CategoryUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CharacteristicActions.CategoryCharacteristicUpdated),
      switchMap((action) =>
        this.characteristicService
          .update(action.categoryCharacteristic, action.categoryId)
          .pipe(
            map((categoryCharacteristic) =>
              CharacteristicActions.CategoryCharacteristicUpdatedSuccessfully({
                categoryCharacteristic,
                partialCharacteristic: action.partialCharacteristic,
              })
            ),
            catchError((error) =>
              of(
                CharacteristicActions.CategoryCharacteristicUpdateFailed({
                  error,
                })
              )
            )
          )
      )
    )
  );
}
