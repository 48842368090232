import { Role } from './role.model';

export class RolesTable {
  public static roles: Role[] = [
    new Role(
      'ROLE_ADMIN',
      'Administrator',
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      true
    ),
    new Role('ROLE_MANAGER', 'Manager', [3, 4, 10], false),
    new Role('ROLE_GUEST', 'Guest', [], false),
  ];
}
