import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { QueryParamsModel } from '../_base/crud';
import { AboutModel } from './about.model';

export const AboutsPageRequested = createAction(
  '[About List Page] About Page Requested ',
  props<{ page: QueryParamsModel }>()
);
export const AboutsPageLoadedSuccessfully = createAction(
  '[About API] About Page Loaded Successfully ',
  props<{ abouts: AboutModel[] }>()
);
export const AboutsPageLoadFailed = createAction(
  'About API] About Page Request Failed ',
  props<{ error: any }>()
);

export const AboutCreated = createAction(
  'About List Page] About Created ',
  props<{ About: AboutModel }>()
);
export const AboutCreatedSuccessfully = createAction(
  '[About List Page] About Created Successfully ',
  props<{ About: AboutModel }>()
);
export const AboutCreationFailed = createAction(
  '[About List Page] About Creation Failed ',
  props<{ error: any }>()
);

export const AboutUpdated = createAction(
  '[About List Page] About Updated ',
  props<{ About: AboutModel; id: number }>()
);
export const AboutUpdatedSuccessfully = createAction(
  '[About List Page] About Updated Successfully ',
  props<{ About: AboutModel; id: number }>()
);
export const AboutUpdateFailed = createAction(
  '[About List Page] About Update Failed ',
  props<{ error: any }>()
);

export const AboutDeleted = createAction(
  '[About List Page] About Deleted ',
  props<{ AboutId: number }>()
);
export const AboutDeletedSuccessfully = createAction(
  '[AboutList Page] About deleted Successfully ',
  props<{ AboutId: number }>()
);
export const AboutDeleteFailed = createAction(
  '[About List Page] About delete Failed ',
  props<{ error: any }>()
);
