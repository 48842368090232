import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import { Response } from '../../_base/crud/models/response';
import { Action, createReducer, on } from '@ngrx/store';
import { ProductCharacteristicModel } from './product-characteristic.model';
//Actions
import * as CharacteristicActions from './product-characteristic.actions';

export interface ProductCharacteristicState
  extends EntityState<ProductCharacteristicModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedProductCharacteristicId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
}

export const adapter: EntityAdapter<ProductCharacteristicModel> =
  createEntityAdapter<ProductCharacteristicModel>();

export const initialProductCharacteristicState: ProductCharacteristicState =
  adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedProductCharacteristicId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    errorMessage: '',
  });

const productCharacteristicReducer = createReducer(
  initialProductCharacteristicState,
  on(
    CharacteristicActions.ProductCharacteristicListRequested,
    (state, action) => ({
      ...state,
      actionsLoading: true,
      listLoading: true,
      showInitWaitingMessage: true,
      error: null,
      progress: 0,
    })
  ),

  on(
    CharacteristicActions.ProductCharacteristicListRequestedSuccessfully,
    (state, action) =>
      adapter.addAll(action.productCharacteristics, {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
      })
  ),
  on(
    CharacteristicActions.ProductCharacteristicListRequestFailed,
    (state, action) => ({
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      error: action.error,
    })
  ),
  //creation
  on(CharacteristicActions.ProductCharacteristicCreated, (state) => ({
    ...state,
    actionsLoading: false,
  })),

  on(
    CharacteristicActions.ProductCharacteristicCreatedSuccessfully,
    (state, action) =>
      adapter.addOne(action.productCharacteristic, {
        ...state,
        actionsLoading: true,
        lastCreatedProductCharacteristicId: action.productCharacteristic.id,
        progress: 0,
      })
  ),
  on(
    CharacteristicActions.ProductCharacteristicCreationFailed,
    (state, action) => ({
      ...state,
      actionsLoading: false,
      error: action.error,
    })
  ),
  //Update
  on(CharacteristicActions.ProductCharacteristicUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(
    CharacteristicActions.ProductCharacteristicUpdatedSuccessfully,
    (state, action) =>
      adapter.updateOne(action.partialCharacteristic, {
        ...state,
        actionsLoading: false,
      })
  ),
  on(
    CharacteristicActions.ProductCharacteristicUpdateFailed,
    (state: ProductCharacteristicState, action) => {
      let errorMessage: string;
      switch (action.error.status) {
        case 500:
          errorMessage = 'EDIT.ERROR.SERVER';
          break;
        case 403:
          errorMessage = 'AUTH.VALIDATION.NOT_AUTHORIZED';
          break;
        case 404:
          errorMessage = 'EDIT.ERROR.NOT_FOUND';
          break;
        default:
          errorMessage = 'EDIT.ERROR.UNKNOWN';
          break;
      }
      return {
        ...state,
        listLoading: false,
        actionsLoading: false,
        errorMessage: errorMessage,
        error: action.error,
      };
    }
  ),
  //Delete
  on(CharacteristicActions.ProductCharacteristicDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(
    CharacteristicActions.ProductCharacteristicDeletedSuccessfully,
    (state, action) =>
      adapter.removeOne(action.id, {
        ...state,
        actionsLoading: false,
        lastAction: 'DELETE_CATEGORY_SIMPLE.MESSAGE',
      })
  ),
  on(
    CharacteristicActions.ProductCharacteristicDeleteFailed,
    (state: ProductCharacteristicState, action) => {
      let errorMessage: string;
      switch (action.error.status) {
        case 500:
          errorMessage = 'DELETE.ERROR.SERVER';
          break;
        case 403:
          errorMessage = 'AUTH.VALIDATION.CANT_DELETE';
          break;
        case 404:
          errorMessage = 'AUTH.VALIDATION.NOT_FOUND';
          break;
        default:
          errorMessage = 'AUTH.VALIDATION.NO_CONNECTION';
          break;
      }
      return {
        ...state,
        listLoading: false,
        actionsLoading: false,
        errorMessage: errorMessage,
        error: action.error,
      };
    }
  )
);

export function productCharacteristicsReducer(
  state: ProductCharacteristicState | undefined,
  action: Action
) {
  return productCharacteristicReducer(state, action);
}
