// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';
import { ArchivedProductListComponent } from './views/pages/archive/archived-product-list/archived-product-list.component';
import { AboutComponent } from './views/pages/a-propos/about/about.component';
import { AboutEditComponent } from './views/pages/a-propos/about/about-edit/about-edit.component';
import { ArchivedQuotationListComponent } from './views/pages/archive/archived-quotation-list/archived-quotation-list.component';
import { ArchivedClientListComponent } from './views/pages/archive/archived-client-list/archived-client-list.component';
import { ArchivedOrderListComponent } from './views/pages/archive/archived-order-list/archived-order-list.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('app/views/pages/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/views/pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'mail',
        loadChildren: () =>
          import('app/views/pages/apps/mail/mail.module').then(
            (m) => m.MailModule
          ),
      },
      {
        path: 'ecommerce',
        loadChildren: () =>
          import('app/views/pages/apps/e-commerce/e-commerce.module').then(
            (m) => m.ECommerceModule
          ),
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('app/views/pages/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: 'service-client',
        loadChildren: () =>
          import('app/views/pages/service-client/service-client.module').then(
            (m) => m.ServiceClientModule
          ),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('app/views/pages/blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: 'builder',
        loadChildren: () =>
          import('app/views/theme/content/builder/builder.module').then(
            (m) => m.BuilderModule
          ),
      },
      {
        path: 'archive/products',
        component: ArchivedProductListComponent,
      },
      {
        path: 'archive/clients',
        component: ArchivedClientListComponent,
      },
      {
        path: 'archive/quotations',
        component: ArchivedQuotationListComponent,
      },
      {
        path: 'archive/orders',
        component: ArchivedOrderListComponent,
      },

      {
        path: 'a-propos/about',
        component: AboutComponent,
      },
      {
        path: 'a-propos/about/add',
        component: AboutEditComponent,
      },
      {
        path: 'a-propos/about/edit',
        component: AboutEditComponent,
      },
      {
        path: 'a-propos/about/edit/:id',
        component: AboutEditComponent,
      },

      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Accès interdit !',
          desc: 'Il semble que vous n\'êtes pas autorisé à accéder à la page demandée. <br> Veuillez contacter l\'administrateur',
        },
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
