import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { OrderItemModel } from './OrderItem.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class OrderItemsService extends AbstractService<OrderItemModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}order/item`;
  }

  public save(entity: OrderItemModel): Observable<OrderItemModel> {
    console.log('saving ... ' + JSON.stringify(entity));
    const res = this.http.post<OrderItemModel>(this.url, entity);
    res.pipe(tap((val) => console.log(JSON.stringify(val))));
    return res;
  }
}
