import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductModel } from './product.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as ProductActions from './product.actions';

export interface ProductState extends EntityState<ProductModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedProductId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  lastAction: string;
  errorMessage: string;
}

export const adapter: EntityAdapter<ProductModel> =
  createEntityAdapter<ProductModel>();

export const initialProductState: ProductState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedProductId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  lastAction: '',
  errorMessage: '',

});

const productReducer = createReducer(
  initialProductState,
  on(ProductActions.ProductsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.queryParams,
  })),
  on(ProductActions.ProductsPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.products, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(ProductActions.ProductsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // creation
  on(ProductActions.ProductCreated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ProductActions.ProductCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.product, {
      ...state,
      actionsLoading: true,
      lastCreatedProductId: action.product.id,
      lastAction: 'EDIT.ADD_MESSAGE',
    })
  ),
  on(ProductActions.ProductCreationFailed, (state, action) => {
    let Message: string;
    switch (action.error.status) {
      case 409:
        Message = 'Cette référence constructeur existe déjà';
        break;
      default:
        Message = 'Une erreur est mmmm survenu';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage : Message,
      error: action.error,
    };
  }),
  // Update
  on(ProductActions.ProductUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ProductActions.ProductUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialProduct, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(ProductActions.ProductUpdateFailed, (state, action) => {
    let errorMessage: string;
    switch (action.error.status) {
  case 409:
    errorMessage = ' Cette référence constructeur existe déjà';
    break;
  default:
    errorMessage = 'Une erreru est survenu';
    break;
}
    return {
  ...state,
  listLoading: false,
  actionsLoading: false,
  errorMessage,
  error: action.error,
      lastAction: errorMessage,

    };
  }),
// Update
on(ProductActions.ProductUpdated, (state) => ({
  ...state,
  actionsLoading: true,
  lastAction: '',
})),
  // Delete
  on(ProductActions.ProductDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ProductActions.ProductDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.productId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(ProductActions.ProductDeleteFailed, (state, action) => ({
  ...state,
      error: action.error,
      actionsLoading: false,
  })),
  // Product Resource Delete
  on(ProductActions.ProductResourceDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ProductActions.ProductResourceDeletedSuccessfully, (state, action) => ({
    ...state,
    actionsLoading: false,
    lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
  })),
  on(ProductActions.ProductResourceDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  })),
  // Archive Product
  on(ProductActions.ArchiveProduct, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ProductActions.ProductArchivedSuccessfully, (state, action) =>
    adapter.removeOne(action.productId, {
      ...state,
      actionsLoading: false,
    })
  ),
  on(ProductActions.ProductArchivingFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  })),
  // Change Product Visibility
  on(ProductActions.ChangeProductVisibility, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ProductActions.ProductVisibilityChangedSuccessfully, (state, action) =>
    adapter.updateOne(
      { id: action.product.id, changes: action.product },
      {
        ...state,
        actionsLoading: false,
      }
    )
  ),
  on(ProductActions.ProductVisibilityChangeFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  }))
);

export function productsReducer(
  state: ProductState | undefined,
  action: Action
) {
  return productReducer(state, action);
}
