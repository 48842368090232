import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
  QueryResultsModel,
} from '../../../../../../core/_base/crud';
import { ActivatedRoute, Router } from '@angular/router';
import { KtDialogService } from '../../../../../../core/_base/layout';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { OrderModel } from '../../../../../../core/e-commerce/orders/order.model';
import { OrdersService } from '../../../../../../core/e-commerce/orders/orders.service';
import { OrdersPageRequested } from '../../../../../../core/e-commerce/orders/orders.actions';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  selectOrdersInitWaitingMessage,
  selectOrdersInStore,
  selectOrdersPageLastQuery,
} from '../../../../../../core/e-commerce/orders/orders.selectors';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { ProductsService } from '../../../../../../core/e-commerce/products/products.service';
import { ClientModel } from '../../../../../../core/e-commerce/clients/client.model';
import { ClientService } from '../../../../../../core/e-commerce/clients/client.service';

@Component({
  selector: 'kt-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit, AfterViewInit , OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  dataSource: MatTableDataSource<OrderModel> = new MatTableDataSource([]);

  totalElements = 0;
  displayedColumns = [
    'select',
    'id',
    'client',
    'company',
    'createdAt',
    'totalPrice',
    'status',
    'actions',
  ];

  loading$: Observable<boolean> = of(true);

  filterStatus = new FormControl('');

  selection = new SelectionModel<OrderModel>(true, []);

  lastQuery: QueryParamsModel;

  pageTitle = 'Liste de commandes';

  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private ktDialogService: KtDialogService,
    private orderService: OrdersService,
    private activatedRoute: ActivatedRoute,
    private productService: ProductsService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private clientService: ClientService
  ) {}

  /**
   * On init
   */
  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.search) {
      this.pageTitle =
        this.activatedRoute.snapshot.queryParams.search +
        ' | Liste de commandes';
      this.searchInput.nativeElement.value =
        this.activatedRoute.snapshot.queryParams.search;
    }
    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';

    const lastQuerySubscription = this.store
      .pipe(select(selectOrdersPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    this.loadOrderList(this.lastQuery);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadOrderList()))
      .subscribe();

    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.addSearch();
          this.loadOrderList();
        })
      )
      .subscribe();

    this.ktDialogService.show();

    const initWaitingMessageSubscription = this.store
      .pipe(select(selectOrdersInitWaitingMessage))
      .subscribe((v) => {
        if (!v) {
          this.loading$ = of(false);
          this.ktDialogService.hide();
        }
      });

    const selectOrdersInStoreSubscription = this.store
      .pipe(select(selectOrdersInStore))
      .subscribe((response: QueryResultsModel) => {
        this.dataSource.data = response.items;
        this.paginator.length = response.totalCount;
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 1000);
      });

    const routeSubscription = this.activatedRoute.queryParams.subscribe(() => {
      const pageSize = this.lastQuery.pageSize;
      const pageNumber = this.lastQuery.pageNumber;
      this.paginator._changePageSize(pageSize);
      this.paginator.pageIndex = pageNumber;
      this.loadOrderList(this.lastQuery);
    });

    this.subscriptions.push(
      paginatorSubscriptions,
      lastQuerySubscription,
      initWaitingMessageSubscription,
      selectOrdersInStoreSubscription,
      routeSubscription,
      searchSubscription
    );
  }

  ngAfterViewInit() {}

  loadOrderList(filter?: QueryParamsModel) {
    let queryParams: QueryParamsModel;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }

    this.loading$ = of(true);
    this.store.dispatch(OrdersPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }

    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  editOrder(orderId: number) {
    this.router.navigate(['ecommerce', 'orders', 'edit', orderId]);
  }

  viewOrder(item: OrderModel) {
    if (!item.items[0] || !item.items[0].product) {
      item.items.forEach((i) => {
        this.productService
          .getById(i.productId)
          .subscribe((p) => (i.product = p));
      });
    }
    this.dialog.open(OrderDetailComponent, {
      data: item,
      width: '900px',
    });
  }

  public sendViaEmail(order: OrderModel) {
    const _title = 'Commande  ' + order.number;
    const _description = 'Envoyer une copie de commande en piece jointe';
    const _waitDesciption = 'Envoie en cours...';
    let _defaultMail = '';
    this.clientService.getById(order.clientId).subscribe((c: ClientModel) => {
      _defaultMail = c.mail;
      const dialogRef = this.layoutUtilsService.sendMail(
        _title,
        _description,
        _waitDesciption,
        _defaultMail
      );
      dialogRef.afterClosed().subscribe((res) => {
        if (!res) {
          return;
        }
        this.orderService.sendInvoiceViaEmail(order.id, res).subscribe(
          () =>
            this.layoutUtilsService.showActionNotification(
              'Un mail a été envoyé à ' + res,
              MessageType.Create
            ),
          () =>
            this.layoutUtilsService.showActionNotification(
              "Oops! l'e-mail n'a pas été envoyé",
              MessageType.Delete
            )
        );
      });
    });
  }

  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'PAID':
        return 'success';
      case 'CANCELED':
        return 'danger';
      case 'IN_DELIVERY':
        return 'success';
      case 'DELIVERED':
        return 'success';
      case 'WAITING_FOR_PAYMENT':
        return 'metal';
    }
    return '';
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  refresh() {}
  addSearch() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        search: this.searchInput.nativeElement.value
          ? this.searchInput.nativeElement.value
          : null,
      },
      queryParamsHandling: 'merge',
    });
  }

  archiveOrder(_item: OrderModel) {
    const _title = 'Archivage de commande ==> ' + _item.number;
    const _description = 'Voulez-vous vraiment archiver ce commande ?';
    const _waitDesciption = 'Archivage en cours...';
    const btnTitle = 'Archiver';
    const dialogRef = this.layoutUtilsService.archiveElement(
      _title,
      _description,
      _waitDesciption,
      btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.orderService.archiveOrder(_item.id).subscribe((data) => {
        this.layoutUtilsService.showActionNotification(
          'La commande est archivé',
          MessageType.Update
        );
        this.loadOrderList();
      });
    });
  }
}
