import { createAction, props } from '@ngrx/store';
import { StockModel } from './stock.model';
import { Update } from '@ngrx/entity';

export const StocksListRequested = createAction(
  '[STOCK List] Get stock list of the given product ',
  props<{ productId: number }>()
);
export const StocksPageLoadedSuccessfully = createAction(
  '[STOCK API] Stocks Page Loaded Successfully ',
  props<{ productId: number; productStocks: StockModel[] }>()
);
export const StocksPageLoadFailed = createAction(
  '[STOCK API] Stocks Page Request Failed ',
  props<{ error: any }>()
);

export const ProductStockCreate = createAction(
  '[ProductStock Create] ProductStock Created ',
  props<{ stock: StockModel }>()
);
export const ProductStockCreatedSuccessfully = createAction(
  '[ProductStock Create] ProductStock Created Successfully ',
  props<{ stock: StockModel }>()
);
export const ProductStockCreationFailed = createAction(
  '[ProductStock Create] ProductStock Creation Failed ',
  props<{ error: any }>()
);

export const ProductStockUpdate = createAction(
  '[ProductStock Update] Update ProductStock ',
  props<{ stock: StockModel }>()
);
export const ProductStockUpdatedSuccessfully = createAction(
  '[ProductStock Update] ProductStock Updated Successfully ',
  props<{ stock: StockModel }>()
);
export const ProductStockUpdateFailed = createAction(
  '[ProductStock Update] ProductStock Update Failed ',
  props<{ error: any }>()
);

export const ProductStockDelete = createAction(
  '[ProductStock Delete] ProductStock Deleted ',
  props<{ stockId: number }>()
);
export const ProductStockDeletedSuccessfully = createAction(
  '[ProductStock Delete] ProductStock deleted Successfully ',
  props<{ stockId: number }>()
);
export const ProductStockDeleteFailed = createAction(
  '[ProductStock Delete] ProductStock delete Failed ',
  props<{ error: any }>()
);
