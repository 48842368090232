import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'kt-send-pdf-mail',
  templateUrl: './send-pdf-mail.component.html',
})
export class SendPdfMailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SendPdfMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  viewLoading = false;
  mail = new FormControl('', Validators.required);

  ngOnInit(): void {
    this.mail.setValue(this.data.defaultMail);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    setTimeout(() => {
      this.dialogRef.close(this.mail.value);
    }, 1000);
  }
}
