import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { ClaimModel } from './claim.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { QueryParamsModel } from '../../_base/crud';

@Injectable()
export class ClaimService extends AbstractService<ClaimModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}complaint`;
  }

  public getClaims(
    queryParams: QueryParamsModel
  ): Observable<Page<ClaimModel>> {
    const params = new HttpParams()
      .append(
        'page',
        queryParams?.pageNumber ? queryParams.pageNumber.toString() : '0'
      )
      .append(
        'size',
        queryParams?.pageSize ? queryParams.pageSize.toString() : '10'
      )
      .append(
        'keyword',
        queryParams?.filter?.query ? queryParams.filter.query : ''
      )
      .append(
        'sort',
        queryParams?.sortField && queryParams?.sortOrder
          ? queryParams?.sortField + ',' + queryParams.sortOrder
          : 'id,desc'
      )
      .append(
        'status',
        queryParams?.filter?.status ? queryParams?.filter.status : ''
      )
      .append(
        'priorities',
        queryParams?.filter?.priorities ? queryParams?.filter.priorities : ''
      )
      .append(
        'admins',
        queryParams?.filter?.admins ? queryParams?.filter.admins : '0'
      );

    const url = `${this.url}/unarchived`;
    return this.http.get<Page<ClaimModel>>(url, { params });
  }

  public getClaim(claimId: number): Observable<ClaimModel> {
    const url = `${this.url}/${claimId}/admin`;
    return this.http.get<ClaimModel>(url);
  }

  public archiveClaim(claimId: number): Observable<ClaimModel> {
    const url = `${this.url}/${claimId}/archive`;
    return this.http.post<ClaimModel>(url, null);
  }

  public changeClaimStatus(claim: ClaimModel): Observable<ClaimModel> {
    const url = `${this.url}/${claim.id}/status`;
    return this.http.patch<ClaimModel>(url, claim.status);
  }

  public affectAdmin(claim: ClaimModel): Observable<ClaimModel> {
    const url = `${this.url}/${claim.id}/associate-admin`;
    return this.http.patch<ClaimModel>(url, claim.admin.id);
  }

  public exportClaim(id: number): Observable<Blob> {
    const url = `${this.url}/${id}/export`;
    return this.http.get(url, {responseType: 'blob'});
}
}
