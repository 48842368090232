import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AccessoriesService } from '../accessories/accessories.service';
// Actions
import * as AccessoriesActions from './accesories.actions';
import { LayoutUtilsService, MessageType } from '../../../_base/crud';

@Injectable()
export class AccessorieEffects {
  constructor(
    private actions$: Actions,
    private accessorieService: AccessoriesService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}


  // add Accessories to category
    Category3LevelRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccessoriesActions.Categories3LevelSave),
            switchMap((action) =>
                this.accessorieService
                    .addAccessoire(
                        action.idOwner,
                        action.id
                    )
                    .pipe(
                        map((categoryPage) => this.mapResult(categoryPage , action.id)
                        ),
                        catchError((error) =>
                            of(AccessoriesActions.Categories3LevelSaveFailed({ error }))
                        )
                    )
               )
            )
       );

  // get list accessories selected
  ListSelectedAccessories$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AccessoriesActions.accessoriesListPageRequested),
    switchMap((action) =>
      this.accessorieService
        .getAllAccessoire(
          action.idOwner
        )
        .pipe(
          map((categoryPage) =>
          AccessoriesActions.accessoriesListPageRequestedSuccessfully({
            categories: categoryPage
            })
          ),
          catchError((error) =>
            of(AccessoriesActions.accessoriesListPageRequestedFailed({ error }))
          )))));

  // delete accessoir from category
  AccessorieDeleted$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AccessoriesActions.AccessorieDeleted),
    switchMap((action) =>
      this.accessorieService.deleteAccessorie(action.categoryId, action.id).pipe(
        map(() =>
        AccessoriesActions.AccessorieDeletedSuccessfully({
            categoryId: action.categoryId,
            id: action.id
          })
        ),
        catchError((error) =>
          of(AccessoriesActions.AccessorieDeleteFailed({ error }))
        )))));

AccessorieDeletedSuccessfully$ = createEffect(
  () =>
    this.actions$.pipe(
      ofType(AccessoriesActions.AccessorieDeletedSuccessfully),
      switchMap((action) =>
        of(
          this.layoutUtilsService.showActionNotification(
            'Catégorie supprimée',
            MessageType.Delete,
            3000,
            false
          )))),
  { dispatch: false }
);

AccessorieDeleteFailed$ = createEffect(
  () =>
    this.actions$.pipe(
      ofType(AccessoriesActions.AccessorieDeleteFailed),
      switchMap((action) =>
        of()
      )),
  { dispatch: false });

    mapResult(categoryPage, idAcc: number) {
        const accessory = categoryPage.accessories.filter( acc => acc.id === idAcc);
        return AccessoriesActions.Categories3LevelSavedSuccessfully({
            category: accessory[0]
        });
    }
}
