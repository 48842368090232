import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../core/_base/crud';
import { ActivatedRoute, Router } from '@angular/router';
import { KtDialogService } from '../../../../core/_base/layout';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { merge, Observable, of, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { OrderModel } from '../../../../core/e-commerce/orders/order.model';
import { OrdersService } from '../../../../core/e-commerce/orders/orders.service';
import { finalize, tap } from 'rxjs/operators';
import { ProductsService } from '../../../../core/e-commerce/products/products.service';
import { ClientService } from '../../../../core/e-commerce/clients/client.service';
import { OrderDetailComponent } from '../../apps/e-commerce/orders/order-detail/order-detail.component';
import { CompaniesService } from '../../../../core/e-commerce/companies/companies.service';

@Component({
  selector: 'kt-archived-orders-list',
  templateUrl: './archived-order-list.component.html',
  styleUrls: ['./archived-order-list.component.scss'],
})
export class ArchivedOrderListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  dataSource: MatTableDataSource<OrderModel> = new MatTableDataSource([]);

  totalElements = 0;
  displayedColumns = [
    'select',
    'id',
    'client',
    'company',
    'createdAt',
    'totalPrice',
    'status',
    'actions',
  ];

  loading$: Observable<boolean> = of(true);

  filterStatus = new FormControl('');

  selection = new SelectionModel<OrderModel>(true, []);

  lastQuery: QueryParamsModel;

  pageTitle = 'Liste de commandes archivés';

  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private ktDialogService: KtDialogService,
    private orderService: OrdersService,
    private activatedRoute: ActivatedRoute,
    private productService: ProductsService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private clientService: ClientService,
    private companyService: CompaniesService
  ) {}

  /**
   * On init
   */

  ngOnInit(): void {
    this.loadOrderList();

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadOrderList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }

  loadOrderList(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }
    this.orderService
      .getArchivedOrders(queryParams)
      .pipe(
        finalize(() => {
          this.loading$ = of(false);
        })
      )
      .subscribe((orders) => {
        orders.content.forEach((o) =>
          this.clientService.getById(o.clientId).subscribe((c) => {
            o.clientName =
              c.name.toLowerCase() + ' ' + c.lastname.toLowerCase();
            this.companyService.getById(c.companyId).subscribe((company) => {
              o.companyName = company.name;
              o.clientNumber = company.accountNumber;
            });
          })
        );
        this.dataSource.data = orders.content;
        this.totalElements = orders.totalElements;
      });
  }

  ngAfterViewInit() {}

  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }

    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  viewOrder(item: OrderModel) {
    if (!item.items[0] || !item.items[0].product) {
      item.items.forEach((i) => {
        this.productService
          .getById(i.productId)
          .subscribe((p) => (i.product = p));
      });
    }
    this.dialog.open(OrderDetailComponent, {
      data: item,
      width: '900px',
    });
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  refresh() {}

  addSearch() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        search: this.searchInput.nativeElement.value
          ? this.searchInput.nativeElement.value
          : null,
      },
      queryParamsHandling: 'merge',
    });
  }
  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'PAID':
        return 'success';
      case 'CANCELED':
        return 'danger';
      case 'IN_DELIVERY':
        return 'success';
      case 'DELIVERED':
        return 'success';
      case 'WAITING_FOR_PAYMENT':
        return 'metal';
    }
    return '';
  }

  desarchiveOrder(_item: OrderModel) {
    const _title = 'Désarchiver le produit';
    const _description = 'Voulez-vous vraiment dérachiver ce produit ?';
    const _waitDesciption = 'Désarchivage en cours...';
    const btnTitle = 'désarchiver';
    const dialogRef = this.layoutUtilsService.archiveElement(
      _title,
      _description,
      _waitDesciption,
      btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.orderService.unarchiveOrder(_item.id).subscribe((data) => {
        this.layoutUtilsService.showActionNotification(
          'La commande est désarchivé',
          MessageType.Update
        );
        this.loadOrderList();
      });
    });
  }
}
