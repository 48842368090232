import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {LayoutUtilsService, QueryParamsModel} from '../../../../../../core/_base/crud';
import {MatPaginator, MatSort} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {fromEvent, merge, Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import {debounceTime, distinctUntilChanged, skip, tap} from 'rxjs/operators';
import {LogsModel} from '../../../../../../core/e-commerce/odoo/logs.model';
import {LogsDatasource} from '../../../../../../core/e-commerce/odoo/logs.datasource';
import {LogsActions} from '../../../../../../core/e-commerce';
import {DatePipe} from '@angular/common';
import {selectLogsPageLastQuery} from '../../../../../../core/e-commerce/odoo/logs.selector';

@Component({
  selector: 'kt-logs-list',
  templateUrl: './logs-list.component.html',
  styleUrls: ['./logs-list.component.scss']
})
export class LogsListComponent implements OnInit , OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('sort2', { static: true }) sort2: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  logsForm: FormGroup;
  dataSource: LogsDatasource;
  displayedColumns = ['select', 'id', 'operation', 'status', 'type', 'nom', 'date', 'queue'];
  logsResult: LogsModel[] = [];

  loadingAfterSubmit = false;
  loading$: Observable<boolean>;
  filterOperation = new FormControl('');
  filterAction = new FormControl('');
  filterModel = new FormControl('');
  filterStatus = new FormControl('');
  startDate = '';
  endDate = '';
  dateFilter = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });
  lastQuery: QueryParamsModel;

  private subscriptions: Subscription[] = [];
  constructor(
      private fb: FormBuilder,
      private store: Store<AppState>,
      private layoutUtilsService: LayoutUtilsService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
  ) {}

  ngOnInit(): void {
    this.logsForm = this.fb.group({
      id: [null],
      label: ['', Validators.required],
    });

    this.paginator._changePageSize(150);
    this.sort.active = 'createdAt';
    this.sort.direction = 'desc';

    const sortSubscription = this.sort.sortChange.subscribe(
        () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
        this.sort.sortChange,
        this.paginator.page
    )
        .pipe(tap(() => this.loadLogsList()))
        .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    /* search*/
    const searchSubscription = fromEvent(
        this.searchInput.nativeElement,
        'keyup'
    )
        .pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => {
              this.paginator.pageIndex = 0;
              this.loadLogsList();
            })
        )
        .subscribe();
    this.subscriptions.push(searchSubscription);
    /**/
    this.loadLogsList();
    // Init DataSource
    this.dataSource = new LogsDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
        .pipe(skip(1), distinctUntilChanged())
        .subscribe((res) => {
          this.logsResult = res;
        });
    this.subscriptions.push(entitiesSubscription);
    /**/
    const lastQuerySubscription = this.store
        .pipe(select(selectLogsPageLastQuery))
        .subscribe((res) => (this.lastQuery = res));
    this.loadLogsList(this.lastQuery);
    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(() => {
      const pageSize = this.lastQuery.pageSize;
      const pageNumber = this.lastQuery.pageNumber;
      this.paginator._changePageSize(pageSize);
      this.paginator.pageIndex = pageNumber;
      this.loadLogsList(this.lastQuery);
    });

    this.subscriptions.push(
        lastQuerySubscription,
        entitiesSubscription,
        routeSubscription,
        sortSubscription,
        searchSubscription
    );
  }

  loadLogsList(filter?: QueryParamsModel) {
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
          this.filterConfiguration(),
          this.sort.direction,
          this.sort.active,
          this.paginator.pageIndex,
          this.paginator.pageSize
      );
    }
    queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
    );
    this.store.dispatch(
        LogsActions.LogsPageRequested({
          page: queryParams,
        })
    );
  }

  filterByDate() {
    if (this.dateFilter.get('endDate').value) {
      this.startDate = new DatePipe('fr-FR').transform(
          new Date(this.dateFilter.get('startDate').value),
          'YYYY/MM/dd'
      );
      this.endDate = new DatePipe('fr-FR').transform(
          new Date(this.dateFilter.get('endDate').value),
          'YYYY/MM/dd'
      );
    } else {
      this.startDate = '';
      this.endDate = '';
    }
    this.loadLogsList();
  }

  filterConfiguration(): string {
    const filter: any = {};
    if (this.filterOperation.value && this.filterOperation.value !== '') {
      filter.operation = this.filterOperation.value;
    }
    if (this.filterAction.value && this.filterAction.value !== '') {
      filter.action = this.filterAction.value;
    }
    if (this.filterModel.value && this.filterModel.value !== '') {
      filter.model = this.filterModel.value;
    }
    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }
    if (this.dateFilter.value) {
      filter.startDate = this.startDate;
      filter.endDate = this.endDate;
    }
    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  editLogs(logId) {
    this.router.navigate(['ecommerce', 'odoo', 'logs', 'edit', logId]);
  }
}
