import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { DataSource } from '@angular/cdk/collections';
import { ClientQuestionModel } from '../client-question/client-question.model';
import { ClientQuestionService } from '../client-question/client-question.service';
import {
  selectFAQsInitWaitingMessage,
  selectFAQsInStore,
  selectFAQsPageLoading,
} from './faq.selectors';

/**
 * Datasource that doesnt use NGRX
 * check this course https://blog.angular-university.io/angular-material-data-table/
 */

export class QuestionAnswerDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectFAQsPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectFAQsInitWaitingMessage)
    );

    this.store
      .pipe(select(selectFAQsInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
