// Angular
import { Injectable } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
// Partials for CRUD
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UnblockEntityDialogComponent,
  UpdateStatusDialogComponent,
} from '../../../../views/partials/content/crud';
import { ArchiveEntityDialogComponent } from '../../../../views/partials/content/crud/archive-entity-dialog/archive-entity-dialog.component';
import { SendPdfMailComponent } from '../../../../views/pages/apps/e-commerce/send-pdf-mail/send-pdf-mail.component';

export enum MessageType {
  Create,
  Read,
  Update,
  Delete,
  Unblock,
}

@Injectable()
export class LayoutUtilsService {
  /**
   * Service constructor
   *
   * @param snackBar: MatSnackBar
   * @param dialog: MatDialog
   */
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  archiveElement(
    title: string = '',
    description: string = '',
    waitDesciption: string = '',
    btnTitle: string = ''
  ) {
    return this.dialog.open(ArchiveEntityDialogComponent, {
      data: { title, description, waitDesciption, btnTitle },
      width: '440px',
    });
  }

  /**
   * Showing (Mat-Snackbar) Notification
   *
   * @param _message
   * @param _type
   * @param _duration
   * @param _showCloseButton
   * @param _showUndoButton
   * @param _undoButtonDuration
   * @param _verticalPosition
   */
  showActionNotification(
    _message: string,
    _type: MessageType = MessageType.Create,
    _duration: number = 10000,
    _showCloseButton: boolean = true,
    _showUndoButton: boolean = true,
    _undoButtonDuration: number = 3000,
    _verticalPosition: 'top' | 'bottom' = 'bottom'
  ) {
    const _data = {
      message: _message,
      snackBar: this.snackBar,
      showCloseButton: _showCloseButton,
      showUndoButton: _showUndoButton,
      undoButtonDuration: _undoButtonDuration,
      verticalPosition: _verticalPosition,
      type: _type,
      action: 'Annuler',
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      duration: _duration,
      data: _data,
      verticalPosition: _verticalPosition,
    });
  }

  /**
   * Showing Confirmation (Mat-Dialog) before Entity Removing
   *
   * @param title: stirng
   * @param description: stirng
   * @param waitDesciption: string
   */
  deleteElement(
    title: string = '',
    description: string = '',
    waitDesciption: string = ''
  ) {
    return this.dialog.open(DeleteEntityDialogComponent, {
      data: { title, description, waitDesciption },
      width: '440px',
    });
  }

  /**
   * Showing Fetching Window(Mat-Dialog)
   *
   * @param _data: any
   */
  fetchElements(_data) {
    return this.dialog.open(FetchEntityDialogComponent, {
      data: _data,
      width: '400px',
    });
  }

  /**
   * Showing Update Status for Entites Window
   *
   * @param title: string
   * @param statuses: string[]
   * @param messages: string[]
   */
  updateStatusForEntities(title, statuses, messages) {
    return this.dialog.open(UpdateStatusDialogComponent, {
      data: { title, statuses, messages },
      width: '480px',
    });
  }

  /**
   * Showing Unblock Entity for Entities Window
   *
   * @param title: string
   * @param description: string
   * @param waitDescription: string
   */
  unblockEntity(title, description, waitDesciption) {
    return this.dialog.open(UnblockEntityDialogComponent, {
      data: { title, description, waitDesciption },
      width: '440px',
    });
  }

  sendMail(
    title: string = '',
    description: string = '',
    waitDesciption: string = '',
    defaultMail: string = ''
  ) {
    return this.dialog.open(SendPdfMailComponent, {
      data: { title, description, waitDesciption, defaultMail },
      width: '440px',
    });
  }
}
