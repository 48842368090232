import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  skip,
  take,
  delay,
} from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../core/_base/crud';
import { SubheaderService } from '../../../../../core/_base/layout';
import * as UserActions from '../../../../../core/user-management/user.actions';
import { UsersDataSource } from '../../../../../core/user-management/users.datasource';
import { UserModel } from '../../../../../core/_models/user.model';
import { RolesTable } from '../../../../../core/user-management/roles.table';
import { Role } from '../../../../../core/user-management/role.model';

@Component({
  selector: 'kt-users-list',
  templateUrl: './users-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements OnInit, OnDestroy {
  dataSource: UsersDataSource;
  displayedColumns = [
    'select',
    'id',
    'mail',
    'name',
    'lastname',
    '_roles',
    'actions',
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  lastQuery: QueryParamsModel;

  selection = new SelectionModel<UserModel>(true, []);
  usersResult: UserModel[] = [];
  allRoles: Role[] = RolesTable.roles;

  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   *
   * @param activatedRoute: ActivatedRoute
   * @param store: Store<AppState>
   * @param router: Router
   * @param layoutUtilsService: LayoutUtilsService
   * @param subheaderService: SubheaderService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // load roles list
    // const rolesSubscription = this.store.pipe(select(selectAllRoles)).subscribe(res => this.allRoles = res);
    // this.subscriptions.push(rolesSubscription);

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(
        tap(() => {
          this.loadUsersList();
        })
      )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadUsersList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('User management');

    // Init DataSource
    this.dataSource = new UsersDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.usersResult = res;
      });
    this.subscriptions.push(entitiesSubscription);

    // First Load
    of(undefined)
      .pipe(take(1), delay(1000))
      .subscribe(() => {
        // Remove this line, just loading imitation
        this.loadUsersList();
      });
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  /**
   * Load users list
   */
  loadUsersList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.store.dispatch(
      // new UsersPageRequested({ page: queryParams }));
      UserActions.AllUsersRequested()
    );
    this.selection.clear();
  }

  /** FILTRATION */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
    filter.lastName = searchText;
    filter.username = searchText;
    filter.email = searchText;
    filter.fillname = searchText;
    return filter;
  }

  /** ACTIONS */
  /**
   * Delete user
   *
   * @param _item: User
   */
  deleteUser(_item: UserModel) {
    const _title = "Supprimer l'utilisateur";
    const _description = 'Voulez-vous vraiment supprimer cet utilisateur ?';
    const _waitDesciption = 'Suppression en cours...';
    const _deleteMessage = `l'utilisateur a été supprimé`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(UserActions.UserDeleted({ id: _item.id }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  /**
   * Fetch selected rows
   */
  fetchUsers() {
    const messages = [];
    this.selection.selected.forEach((elem) => {
      messages.push({
        text: `${elem.name}, ${elem.mail}`,
        id: elem.id.toString(),
        status: elem.name,
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.usersResult.length;
    return numSelected === numRows;
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.usersResult.length) {
      this.selection.clear();
    } else {
      this.usersResult.forEach((row) => this.selection.select(row));
    }
  }

  /* UI */
  /**
   * Returns user roles string
   *
   * @param user: User
   */
  //todo
  getUserRolesStr(user: UserModel): string {
    const titles: string[] = [];
    // each(user.role, (roleId: string) => {
    // 	const _role = find(this.allRoles, (role: Role) => role.id === roleId);
    // 	if (_role) {
    // 		titles.push(_role.title);
    // 	}
    // });
    titles.push(user.role);
    return titles.join(', ');
  }

  /**
   * Redirect to edit page
   *
   * @param id
   */
  editUser(id) {
    this.router.navigate(['../users/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }
}
