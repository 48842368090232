import { createAction, props } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { ClaimModel } from './claim.model';

export const ClaimPageRequest = createAction(
  '[Claim API] Claim Page Request',
  props<{ queryParams: QueryParamsModel }>()
);
export const ClaimPageLoadedSuccessfully = createAction(
  '[Claim API] Claim Page Loaded Successfully',
  props<{ claims: ClaimModel[]; totalCount: number; page: number }>()
);
export const ClaimPageLoadFailed = createAction(
  '[Claim API] Claim Page Load Failed',
  props<{ error: any }>()
);

export const ClaimRequest = createAction(
  '[Claim API] Claim Request',
  props<{ claimId: number }>()
);
export const ClaimLoadedSuccessfully = createAction(
  '[Claim API] Claim Loaded Successfully',
  props<{ claim: ClaimModel }>()
);
export const ClaimLoadFailed = createAction(
  '[Claim API] Claim Load Failed',
  props<{ error: any }>()
);

export const ClaimStatusChange = createAction(
  '[Claim API] Claim Status Change',
  props<{ claim: ClaimModel }>()
);
export const ClaimStatusChangedSuccessfully = createAction(
  '[Claim API] Claim Status Changed Successfully',
  props<{ claim: ClaimModel }>()
);
export const ClaimStatusChangeFailed = createAction(
  '[Claim API] Claim Status Change Failed',
  props<{ error: any }>()
);

export const ClaimArchive = createAction(
  '[Claim API] Claim Archive',
  props<{ claimId: number }>()
);
export const ClaimArchivedSuccessfully = createAction(
  '[Claim API] Claim Archived Successfully ',
  props<{ claim: ClaimModel }>()
);
export const ClaimArchiveFailed = createAction(
  '[Claim API] Claim Archive Failed ',
  props<{ error: any }>()
);

export const ClaimAdminAffectation = createAction(
  '[Claim API] Claim Affectation',
  props<{ claim: ClaimModel }>()
);
export const ClaimAdminAffectedSuccessfully = createAction(
  '[Claim API] Claim Affected Successfully ',
  props<{ claim: ClaimModel }>()
);
export const ClaimAdminAffectationFailed = createAction(
  '[Claim API] Claim Affectation Failed ',
  props<{ error: any }>()
);
