import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductModel } from './product.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as ProductActions from './product.actions';
import {ProductModelOpt} from './productOptm.model';

export interface ProductState extends EntityState<ProductModelOpt> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedProductId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    errorMessage: string;
}

export const adapter: EntityAdapter<ProductModelOpt> =
    createEntityAdapter<ProductModelOpt>();

export const initialProductState: ProductState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedProductId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    errorMessage: '',

});

const productReducerOpt = createReducer(
    initialProductState,
    on(ProductActions.ProductsPageRequestedOpt, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        lastQuery: action.queryParams,
    })),
    on(ProductActions.ProductsPageLoadedSuccessfullyOPt, (state, action) =>
        adapter.addAll(action.products, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(ProductActions.ProductsPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
);

export function productsReducerOpt(
    state: ProductState | undefined,
    action: Action
) {
    return productReducerOpt(state, action);
}
