import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { ProductModel } from './product.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { QueryParamsModel } from '../../_base/crud';
import { OdooStockModel } from '../OdooStocks/OdooStockModel';
import {ProductModelOpt} from './productOptm.model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService extends AbstractService<ProductModel> {
  url: string;

  constructor(
    protected http: HttpClient
    ) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}product`;
  }

  public getAllPaged2(
    queryParams: QueryParamsModel
  ): Observable<Page<ProductModel>> {
    let params = new HttpParams()
      .append(
        'page',
        queryParams.pageNumber ? queryParams.pageNumber.toString() : '0'
      )
      .append(
        'size',
        queryParams.pageSize ? queryParams.pageSize.toString() : '10'
      )
      .append(
        'search',
        queryParams.filter.query ? queryParams.filter.query : ''
      )
      .append(
        'sort',
        queryParams.sortField && queryParams.sortOrder
          ? `${queryParams.sortField},${queryParams.sortOrder}`
          : ''
      );

    params = queryParams.filter.categories
      ? params.append('categories', queryParams.filter.categories.toString())
      : params;

    params = queryParams.filter.status
      ? params.append('visible', queryParams.filter.status.toString())
      : params;

    params = queryParams.filter.productStatus
      ? params.append('status', queryParams.filter.productStatus.toString())
      : params;
    params = queryParams.filter.manufacturer
      ? params.append('manufacture', queryParams.filter.manufacturer.toString())
      : params;

    params = queryParams.filter.productSource
        ? params.append('source', queryParams.filter.productSource.toString())
        : params;
    params = queryParams.filter.startDate
      ? params.append('dateMin', queryParams.filter.startDate)
      : params;
    params = queryParams.filter.endDate
      ? params.append('dateMax', queryParams.filter.endDate)
      : params;

    const url = `${this.url}`;
    return this.http.get<Page<ProductModel>>(url, { params });
  }

  public getAllPaged2Opt(
      queryParams: QueryParamsModel
  ): Observable<Page<ProductModelOpt>> {
    let params = new HttpParams()
        .append(
            'page',
            queryParams.pageNumber ? queryParams.pageNumber.toString() : '0'
        )
        .append(
            'size',
            queryParams.pageSize ? queryParams.pageSize.toString() : '10'
        )
        .append(
            'search',
            queryParams.filter.query ? queryParams.filter.query : ''
        )
        .append(
            'sort',
            queryParams.sortField && queryParams.sortOrder
                ? `${queryParams.sortField},${queryParams.sortOrder}`
                : ''
        );

    params = queryParams.filter.categories
        ? params.append('categories', queryParams.filter.categories.toString())
        : params;

    params = queryParams.filter.status
        ? params.append('visible', queryParams.filter.status.toString())
        : params;

    params = queryParams.filter.productStatus
        ? params.append('status', queryParams.filter.productStatus.toString())
        : params;
    params = queryParams.filter.manufacturer
        ? params.append('manufacture', queryParams.filter.manufacturer.toString())
        : params;

    params = queryParams.filter.productSource
        ? params.append('source', queryParams.filter.productSource.toString())
        : params;
    params = queryParams.filter.startDate
        ? params.append('dateMin', queryParams.filter.startDate)
        : params;
    params = queryParams.filter.endDate
        ? params.append('dateMax', queryParams.filter.endDate)
        : params;

    const url = `${this.url}`;
    return this.http.get<Page<ProductModelOpt>>(url, { params });
  }

  public getArchivedProducts(
    queryParams: QueryParamsModel
  ): Observable<Page<ProductModel>> {
    let params = new HttpParams()
      .append(
        'page',
        queryParams.pageNumber ? queryParams.pageNumber.toString() : '0'
      )
      .append(
        'size',
        queryParams.pageSize ? queryParams.pageSize.toString() : '10'
      )
      .append(
        'search',
        queryParams.filter.query ? queryParams.filter.query : ''
      )
      .append(
        'sort',
        queryParams.sortField && queryParams.sortOrder
          ? `${queryParams.sortField},${queryParams.sortOrder}`
          : 'id,asc'
      );

    params = queryParams.filter.categories
      ? params.append('categories', queryParams.filter.categories.toString())
      : params;

    const url = `${this.url}/archived`;
    return this.http.get<Page<ProductModel>>(url, { params });
  }

  public deleteResource(
    productId: number,
    resourceId: number
  ): Observable<any> {
    const url = `${this.url}/${productId}/productresource/${resourceId}`;
    return this.http.delete<void>(url);
  }

  public setResourceAsDefault(productId: number, resourceId: number) {
    const url = `${this.url}/${productId}/productresource/${resourceId}/isdefault`;
    return this.http.patch(url, null);
  }

  public archiveProduct(productId: number): Observable<any> {
    const url = `${this.url}/archive/${productId}`;
    return this.http.post<void>(url, null);
  }

  public unarchiveProduct(productId: number): Observable<any> {
    const url = `${this.url}/unarchive/${productId}`;
    return this.http.post<void>(url, null);
  }

  public changeProductVisibility(product: ProductModel): Observable<any> {
    const url = `${this.url}/${product.visible ? 'in' : ''}visible/${
      product.id
    }`;
    return this.http.patch<void>(url, null);
  }

  public linkProductToSubstitution(payload: any): Observable<ProductModel> {
    const url = `${this.url}/substitution`;
    return this.http.patch<ProductModel>(url, payload);
  }

  public unlinkProductToSubstitution(id: number): Observable<void> {
    const url = `${this.url}/${id}/substitution`;
    return this.http.delete<void>(url);
  }
  public ExportExcelProducts(categoryId: number): Observable<Blob> {
    const url = `${this.url}/download/product/${categoryId}`;
    return this.http.get(url, { responseType: 'blob'});
  }
  public getEDIStocks(productId: number): Observable<OdooStockModel[]> {
    return this.http.get<OdooStockModel[]>(`${this.url}/${productId}/edistocks`);
  }
  public getACHATStocks(productId: number): Observable<OdooStockModel[]> {
    return this.http.get<OdooStockModel[]>(`${this.url}/${productId}/achatstocks`);
  }
}
