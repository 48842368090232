import { HttpClient } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressModel } from './address.model';

@Injectable()
export class SupplierAddressesService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}supplier`;
  }

  public addNewAddress(
    companyId: number,
    address: AddressModel
  ): Observable<AddressModel> {
    const url = `${this.url}/${companyId}/address`;
    return this.http.post<AddressModel>(url, address);
  }

  public updateAddress(address: AddressModel): Observable<AddressModel> {
    const url = `${this.url}/address`;
    return this.http.put<AddressModel>(url, address);
  }

  public deleteAddress(supplierId: number, id: number): Observable<void> {
    const url = `${this.url}/${supplierId}/addresses/${id}`;
    return this.http.delete<void>(url);
  }

  public getAddressBySupplier(supplierId: number): Observable<AddressModel[]> {
    const url = `${this.url}/${supplierId}/addresses`;
    return this.http.get<AddressModel[]>(url);
  }
}
