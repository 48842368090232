// NGRX
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../_base/crud';

// Models
import { UserModel } from '../_models/user.model';

export const AllUsersRequested = createAction(
  '[Users Module] All Users Requested '
);
export const AllUsersLoaded = createAction(
  '[Users API] All users Loaded successfully ',
  props<{ users: UserModel[] }>()
);
export const AllUsersRequestedFailed = createAction(
  '[Users API] Users Loading Failed',
  props<{ error: any }>()
);

export const UserOnServerCreated = createAction(
  '[Edit User Component] User On Server Created',
  props<{ user: UserModel }>()
);
export const UserAddedSuccessfully = createAction(
  '[Users API] User on server added Successfully',
  props<{ user: UserModel }>()
);
export const AddUserFailed = createAction(
  '[Users API] Add User failed',
  props<{ error: any }>()
);

export const UserCreated = createAction(
  '[Edit User Dialog] User Created',
  props<{ user: UserModel }>()
);
export const UserUpdated = createAction(
  '[Edit User Dialog] User Updated',
  props<{ partialUser: Update<UserModel>; user: UserModel }>()
);
export const UserUpdatedSuccessfully = createAction(
  '[Users API] User Updated Successfully',
  props<{ partialUser: Update<UserModel>; user: UserModel }>()
);
export const UserUpdateFailed = createAction(
  '[Users API] User Update Failed',
  props<{ error: any }>()
);

export const UserDeleted = createAction(
  '[Users List Page] User Deleted',
  props<{ id: number }>()
);
export const UserDeletingFailed = createAction(
  '[Users API] Deleting User Failed',
  props<{ error: any }>()
);
export const UserDeletedSuccessfully = createAction(
  '[Users API] User Deleted Successfully',
  props<{ id: number }>()
);

// export const UsersPageRequested = createAction('[Users List Page] Users Page Requested', props<{page: QueryParamsModel}>());
// export const UsersPageLoaded = createAction('[Users API] Users Page Loaded',props<{users:UserModel[], totalCount: number, page: QueryParamsModel}>());
// export const UsersPageCancelled = createAction('[Users API] Users Page Cancelled');
// export const UsersPageToggleLoading = createAction('[Users] Users Page Toggle Loading',props<{isLoading:boolean}>());
// export const UsersActionToggleLoading = createAction('[Users] Users Action Toggle Loading', props<{isLoading: boolean}>());
