import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CategoryModel } from './category.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
// actions
import * as CategoryActions from './category.action';
import { Response } from '../../_base/crud/models/response';
import {
  CategoryCreationFailed,
  CategoryDeleteFailed,
  CategoryUpdateFailed,
} from './category.action';
import {CategoryOptModel} from './categoryOpt.model';

export interface CategoryState extends EntityState<CategoryModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedCategoryId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<CategoryModel> =
  createEntityAdapter<CategoryModel>();

export const initialCategoryState: CategoryState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedCategoryId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

// @ts-ignore
// @ts-ignore
const categoryReducer = createReducer(
  initialCategoryState,
  on(CategoryActions.CategoriesPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
    lastQuery: action.page,
  })),
  on(CategoryActions.CategoryUploadProgressed, (state, action) => ({
    ...state,
    progress: action.progress,
  })),

  on(CategoryActions.CategoriesPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.categories, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(CategoryActions.CategoriesPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // creation
  on(CategoryActions.CategoryCreated, (state) => ({
    ...state,
    actionsLoading: false,
    lastAction: '',
  })),
  on(CategoryActions.CategoryCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.category, {
      ...state,
      actionsLoading: true,
      lastCreatedCategoryId: action.category.id,
      lastAction: 'EDIT.ADD_MESSAGE',
      progress: 0,
    })
  ),
  on(CategoryCreationFailed, (state: CategoryState, action) => {
    let errorMessage: string;
    switch (action.error.status) {
      case 500:
        errorMessage = 'EDIT.ERROR.SERVER';
        break;
      case 403:
        errorMessage = 'AUTH.VALIDATION.NOT_AUTHORIZED';
        break;
      case 404:
        errorMessage = 'EDIT.ERROR.NOT_FOUND';
        break;
      case 409:
        errorMessage = ' Le nom de la catégorie doit être unique';
        break;
      default:
        errorMessage = 'EDIT.ERROR.UNKNOWN';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage,
      error: action.error,
    };
  }),
  // Update
  on(CategoryActions.CategoryUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
    progress: 0,
  })),
  on(CategoryActions.CategoryUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialCategory, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
      progress: 0,
    })
  ),
  on(CategoryUpdateFailed, (state: CategoryState, action) => {
    let errorMessage: string;
    switch (action.error.status) {
      case 500:
        errorMessage = 'EDIT.ERROR.SERVER';
        break;
      case 403:
        errorMessage = 'AUTH.VALIDATION.NOT_AUTHORIZED';
        break;
      case 404:
        errorMessage = 'EDIT.ERROR.NOT_FOUND';
        break;
      default:
        errorMessage = 'EDIT.ERROR.UNKNOWN';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage,
      error: action.error,
    };
  }),
  // Delete
  on(CategoryActions.CategoryDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(CategoryActions.CategoryDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.categoryId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE_CATEGORY_SIMPLE.MESSAGE',
    })
  ),
  on(CategoryDeleteFailed, (state: CategoryState, action) => {
    let errorMessage: string;
    switch (action.error.status) {
      case 500:
        errorMessage = 'DELETE_CATEGORY_SIMPLE.FOREIGN_KEY_ERROR';
        break;
      case 403:
        errorMessage = 'AUTH.VALIDATION.CANT_DELETE';
        break;
      case 404:
        errorMessage = 'AUTH.VALIDATION.NOT_FOUND';
        break;
      case 400:
        errorMessage = 'Action refusé ! Cette catégorie contient une ou plusieurs sous catégories';
        break;
      case 409:
        errorMessage = 'Action refusé ! Cette catégorie contient un ou plusieurs produits';
        break;
      default:
        errorMessage = 'AUTH.VALIDATION.NO_CONNECTION';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage,
      error: action.error,
    };
  }),
  on(CategoryActions.CategoriesListRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(CategoryActions.CategoriesListRequestedSuccessfully, (state, action) =>
    adapter.addAll(action.category, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(CategoryActions.CategoriesListRequestFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  
);

export function categoriesReducer(
  state: CategoryState | undefined,
  action: Action
) {
  return categoryReducer(state, action);
}
