import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  GestureConfig,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatFormFieldModule,
} from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { InlineSVGModule } from 'ng-inline-svg';
import 'hammerjs';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';
import { PartialsModule } from './views/partials/partials.module';
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from './core/_base/layout';
import { AuthModule } from './views/pages/auth/auth.module';
import {
  HttpUtilsService,
  LayoutUtilsService,
  TypesUtilsService,
} from './core/_base/crud';
import { LayoutConfig } from './core/_config/layout.config';
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { productStocksReducer } from './core/e-commerce/stocks/stock.reducers';
import { StockEffects } from './core/e-commerce/stocks/stock.effects';

import { FaqEffects } from './core/service-client/questionanswer/faq.effects';
import { fAQReducer } from './core/service-client/questionanswer/faq.reducers';
import { BlogsReducer } from './core/blog/blog.reducers';
import { BlogEffects } from './core/blog/blog.effects';
import { questionsReducer } from './core/service-client/client-question/client-question.reducers';
import { ClientQuestionEffects } from './core/service-client/client-question/client-question.effects';
import { ClientQuestionService } from './core/service-client/client-question/client-question.service';
import { AboutsReducer } from './core/about/about.reducers';
import { AboutEffects } from './core/about/about.effects';
import { AboutService } from './core/about/about.service';
import { suppliersReducer } from './core/e-commerce/suppliers/supplier.reducers';
import { ContactsReducer } from './core/e-commerce/contacts-supplier/contact.reducers';
import { SupplierEffects } from './core/e-commerce/suppliers/supplier.effects';
import { ContactEffects } from './core/e-commerce/contacts-supplier/contact.effects';
import { AddressTransPipe } from './views/pipes/address-trans.pipe';
import {OutstandingService} from './core/e-commerce/outstanding/outstanding.service';
import {ProductCharacteristicEffects} from './core/e-commerce/product-characteristic/product-characteristic.effects';
import {productCharacteristicsReducer} from './core/e-commerce/product-characteristic/product-characteristic.reducer';
import { PromoCodeEffects } from './core/e-commerce/promo-code/promo-code.effects';
import { promoCodesReducer } from './core/e-commerce/promo-code/promo-code.reducer';
import { discountCompaniesReducer } from './core/e-commerce/company-promotions/company-discount.reducers';
import { DiscountCompaniesEffects } from './core/e-commerce/company-promotions/company-discount.effects';
import { reducers } from './core/reducers';
import { metaReducers } from './core/e-commerce/company-promotions/state-hydration';
import { Ng2SearchPipeModule } from 'ng2-search-filter';


registerLocaleData(localeFr, 'fr');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

export function hljsLanguages(): HighlightLanguage[] {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
    { name: 'json', func: json },
  ];
}

@NgModule({
  declarations: [AppComponent, AddressTransPipe],
  imports: [
    MatFormFieldModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    PartialsModule,
    CoreModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    Ng2SearchPipeModule,
    OverlayModule,
    // StoreModule.forRoot(reducers, {metaReducers}),
    StoreModule.forRoot({
      productStocks: productStocksReducer,
      about: AboutsReducer,
      faq: fAQReducer,
      blog: BlogsReducer,
      questions: questionsReducer,
      suppliers: suppliersReducer,
      contacts: ContactsReducer,
      ProductCharacteristic: productCharacteristicsReducer,
      promoCodes: promoCodesReducer,
    }),
    EffectsModule.forRoot([
      StockEffects,
      AboutEffects,
      FaqEffects,
      BlogEffects,
      ClientQuestionEffects,
      SupplierEffects,
      ContactEffects,
      PromoCodeEffects,
      ProductCharacteristicEffects,
    ]),

    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    ThemeModule,
  ],
  exports: [],
  providers: [
    // AuthService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    AboutService,
    DataTableService,
    SplashScreenService,
    OutstandingService,
    ClientQuestionService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig,
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: { languages: hljsLanguages },
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
