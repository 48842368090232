import { createAction, props } from '@ngrx/store';
import { ClaimReasonModel } from './claim-reason.model';
import { Update } from '@ngrx/entity';

export const ClaimReasonListRequest = createAction(
  '[Claim Reason API] Claim Reasons Request',
  props<{ keyword: string }>()
);
export const ClaimReasonListLoadedSuccessfully = createAction(
  '[Claim Reason API] Claim Reasons Loaded Successfully',
  props<{ claimReasons: ClaimReasonModel[] }>()
);
export const ClaimReasonListLoadFailed = createAction(
  '[Claim Reason API] Claim Reasons Load Failed',
  props<{ error: any }>()
);

export const ClaimReasonCreation = createAction(
  '[Claim Reason API] Claim Reason Creation',
  props<{ reason: ClaimReasonModel }>()
);
export const ClaimReasonCreatedSuccessfully = createAction(
  '[Claim Reason API] Claim Reason Created Successfully',
  props<{ claimReason: ClaimReasonModel }>()
);
export const ClaimReasonCreationFailed = createAction(
  '[Claim Reason API] Claim Reason Creation Failed',
  props<{ error: any }>()
);

export const ClaimReasonUpdate = createAction(
  '[Claim Reason API] Claim Reason Update',
  props<{
    reason: ClaimReasonModel;
    partialClaimReason: Update<ClaimReasonModel>;
  }>()
);
export const ClaimReasonUpdatedSuccessfully = createAction(
  '[Claim Reason API] Claim Reason Updated Successfully',
  props<{
    claimReason: ClaimReasonModel;
    partialClaimReason: Update<ClaimReasonModel>;
  }>()
);
export const ClaimReasonUpdateFailed = createAction(
  '[Claim Reason API] Claim Reason Update Failed',
  props<{ error: any }>()
);

export const ClaimReasonListStatusUpdate = createAction(
  '[Claim Reason API] Claim Reason List Status Update',
  props<{
    reasons: ClaimReasonModel[];
    partialClaimReasons: Update<ClaimReasonModel>[];
  }>()
);
export const ClaimReasonListStatusUpdatedSuccessfully = createAction(
  '[Claim Reason API] Claim Reason List Status Updated Successfully',
  props<{
    claimReasons: ClaimReasonModel[];
    partialClaimReasons: Update<ClaimReasonModel>[];
  }>()
);
export const ClaimReasonListStatusUpdateFailed = createAction(
  '[Claim Reason API] Claim Reason Update List Status Failed',
  props<{ error: any }>()
);
