import { ProductModel } from '../products/product.model';

export class PromotionModel {
  id: number;
  name: string;
  description: string;
  status: string;
  clientGroup: string;
  promoCode: string;
  numOfUse: number;
  startDate: Date;
  endDate: Date;
  priority: number;
  label: string;
  value?: number;

  products?: ProductModel[];
}
