import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { claimReasonsReducer } from '../../../core/service-client/claim/claim-reason/claim-reason.reducer';
import { ClaimReasonEffects } from '../../../core/service-client/claim/claim-reason/claim-reason.effects';
import { claimsReducer } from '../../../core/service-client/claim/claim.reducer';
import { ClaimEffects } from '../../../core/service-client/claim/claim.effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import {
  HttpUtilsService,
  LayoutUtilsService,
  TypesUtilsService,
} from '../../../core/_base/crud';
// Shared
import { ActionNotificationComponent } from '../../partials/content/crud';
// Components

import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
} from '@angular/material';

import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { QuestionAnswerService } from '../../../core/service-client/questionanswer/question-answer.service';
import { QuestionAnswerEditComponent } from './question-answer/question-answer-edit/question-answer-edit.component';
import { ClientQuestionComponent } from './client-question/client-question.component';
import { ClientQuestionEditComponent } from './client-question/client-question-edit/client-question-edit.component';
import { FlexModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ClaimListComponent } from './claim/claim-list/claim-list.component';
import { MatDividerModule } from '@angular/material/divider';
import { ClaimDetailComponent } from './claim/claim-detail/claim-detail.component';
import { ClaimReasonEditComponent } from './claim/claim-reason/claim-reason-edit/claim-reason-edit.component';
import { ClaimReasonListComponent } from './claim/claim-reason/claim-reason-list/claim-reason-list.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { AdminListComponent } from './claim/admin-list/admin-list.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

// export let ServiceClientModule = undefined;

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'question-answer',
        component: QuestionAnswerComponent,
      },
      {
        path: 'claims',
        component: ClaimListComponent,
      },
      {
        path: 'claims/:id',
        component: ClaimDetailComponent,
      },
      {
        path: 'question-answer/add',
        component: QuestionAnswerEditComponent,
      },
      {
        path: 'question-answer/edit',
        component: QuestionAnswerEditComponent,
      },
      {
        path: 'question-answer/edit/:id',
        component: QuestionAnswerEditComponent,
      },
      {
        path: 'client-question',
        component: ClientQuestionComponent,
      },
      {
        path: 'client-question/edit',
        component: ClientQuestionEditComponent,
      },
      {
        path: 'client-question/edit/:id',
        component: ClientQuestionEditComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),

    // Users
    // StoreModule.forFeature('users', usersReducer),
    // EffectsModule.forFeature([UserEffects]),

    // Claim Reasons
    StoreModule.forFeature('claimReasons', claimReasonsReducer),
    EffectsModule.forFeature([ClaimReasonEffects]),

    // Claims
    StoreModule.forFeature('claims', claimsReducer),
    EffectsModule.forFeature([ClaimEffects]),

    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    FlexModule,
    MatSlideToggleModule,
    PerfectScrollbarModule,
    MatDividerModule,
    MatGridListModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px',
      },
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    QuestionAnswerService,
  ],
  entryComponents: [ActionNotificationComponent],
  declarations: [
    QuestionAnswerComponent,
    QuestionAnswerEditComponent,
    ClientQuestionComponent,
    ClientQuestionEditComponent,
    ClaimListComponent,
    ClaimDetailComponent,
    ClaimReasonEditComponent,
    ClaimReasonListComponent,
    AdminListComponent,
  ],
})
export class ServiceClientModule {}
