// RxJS
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  selectSuppliersInStore,
  selectSuppliersPageLoading,
  selectSuppliersShowInitWaitingMessage,
} from './supplier.selector';

// Selectors

export class SuppliersDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectSuppliersPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectSuppliersShowInitWaitingMessage)
    );

    this.store
      .pipe(select(selectSuppliersInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
