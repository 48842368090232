import { select, Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { BaseDataSource, QueryResultsModel } from '../../../_base/crud';
import {
  selectAccessoirInStore,
  selectAccessoirPageLoading,
  selectAccessoirShowInitWaitingMessage,
} from '../accessories/accessoir.selector';

export class AccessoiresDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(select(selectAccessoirPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectAccessoirShowInitWaitingMessage)
    );

    this.store
      .pipe(select(selectAccessoirInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
