import { HttpClient } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../../environments/environment';
import { AbstractService } from '../../../services/abstract-service.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CommercialGestureModel,
  CommercialGestureStatus,
  ConversationModel,
} from './conversation.model';

@Injectable()
export class ConversationService extends AbstractService<ConversationModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}complaint`;
  }

  public sendMessage(
    claimId: number,
    message: string,
    commercialGesture: boolean,
    files: File[]
  ): Observable<ConversationModel> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    formData.append(
      'conversation',
      JSON.stringify({ message, commercialGesture })
    );

    const url = `${this.url}/${claimId}/conversation/admin`;
    return this.http.post<ConversationModel>(url, formData);
  }

  public changeCommercialGestureStatus(
    conversationId: number,
    status: CommercialGestureStatus
  ): Observable<CommercialGestureModel> {
    const url = `${this.url}/conversation/${conversationId}/gesture`;
    return this.http.patch<CommercialGestureModel>(url, status);
  }
}
