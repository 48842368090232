import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

import { Update } from '@ngrx/entity';
import { MatDialog } from '@angular/material/dialog';
import { ProductCharacteristicModel } from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.model';
import { AppState } from '../../../../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
  QueryResultsModel,
  TypesUtilsService,
} from '../../../../../../../../../core/_base/crud';
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../../../../../core/_base/layout';
import { ProductCharacteristicsService } from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristics.service';
import {
  selectProductCharacteristicById,
  selectLastCreatedProductCharacteristicId,
} from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.selectors';
// Actions
import * as CharacteristicActions from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.actions';
import * as CategoryCharacteristicActions from '../../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.actions';
import { CategoryCharacteristicModel } from '../../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.model';
import { selectCategoryCharacteristicInStore } from '../../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.selectors';
import { ProductsService } from '../../../../../../../../../core/e-commerce/products/products.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'kt-product-characteristic-edit',
  templateUrl: './product-characteristic-edit.component.html',
})
export class ProductCharacteristicEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // Public properties
  characteristic: ProductCharacteristicModel;
  characteristicId$: Observable<number>;
  productId: number;
  oldCharacteristic: ProductCharacteristicModel;
  selectedTab = 0;
  loadingSubject = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean>;
  characteristicForm: FormGroup;
  hasFormErrors = false;
  values: string[] = [];
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;

  // Category Characteristics
  categoryCharacteristics: CategoryCharacteristicModel[];
  suggestedCharacteristics: CategoryCharacteristicModel[];
  categoryId: number;

  //private
  catcharSelected: CategoryCharacteristicModel;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param typesUtilsService: TypesUtilsService
   * @param fb: FormBuilder
   * @param dialog: MatDialog
   * @param subheaderService: SubheaderService
   * @param layoutUtilsService: SubheaderService
   * @param layoutConfigService: LayoutConfigService
   * @param cdr: ChangeDetectorRef
   */
  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private typesUtilsService: TypesUtilsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private charService: ProductCharacteristicsService,
    private productsService: ProductsService,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      this.productId = params.productid;
      if (id && id > 0) {
        this.store
          .pipe(select(selectProductCharacteristicById(id)))
          .subscribe((result) => {
            if (!result) {
              //todo add the get by id service
              // this.loadProductFromService(id);
              return;
            }
            this.loadCharacteristic(result);
          });
      } else {
        const newChar = new ProductCharacteristicModel();
        this.loadCharacteristic(newChar);
      }
    });
    this.getActiveTab();

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById('kt_header'));
      this.headerMargin = parseInt(style.height, 0);
    };

    this.getCategoryCharacteristics();
  }

  ngAfterViewInit() {
    const sub = this.characteristicForm
      .get('label')
      .valueChanges.subscribe((value) => {
        this.suggestedCharacteristics = this.categoryCharacteristics.filter(
          (c) => c.label.toLowerCase().includes(value.toLowerCase())
        );
      });
    this.componentSubscriptions.push(sub);
  }

  loadCharacteristic(_char, fromService: boolean = false) {
    if (!_char) {
      this.goBack('');
    }
    this.characteristic = _char;
    this.characteristicId$ = of(_char.id);
    this.oldCharacteristic = Object.assign({}, _char);
    this.initChar();
    if (fromService) {
      this.cdr.detectChanges();
    }
  }

  // If product not found in store
  // loadCharacteristicFromService(productId) {
  //     this.categoriesService.getById(productId).subscribe(res => {
  //         this.loadProduct(res, true);
  //     });
  // }

  getActiveTab() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.tab) {
        this.selectedTab = params.tab;
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Init char
   */
  initChar() {
    //todo fix the bread crumb
    this.createForm();
    this.loadingSubject.next(false);
    if (!this.characteristic.id) {
      this.subheaderService.setBreadcrumbs([
        { title: 'eCommerce', page: `/ecommerce` },
        {
          title: 'Characteristics',
          page: `/ecommerce/products/characteristic`,
        },
        {
          title: 'Create Charactersitic',
          page: `/ecommerce/products/characteristic/add`,
        },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit Product');
    this.subheaderService.setBreadcrumbs([
      { title: 'eCommerce', page: `/ecommerce` },
      { title: 'Categories', page: `/ecommerce/products` },
      {
        title: 'Edit Product',
        page: `/ecommerce/products/edit`,
        queryParams: { id: this.characteristic.id },
      },
    ]);
  }

  /**
   * Create form
   */
  createForm() {
    this.characteristicForm = this.fb.group({
      label: [this.characteristic.label, Validators.required],
      additionalInfo: [this.characteristic.additionalInfo],
      value: [this.characteristic.value, Validators.required],
    });
  }

  /**
   * Go back to the list
   *
   * @param id: any
   */
  //todo fix the goback
  goBack(id) {
    this.loadingSubject.next(false);
    const url = `/ecommerce/products/edit/${this.productId}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  goBackWithoutId() {
    this.router.navigateByUrl(
      `/ecommerce/products/edit/${this.productId}`,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  /**
   * Refresh char
   *
   * @param isNew: boolean
   * @param id: number
   */
  refreshChar(isNew: boolean = false, id = 0) {
    this.loadingSubject.next(false);
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `/ecommerce/products/edit/${this.productId}/characteristics/edit/${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  getCategoryCharacteristics() {
    const productSub = this.productsService
      .getById(this.productId)
      .subscribe((product) => {
        this.categoryId = product.categoryId;
        this.store.dispatch(
          CategoryCharacteristicActions.CategoryCharacteristicListRequested({
            categoryId: this.categoryId,
          })
        );
        const catCharsSub = this.store
          .select(selectCategoryCharacteristicInStore)
          .subscribe((res) => {
            this.categoryCharacteristics = res.items;
          });
        this.componentSubscriptions.push(catCharsSub);
      });
    this.componentSubscriptions.push(productSub);
  }

  labelSelect(label: string, initialSelect: boolean) {
    this.catcharSelected = this.categoryCharacteristics.filter(
      (obj) => obj.label == label
    )[0];
    if (!initialSelect) this.characteristicForm.controls.value.setValue('');
  }

  /**
   * Save data
   *
   * @param withBack: boolean
   */
  onSumbit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.characteristicForm.controls;
    /** check form */
    if (this.characteristicForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    // tslint:disable-next-line:prefer-const
    let editedChar = this.prepareCharacteristic();

    if (editedChar.id > 0) {
      this.updateProduct(editedChar, withBack);
      return;
    }

    this.addProduct(editedChar, withBack);
  }

  /**
   * Returns object for saving
   */
  prepareCharacteristic(): ProductCharacteristicModel {
    const controls = this.characteristicForm.controls;
    const _characteristic = new ProductCharacteristicModel();
    _characteristic.id = this.characteristic.id;
    _characteristic.label = controls.label.value;
    _characteristic.additionalInfo = controls.additionalInfo.value;
    // converting the list to string
    // console.log(this.catcharSelected?.valueType);
    if (
      this.catcharSelected &&
      this.catcharSelected.valueType == 'multipleListChoice'
    ) {
      let stringvalue: string = '';
      stringvalue = stringvalue.concat(controls.value.value);
      _characteristic.value = stringvalue;
      console.log(stringvalue);
    } else {
      _characteristic.value = controls.value.value;
    }

    return _characteristic;
  }

  /**
   * Add characteristic
   *
   * @param _characteristic: ProductCharacteristicModel
   * @param withBack: boolean
   */
  addProduct(
    _characteristic: ProductCharacteristicModel,
    withBack: boolean = false
  ) {
    this.loadingSubject.next(true);
    this.store.dispatch(
      CharacteristicActions.ProductCharacteristicCreated({
        productId: this.productId,
        productCharacteristic: _characteristic,
      })
    );
    const storesub$ = this.store
      .pipe(select(selectLastCreatedProductCharacteristicId))
      .subscribe((newId) => {
        if (!newId) {
          return;
        }
        this.loadingSubject.next(false);
        if (withBack) {
          this.goBack(newId);
        } else {
          const message = `La nouvelle caractéristique a été ajouté avec succès`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            10000,
            true,
            true
          );
          this.refreshChar(true, newId);
        }
      });

    this.componentSubscriptions.push(storesub$);
  }

  /**
   * add the new value to the list values
   */

  addValue() {
    const controls = this.characteristicForm.controls;
    const newValue = controls.value.value;
    this.values.push(newValue);
    controls.value.setValue('');
    console.log(JSON.stringify(this.values));
  }
  removeValue(value) {
    this.values = this.values.filter((obj) => obj !== value);
    console.log(this.values);
  }

  /**
   * Update char
   *
   * @param _characteristic: ProductCharacteristicModel
   * @param withBack: boolean
   */
  updateProduct(
    _characteristic: ProductCharacteristicModel,
    withBack: boolean = false
  ) {
    this.loadingSubject.next(true);
    const updateChar: Update<ProductCharacteristicModel> = {
      id: _characteristic.id,
      changes: _characteristic,
    };

    this.store.dispatch(
      CharacteristicActions.ProductCharacteristicUpdated({
        partialCharacteristic: updateChar,
        productCharacteristic: _characteristic,
        productId: this.productId,
      })
    );
    if (withBack) {
      this.goBack(_characteristic.id);
    } else {
      const message = `Sauvegarde avec succès`;
      this.layoutUtilsService.showActionNotification(
        message,
        MessageType.Update,
        10000,
        true,
        true
      );
      this.refreshChar(false);
    }
  }

  /**
   * Returns component title
   */
  getComponentTitle() {
    let result = 'Nouvelle caractérisitique';
    if (!this.characteristic || !this.characteristic.id) {
      return result;
    }

    result = `Modifier caractéristique - ${this.characteristic.label}`;
    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
