// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
// NGRX
import { ClientModel } from '../../../../../../../../core/e-commerce/clients/client.model';
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../../../core/_base/crud';
import { ClientDatasource } from '../../../../../../../../core/e-commerce/clients/client.datasource';
import { ClientService } from '../../../../../../../../core/e-commerce/clients/client.service';
import { CompaniesService } from '../../../../../../../../core/e-commerce/companies/companies.service';
import { skip } from 'rxjs/operators';
import { ClientfunctionModel } from '../../../../../../../../core/e-commerce/clientfunctions/clientfunction.model';
import { ClientfunctionsService } from '../../../../../../../../core/e-commerce/clientfunctions/clientfunctions.service';
import { CompanyModel } from '../../../../../../../../core/e-commerce/companies/company.model';
import { Location } from '@angular/common';
import { AddressModel } from '../../../../../../../../core/e-commerce/companies/address.model';

@Component({
  selector: 'kt-user-edit',
  templateUrl: './client-edit.component.html',
  styles: [
    `.odoo-message{
                font-size: 15px ;
                background-color: red ;
                color: white;
                width: 100%;
                margin-right: 206px;
            }
        `,
  ],
})
export class ClientEditComponent implements OnInit, OnDestroy {
  // Public properties
  client: ClientModel;
  client$: Observable<ClientModel>;
  oldClient: ClientModel;
  selectedTab = 0;
  loading$: Observable<boolean>;
  addressesSubject$ = new BehaviorSubject<AddressModel[]>([]);
  clientForm: FormGroup;
  hasFormErrors = false;

  // Private properties
  private subscriptions: Subscription[] = [];
  private companyId: number;
  private companyName: string;

  dataSource: ClientDatasource;
  functions: ClientfunctionModel[];
  status = [
    { name: 'En attente', value: 'PENDING' },
    { name: 'Activé', value: 'ACTIVATED' },
    {
      name: 'désactivé',
      value: 'DEACTIVATED',
    },
    { name: 'bloqué', value: 'BLOCKED' },
  ];
  roles = [
    { name: 'Acheteur', value: 'ROLE_DEFAULT' },
    { name: 'Gestionnaire', value: 'ROLE_OWNER' },
  ];

  suggestedCompany: CompanyModel[] = [];

  /**
   * Component constructor
   *
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param location
   * @param userFB: FormBuilder
   * @param subheaderService: SubheaderService
   * @param layoutUtilsService: LayoutUtilsService
   * @param layoutConfigService: LayoutConfigService
   * @param clientService
   * @param comapnyService
   * @param functionService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private userFB: FormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private clientService: ClientService,
    private comapnyService: CompaniesService,
    private functionService: ClientfunctionsService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.getFunctions();
    this.dataSource = new ClientDatasource(
      this.comapnyService,
      this.clientService
    );
    this.loading$ = this.dataSource.loading$;
    this.companyId = this.activatedRoute.snapshot.params.companyid;
    if (this.companyId) {
      this.comapnyService
        .getById(this.activatedRoute.snapshot.params.companyid)
        .subscribe((value: CompanyModel) => (this.companyName = value.name));
    }
    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      if (id && id > 0) {
        this.dataSource.loadClientById(id);
        this.client$ = this.dataSource.entity$;
        this.client$.pipe(skip(1)).subscribe((res) => {
          if (res) {
            this.companyId = res.companyId;
            this.client = res;
            this.oldClient = res;
            if (!res.function) {
              this.oldClient.function = new ClientfunctionModel();
            }
            this.addressesSubject$.next(this.client.addresses);
            this.initUser();
          }
        });
      } else {
        this.client = new ClientModel();
        this.oldClient = new ClientModel();
        this.initUser();
        this.oldClient = Object.assign({}, this.client);
      }
    });
    this.subscriptions.push(routeSubscription);
    this.showErrors();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  showErrors() {
    const errorSub = this.dataSource.error$
      .pipe(skip(1))
      .subscribe((error: any) => {
        const message = `Error : ` + error;
        this.layoutUtilsService.showActionNotification(
          message,
          MessageType.Delete,
          9000,
          true,
          false
        );
      });
    this.subscriptions.push(errorSub);
  }

  /**
   * Init user
   */
  initUser() {
    this.createForm();
    if (!this.client.id) {
      this.subheaderService.setTitle('Create client');
      this.subheaderService.setBreadcrumbs([
        { title: 'Client Management', page: `company-management` },
        { title: 'Clients', page: `company-management/companies` },
        { title: 'Create company', page: `company-management/companies/add` },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit company');
    this.subheaderService.setBreadcrumbs([
      { title: 'Company Management', page: `company-management` },
      { title: 'Companies', page: `user-management/companies` },
      {
        title: 'Edit company',
        page: `companies-management/companies/edit`,
        queryParams: { id: this.client.id },
      },
    ]);
  }

  /**
   * Create form
   */
  createForm() {
    this.clientForm = this.userFB.group({
      name: [this.client.name, Validators.required],
      lastname: [this.client.lastname, Validators.required],
      companyName: [
        {
          value:
            this.client.id && this.client.companyName
              ? this.client.companyName
              : this.companyName,
          disabled: this.companyName,
        },
      ],
      phone: [this.client.phone],
      mail: [this.client.mail, Validators.required],
      status: [
        {
          value: this.client.id ? this.client.status : 'PENDING',
          disabled: !this.client.id,
        },
      ],
      role: [
        {
          value: this.client.id ? this.client.role : 'ROLE_DEFAULT',
          disabled: !this.client.id,
        },
      ],
      title: [this.client.title],
      function: [this.client.function.id],
    });
    if (this.router.url.split('/')[2] !== 'mainclient') {
      this.clientForm.get('companyName').setValidators(Validators.required);
    }
    this.clientForm.get('companyName').valueChanges.subscribe((v) => {
      const queryParams = new QueryParamsModel({ query: v }, null, null, 0, 10);
      this.comapnyService
        .getAllByFilter(queryParams)
        .subscribe((companies) => (this.suggestedCompany = companies.content));
    });
  }

  goBackWithoutId() {
    this.location.back();
  }

  /**
   * Redirect to list
   *
   */
  // goBackWithId() {
  //     const url = `../edit/`;
  //     this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
  // }

  /**
   * Refresh user
   *
   * @param isNew: boolean
   * @param id: number
   */
  refreshUser(isNew: boolean = false, idcomapny?: number, id?: number) {
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `/ecommerce/companies/${idcomapny}/clients/edit/${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  /**
   * Reset
   */
  reset() {
    this.client = Object.assign({}, this.oldClient);
    this.createForm();
    this.hasFormErrors = false;
    this.clientForm.markAsPristine();
    this.clientForm.markAsUntouched();
    this.clientForm.updateValueAndValidity();
  }

  /**
   * Save data
   *
   * @param withBack: boolean
   */
  onSumbit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.clientForm.controls;
    /** check form */
    if (this.clientForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    const editedUser = this.prepareClient();

    if (editedUser.id > 0) {
      this.updateUser(editedUser, withBack);
      return;
    }

    this.addUser(editedUser, withBack);
  }

  /**
   * Returns prepared data for save
   */
  prepareClient(): ClientModel {
    const controls = this.clientForm.controls;
    const _client = new ClientModel();
    _client.id = this.client.id;
    _client.name = controls.name.value;
    _client.lastname = controls.lastname.value;
    _client.companyId = this.companyId;
    _client.phone = controls.phone.value;
    _client.mail = controls.mail.value;
    _client.status = controls.status.value;
    _client.role = controls.role.value;
    _client.title = controls.title.value;
    _client.function.id = controls.function.value;
    return _client;
  }

  /**
   * Add User
   *
   * @param _company: CompanyModel
   * @param withBack: boolean
   */
  addUser(_client: ClientModel, withBack: boolean = false) {
    _client.conditionsValidated = true;
    _client.functionId = _client.function.id;

    this.dataSource.createClient(_client);
    const addSubscription = this.dataSource.entity$
      .pipe(skip(1))
      .subscribe((res) => {
        if (res == null) {
          return;
        }
        this.client.id = res.id;
        const message = `Un nouveau client a été ajouté avec succès.`;
        this.layoutUtilsService.showActionNotification(
          message,
          MessageType.Create,
          5000,
          true,
          true
        );
        if (res.id) {
          if (withBack) {
            this.refreshUser(true, this.companyId, res.id);
          } else {
            this.refreshUser(true, this.companyId, res.id);
          }
        }
      });
    this.subscriptions.push(addSubscription);
  }

  /**
   * Update user
   *
   * @param _company: Company
   * @param withBack: boolean
   */
  updateUser(_client: ClientModel, withBack: boolean = false) {
    this.clientService.updateClient(_client).subscribe(
      (value) => {
        if (value) {
          const message = `Client modifié avec succès.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Update,
            5000,
            true,
            true
          );
          if (withBack) {
            // this.goBackWithId();
            this.refreshUser(false);
          } else {
            this.refreshUser(false);
          }
        }
      },
      () => {
        this.layoutUtilsService.showActionNotification(
          'Une erreur est survenue ! veuillez vérfier les informations',
          MessageType.Update,
          5000,
          true,
          true
        );
      }
    );
    // this.store.dispatch(ClientActions.CompanyUpdated({company: _company, partialCompany: updatedCompany,}));
  }

  /**
   * Returns component title
   */
  getComponentTitle() {
    let result = 'Ajouter client';
    if (!this.client || !this.client.id) {
      return result;
    }

    result = `Modifier client - ${this.client.name}`;
    return result;
  }

  /**
   * Close Alert
   *
   * @param $event: Event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getFunctions() {
    this.functionService.getAllPaged2().subscribe((response: any) => {
      this.functions = response;
    });
  }

  selectCompany(event: any) {
    this.companyId = this.suggestedCompany.find((c) => c.name === event).id;
  }
  goToOdoo() {
    window.open(`https://imtech.dev.odoo.wissal-group.com/showcontact?email=${this.client.mail}`, '_blank');
  }
}
