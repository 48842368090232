import { createAction, props } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { ClientfunctionModel } from './clientfunction.model';
import { Update } from '@ngrx/entity';

export const ClientFunctionsPageRequested = createAction(
  '[ClientFunction List Page] ClientFunctions Page Requested ',
  props<{ page: QueryParamsModel }>()
);
export const ClientFunctionsPageLoadedSuccessfully = createAction(
  '[ClientFunction API] ClientFunctions Page Loaded Successfully ',
  props<{
    clientfunctions: ClientfunctionModel[];
    totalCount: number;
    page: number;
  }>()
);
export const ClientFunctionsPageLoadFailed = createAction(
  '[ClientFunction API] ClientFunctions Page Request Failed ',
  props<{ error: any }>()
);

export const ClientFunctionCreated = createAction(
  '[ClientFunction List Page] ClientFunction Created ',
  props<{ clientfunction: ClientfunctionModel }>()
);
export const ClientFunctionCreatedSuccessfully = createAction(
  '[ClientFunction List Page] ClientFunction Created Successfully ',
  props<{ clientfunction: ClientfunctionModel }>()
);
export const ClientFunctionCreationFailed = createAction(
  '[ClientFunction List Page] ClientFunction Creation Failed ',
  props<{ error: any }>()
);

export const ClientFunctionUpdated = createAction(
  '[ClientFunction List Page] ClientFunction Updated ',
  props<{
    clientfunction: ClientfunctionModel;
    partialClientFunction: Update<ClientfunctionModel>;
  }>()
);
export const ClientFunctionUpdatedSuccessfully = createAction(
  '[ClientFunction List Page] ClientFunction Updated Successfully ',
  props<{
    clientfunction: ClientfunctionModel;
    partialClientFunction: Update<ClientfunctionModel>;
  }>()
);
export const ClientFunctionUpdateFailed = createAction(
  '[ClientFunction List Page] ClientFunction Update Failed ',
  props<{ error: any }>()
);

export const ClientFunctionDeleted = createAction(
  '[ClientFunction List Page] ClientFunction Deleted ',
  props<{ clientfunctionId: number }>()
);
export const ClientFunctionDeletedSuccessfully = createAction(
  '[ClientFunction List Page] ClientFunction deleted Successfully ',
  props<{ clientfunctionId: number }>()
);
export const ClientFunctionDeleteFailed = createAction(
  '[ClientFunction List Page] ClientFunction delete Failed ',
  props<{ error: any }>()
);

export const ClientFunctionResourceDeleted = createAction(
  '[ClientFunction List Page] ClientFunction Resource Deleted ',
  props<{ clientfunctionId: number; resourceId: number }>()
);
export const ClientFunctionResourceDeletedSuccessfully = createAction(
  '[ClientFunction List Page] ClientFunction Resource deleted Successfully ',
  props<{ clientfunctionId: number; resourceId: number }>()
);
export const ClientFunctionResourceDeleteFailed = createAction(
  '[ClientFunction List Page] ClientFunction Resource delete Failed ',
  props<{ error: any }>()
);
