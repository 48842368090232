import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

import { Update } from '@ngrx/entity';
import { MatDialog } from '@angular/material/dialog';
import { CategoryCharacteristicModel } from '../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.model';
import { AppState } from '../../../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
  TypesUtilsService,
} from '../../../../../../../../core/_base/crud';
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../../../../core/_base/layout';
import { CategoryCharacteristicsService } from '../../../../../../../../core/e-commerce/category-characteristic/category-characteristics.service';
import {
  selectCategoryCharacteristicById,
  selectLastCreatedCategoryCharacteristicId,
} from '../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.selectors';
// Actions
import * as CharacteristicActions from '../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.actions';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'kt-category-characteristic-edit',
  templateUrl: './category-characteristic-edit.component.html',
})
export class CategoryCharacteristicEditComponent implements OnInit, OnDestroy {
  // Public properties
  characteristic: CategoryCharacteristicModel;
  characteristicId$: Observable<number>;
  categoryId: number;
  oldCharacteristic: CategoryCharacteristicModel;
  selectedTab = 0;
  loadingSubject = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean>;
  characteristicForm: FormGroup;
  hasFormErrors = false;
  values: string[] = [];
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;

  // data

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param typesUtilsService: TypesUtilsService
   * @param fb: FormBuilder
   * @param dialog: MatDialog
   * @param subheaderService: SubheaderService
   * @param layoutUtilsService: SubheaderService
   * @param layoutConfigService: LayoutConfigService
   * @param cdr: ChangeDetectorRef
   */
  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private typesUtilsService: TypesUtilsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private charService: CategoryCharacteristicsService,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      this.categoryId = params.categoryid;
      if (id && id > 0) {
        this.store
          .pipe(select(selectCategoryCharacteristicById(id)))
          .subscribe((result) => {
            if (!result) {
              //todo add the get by id service
              // this.loadCategoryFromService(id);
              return;
            }

            this.loadCharacteristic(result);
          });
      } else {
        const newChar = new CategoryCharacteristicModel();
        this.loadCharacteristic(newChar);
      }
    });
    this.getActiveTab();

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById('kt_header'));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  loadCharacteristic(_char, fromService: boolean = false) {
    if (!_char) {
      this.goBack('');
    }
    this.characteristic = _char;
    this.characteristicId$ = of(_char.id);
    this.oldCharacteristic = Object.assign({}, _char);
    this.initChar();
    if (fromService) {
      this.cdr.detectChanges();
    }
  }

  // If product didn't find in store
  // loadCharacteristicFromService(categoryId) {
  //     this.categoriesService.getById(categoryId).subscribe(res => {
  //         this.loadCategory(res, true);
  //     });
  // }

  getActiveTab() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.tab) {
        this.selectedTab = params.tab;
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Init char
   */
  initChar() {
    //todo fix the bread crumb
    this.createForm();
    this.loadingSubject.next(false);
    if (!this.characteristic.id) {
      this.subheaderService.setBreadcrumbs([
        { title: 'eCommerce', page: `/ecommerce` },
        {
          title: 'Characteristics',
          page: `/ecommerce/categories/characteristic`,
        },
        {
          title: 'Create Charactersitic',
          page: `/ecommerce/categories/characteristic/add`,
        },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit Category');
    this.subheaderService.setBreadcrumbs([
      { title: 'eCommerce', page: `/ecommerce` },
      { title: 'Categories', page: `/ecommerce/categories` },
      {
        title: 'Edit Category',
        page: `/ecommerce/categories/edit`,
        queryParams: { id: this.characteristic.id },
      },
    ]);
  }

  /**
   * Create form
   */
  createForm() {
    this.characteristicForm = this.fb.group({
      label: [this.characteristic.label, Validators.required],
      valueType: [this.characteristic.valueType, Validators.required],
      value: [''],
    });
    this.values = this.characteristic.listValue;
  }

  /**
   * Go back to the list
   *
   * @param id: any
   */
  //todo fix the goback
  goBack(id) {
    this.loadingSubject.next(false);
    const url = `/ecommerce/categories/edit/${this.categoryId}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  goBackWithoutId() {
    this.router.navigateByUrl(
      `/ecommerce/categories/edit/${this.categoryId}`,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  /**
   * Refresh char
   *
   * @param isNew: boolean
   * @param id: number
   */
  refreshChar(isNew: boolean = false, id = 0) {
    this.loadingSubject.next(false);
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `/ecommerce/categories/edit/${this.categoryId}/characteristics/edit/${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  /**
   * Reset
   */
  // reset() {
  //     this.category = Object.assign({}, this.oldCategory);
  //     this.createForm();
  //     this.hasFormErrors = false;
  //     this.categoryForm.markAsPristine();
  //     this.categoryForm.markAsUntouched();
  //     this.categoryForm.updateValueAndValidity();
  // }

  /**
   * Save data
   *
   * @param withBack: boolean
   */
  onSumbit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.characteristicForm.controls;
    /** check form */
    if (this.characteristicForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    // tslint:disable-next-line:prefer-const
    let editedChar = this.prepareCharacteristic();

    if (editedChar.id > 0) {
      this.updateCategory(editedChar, withBack);
      return;
    }

    this.addCategory(editedChar, withBack);
  }

  /**
   * Returns object for saving
   */
  prepareCharacteristic(): CategoryCharacteristicModel {
    const controls = this.characteristicForm.controls;
    const _characteristic = new CategoryCharacteristicModel();
    _characteristic.id = this.characteristic.id;
    _characteristic.label = controls.label.value;
    _characteristic.valueType = controls.valueType.value;
    _characteristic.listValue = this.values;

    return _characteristic;
  }

  /**
   * Add characteristic
   *
   * @param _characteristic: ProductCharacteristicModel
   * @param withBack: boolean
   */
  addCategory(
    _characteristic: CategoryCharacteristicModel,
    withBack: boolean = false
  ) {
    this.loadingSubject.next(true);
    this.store.dispatch(
      CharacteristicActions.CategoryCharacteristicCreated({
        categoryId: this.categoryId,
        categoryCharacteristic: _characteristic,
      })
    );
    const storesub$ = this.store
      .pipe(select(selectLastCreatedCategoryCharacteristicId))
      .subscribe((newId) => {
        if (!newId) {
          return;
        }
        this.loadingSubject.next(false);
        if (withBack) {
          this.goBack(newId);
        } else {
          const message = `Une nouvelle caractéristique a été ajoutée avec succès.`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            10000,
            true,
            true
          );
          this.refreshChar(true, newId);
        }
      });

    this.componentSubscriptions.push(storesub$);
  }

  /**
   * add the new value to the list values
   */

  addValue() {
    const controls = this.characteristicForm.controls;
    const newValue = controls.value.value;
    this.values.push(newValue);
    controls.value.setValue('');
    console.log(JSON.stringify(this.values));
  }
  removeValue(value) {
    this.values = this.values.filter((obj) => obj !== value);
    console.log(this.values);
  }

  /**
   * Update char
   *
   * @param _characteristic: ProductCharacteristicModel
   * @param withBack: boolean
   */
  updateCategory(
    _characteristic: CategoryCharacteristicModel,
    withBack: boolean = false
  ) {
    this.loadingSubject.next(true);
    const updateChar: Update<CategoryCharacteristicModel> = {
      id: _characteristic.id,
      changes: _characteristic,
    };

    this.store.dispatch(
      CharacteristicActions.CategoryCharacteristicUpdated({
        partialCharacteristic: updateChar,
        categoryCharacteristic: _characteristic,
        categoryId: this.categoryId,
      })
    );
    if (withBack) {
      this.goBack(_characteristic.id);
    } else {
      const message = `La caractéristique a été mise à jour avec succès.`;
      this.layoutUtilsService.showActionNotification(
        message,
        MessageType.Update,
        10000,
        true,
        true
      );
      this.refreshChar(false);
    }
  }

  /**
   * Returns component title
   */
  getComponentTitle() {
    let result = 'Créer une caractéristique';
    if (!this.characteristic || !this.characteristic.id) {
      return result;
    }

    result = `Modifier les caractéristiques - ${this.characteristic.label}`;
    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
