import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CarouselModel } from './carousel.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
// actions
import * as CarouselActions from './carousel.action';
import { Response } from '../../_base/crud/models/response';

export interface CarouselState extends EntityState<CarouselModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedCarouselId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<CarouselModel> =
  createEntityAdapter<CarouselModel>();

export const initialCarouselState: CarouselState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedCarouselId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const carouselReducer = createReducer(
  initialCarouselState,
  on(CarouselActions.CarouselsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),

  on(CarouselActions.CarouselsPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.carousels, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(CarouselActions.CarouselsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //creation
  on(CarouselActions.CarouselCreated, (state) => ({
    ...state,
    actionsLoading: false,
    lastAction: '',
  })),
  on(CarouselActions.CarouselCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.carousel, {
      ...state,
      actionsLoading: true,
      lastCreatedCarouselId: action.carousel.id,
      lastAction: 'EDIT.ADD_MESSAGE',
      progress: 0,
    })
  ),
  on(CarouselActions.CarouselCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
    progress: 0,
  })),
  //Update

  on(CarouselActions.CarouselUpdated, (state) => ({
    ...state,
    actionsLoading: false,
    lastAction: '',
  })),
  on(CarouselActions.CarouselUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialCarousel, {
      ...state,
      actionsLoading: true,
      lastCreatedCarouselId: action.carousel.id,
      lastAction: 'EDIT.ADD_MESSAGE',
      progress: 0,
    })
  ),
  on(CarouselActions.CarouselUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
    progress: 0,
  })),

  //Delete
  on(CarouselActions.CarouselDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(CarouselActions.CarouselDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.carouselId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE_CATEGORY_SIMPLE.MESSAGE',
    })
  ),
  on(CarouselActions.CarouselDeleteFailed, (state: CarouselState, action) => {
    let errorMessage: string;
    switch (action.error.status) {
      case 500:
        errorMessage = 'DELETE_CATEGORY_SIMPLE.FOREIGN_KEY_ERROR';
        break;
      case 403:
        errorMessage = 'AUTH.VALIDATION.CANT_DELETE';
        break;
      case 404:
        errorMessage = 'AUTH.VALIDATION.NOT_FOUND';
        break;
      default:
        errorMessage = 'AUTH.VALIDATION.NO_CONNECTION';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage: errorMessage,
      error: action.error,
    };
  })
);

export function carouselsReducer(
  state: CarouselState | undefined,
  action: Action
) {
  return carouselReducer(state, action);
}
