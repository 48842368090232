import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { IDiscountCompanyModel } from './company-discount.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyDiscountService extends AbstractService<IDiscountCompanyModel> {

  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}discount_company`;
  }

  getAllDiscountCompanies():Observable<IDiscountCompanyModel[]>{
    return this.http.get<IDiscountCompanyModel[]>(this.url)
  }

  createDiscountCompanies(discountCompany: IDiscountCompanyModel): Observable<IDiscountCompanyModel> /* Observable<IDiscountCompanyModel> */{
    return this.http.post<IDiscountCompanyModel>(this.url, discountCompany )
  }

  updateDiscountCompanies(discountCompany: Partial<IDiscountCompanyModel>): Observable<IDiscountCompanyModel> {
    const url = `${this.url}/${discountCompany.id}`;
      return this.http.put<IDiscountCompanyModel>(this.url, discountCompany )
    }

  deleteDiscountCompanies(discountCompanyId: number):Observable<{}> {
    const url = `${this.url}/${discountCompanyId}`
      return this.http.delete<IDiscountCompanyModel>(url)
    }
  
}
  

