import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';

import { PromoCodeModel } from './promo-code.model';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeService extends AbstractService<PromoCodeModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}promo_code`;
  }

  public getAllPaged(
    page: number = 0,
    size: number = 100
  ): Observable<Page<PromoCodeModel>> {
    return super.getAllPaged(page, size);
  }

  public getAllPromoCode(
    filter: any,
    page: number ,
    size: number
  ): Observable<Page<PromoCodeModel>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = params.append('search', filter.query ? filter.query : '');
    params = filter.type ? params.append('type', filter.type) : params;
    params = filter.status ? params.append('status', filter.status) : params;
    params = filter.startDate
      ? params.append('dateMinStart', filter.startDate)
      : params;
    params = filter.endDate
      ? params.append('dateMaxStart', filter.endDate)
      : params;
    const options = { params };
    const url = `${this.url}/promo_codes/filter`;
    return this.http.get<Page<PromoCodeModel>>(url, options);
  }
  public updatePromoCodeStatus(promoCode: PromoCodeModel ): Observable<any> {
    const url = `${this.url}/status`;
    return this.http.put<any>(url, promoCode);
  }
  public updatePromotion(entity: any): Observable<any> {
    return this.http.put<any>(this.url, entity);
  }

  public linkProductToPromoCode(entity: any): Observable<any> {
    const url = this.url + '/product_promotion';
    return this.http.patch<any>(url, entity);
  }

  public unlinkProduct(id: number, productId: number): Observable<any> {
    // TODO
    const url = this.url + `/product_promotion/${id}?productId=${productId}`;
    return this.http.delete<any>(url);
  }
  public getPromoCodeById(id: number): Observable<PromoCodeModel> {
    const url = this.url + `/${id}`;
    return this.http.get<PromoCodeModel>(url);
  }
}
