import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import {
  selectCategoryCharacteristicInStore,
  selectCategoryCharacteristicListLoading,
  selectCategoryCharacteristicShowInitWaitingMessage,
} from './category-characteristic.selectors';

export class CategoryCharacteristicDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(
      select(selectCategoryCharacteristicListLoading)
    );

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectCategoryCharacteristicShowInitWaitingMessage)
    );

    this.store
      .pipe(select(selectCategoryCharacteristicInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
