import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CategoriesService } from './categories.service';
// Actions
import * as CategoryActions from './category.action';
import { LayoutUtilsService, MessageType } from '../../_base/crud';

@Injectable()
export class CategoryEffects {
  constructor(
    private actions$: Actions,
    private categoryService: CategoriesService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  CategoryPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.CategoriesPageRequested),
      switchMap((action) =>
        this.categoryService
          .getCategories(
            action.page.filter ?? {},
            action.page.pageNumber,
            action.page.pageSize
          )
          .pipe(
            map((categoryPage) =>
              CategoryActions.CategoriesPageLoadedSuccessfully({
                categories: categoryPage.content,
                page: categoryPage.totalElements,
                totalCount: categoryPage.totalElements,
              })
            ),
            catchError((error) =>
              of(CategoryActions.CategoriesPageLoadFailed({ error }))
            )
          )
      )
    )
  );

  // categories list optimise
    CategoryOptPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.CategoriesOptPageRequested),
            switchMap((action) =>
                this.categoryService
                    .getCategoriesOpt(
                        action.page.filter ?? {},
                        action.page.pageNumber,
                        action.page.pageSize
                    )
                    .pipe(
                        map((categoryPage) =>
                            CategoryActions.CategoriesOptPageLoadedSuccessfully({
                                categories: categoryPage.content,
                                page: categoryPage.totalElements,
                                totalCount: categoryPage.totalElements,
                            })
                        ),
                        catchError((error) =>
                            of(CategoryActions.CategoriesOptPageLoadFailed({ error }))
                        )
                    )
            )
        )
    );


  CategoryDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.CategoryDeleted),
      switchMap((action) =>
        this.categoryService.deleteCategory(action.categoryId).pipe(
          map(() =>
            CategoryActions.CategoryDeletedSuccessfully({
              categoryId: action.categoryId,
            })
          ),
          catchError((error) =>
            of(CategoryActions.CategoryDeleteFailed({ error }))
          )
        )
      )
    )
  );

  CategoryDeletedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.CategoryDeletedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Catégorie supprimée',
              MessageType.Delete,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );


  CategoryDeleteFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.CategoryDeleteFailed),
        switchMap((action) =>
          of(
           /* this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Delete,
              3000,
              false
            )*/
          )
        )
      ),
    { dispatch: false }
  );

  // @Effect()
  // createCategory$: Observable<Action> = this.actions$.pipe(
  //     ofType(CategoryActions.CategoryCreated),
  //     concatMap(action =>
  //         this.categoryService.createCategory(action.category).pipe(
  //             map(event => this.getActionFromHttpEvent(event, 'ADD')),
  //             catchError(error => of(CategoryActions.CategoryCreationFailed({error})))
  //         )
  //     )
  // );
  CategoryCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.CategoryCreated),
      switchMap((action) =>
        this.categoryService.save(action.category).pipe(
          map((category) =>
            CategoryActions.CategoryCreatedSuccessfully({
              category,
            })
          ),
          catchError((error) =>
            of(CategoryActions.CategoryCreationFailed({ error }))
          )
        )
      )
    )
  );
  CategoryCreatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.CategoryCreatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Catégorie crée avec succès'
            )
          )
        )
      ),
    { dispatch: false }
  );

  CategoryCreationFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.CategoryCreationFailed),
        switchMap(() =>
          of(
            /*this.layoutUtilsService.showActionNotification(
              'Vous devez d\'abord créer / activer au moins une sous-catégorie à cette catégorie'
            )*/
          )
        )
      ),
    { dispatch: false }
  );


  CategoryUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.CategoryUpdated),
      switchMap((action) =>
        this.categoryService.update(action.category).pipe(
          map((category) =>
            CategoryActions.CategoryUpdatedSuccessfully({
              category,
              partialCategory: action.partialCategory,
            })
          ),
          catchError((error) =>
            of(CategoryActions.CategoryUpdateFailed({ error }))
          )
        )
      )
    )
  );

  // todo for the image upload
  // @Effect()
  // updateCategory: Observable<Action> = this.actions$.pipe(
  //     ofType(CategoryActions.CategoryUpdated),
  //     concatMap(action =>
  //         this.categoryService.updateCategory(action.category).pipe(
  //             map(event => this.getActionFromHttpEvent(event, 'UPDATE')),
  //             catchError(error => of(CategoryActions.CategoryCreationFailed({error})))
  //         )
  //     )
  // );

  // private getActionFromHttpEvent(event: HttpEvent<CategoryModel>, action: String) {
  //     switch (event.type) {
  //         case HttpEventType.Sent: {
  //             return CategoryActions.CategoryUploadProgressed({progress: 0});
  //         }
  //         case HttpEventType.UploadProgress: {
  //             return CategoryActions.CategoryUploadProgressed({
  //                 progress: Math.round((100 * event.loaded) / event.total)
  //             });
  //         }
  //         case HttpEventType.DownloadProgress: {
  //             return CategoryActions.CategoryUploadProgressed({progress: 100});
  //         }
  //         case HttpEventType.ResponseHeader: {
  //             return CategoryActions.CategoryUploadProgressed({progress: 100})
  //         }
  //         case HttpEventType.Response: {
  //             if (event.status === 200) {
  //                 if (action == 'ADD')
  //                     return CategoryActions.CategoryCreatedSuccessfully({category: event['body']['body']});
  //                 else {
  //                     return CategoryActions.CategoryUpdatedSuccessfully({
  //                         category: event['body']['body'],
  //                         partialCategory: {
  //                             id: event['body']['body']['id'],
  //                             changes: event['body']['body']
  //                         }
  //                     })
  //                 }
  //             } else {
  //                 if (action === 'ADD')
  //                     return CategoryActions.CategoryCreationFailed({
  //                         error: event.statusText
  //                     });
  //                 else
  //                     return CategoryActions.CategoryUpdateFailed({
  //                         error: event.statusText
  //                     })
  //             }
  //         }
  //         default: {
  //             return CategoryActions.CategoryCreationFailed({
  //                 error: `Unknown Event: ${JSON.stringify(event)}`
  //             });
  //         }
  //     }
  // }

  CategoryListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.CategoriesListRequested),
      switchMap((action) =>
        this.categoryService.allCategories().pipe(
          map((categoriesList) =>
            CategoryActions.CategoriesListRequestedSuccessfully({
              category: categoriesList,
            })
          ),
          catchError((error) =>
            of(CategoryActions.CategoriesListRequestFailed({ error }))
          )
        )
      )
    )
  );

  ChangeCategoryStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.ChangeCategoryStatus),
      switchMap((action) =>
        this.categoryService.updateStatus(action.category).pipe(
          map((category) => {
            return CategoryActions.CategoryStatusChangedSuccessfully({
              category,
              partialCategory : action.partialCategory
            });
          }),
          catchError((error) =>
            of(CategoryActions.CategoryStatusChangeFailed({ error }))
          )
        )
      )
    )
  );


  CategoryStatusChangedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.CategoryStatusChangedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Catégorie modifiée avec succès'
            )
          )
        )
      ),
    { dispatch: false }
  );


  CategoryStatusChangeFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CategoryActions.CategoryStatusChangeFailed),
        switchMap(() =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Vous devez d\'abord créer / activer au moins une sous-catégorie à cette catégorie'
            )
          )
        )
      ),
    { dispatch: false }
  );
}
