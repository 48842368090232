import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ClaimReasonModel,
  ClaimReasonStatus,
} from '../../../../../../core/service-client/claim/claim-reason/claim-reason.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { selectClaimReasonsInStore } from '../../../../../../core/service-client/claim/claim-reason/claim-reason.selectors';
import _ from 'lodash';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ClaimReasonEditComponent } from '../claim-reason-edit/claim-reason-edit.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as ClaimReasonActions from '../../../../../../core/service-client/claim/claim-reason/claim-reason.actions';
import { Update } from '@ngrx/entity';

@Component({
  selector: 'kt-claim-reason-list',
  templateUrl: './claim-reason-list.component.html',
  styleUrls: ['./claim-reason-list.component.scss'],
})
export class ClaimReasonListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
  };

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  subscription: Subscription[] = [];
  claimReasons: ClaimReasonModel[] = [];
  claimReasonTypes: string[] = [];

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ClaimReasonListComponent>,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.selectClaimReasons();
  }

  ngAfterViewInit() {
    if (this.searchInput) {
      const searchSubscription = fromEvent<KeyboardEvent>(
        this.searchInput.nativeElement,
        'keyup'
      )
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((event) => {
          this.selectClaimReasons();
          this.claimReasons = this.claimReasons.filter((reason) =>
            reason.title
              .toLowerCase()
              .includes((event.target as HTMLInputElement).value.toLowerCase())
          );
        });
      this.subscription.push(searchSubscription);
    }

    this.ref.detectChanges();
  }

  selectClaimReasons() {
    const selectClaimReasonsSubscription = this.store
      .select(selectClaimReasonsInStore)
      .subscribe((value) => {
        this.claimReasons = _.cloneDeep(value.items);
        this.claimReasonTypes = [
          ...Array.from(new Set(this.claimReasons.map(({ type }) => type))),
        ];
      });

    this.subscription.push(selectClaimReasonsSubscription);
  }

  changeClaimReasonStatus(
    event: MatSlideToggleChange,
    reason: ClaimReasonModel
  ) {
    this.claimReasons.find(
      (claimReason) => claimReason.id === reason.id
    ).status = event.checked
      ? ClaimReasonStatus.ACTIVATED
      : ClaimReasonStatus.DEACTIVATED;
  }

  addReason() {
    this.dialogRef.close();
    this.dialog.open(ClaimReasonEditComponent, {
      width: '400px',
      data: {
        dialog: 'add',
        types: this.claimReasonTypes,
      },
    });
  }

  editReason(reason) {
    this.dialogRef.close();
    this.dialog.open(ClaimReasonEditComponent, {
      width: '400px',
      data: {
        dialog: 'edit',
        types: this.claimReasonTypes,
        id: reason.id,
      },
    });
  }

  cancelEditClaimReasons() {
    this.dialogRef.close();
  }

  applyEditClaimReasons() {
    const updateReasonsStatus: Update<ClaimReasonModel>[] = [];

    this.claimReasons.map((reason) => {
      const updateReasonStatus: Update<ClaimReasonModel> = {
        id: reason.id,
        changes: reason,
      };
      updateReasonsStatus.push(updateReasonStatus);
    });

    this.store.dispatch(
      ClaimReasonActions.ClaimReasonListStatusUpdate({
        reasons: this.claimReasons,
        partialClaimReasons: updateReasonsStatus,
      })
    );

    this.searchInput.nativeElement.value = '';
    this.selectClaimReasons();
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
