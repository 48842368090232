// Lodash
import { each } from 'lodash';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LogoState } from './logo.reducers';
import { LogoModel } from './logo.model';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';

export const selectLogosState = createFeatureSelector<LogoState>('logos');

export const selectLogoById = (logoId: number) =>
  createSelector(selectLogosState, (logosState) => logosState.entities[logoId]);

export const selectLogosPageLoading = createSelector(
  selectLogosState,
  (logosState) => logosState.listLoading
);

export const selectLogosActionLoading = createSelector(
  selectLogosState,
  (customersState) => customersState.actionsLoading
);

export const selectLogosPageLastQuery = createSelector(
  selectLogosState,
  (logosState) => logosState.lastQuery
);

export const selectLastCreatedLogoId = createSelector(
  selectLogosState,
  (logosState) => logosState.lastCreatedLogoId
);

export const selectLogosInitWaitingMessage = createSelector(
  selectLogosState,
  (logosState) => logosState.showInitWaitingMessage
);

export const selectLogos = createSelector(selectLogosState, (logosState) => {
  const items: LogoModel[] = [];
  each(logosState.entities, (element) => {
    items.push(element);
  });
  return items;
});

export const selectLogosInStore = createSelector(
  selectLogosState,
  (logosState) => {
    const items: LogoModel[] = [];
    each(logosState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: LogoModel[] = httpExtension.sortArray(
      items,
      logosState.lastQuery.sortField,
      logosState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, logosState.totalCount, '');
  }
);

export const selectHasLogosInStore = createSelector(
  selectLogosInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  }
);
