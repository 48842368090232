import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { OrderModel } from './order.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { QueryParamsModel } from '../../_base/crud';
import { OrderItemModel } from '../OrderItems/OrderItem.model';

@Injectable()
export class OrdersService extends AbstractService<OrderModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}order`;
  }

  public addNewOrder(entity: any): Observable<OrderModel> {
    return this.http.post<OrderModel>(this.url + '/add', entity);
  }

  public sendInvoiceViaEmail(id: number, mail: string): Observable<any> {
    const url = `${this.url}/pdf/${id}?mail=${mail}`;
    return this.http.get<any>(url);
  }

  public getAllOrderByFilter(
    queryParams: QueryParamsModel
  ): Observable<Page<OrderModel>> {
    let params = new HttpParams();
    params = params.append('page', queryParams.pageNumber.toString());
    params = params.append('size', queryParams.pageSize.toString());
    params = params.append(
      'search',
      queryParams.filter.query ? queryParams.filter.query : ''
    );
    params = queryParams.filter.status
      ? params.append('status', queryParams.filter.status)
      : params;
    const options = { params };
    const url = `${this.url}/page`;
    return this.http.get<Page<OrderModel>>(url, options);
  }

  public updateOrderItem(payload: OrderItemModel): Observable<OrderItemModel> {
    const url = `${this.url}/item`;
    return this.http.put<OrderItemModel>(url, payload);
  }

  public deleteOrderItem(id: number): Observable<void> {
    const url = `${this.url}/item/${id}`;
    return this.http.delete<void>(url);
  }

  public addOrderItem(payload: OrderItemModel): Observable<OrderItemModel> {
    const url = `${this.url}/item`;
    return this.http.post<OrderItemModel>(url, payload);
  }

  public updateOrder(payload: any): Observable<OrderModel> {
    const url = `${this.url}`;
    return this.http.put<OrderModel>(url, payload);
  }
  public archiveOrder(id: number): Observable<OrderModel> {
    const url = `${this.url}/archive/${id}`;
    return this.http.post<OrderModel>(url, null);
  }

  public getArchivedOrders(
    queryParams: QueryParamsModel
  ): Observable<Page<OrderModel>> {
    let params = new HttpParams();
    params = params.append('page', queryParams.pageNumber.toString());
    params = params.append('size', queryParams.pageSize.toString());
    params = params.append(
      'search',
      queryParams.filter.query ? queryParams.filter.query : ''
    );
    params = queryParams.filter.status
      ? params.append('status', queryParams.filter.status)
      : params;

    const options = { params };
    const url = `${this.url}/archived`;
    return this.http.get<Page<OrderModel>>(url, options);
  }

  unarchiveOrder(id: number) {
    const url = `${this.url}/unarchive/${id}`;
    return this.http.post<OrderModel>(url, null);
  }

  findById(id: number): Observable<OrderModel> {
    const url = `${this.url}/find/${id}`;
    return this.http.get<OrderModel>(url);
  }
}
