import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IDiscountCompanyModel } from '../company-discount.model';


export const loadCompaniesDiscounts = createAction(
    '[Discount Company Page] Load Companies Discounts'
  );

export const addNewCompanyDiscount = createAction(
    '[Discount Company Page] Add New Company Discount',
    props<{companyDiscount: IDiscountCompanyModel}>()
  );

export const updateCompanyDiscount = createAction(
    '[Discount Company Page] Update Company Discount',
    props<{update: Update<IDiscountCompanyModel>}>()
  );

export const getDiscountsForSingleCompany = createAction(
    '[Discount Company Page] Get Discounts For Single Company',
    props<{companyId: number}>()
  );


export const deleteDiscountCompany = createAction(
    '[Discount Company Page] delete Comapny Discounts ',
    props<{discountCompanyId: number}>()
  );