import { Component, Inject, OnInit } from '@angular/core';
import { AddressModel } from '../../../../../../../../core/e-commerce/companies/address.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '../../../../../../../../core/e-commerce/clients/client.service';
import { map, startWith, take } from 'rxjs/operators';
import { CountryModel } from '../../../../../../../../core/_models/country.model';
import { Observable } from 'rxjs';
import { CountryService } from '../../../../../../../../core/services/country.service';

@Component({
  selector: 'kt-client-address-edit-dialog',
  templateUrl: './client-address-edit-dialog.component.html',
  styleUrls: ['./client-address-edit-dialog.component.scss'],
})
export class ClientAddressEditDialogComponent implements OnInit {
  addressEditForm: FormGroup;
  loadingAfterSubmit = false;
  address: AddressModel;

  countries: CountryModel[];
  countries$: Observable<CountryModel[]> = this.countryService
    .countries()
    .pipe(take(1));

  constructor(
    public dialogRef: MatDialogRef<ClientAddressEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { address: AddressModel; clientId: number },
    private fb: FormBuilder,
    private clientService: ClientService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.countries$.subscribe((countries) => {
      this.countries = countries;
      this.countries$ = this.addressEditForm.get('country').valueChanges.pipe(
        startWith(''),
        map((value) => {
          return this.countries.filter((country) => {
            return (
              country.name.toLowerCase().includes(value.toLowerCase()) &&
              country.name !== value
            );
          });
        })
      );
    });
    this.initAddressForm();
  }

  initAddressForm() {
    console.log(this.data);
    this.addressEditForm = this.fb.group({
      contactName: [this.data.address ? this.data.address.contactName : ''],
      contactPhone: [this.data.address ? this.data.address.contactPhone : ''],
      country: [
        this.data.address?.country ? this.data.address.country.name : 'France',
      ],
      city: [
        this.data.address ? this.data.address.city : '',
        [Validators.required],
      ],
      streetName: [
        this.data.address ? this.data.address.streetName : '',
        [Validators.required],
      ],
      additionalInfo: [
        this.data.address ? this.data.address.additionalInfo : '',
      ],
      cedex: [this.data.address ? this.data.address.cedex : '', []],
      postalCode: [
        this.data.address ? this.data.address.postalCode : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6),
        ]),
      ],
    });
  }

  save() {
    const controls = this.addressEditForm.controls;

    if (this.addressEditForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loadingAfterSubmit = true;

    this.address = {
      id: null,
      country: this.countries.find((c) => c.name === controls.country.value),
      city: controls.city.value,
      streetName: controls.streetName.value,
      postalCode: controls.postalCode.value,
      additionalInfo: controls.additionalInfo.value,
      cedex: controls.cedex.value,
      type: 'delivryAddress',
      contactName: controls.contactName.value,
      contactPhone: controls.contactPhone.value,
      isDefault: false,
    };

    if (!this.data.address) {
      this.clientService
        .addAddressBook(this.data.clientId, this.address)
        .subscribe((res) => {
          this.closeDialog(res);
        });
    } else {
      this.address.id = this.data.address.id;
      this.clientService
        .updateAddressBook(this.data.clientId, this.address)
        .subscribe((res) => {
          this.closeDialog(res);
        });
    }
  }

  closeDialog(address?: AddressModel) {
    this.dialogRef.close(address);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addressEditForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }
}
