import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
import { AboutModel } from '../../../../../core/about/about.model';
import { AboutDatasource } from '../../../../../core/about/about.datasource';
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../core/_base/crud';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ResourcesService } from '../../../../../core/services/resources.service';
import { AppState } from '../../../../../core/reducers';
import {
  selectAboutById,
  selectAboutsActionLoading,
} from '../../../../../core/about/about.selector';
import {
  AboutCreated,
  AboutUpdated,
} from '../../../../../core/about/about.action';
import { AboutService } from '../../../../../core/about/about.service';

@Component({
  selector: 'kt-about-edit',
  templateUrl: './about-edit.component.html',
  styleUrls: ['./about-edit.component.scss'],
})
export class AboutEditComponent implements OnInit {
  about: AboutModel;
  imageFile: File;
  loadedImage: string;
  uploading = false;
  about$: Observable<AboutModel>;
  oldAbout: AboutModel;
  selectedTab = 0;
  loading$: Observable<boolean>;
  aboutForm: FormGroup;
  hasFormErrors = false;
  progress = new BehaviorSubject<number>(0);
  loadingSubject = new BehaviorSubject<boolean>(true);

  // Private properties
  private subscriptions: Subscription[] = [];
  private aboutId: number;
  dataSource: AboutDatasource;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private aboutFB: FormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private aboutService: AboutService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.loading$ = this.store.pipe(select(selectAboutsActionLoading));

    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      if (id && id > 0) {
        this.about$ = this.store.select(selectAboutById(id));
        this.store.pipe(select(selectAboutById(id))).subscribe((res) => {
          if (res) {
            this.about = res;
            this.oldAbout = Object.assign({}, this.about);
            this.initAbout();
          }
        });
      } else {
        this.about = new AboutModel();
        this.oldAbout = Object.assign({}, this.about);
        this.initAbout();
      }
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  initAbout() {
    this.createForm();
    if (!this.about.id) {
      this.subheaderService.setBreadcrumbs([
        { title: 'A propos', page: `/a-propos` },
        { title: 'About', page: `/a-propos/about` },
        { title: 'Create about', page: `/a-propos/about/add` },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit About');
    this.subheaderService.setBreadcrumbs([
      { title: 'A propos', page: `/a-propos` },
      { title: 'About', page: `/a-propos/about` },
      {
        title: 'Edit About',
        page: `/a-propos/about/edit`,
        queryParams: { id: this.about.id },
      },
    ]);
  }

  createForm() {
    this.aboutForm = this.aboutFB.group({
      title: [this.about.title, Validators.required],
      body: [this.about.body, Validators.required],
      type: [this.about.type, Validators.required],
    });
  }

  goBackWithoutId() {
    this.router.navigateByUrl(
      '/a-propos/about',
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  refreshAbout(isNew: boolean = false, id = 0) {
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `a-propos/about/edit/${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  reset() {
    this.about = Object.assign({}, this.oldAbout);
    this.createForm();
    this.hasFormErrors = false;
    this.aboutForm.markAsPristine();
    this.aboutForm.markAsUntouched();
    this.aboutForm.updateValueAndValidity();
  }

  onSubmit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.aboutForm.controls;
    /** check form */
    if (this.aboutForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    let editedAbout = this.prepareAbout();

    if (editedAbout.id > 0) {
      this.updateAbout(editedAbout, withBack, editedAbout.id);
      return;
    }

    this.addAbout(editedAbout, withBack);
  }

  prepareAbout(): AboutModel {
    const controls = this.aboutForm.controls;
    const _about = new AboutModel();
    _about.id = this.about.id;
    _about.title = controls.title.value;
    _about.body = controls.body.value;
    _about.type = controls.type.value;
    return _about;
  }

  addAbout(_about: AboutModel, withBack: boolean = false) {
    _about.id = undefined;

    this.store.dispatch(AboutCreated({ About: _about }));

    console.log('Entity Created ');
    const message = `Contenu ajouté avec succès.`;
    this.layoutUtilsService.showActionNotification(
      message,
      MessageType.Create,
      5000,
      true,
      true
    );
  }

  updateAbout(_about: AboutModel, withBack: boolean = false, id: number) {
    this.store.dispatch(AboutUpdated({ About: _about, id: id }));
    // this.aboutService.updateAbout(_about , id)
    const message = `Contenu modifié avec succès.`;
    this.layoutUtilsService.showActionNotification(
      message,
      MessageType.Create,
      5000,
      true,
      true
    );
  }

  getComponentTitle() {
    let result = 'Create';
    if (!this.about || !this.about.id) {
      return result;
    }

    result = `Edit contenu - ${this.about.title}`;
    return result;
  }

  /**
   * Close Alert
   *
   * @param $event: Event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
