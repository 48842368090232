// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { AuthActions } from '../../../../../core/auth';
import { currentUserMail } from '../../../../../core/auth/_selectors/auth.selectors';
// import { currentUser, Logout, User } from '../../../../../core/auth';

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  // Public properties
  userMail$: Observable<string>;

  @Input() avatar = false;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(private store: Store<AppState>) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.userMail$ = this.store.pipe(select(currentUserMail));
  }

  /**
   * Log out
   */
  logout() {
    this.store.dispatch(AuthActions.logout());
  }
}
