// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
// State
import { each } from 'lodash';
import { UsersState } from './user.reducers';
import { UserModel } from '../_models/user.model';
import { HttpExtenstionsModel, QueryResultsModel } from '../_base/crud';

export const selectUsersState = createFeatureSelector<UsersState>('users');

export const selectError = createSelector(
  selectUsersState,
  (state: UsersState) => state.error
);

export const selectUserById = (userId: number) =>
  createSelector(selectUsersState, (usersState) => usersState.entities[userId]);

export const selectUsersPageLoading = createSelector(
  selectUsersState,
  (usersState) => {
    return usersState.listLoading;
  }
);

export const selectUsersActionLoading = createSelector(
  selectUsersState,
  (usersState) => usersState.actionsloading
);

export const selectLastCreatedUserId = createSelector(
  selectUsersState,
  (usersState) => usersState.lastCreatedUserId
);

export const selectUsersPageLastQuery = createSelector(
  selectUsersState,
  (usersState) => usersState.lastQuery
);

export const selectUsersInStore = createSelector(
  selectUsersState,
  (usersState) => {
    const items: UserModel[] = [];
    each(usersState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: UserModel[] = httpExtension.sortArray(
      items,
      usersState.lastQuery.sortField,
      usersState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, usersState.totalCount, '');
  }
);

export const selectUsersShowInitWaitingMessage = createSelector(
  selectUsersState,
  (usersState) => usersState.showInitWaitingMessage
);

export const selectHasUsersInStore = createSelector(
  selectUsersState,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  }
);
