import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as ContactActions from './contact.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ContactService } from './contact.service';

@Injectable()
export class ContactEffects {
  constructor(
    private actions$: Actions,
    private contactService: ContactService,
    private store: Store<AppState>
  ) {}

  ContactsPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.ContactsPageRequested),
      switchMap((action) =>
        this.contactService
          .getClientsBySupplier(action.supplierId, action.page)
          .pipe(
            map((contactsPage) =>
              ContactActions.ContactsPageLoadedSuccessfully({
                contact: contactsPage.content,
                page: contactsPage.totalElements,
                totalCount: contactsPage.totalElements,
              })
            ),
            catchError((error) =>
              of(ContactActions.ContactsPageLoadFailed({ error }))
            )
          )
      )
    )
  );

  UpdateContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.UpdateContact),
      switchMap((action) =>
        this.contactService.updateContact(action.contact).pipe(
          map((Contact) =>
            ContactActions.ContactUpdatedSuccessfully({ contact: Contact })
          ),
          catchError((error) =>
            of(ContactActions.ContactUpdateFailed({ error }))
          )
        )
      )
    )
  );

  DeleteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.DeleteContact),
      switchMap((action) =>
        this.contactService.deleteContact(action.contactId).pipe(
          map((Supplier) =>
            ContactActions.ContactDeletedSuccessfully({
              contactId: action.contactId,
            })
          ),
          catchError((error) =>
            of(ContactActions.ContactDeletionFailed({ error }))
          )
        )
      )
    )
  );

  CreateContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactActions.CreateContact),
      switchMap((action) =>
        this.contactService.addContact(action.contact).pipe(
          map((Contact) =>
            ContactActions.ContactCreatedSuccessfully({ contact: Contact })
          ),
          catchError((error) =>
            of(ContactActions.ContactCreationFailed({ error }))
          )
        )
      )
    )
  );
}
