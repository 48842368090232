import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import {
  selectLogsInStore,
  selectLogsPageLoading,
  selectLogsShowInitWaitingMessage,
} from './logs.selector';

export class LogsDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(select(selectLogsPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectLogsShowInitWaitingMessage)
    );

    this.store
      .pipe(select(selectLogsInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
