import { createAction, props } from '@ngrx/store';
import { ManufacturerModel } from './manufacturer.model';
import { QueryParamsModel } from '../../_base/crud';

export const ManufacturersListRequested = createAction(
  '[Manufacturers list Page] Manufacturers Requested'
);
export const ManufacturersListRequestedSuccessfully = createAction(
  '[Manufacturers list Page] Manufacturers Requested Successfully',
  props<{ manufacturers: ManufacturerModel[] }>()
);
export const ManufacturersListRequestFailed = createAction(
  '[Manufacturers list Page] Manufacturers Request Failed',
  props<{ error: any }>()
);

export const UpdateManufacturer = createAction(
  '[Manufacturers API] Update Manufacturer',
  props<{ manufacturer: ManufacturerModel }>()
);
export const ManufacturerUpdatedSuccessfully = createAction(
  '[Manufacturers API] Manufacturers updated Successfully',
  props<{ manufacturer: ManufacturerModel }>()
);
export const ManufacturerUpdateFailed = createAction(
  '[Manufacturers API] Manufacturers Update Failed',
  props<{ error: any }>()
);

export const DeleteManufacturer = createAction(
  '[Manufacturers API] Delete Manufacturer',
  props<{ manufacturerId: number }>()
);
export const ManufacturerDeletedSuccessfully = createAction(
  '[Manufacturers API] Manufacturers deleted Successfully',
  props<{ manufacturerId: number }>()
);
export const ManufacturerDeletionFailed = createAction(
  '[Manufacturers API] Manufacturers Deletion Failed',
  props<{ error: any }>()
);

export const CreateManufacturer = createAction(
  '[Manufacturers API] Create Manufacturer',
  props<{ manufacturer: ManufacturerModel }>()
);
export const ManufacturerCreatedSuccessfully = createAction(
  '[Manufacturers API] Manufacturers created Successfully',
  props<{ manufacturer: ManufacturerModel }>()
);
export const ManufacturerCreationFailed = createAction(
  '[Manufacturers API] Manufacturers Creation Failed',
  props<{ error: any }>()
);

export const ManufacturersPageRequested = createAction(
  '[Manufacturers list Page] Manufactures Requested ',
  props<{ page: QueryParamsModel }>()
);
export const ManufacturersPageLoadedSuccessfully = createAction(
  '[Manufacturers API] Manufacturers Page Loaded Successfully',
  props<{
    manufacturer: ManufacturerModel[];
    totalCount: number;
    page: number;
  }>()
);
export const ManufacturersPageLoadFailed = createAction(
  '[Manufacturers API] Manufacturer Page Request Failed ',
  props<{ error: any }>()
);
