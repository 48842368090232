import { createAction, props } from '@ngrx/store';
import { CategoryCharacteristicModel } from './category-characteristic.model';
import { Update } from '@ngrx/entity';

export const CategoryCharacteristicListRequested = createAction(
  '[CategoryCharacteristic list Page] Characteristic Requested',
  props<{ categoryId: number }>()
);
export const CategoryCharacteristicListRequestedSuccessfully = createAction(
  '[CategoryCharacteristic list Page] Characteristic Requested Successfully',
  props<{ categoryCharacteristics: CategoryCharacteristicModel[] }>()
);
export const CategoryCharacteristicListRequestFailed = createAction(
  '[CategoryCharacteristic list Page] Characteristic Request Failed',
  props<{ error: any }>()
);

export const CategoryCharacteristicCreated = createAction(
  '[CategoryCharacteristic List Page] Characteristic Created ',
  props<{
    categoryCharacteristic: CategoryCharacteristicModel;
    categoryId: number;
  }>()
);
export const CategoryCharacteristicCreatedSuccessfully = createAction(
  '[CategoryCharacteristic List Page] Characteristic Created Successfully ',
  props<{ categoryCharacteristic: CategoryCharacteristicModel }>()
);
export const CategoryCharacteristicCreationFailed = createAction(
  '[Category List Page] Characteristic Creation Failed ',
  props<{ error: any }>()
);

export const CategoryCharacteristicUpdated = createAction(
  '[CategoryCharacteristic List Page] Characteristic Updated ',
  props<{
    categoryCharacteristic: CategoryCharacteristicModel;
    partialCharacteristic: Update<CategoryCharacteristicModel>;
    categoryId: number;
  }>()
);
export const CategoryCharacteristicUpdatedSuccessfully = createAction(
  '[CategoryCharacteristic List Page] Characteristic Updated Successfully ',
  props<{
    categoryCharacteristic: CategoryCharacteristicModel;
    partialCharacteristic: Update<CategoryCharacteristicModel>;
  }>()
);
export const CategoryCharacteristicUpdateFailed = createAction(
  '[Category List Page] Characteristic Update Failed ',
  props<{ error: any }>()
);

export const CategoryCharacteristicDeleted = createAction(
  '[CategoryCharacteristic List Page] Characteristic Deleted ',
  props<{ id: number; categoryId: number }>()
);
export const CategoryCharacteristicDeletedSuccessfully = createAction(
  '[CategoryCharacteristic List Page] Characteristic Deleted Successfully ',
  props<{ id: number }>()
);
export const CategoryCharacteristicDeleteFailed = createAction(
  '[Category List Page] Characteristic Delete Failed ',
  props<{ error: any }>()
);
