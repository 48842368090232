import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import * as AuthActions from '../_actions/auth.actions';
import { loginFailure } from '../_actions/auth.actions';
import { of } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((action) =>
        this.authService.login(action.mail, action.password).pipe(
          map((token) => AuthActions.loggingIn(token)),
          catchError((error: HttpErrorResponse) =>
            of(loginFailure({ errorCode: error.status }))
          )
        )
      )
    );
  });

  /**
   * since the reducer executes before the effect we can't check the result of the login query in the reducer of
   * loginsuccess so we added this action that puts the token in local storage
   * and then provide the login success action
   */
  logginIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loggingIn),
      switchMap((action) =>
        of(action).pipe(
          map((token) => AuthActions.loginSuccess(token)),
          tap((action) => {
            localStorage.setItem('authToken', action.token);
          })
        )
      )
    );
  });

  getLoggedInUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getLoggedInUser),
      switchMap((action) =>
        this.authService.getLoggedinUser().pipe(
          map((user) => AuthActions.getLoggedInUserSucceed({ user })),
          catchError((error) =>
            of(AuthActions.getLoggedInUserFailed({ errorCode: error }))
          )
        )
      )
    );
  });

  loginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((action) => {
            const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
            if (returnUrl) {
                this.router.navigateByUrl(returnUrl);
            } else {
                this.router.navigate(['/dashboard']);
            }
        })
      ),
    { dispatch: false }
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          localStorage.removeItem('authToken');
          this.router.navigate(['/auth/login']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}
