import { Pipe, PipeTransform } from '@angular/core';
import { AddressModel } from '../../core/e-commerce/companies/address.model';
import { CountryModel } from '../../core/_models/country.model';

@Pipe({
  name: 'addressTrans',
})
export class AddressTransPipe implements PipeTransform {
  transform(address: AddressModel): string {
    return (
      address.contactName +
      '#' +
      address.contactPhone +
      '#' +
      address.streetName +
      '*' +
      address.additionalInfo +
      '*' +
      address.cedex +
      '#' +
      address.postalCode +
      '#' +
      address.city +
      '#' +
      address.country.name
    );
  }
  inverse(address: string, countries: CountryModel[]): AddressModel {
    return {
      id: 0,
      type: 'delivryAddress',
      contactName: address.split('#')[0],
      contactPhone: address.split('#')[1],
      streetName: address.split('#')[2].split('*')[0],
      additionalInfo: address.split('#')[2].split('*')[1],
      cedex: address.split('#')[2].split('*')[2],
      postalCode: address.split('#')[3],
      city: address.split('#')[4],
      country: countries.find((c) => c.name === address.split('#')[5]),
    };
  }
}
