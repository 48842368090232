import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SearchedProductService } from '../searched-product.service';
import { ProductsService } from '../../../../../../../../core/e-commerce/products/products.service';
import { QueryParamsModel } from '../../../../../../../../core/_base/crud';

@Component({
  selector: 'kt-product-search-dropdown',
  templateUrl: './product-search-dropdown.component.html',
  styleUrls: ['./product-search-dropdown.component.scss'],
})
export class ProductSearchDropdownComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @Output() searchedProduct = new EventEmitter<any>(); // TODO don't use any, must type the exchanged entity

  data: any[];
  loading: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private productService: ProductsService,
    private searchProductService: SearchedProductService
  ) {}

  ngOnInit(): void {}

  search(e) {
    this.data = null;
    this.loading = true;

    this.searchProductService
      .getProductByFAB(e.target.value.trim())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((e) => {
        this.data = [e];
        this.checkIfProductAlreadyExist();
        this.cdr.markForCheck();
      });
  }

  clear(e) {
    this.data = null;
    this.loading = false;
    this.searchInput.nativeElement.value = '';
  }

  openChange() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  checkIfProductAlreadyExist() {
    this.data.forEach((e) => {
      let queryParamsModel = new QueryParamsModel(null, null, null, null, null);
      queryParamsModel = {
        ...queryParamsModel,
        filter: {
          query: this.searchInput.nativeElement.value,
        },
      };
      this.productService.getAllPaged2(queryParamsModel).subscribe((s) => {
        if (s.content.length) e.exists = true;
      });
    });
  }
}
