import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../../core/_base/crud';
import { AddressModel } from '../../../../../../../core/e-commerce/suppliers/address.model';
import { ActivatedRoute } from '@angular/router';
import { SupplierAddressesService } from '../../../../../../../core/e-commerce/suppliers/addresses.service';
import { AddressSupplierEditDialogComponent } from '../../../suppliers/addresses-supplier/address-edit/address-edit-dialog.component';
import { SupplierService } from '../../../../../../../core/e-commerce/suppliers/supplier.service';

@Component({
  selector: 'kt-supplier-address-list',
  templateUrl: './addresses-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressesSupplierListComponent implements OnInit, OnDestroy {
  // Incoming data
  dataSource = [];

  addressesSubject$ = new BehaviorSubject<AddressModel[]>([]);

  displayedColumns = [
    'id',
    'country',
    'city',
    'streetName',
    'postalCode',
    'actions',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  private componentSubscriptions: Subscription[] = [];
  private supplierId: number;
  supplierName: string;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private activatedRoute: ActivatedRoute,
    private addressesService: SupplierAddressesService,
    private supplierService: SupplierService
  ) {}

  ngOnInit() {
    this.supplierId = this.activatedRoute.snapshot.queryParams.id;
    this.supplierService.getSupplierById(this.supplierId).subscribe((res) => {
      this.supplierName = res.name;
    });
    const addressesSub = this.addressesService
      .getAddressBySupplier(this.supplierId)
      .subscribe((res) => {
        if (res) {
          this.addressesSubject$.next(res);
          this.dataSource = res;
          // console.log('Addreses : '+JSON.stringify(res));
        } else {
          this.dataSource = [];
        }
      });
    this.componentSubscriptions.push(addressesSub);
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
    }
  }

  /**
   * Load Specs List
   */
  loadSpecsList() {}

  /** ACTIONS */
  /**
   * Delete spec
   *
   * @param _item: AddressModel
   */
  deleteAddress(_item: AddressModel) {
    const _title = 'Address Delete';
    const _description = 'Are you sure to permanently delete this address?';
    const _waitDesciption = 'Address is deleting...';
    const _deleteMessage = `Address has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.addressesService
        .deleteAddress(this.supplierId, _item.id)
        .subscribe((res) => {
          const afterDelete = this.addressesSubject$.value.filter(
            (elem) => elem != _item
          );
          this.addressesSubject$.next(afterDelete);
          this.layoutUtilsService.showActionNotification(
            _deleteMessage,
            MessageType.Delete
          );
          this.loadSpecsList();
        });
    });
  }

  addAdress() {
    const newAddress = new AddressModel();
    const dialogRef = this.dialog.open(AddressSupplierEditDialogComponent, {
      data: { address: null, supplierId: this.supplierId },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        newAddress.country = res.country;
        newAddress.city = res.city;
        newAddress.streetName = res.streetName;
        newAddress.postalCode = res.postalCode;
        newAddress.cedex = res.cedex;
        newAddress.supplierId = this.supplierId;

        const newAddrList = this.dataSource.concat([res]);
        this.addressesSubject$.next(newAddrList);
        const saveMessage = `Address has been created`;
        this.layoutUtilsService.showActionNotification(
          saveMessage,
          MessageType.Create,
          10000,
          true,
          true
        );
        this.ngOnInit();
      }
    });
  }

  /**
   * Edit add spec dialog ans save data
   *
   * @param item: ProductAddressModel
   */
  editAddress(item: AddressModel) {
    const _item = Object.assign({}, item);
    const dialogRef = this.dialog.open(AddressSupplierEditDialogComponent, {
      data: { address: _item, supplierId: this.supplierId },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const newAddrList = this.dataSource.map(
          (obj) => [res].find((o) => o.id === obj.id) || obj
        );
        this.addressesSubject$.next(newAddrList);

        const saveMessage = `Address has been updated`;
        this.layoutUtilsService.showActionNotification(
          saveMessage,
          MessageType.Update,
          10000,
          true,
          true
        );
      }
    });
  }
}
