// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// CRUD
// State
import { each } from 'lodash';
import { QuestionsState } from './client-question.reducers';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { ClientQuestionModel } from './client-question.model';

export const selectQuestionsState =
  createFeatureSelector<QuestionsState>('questions');

export const selectError = createSelector(
  selectQuestionsState,
  (state: QuestionsState) => state.error
);

export const selectQuestionById = (id: number) =>
  createSelector(
    selectQuestionsState,
    (questionsState) => questionsState.entities[id]
  );

export const selectQuestionsStore = createSelector(
  selectQuestionsState,
  (questionsState: QuestionsState) => {
    const questions: ClientQuestionModel[] = [];
    Object.entries(questionsState.entities).forEach(([k, v]) =>
      questions.push(v)
    );
    return questions;
  }
);

export const selectQuestionsInStore = createSelector(
  selectQuestionsState,
  (questionState) => {
    const items: ClientQuestionModel[] = [];
    each(questionState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: ClientQuestionModel[] = httpExtension.sortArray(
      items,
      questionState.lastQuery.sortField,
      questionState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result);
  }
);

export const selectQuestionsPageLoading = createSelector(
  selectQuestionsState,
  (questionState) => questionState.listLoading
);

export const selectQuestionsActionLoading = createSelector(
  selectQuestionsState,
  (questionState) => questionState.actionsloading
);

export const selectQuestionsPageLastQuery = createSelector(
  selectQuestionsState,
  (questionState) => questionState.lastQuery
);

export const selectLastCreatedBlogId = createSelector(
  selectQuestionsState,
  (questionState) => questionState.lastCreatedQuestionId
);

export const selectQuestionsInitWaitingMessage = createSelector(
  selectQuestionsState,
  (questionState) => questionState.showInitWaitingMessage
);

export const selectHasQuestionsInStore = createSelector(
  selectQuestionsInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  }
);
