import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OrderModel } from './order.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as OrderActions from './orders.actions';

export interface OrderState extends EntityState<OrderModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedOrderId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  lastAction: string;
}

export const adapter: EntityAdapter<OrderModel> =
  createEntityAdapter<OrderModel>();

export const initialOrderState: OrderState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedOrderId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  lastAction: '',
});

const orderReducer = createReducer(
  initialOrderState,
  on(OrderActions.OrdersPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),
  on(OrderActions.OrdersPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.orders, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(OrderActions.OrdersPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //creation
  on(OrderActions.OrderCreated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(OrderActions.OrderCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.order, {
      ...state,
      actionsLoading: false,
      lastCreatedOrderId: action.order.id,
      lastAction: 'EDIT.ADD_MESSAGE',
    })
  ),
  on(OrderActions.OrderCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  //Update
  on(OrderActions.OrderUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(OrderActions.OrderUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialOrder, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(OrderActions.OrderUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  // //Update Order Item
  // on(OrderActions.OrderItemUpdated,
  //     (state) => ({...state, actionsLoading: true, lastAction: ''})),
  // on(OrderActions.OrderItemUpdatedSuccessfully,
  //     (state, action) =>
  //         (adapter.updateOne({id: action.order?.id, changes: action.order}, {
  //             ...state,
  //             actionsLoading: false
  //         }))),
  // on(OrderActions.OrderItemUpdateFailed,
  //     (state, action) => ({...state, actionsLoading: false, error: action.error})),

  //Delete
  on(OrderActions.OrderDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(OrderActions.OrderDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.orderId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(OrderActions.OrderDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  }))
);

export function ordersReducer(state: OrderState | undefined, action: Action) {
  return orderReducer(state, action);
}
