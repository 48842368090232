import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderState } from './order.reducers';
import { OrderModel } from './order.model';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
// Lodash
import { each } from 'lodash';
import { selectCategoriesState } from '../categories/category.selector';

export const selectOrdersState = createFeatureSelector<OrderState>('orders');

export const selectOrderById = (orderId: number) =>
  createSelector(selectOrdersState, (ordersState) => {
    return ordersState.entities[orderId];
  });

export const selectOrdersPageLoading = createSelector(
  selectOrdersState,
  (ordersState) => ordersState.listLoading
);

export const selectOrdersActionLoading = createSelector(
  selectOrdersState,
  (customersState) => customersState.actionsLoading
);

export const selectOrdersPageLastQuery = createSelector(
  selectOrdersState,
  (ordersState) => ordersState.lastQuery
);

export const selectLastCreatedOrderId = createSelector(
  selectOrdersState,
  (ordersState) => ordersState.lastCreatedOrderId
);

export const selectOrdersInitWaitingMessage = createSelector(
  selectOrdersState,
  (ordersState) => ordersState.showInitWaitingMessage
);

export const selectError = createSelector(
  selectOrdersState,
  (ordersState) => ordersState.error
);

export const selectOrdersInStore = createSelector(
  selectOrdersState,
  (ordersState) => {
    const items: OrderModel[] = [];
    each(ordersState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: OrderModel[] = httpExtension.sortArray(
      items,
      ordersState.lastQuery.sortField,
      ordersState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, ordersState.totalCount, '');
  }
);

export const selectHasOrdersInStore = createSelector(
  selectOrdersInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  }
);
