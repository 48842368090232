import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  skip,
  finalize,
} from 'rxjs/operators';
import {fromEvent, merge, Subscription} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { SubheaderService } from '../../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../core/_base/crud';

import {
  CategoryActions, ManufacturerActions,
  ProductActions
} from '../../../../../../core/e-commerce';
import { ProductsDataSource } from '../../../../../../core/e-commerce/products/products.datasource';
import { ProductModel } from '../../../../../../core/e-commerce/products/product.model';
import { selectProductsPageLastQuery } from '../../../../../../core/e-commerce/products/product.selectors';
import { ProductResource } from '../../../../../../core/e-commerce/products/product-resources/product-resource';
import { CategoryModel } from '../../../../../../core/e-commerce/categories/category.model';
import {
  selectCategoriesListInStore,
} from '../../../../../../core/e-commerce/categories/category.selector';
import {selectManufacturersInStore} from '../../../../../../core/e-commerce/manufacturers/manufacturer.selector';

import { FormControl, FormGroup } from '@angular/forms';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { ProductsService } from '../../../../../../core/e-commerce/products/products.service';
import { DatePipe } from '@angular/common';
import { ProductCharacteristicsService } from '../../../../../../core/e-commerce/product-characteristic/product-characteristics.service';
import { ProductCharacteristicModel } from '../../../../../../core/e-commerce/product-characteristic/product-characteristic.model';
import { ManufacturerModel } from '../../../../../../core/e-commerce/manufacturers/manufacturer.model';
import {ProductModelOpt} from '../../../../../../core/e-commerce/products/productOptm.model';
import {CategoriesService} from '../../../../../../core/e-commerce/categories/categories.service';
import {Actions, ofType} from '@ngrx/effects';
import {AccessoriesService} from '../../../../../../core/e-commerce/categories/accessories/accessories.service';


@Component({
  selector: 'kt-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsListComponent implements OnInit, OnDestroy {
  dataSource: ProductsDataSource;
  displayedColumns = [
    'select',
    'id',
    'code',
    'name',
    'status',
    'image',
    'source',
    'categorie',
    'fabriquant',
    'updater',
    'updateDate',
    'actions'
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('exporter', { static: true }) exporter: MatTableExporterDirective;


  filterStatus = new FormControl('');
  filterProductStatus = new FormControl('');
  filterProductSource = new FormControl('');
  filterCategories = new FormControl(0);
  filterManufacturer = new FormControl('');
  lastQuery: QueryParamsModel;

  selection = new SelectionModel<ProductModelOpt>(true, []);

  productsResult: ProductModelOpt[] = [];
  private subscriptions: Subscription[] = [];

  imageGallery: ProductResource[] = [];
  parentCategories: CategoryModel[] = [];
  isExporting = true;
  productChar: ProductCharacteristicModel[];
  manufacturers: ManufacturerModel[];
  startDate = '';
  endDate = '';
  dateFilter = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });




  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private productService: ProductsService,
    private productCharacteristicsService: ProductCharacteristicsService,
    private categoriesService: CategoriesService,
    private actions$: Actions,
    private accessoriesService: AccessoriesService,
  ) {
   /* this.store.dispatch(CategoryActions.CategoriesListRequested());
    this.actions$.pipe(ofType(CategoryActions.CategoriesListRequestedSuccessfully)).subscribe((data) => {
      if (data) {
        this.parentCategories = data.category.filter((c) => c.parentId);
        }
    });*/
    this.accessoriesService.getCategories3Niveau().subscribe((data) => {
      if (data) {
        this.parentCategories = data;
      }
    });
  }

  ngOnInit() {
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );

    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';

    /* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadProductsList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadProductsList();
        })
      )
      .subscribe();

    // Init DataSource
    this.dataSource = new ProductsDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.productsResult = res;
      });

    const lastQuerySubscription = this.store
      .pipe(select(selectProductsPageLastQuery))
      .subscribe((res) => {
        this.lastQuery = res;
        this.loadProductsList(this.lastQuery);
      });

    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(() => {
      const pageSize = this.lastQuery.pageSize;
      const pageNumber = this.lastQuery.pageNumber;
      this.paginator._changePageSize(pageSize);
      this.paginator.pageIndex = pageNumber;
      this.loadProductsList(this.lastQuery);
    });

    this.subscriptions.push(
      lastQuerySubscription,
      entitiesSubscription,
      routeSubscription,
      searchSubscription
    );
    this.loadManufacturers();
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }
   loadManufacturers() {
    this.store.dispatch(ManufacturerActions.ManufacturersListRequested());
    const manufacturers$ = this.store
      .select(selectManufacturersInStore)
      .subscribe((results) => {
        this.manufacturers = results.items;
      });
  }
  filterByDate() {
    if (this.dateFilter.get('endDate').value) {
      this.startDate = new DatePipe('fr-FR').transform(
        new Date(this.dateFilter.get('startDate').value),
        'YYYY/MM/dd'
      );
      this.endDate = new DatePipe('fr-FR').transform(
        new Date(this.dateFilter.get('endDate').value),
        'YYYY/MM/dd'
      );
    } else {
      this.startDate = '';
      this.endDate = '';
    }
    this.loadProductsList();
  }

  /**
   * Load Products List
   */
  loadProductsList(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }

    this.store.dispatch(
      ProductActions.ProductsPageRequestedOpt({ queryParams })
    );
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterCategories.value) {
      filter.categories = [];
      filter.categories.push(this.filterCategories.value);
    }
    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }
    if (this. filterProductStatus.value && this.filterProductStatus.value !== '') {
      filter.productStatus = this. filterProductStatus.value;
    }

    if (this. filterProductSource.value && this.filterProductSource.value !== '') {
      filter.productSource = this. filterProductSource.value;
    }

    if (this.filterManufacturer.value) {
      filter.manufacturer = this.filterManufacturer.value;
    }
    if (this.dateFilter.value) {
    filter.startDate = this.startDate;
    filter.endDate = this.endDate;
    }

    // if (this.filterCondition && this.filterCondition.length > 0) {
    // 	filter.condition = +this.filterCondition;
    // }

    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  archiveProduct( _item : ProductModelOpt) {
    const _title = 'Archivage de produit ==> ' + _item.name;
    const _description = 'Voulez-vous vraiment archiver ce produit ?';
    const _waitDesciption = 'Archivage en cours...';
    const btnTitle = 'Archiver';

    const dialogRef = this.layoutUtilsService.archiveElement(
      _title,
      _description,
      _waitDesciption,
      btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(
        ProductActions.ArchiveProduct({ productId: _item.id })
      );
    });
  }

  changeProductVisiblity(product: ProductModel , value) {
    if (!product.visible) {
    this.productCharacteristicsService.getByProductId(product.id).subscribe((res) => {
      this.productChar = res;
      if (this.productChar.length < 2 || undefined) {
        this.layoutUtilsService.showActionNotification(
          'Vérifiez les champs: description courte/ longue et le nombre de caractéristiques!',
          MessageType.Delete
        );
        value.checked = false;
        this.ngOnInit();
      } else {
        this.store.dispatch(
          ProductActions.ChangeProductVisibility({ product })
        );
      }
    });
    } else {
      this.store.dispatch(
        ProductActions.ChangeProductVisibility({ product })
      );
    }
  }

  /**
   * Delete products
   */
  deleteProducts() {
    const _title = 'Products Delete';
    const _description =
      'Are you sure to permanently delete selected products?';
    const _waitDesciption = 'Products are deleting...';
    const _deleteMessage = 'Selected products have been deleted';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      const idsForDeletion: number[] = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.selection.selected.length; i++) {
        idsForDeletion.push(this.selection.selected[i].id);
      }
      // todo make this work

      // this.store.dispatch(new ManyProductsDeleted({ ids: idsForDeletion }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
      this.selection.clear();
    });
  }

  /**
   * Fetch selected products
   */
  fetchProducts() {}

  /**
   * Update status dialog
   */
  updateStatusForProducts() {}

  /**
   * Redirect to edit page
   *
   * @param id: any
   */
  editProduct(id) {
    this.router.navigate(['/ecommerce/products/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }

  createProduct() {
    this.router.navigateByUrl('/ecommerce/products/add');
  }

  /**
   * Check all rows are selected
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsResult.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection
   */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.productsResult.forEach((row) => this.selection.select(row));
    }
  }

  /* UI */
  /**
   * Returns status string
   *
   * @param status: number
   */
  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Selling';
      case 1:
        return 'Sold';
    }
    return '';
  }

  /**
   * Returns CSS Class by status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'IN_STOCK':
        return 'success';
      case 'OUT_OF_STOCK':
        return 'danger';
      case 'ON_COMMAND':
        return 'metal';
    }
    return '';
  }

  onRightClick(id) {
    window.open('/ecommerce/products/edit/' + id, '_blank');
  }
  exportProducts(id: number) {
    this.isExporting = false;
    this.productService.ExportExcelProducts(id).pipe(finalize(() => this.isExporting = true)).subscribe((product) => {
      const blob = new Blob([product], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download =
        'Products_' +
        new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') +
        '.xlsx';
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
}
