import { createAction, props } from '@ngrx/store';


// get list accessories
export const accessoriesListPageRequested = createAction(
  '[Category List Page] accessories List Request ',
  props<{ idOwner: number }>()
);
export const accessoriesListPageRequestedSuccessfully = createAction(
  '[Category API] accessories List Loaded Successfully ',
  props<{ categories: any[] }>()
);
export const accessoriesListPageRequestedFailed = createAction(
  '[Category API] accessories List Request Failed ',
  props<{ error: any }>()
);

// delete accessories from list selected
export const AccessorieDeleted = createAction(
  '[Category List Page] Accessorie Deleted ',
  props<{ categoryId: number , id: number}>()
);
export const AccessorieDeletedSuccessfully = createAction(
  '[Category List Page] Accessorie deleted Successfully ',
  props<{ categoryId: number , id: number}>()
);
export const AccessorieDeleteFailed = createAction(
  '[Category List Page] Accessorie delete Failed ',
  props<{ error: any }>()
);

// save accessories
export const Categories3LevelSave = createAction(
  '[Category List Page] Categories 3rd Level ',
  props<{ idOwner: number ; id: number }>()
);
export const Categories3LevelSavedSuccessfully = createAction(
  '[Category API] Categories 3rd Level Loaded Successfully ',
  props<{ category: any }>()
);
export const Categories3LevelSaveFailed = createAction(
  '[Category API] Categories 3rd Level Request Failed ',
  props<{ error: any }>()
);
