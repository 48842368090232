// NGRX
import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import * as UserActions from './user.actions';
import { QueryParamsModel } from '../_base/crud';
import { UserModel } from '../_models/user.model';
// CRUD
// Models

// tslint:disable-next-line:no-empty-interface
export interface UsersState extends EntityState<UserModel> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedUserId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
}

export const adapter: EntityAdapter<UserModel> =
  createEntityAdapter<UserModel>();

export const initialUsersState: UsersState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  lastCreatedUserId: undefined,
  showInitWaitingMessage: true,
  error: null,
});

const userReducer = createReducer(
  initialUsersState,
  on(UserActions.AllUsersRequested, (state) => ({
    ...state,
    listLoading: true,
    actionsloading: true,
    showInitWaitingMessage: true,
  })),
  on(UserActions.AllUsersLoaded, (state, action) =>
    adapter.addAll(action.users, {
      ...state,
      listLoading: false,
      actionsloading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(UserActions.UserDeleted, (state, action) => state),
  on(UserActions.UserDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(UserActions.UserOnServerCreated, (state, action) => ({
    ...state,
    actionsloading: true,
  })),
  on(UserActions.UserAddedSuccessfully, (state, action) =>
    adapter.addOne(action.user, {
      ...state,
      actionsloading: false,
      lastCreatedUserId: action.user.id,
    })
  ),
  on(UserActions.AddUserFailed, (state, action) => ({
    ...state,
    actionsloading: false,
    error: action.error,
  })),
  on(UserActions.UserUpdated, (state, action) => ({
    ...state,
    actionsloading: true,
  })),
  on(UserActions.UserUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialUser, { ...state, actionsloading: false })
  ),
  on(UserActions.UserUpdateFailed, (state, action) => ({
    ...state,
    actionsloading: false,
    error: action.error,
  }))
);

export function usersReducer(state: UsersState | undefined, action: Action) {
  return userReducer(state, action);
}
