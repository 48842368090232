import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  selectClaimsInStore,
  selectClaimPageLoading,
  selectClaimsInitWaitingMessage,
} from './claim.selectors';

export class ClaimDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectClaimPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectClaimsInitWaitingMessage)
    );

    this.store
      .pipe(select(selectClaimsInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
