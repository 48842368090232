import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CategoryModel } from '../category.model';
import { ADMIN_CONFIG } from '../../../../../environments/environment';
import { AbstractService } from '../../../services/abstract-service.service';

@Injectable()

export class AccessoriesService extends AbstractService<CategoryModel> {
  url: string;
  productId: number;
  constructor(
    protected http: HttpClient,
    private route: ActivatedRoute,
    ) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}category`;
  }
  public deleteAccessorie(categoryId: number, id: number) {
    let params = new HttpParams();
    params = params.append('categoryId', categoryId.toString());
    params = params.append('id', id.toString());
    const options = { params };
    const url = `${this.url}/unlink-accessories`;
    return this.http.post<any>(url, {}, {params});
  }


  public getAllAccessoire(id: number): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('id', id.toString());
    const options = { params };
    const url = `${this.url}/{id}/accessories`;
    return this.http.get<any[]>(url, options);
  }

  public getCategories3Niveau( filter?: any,
  ): Observable<CategoryModel[]> {
    let params = new HttpParams();
    params = params.append('search', filter?.query ? filter?.query : '');
    params = filter?.type ? params.append('type', filter.type) : params;
    params = filter?.status ? params.append('active', filter.status) : params;

    const options = { params };
    const url = `${this.url}/All`;
    return this.http.get<CategoryModel[]>(url, options);
}

public addAccessoire(idOwner: number, id: number): Observable<any> {
  const url = `${this.url}/addaccessories`;
  const body = {
    'accessoriesOwner' : idOwner,
    'accessories' : id
  };
  return this.http.post<any>(url, body);
}
}
