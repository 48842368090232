import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { MainClientModel } from './mainclient.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { ClientModel } from '../clients/client.model';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { ProductModel } from '../products/product.model';

@Injectable({
  providedIn: 'root',
})
export class MainClientService extends AbstractService<MainClientModel> {
  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}client`;
    this.urlOrder = `${ADMIN_CONFIG.apiEndpoint}order`;
    this.urlQuotation = `${ADMIN_CONFIG.apiEndpoint}quotation`;
  }

  protected url: string;
  protected urlOrder: string;
  protected urlQuotation: string;

  public registerClient(entity: MainClientModel): Observable<MainClientModel> {
    console.log('entity to save' + JSON.stringify(entity));
    console.log(this.url);

    const res = this.http.post<MainClientModel>(this.url + '/add', entity);
    // res.pipe(tap(val => console.log(JSON.stringify(val))));
    return res;
  }

  public getAllClients(
    queryParams: QueryParamsModel
  ): Observable<Page<ClientModel>> {
    const url = `${this.url}/all`;
    let params = new HttpParams();
    params = params.append('page', queryParams.pageNumber.toString());
    params = params.append('size', queryParams.pageSize.toString());
    params = params.append(
      'search',
      queryParams.filter.query ? queryParams.filter.query : ''
    );
    params = queryParams.filter.status
      ? params.append('status', queryParams.filter.status)
      : params;
    const options = { params };
    return this.http.get<Page<ClientModel>>(url, options);
  }
  public getArchivedClients(
    queryParams: QueryParamsModel
  ): Observable<Page<ClientModel>> {
    let params = new HttpParams()
      .append(
        'page',
        queryParams.pageNumber ? queryParams.pageNumber.toString() : '0'
      )
      .append(
        'size',
        queryParams.pageSize ? queryParams.pageSize.toString() : '10'
      )
      .append(
        'search',
        queryParams.filter.query ? queryParams.filter.query : ''
      );

    params = queryParams.filter.status
      ? params.append('status', queryParams.filter.status)
      : params;
    const options = { params };

    const url = `${this.url}/archivedClient`;
    return this.http.get<Page<ClientModel>>(url, options);
  }

  public archiveClient(clientId: number): Observable<any> {
    const url = `${this.url}/archiveClient/${clientId}`;
    return this.http.post<void>(url, null);
  }

  public unarchiveClient(clientId: number): Observable<any> {
    const url = `${this.url}/unarchiveClient/${clientId}`;
    return this.http.post<void>(url, null);
  }
  public findClientCommands(): Observable<ClientModel[]> {
    const url = `${this.url}/find-Clients-Commands/`;
    return this.http.get<ClientModel[]>(url);
  }
  public findClientDevis(): Observable<ClientModel[]> {
    const url = `${this.url}/find-Clients-quotations/delete`;
    return this.http.get<ClientModel[]>(url);
  }
  public archivedAllCommande(mail: string): Observable<any> {
    const url = `${this.urlOrder}/archiveAll/${mail}`;
    return this.http.post<void>(url, null);
  }
  public archivedAllDevis(mail: string): Observable<any> {
    const url = `${this.urlQuotation}/archiveAll/${mail}`;
    return this.http.post<void>(url, null);
  }
  public unarchivedAllCommande(mail: string): Observable<any> {
    const url = `${this.urlOrder}/unarchiveAll/${mail}`;
    return this.http.post<void>(url, null);
  }
  public unarchivedAllDevis(mail: string): Observable<any> {
    const url = `${this.urlQuotation}/unarchiveAll/${mail}`;
    return this.http.post<void>(url, null);
  }
}
