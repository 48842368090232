import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { CategoryCharacteristicModel } from './category-characteristic.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CategoryCharacteristicsService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}category`;
  }

  public getByCategoryId(
    id: number
  ): Observable<CategoryCharacteristicModel[]> {
    const url = `${this.url}/${id}/categorycharacterstic`;
    return this.http.get<CategoryCharacteristicModel[]>(url);
  }

  public save(
    entity: CategoryCharacteristicModel,
    categoryId: number
  ): Observable<CategoryCharacteristicModel> {
    //todo the backend require a list and return a list no idea why !
    const list: CategoryCharacteristicModel[] = [];
    list.push(entity);
    console.log('saving ... ' + JSON.stringify(list));
    const res = this.http.post<CategoryCharacteristicModel[]>(
      `${this.url}/${categoryId}/categorycharacterstic`,
      list
    );
    return res.pipe(map((result) => result[0]));
  }

  public update(
    entity: CategoryCharacteristicModel,
    categoryId: number
  ): Observable<CategoryCharacteristicModel> {
    return this.http.put<CategoryCharacteristicModel>(
      `${this.url}/${categoryId}/categorycharacterstic`,
      entity
    );
  }

  public delete(id: number, categoryId: number): Observable<any> {
    const url = `${this.url}/${categoryId}/categorycharacterstic/${id}`;
    const res = this.http.delete<void>(url);
    return res;
  }
}
