import { ProductModel } from '../products/product.model';

export class DiscountModel {
  id: number;
  discountType: 'PERCENTAGE' | 'FIX';
  startDate: Date;
  endDate: Date;
  value: number;
  products?: ProductModel;
}
