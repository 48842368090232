import { createAction, props } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { ProductModel } from './product.model';
import { Update } from '@ngrx/entity';
import {ProductModelOpt} from './productOptm.model';

export const ProductsPageRequested = createAction(
  '[Product List Page] Products Page Requested ',
  props<{ queryParams: QueryParamsModel }>()
);
export const ProductsPageLoadedSuccessfully = createAction(
  '[Product API] Products Page Loaded Successfully ',
  props<{ products: ProductModel[]; totalCount: number; page: number }>()
);
export const ProductsPageLoadFailed = createAction(
  '[Product API] Products Page Request Failed ',
  props<{ error: any }>()
);

// get list product Optimised

export const ProductsPageRequestedOpt = createAction(
    '[Product List Page Opt] Products Page Requested Opt',
    props<{ queryParams: QueryParamsModel }>()
);
export const ProductsPageLoadedSuccessfullyOPt = createAction(
    '[Product API Opt] Products Page Loaded Successfully Opt',
    props<{ products: ProductModelOpt[]; totalCount: number; page: number }>()
);
export const ProductsPageLoadFailedOpt = createAction(
    '[Product API Opt] Products Page Request Failed Opt',
    props<{ error: any }>()
);



export const ProductCreated = createAction(
  '[Product List Page] Product Created ',
  props<{ product: ProductModel }>()
);
export const ProductCreatedSuccessfully = createAction(
  '[Product List Page] Product Created Successfully ',
  props<{ product: ProductModel }>()
);
export const ProductCreationFailed = createAction(
  '[Product List Page] Product Creation Failed ',
  props<{ error: any }>()
);

export const ProductUpdated = createAction(
  '[Product List Page] Product Updated ',
  props<{ product: ProductModel; partialProduct: Update<ProductModel> }>()
);
export const ProductUpdatedSuccessfully = createAction(
  '[Product List Page] Product Updated Successfully ',
  props<{ product: ProductModel; partialProduct: Update<ProductModel> }>()
);
export const ProductUpdateFailed = createAction(
  '[Product List Page] Product Update Failed ',
  props<{ error: any }>()
);

export const ProductDeleted = createAction(
  '[Product List Page] Product Deleted ',
  props<{ productId: number }>()
);
export const ProductDeletedSuccessfully = createAction(
  '[Product List Page] Product deleted Successfully ',
  props<{ productId: number }>()
);
export const ProductDeleteFailed = createAction(
  '[Product List Page] Product delete Failed ',
  props<{ error: any }>()
);

export const ProductResourceDeleted = createAction(
  '[Product List Page] Product Resource Deleted ',
  props<{ productId: number; resourceId: number }>()
);
export const ProductResourceDeletedSuccessfully = createAction(
  '[Product List Page] Product Resource deleted Successfully ',
  props<{ productId: number; resourceId: number }>()
);
export const ProductResourceDeleteFailed = createAction(
  '[Product List Page] Product Resource delete Failed ',
  props<{ error: any }>()
);

export const ArchiveProduct = createAction(
  '[Product Archive] Archive Product',
  props<{ productId: number }>()
);
export const ProductArchivedSuccessfully = createAction(
  '[Product Archive] Product Archived Successfully ',
  props<{ productId: number }>()
);
export const ProductArchivingFailed = createAction(
  '[Product Archive] Product Archiving Failed ',
  props<{ error: any }>()
);

export const ChangeProductVisibility = createAction(
  '[Product Visibility] Change Product Visibility',
  props<{ product: ProductModel }>()
);
export const ProductVisibilityChangedSuccessfully = createAction(
  '[Product Visibility] Product Visibility changed Successfully ',
  props<{ product: ProductModel }>()
);
export const ProductVisibilityChangeFailed = createAction(
  '[Product Visibility] Product Visibility change Failed ',
  props<{ error: any }>()
);
