import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ProductModel } from '../../../../core/e-commerce/products/product.model';
import { merge, Observable, of, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
  QueryResultsModel,
} from '../../../../core/_base/crud';
import { ProductActions } from '../../../../core/e-commerce';
import { SelectionModel } from '@angular/cdk/collections';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { ProductResource } from '../../../../core/e-commerce/products/product-resources/product-resource';
import { CategoryModel } from '../../../../core/e-commerce/categories/category.model';
import { selectCategoriesInStore } from '../../../../core/e-commerce/categories/category.selector';
import {
  selectProductsInitWaitingMessage,
  selectProductsInStore,
  selectProductsPageLoading,
} from '../../../../core/e-commerce/products/product.selectors';
import { ProductsService } from '../../../../core/e-commerce/products/products.service';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-archived-product-list',
  templateUrl: './archived-product-list.component.html',
  styleUrls: ['./archived-product-list.component.scss'],
})
export class ArchivedProductListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  dataSource: MatTableDataSource<ProductModel> = new MatTableDataSource([]);
  totalElements = 0;
  displayedColumns = [
    'select',
    'id',
    'code',
    'name',
    'designation',
    'eancode',
    'status',
    'image',
    'actions',
  ];

  loading$: Observable<boolean> = of(false);
  isPreloadTextViewed$: Observable<boolean> = of(true);

  filterCategories = new FormControl(0);

  selection = new SelectionModel<ProductModel>(true, []);

  imageGallery: ProductResource[] = [];
  parentCategories: CategoryModel[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private productService: ProductsService,
    private layoutUtilsService: LayoutUtilsService
  ) {
    this.store
      .pipe(select(selectCategoriesInStore))
      .subscribe((response: QueryResultsModel) => {
        this.parentCategories = response.items.filter((c) => !c.parentId);
      });
  }

  ngOnInit(): void {
    this.loadProductsList();

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadProductsList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }

  loadProductsList(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }

    this.isPreloadTextViewed$ = of(true);

    this.productService
      .getArchivedProducts(queryParams)
      .pipe(
        finalize(() => {
          this.isPreloadTextViewed$ = of(false);
        })
      )
      .subscribe((products) => {
        this.dataSource.data = products.content;
        this.totalElements = products.totalElements;
      });
  }

  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterCategories.value) {
      filter.categories = [];
      filter.categories.push(this.filterCategories.value);
    }

    return filter;
  }

  unarchiveProduct(_item: ProductModel) {
    const _title = 'Désarchiver le produit';
    const _description = 'Voulez-vous vraiment dérachiver ce produit ?';
    const _waitDesciption = 'Désarchivage en cours...';
    const btnTitle = 'désarchiver';

    const dialogRef = this.layoutUtilsService.archiveElement(
      _title,
      _description,
      _waitDesciption,
      btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.productService.unarchiveProduct(_item.id).subscribe(() => {
        this.dataSource.data = this.dataSource.data.filter(
          (p) => p.id != _item.id
        );
        this.layoutUtilsService.showActionNotification(
          'Le produit est désarchivé',
          MessageType.Update
        );
      });
    });
  }

  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'IN_STOCK':
        return 'success';
      case 'OUT_OF_STOCK':
        return 'danger';
      case 'ON_COMMAND':
        return 'metal';
    }
    return '';
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.paginator.pageSize;
    return numSelected === numRows;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }
}
