export class AddressModel {
  id: number;
  city: string;
  country: string;
  streetName: string;
  additionalInfo: string;
  postalCode: string;
  cedex: string;
  supplierId: number;
}
