// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
// RxJS
import { Observable, Subscription } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../core/_base/crud';
// Services and Models
//Actions
import * as UserActions from '../../../../../core/user-management/user.actions';
import { UserModel } from '../../../../../core/_models/user.model';
import {
  selectError,
  selectLastCreatedUserId,
  selectUserById,
  selectUsersActionLoading,
} from '../../../../../core/user-management';

@Component({
  selector: 'kt-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],

})
export class UserEditComponent implements OnInit, OnDestroy {
  // Public properties
  user: UserModel;
  oldUser: UserModel;
  selectedTab = 0;
  loading$: Observable<boolean>;
  userForm: FormGroup;
  hasFormErrors = false;
  // Private properties
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param userFB: FormBuilder
   * @param subheaderService: SubheaderService
   * @param layoutUtilsService: LayoutUtilsService
   * @param store: Store<AppState>
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userFB: FormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private layoutConfigService: LayoutConfigService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.loading$ = this.store.pipe(select(selectUsersActionLoading));

    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      if (id && id > 0) {
        this.store.pipe(select(selectUserById(id))).subscribe((res) => {
          if (res) {
            this.user = Object.assign({}, res);
            this.user.password = undefined;
            this.oldUser = Object.assign({}, this.user);
            this.initUser();
          }
        });
      } else {
        this.user = new UserModel();
        this.oldUser = Object.assign({}, this.user);
        this.initUser();
      }
    });
    this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  /**
   * Init user
   */
  initUser() {
    this.createForm();
    if (!this.user.id) {
      this.subheaderService.setTitle('Create user');
      this.subheaderService.setBreadcrumbs([
        { title: 'User Management', page: `user-management` },
        { title: 'Users', page: `user-management/users` },
        { title: 'Create user', page: `user-management/users/add` },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit user');
    this.subheaderService.setBreadcrumbs([
      { title: 'User Management', page: `user-management` },
      { title: 'Users', page: `user-management/users` },
      {
        title: 'Edit user',
        page: `user-management/users/edit`,
        queryParams: { id: this.user.id },
      },
    ]);
  }

  /**
   * Create form
   */
  createForm() {
    if (this.user.id > 0) {
      this.userForm = this.userFB.group({
        name: [this.user.name, Validators.required],
        lastname: [this.user.lastname, Validators.required],
        mail: [this.user.mail, Validators.email],
        role: [this.user.role, Validators.required],
        password: [
          undefined,
          [
            Validators.pattern(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
            ),
          ],
        ],
        confirmpass: [undefined, [this.matchValues('password')]],
      });
    } else {
      this.userForm = this.userFB.group({
        name: [this.user.name, Validators.required],
        lastname: [this.user.lastname, Validators.required],
        mail: [this.user.mail, Validators.email],
        role: [this.user.role, Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
            ),
          ],
        ],
        confirmpass: ['', [Validators.required, this.matchValues('password')]],
      });
    }
  }

  public matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  /**
   * Redirect to list
   *
   */
  goBackWithId() {
    const url = `/user-management/users`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  /**
   * Refresh user
   *
   * @param isNew: boolean
   * @param id: number
   */
  refreshUser(isNew: boolean = false, id = 0) {
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `/user-management/users/edit/${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  /**
   * Reset
   */
  reset() {
    this.user = Object.assign({}, this.oldUser);
    this.createForm();
    this.hasFormErrors = false;
    this.userForm.markAsPristine();
    this.userForm.markAsUntouched();
    this.userForm.updateValueAndValidity();
  }

  /**
   * Save data
   *
   * @param withBack: boolean
   */
  onSumbit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.userForm.controls;
    /** check form */
    if (this.userForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    const editedUser = this.prepareUser();
    console.log();

    if (editedUser.id > 0) {
      this.updateUser(editedUser, withBack);
      return;
    }

    this.addUser(editedUser, withBack);
  }

  /**
   * Returns prepared data for save
   */
  prepareUser(): UserModel {
    const controls = this.userForm.controls;
    const _user = new UserModel();
    _user.role = controls.role.value;
    _user.id = this.user.id;
    _user.name = controls.name.value;
    _user.mail = controls.mail.value;
    _user.lastname = controls.lastname.value;
    _user.password = controls.password.value;
    return _user;
  }

  /**
   * Add User
   *
   * @param _user: User
   * @param withBack: boolean
   */
  addUser(_user: UserModel, withBack: boolean = false) {
    this.store.dispatch(UserActions.UserOnServerCreated({ user: _user }));
    const addSubscription = this.store
      .pipe(select(selectLastCreatedUserId))
      .subscribe((newId) => {
        console.log('last created user id : ' + newId);
        if (newId) {
          const message = `Un nouvel utilisateur a été créé`;
          this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            true
          );
        }
        if (newId) {
          if (withBack) {
            this.goBackWithId();
          } else {
            this.refreshUser(true, newId);
          }
        }
      });
    const errorSubscription = this.store
      .pipe(select(selectError))
      .subscribe((error) => {
        if (error) this.hasFormErrors = true;
      });
    this.subscriptions.push(errorSubscription);
    this.subscriptions.push(addSubscription);
  }

  /**
   * Update user
   *
   * @param _user: User
   * @param withBack: boolean
   */
  updateUser(_user: UserModel, withBack: boolean = false) {
    // Update User
    // tslint:disable-next-line:prefer-const

    const updatedUser: Update<UserModel> = {
      id: _user.id,
      changes: _user,
    };
    this.store.dispatch(
      UserActions.UserUpdated({ partialUser: updatedUser, user: _user })
    );
    console.log(JSON.stringify(updatedUser));

    const message = `Mise à jour avec succès`;
    this.layoutUtilsService.showActionNotification(
      message,
      MessageType.Update,
      5000,
      true,
      true
    );
    if (withBack) {
      this.goBackWithId();
    } else {
      this.refreshUser(false);
    }
  }

  /**
   * Returns component title
   */
  getComponentTitle() {
    let result = 'Nouvel Utilisateur';
    if (!this.user || !this.user.id) {
      return result;
    }

    result = `Modifier Utilisateur - ${this.user.name}`;
    return result;
  }

  /**
   * Close Alert
   *
   * @param $event: Event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
