import { createAction, props } from '@ngrx/store';
import { SupplierModel } from './supplier.model';
import { QueryParamsModel } from '../../_base/crud';
import { ManufacturerModel } from '../manufacturers/manufacturer.model';
import { Update } from '@ngrx/entity';

export const SuppliersListRequested = createAction(
  '[Suppliers list Page] Suppliers Requested'
);
export const SuppliersListRequestedSuccessfully = createAction(
  '[Suppliers list Page] Suppliers Requested Successfully',
  props<{ suppliers: SupplierModel[] }>()
);
export const SuppliersListRequestFailed = createAction(
  '[Suppliers list Page] Suppliers Request Failed',
  props<{ error: any }>()
);

export const UpdateSupplier = createAction(
  '[Suppliers API] Update Supplier',
  props<{ supplier: SupplierModel }>()
);
export const SupplierUpdatedSuccessfully = createAction(
  '[Suppliers API] Suppliers updated Successfully',
  props<{ supplier: SupplierModel }>()
);
export const SupplierUpdateFailed = createAction(
  '[Suppliers API] Suppliers Update Failed',
  props<{ error: any }>()
);

export const DeleteSupplier = createAction(
  '[Suppliers API] Delete Supplier',
  props<{ supplierId: number }>()
);
export const SupplierDeletedSuccessfully = createAction(
  '[Suppliers API] Suppliers deleted Successfully',
  props<{ supplierId: number }>()
);
export const SupplierDeletionFailed = createAction(
  '[Suppliers API] Suppliers Deletion Failed',
  props<{ error: any }>()
);

export const CreateSupplier = createAction(
  '[Suppliers API] Create Supplier',
  props<{ supplier: SupplierModel }>()
);
export const SupplierCreatedSuccessfully = createAction(
  '[Suppliers API] Suppliers created Successfully',
  props<{ supplier: SupplierModel }>()
);
export const SupplierCreationFailed = createAction(
  '[Suppliers API] Suppliers Creation Failed',
  props<{ error: any }>()
);

export const SuppliersPageRequested = createAction(
  '[Suppliers list Page] Suppliers Requested ',
  props<{ page: QueryParamsModel }>()
);
export const SuppliersPageLoadedSuccessfully = createAction(
  '[Suppliers API] Suppliers Page Loaded Successfully',
  props<{ supplier: SupplierModel[]; totalCount: number; page: number }>()
);
export const SuppliersPageLoadFailed = createAction(
  '[Suppliers API] Suppliers Page Request Failed ',
  props<{ error: any }>()
);
export const ChangeSupplierStatus = createAction(
  '[Supplier Status] Change Supplier Status',
  props<{ supplier: SupplierModel; partialSupplier: Update<SupplierModel> }>()
);
export const SupplierStatusChangedSuccessfully = createAction(
  '[Supplier Status] Supplier Status changed Successfully ',
  props<{ supplier: SupplierModel; partialSupplier: Update<SupplierModel> }>()
);
export const SupplierStatusChangeFailed = createAction(
  '[Supplier Status] Supplier Status change Failed ',
  props<{ error: any }>()
);

