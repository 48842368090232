import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  QuotationItem,
  QuotationModel,
} from '../../../../../../core/e-commerce/quotation/quotation.model';
import { QuotationService } from '../../../../../../core/e-commerce/quotation/quotation.service';
import { finalize } from 'rxjs/operators';
import { KtDialogService } from '../../../../../../core/_base/layout';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { DatePipe } from '@angular/common';
import { ClientService } from '../../../../../../core/e-commerce/clients/client.service';
import { CompaniesService } from '../../../../../../core/e-commerce/companies/companies.service';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import { ClientModel } from '../../../../../../core/e-commerce/clients/client.model';
import { AddressTransPipe } from '../../../../../pipes/address-trans.pipe';
import { AddressEditDialogComponent } from '../../companies/companies-list/_subs/addresses/address-edit/address-edit-dialog.component';
import { MatDialog } from '@angular/material';
import { CountryModel } from '../../../../../../core/_models/country.model';
import { CountryService } from '../../../../../../core/services/country.service';

@Component({
  selector: 'kt-quotation-edit',
  templateUrl: './quotation-edit.component.html',
  styleUrls: ['./quotation-edit.component.scss'],
})
export class QuotationEditComponent implements OnInit {
  title = '';
  selectedTab = 0;
  quotation: QuotationModel;

  quotationForm: FormGroup;
  loading = false;
  hasFormErrors = false;

  customCurrencyMaskConfig = {
    align: 'left',
    prefix: '',
    suffix: ' €',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };
  quotationId: number;
  quotationItems: Partial<QuotationItem>[] = [];
  suggestedClients: ClientModel[];
  status: string = null;

  private countries: CountryModel[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private ktDialogService: KtDialogService,
    private clientService: ClientService,
    private companyService: CompaniesService,
    private quotationService: QuotationService,
    private countryService: CountryService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.quotationId = this.route.snapshot.params.id;
    if (this.quotationId) {
      this.ktDialogService.show();
      this.loading = true;
      this.quotationService
        .getById(this.quotationId)
        .pipe(finalize(() => {
          this.ktDialogService.hide();
          this.loading = false;
        }))
        .subscribe((q) => {
          this.quotation = q;
          this.quotationItems = q.items;
          this.getClientInfo(q.clientId);
          this.updateComponentTitle();
          this.initFormGroup();
        });
    } else {
      this.initFormGroup();
      setTimeout(() => {
        this.updateComponentTitle();
      }, 100);
    }

    this.countryService
      .countries()
      .subscribe((countries) => (this.countries = countries));
  }

  getClientInfo(clientId: number) {
    this.clientService.getById(clientId).subscribe((c) => {
      this.quotation.clientMail = c.mail;
      this.quotation.clientName = c.name + ' ' + c.lastname;
      this.companyService.getById(c.companyId).subscribe(
        (company) => {
          this.quotation.companyName = company.name;
          this.initFormGroup();
        },
        () => {
          this.initFormGroup();
          this.quotationForm.get('companyName').markAsTouched();
        }
      );
    });
  }

  initFormGroup() {
    this.quotationForm = this.fb.group({
      id: [this.quotation ? this.quotation.id : null],
      number: [this.quotation ? this.quotation.number : null],
      createdAt: [
        this.quotation
          ? new DatePipe('fr-FR').transform(
              this.quotation.createdAt,
              'dd/MM/YYYY'
            )
          : null,
      ],
      message: [this.quotation ? this.quotation.message : null],
      address: [this.quotation ? this.quotation.address : null],
      clientName: [this.quotation ? this.quotation.clientName : null],
      companyName: [
        this.quotation ? this.quotation.companyName : null,
        Validators.required,
      ],
      expiredAt: [this.quotation ? new Date(this.quotation.expiredAt) : null],
      clientId: [
        this.quotation ? this.quotation.id : null,
        Validators.required,
      ],
      totalPrice: [this.quotation ? this.quotation.totalPrice : 0],
      shippingCost: [
        this.quotation ? this.quotation.shippingCost : 0,
        Validators.required,
      ],
      status: [
        this.quotation ? this.quotation.status : null,
        Validators.required,
      ],
    });
    if (!this.quotationId) {
      this.quotationForm.get('clientName').valueChanges.subscribe((v) => {
        this.clientService
          .searchClient(v)
          .subscribe((clients) => (this.suggestedClients = clients));
      });
    }
    this.quotationForm.get('status').valueChanges.subscribe((selectedValue) => {
      this.status = selectedValue;
    });
    this.quotationForm
      .get('shippingCost')
      .valueChanges.subscribe((_) => this.calculateTotalPrice());
  }

  goBack() {
    this.router.navigate(['ecommerce', 'quotations']);
  }

  onSumbit() {
    if (this.quotationId) {
      const payload = {
        id: this.quotationId,
        status: this.status,
        address: this.quotationForm.get('address').value,
        expiredAt: this.quotationForm.get('expiredAt').value,
        shippingCost: this.quotationForm.get('shippingCost').value,
      };
      this.quotationService.updateQuotation(payload).subscribe(
        () => {
          this.layoutUtilsService.showActionNotification(
            'Mise à jour avec succès'
          );
        },
        () => {
          this.layoutUtilsService.showActionNotification(
            'Une erreur est survenue !'
          );
        }
      );
    } else {
      this.createNewQuotation();
    }
  }

  reset() {}

  updateComponentTitle() {
    if (!this.quotation) {
      this.title = 'Créer un nouveau devis';
    } else {
      this.title = 'Devis N° ' + this.quotation.number;
    }
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  public sendViaEmail(quotation: QuotationModel) {
    const _title = 'Devis  ' + quotation.number;
    const _description = 'Envoyer une copie de devis en piece jointe';
    const _waitDesciption = 'Envoie en cours...';
    const _defaultMail = quotation.clientMail;
    const dialogRef = this.layoutUtilsService.sendMail(
      _title,
      _description,
      _waitDesciption,
      _defaultMail
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.quotationService.sendQuotationViaEmail(quotation.id, res).subscribe(
        () =>
          this.layoutUtilsService.showActionNotification(
            'Un mail a été envoyé à ' + res,
            MessageType.Create
          ),
        () =>
          this.layoutUtilsService.showActionNotification(
            'Oops! l\'e-mail n\'a pas été envoyé',
            MessageType.Delete
          )
      );
    });
  }

  updateQuotationItems(items) {
    this.quotationItems = items;
    this.calculateTotalPrice();
  }

  private createNewQuotation() {
    if (this.quotationForm.invalid) {
      this.quotationForm.markAllAsTouched();
      return;
    }
    if (!this.quotationItems.length) {
      this.selectedTab = 1;
      this.layoutUtilsService.showActionNotification(
        'Veuillez rajouter des produits'
      );
      return;
    }
    const paylod = {
      clientId: this.quotationForm.value.clientId,
      status: this.quotationForm.value.status,
      address: this.quotationForm.value.address,
      message: this.quotationForm.value.message,
      items: this.quotationItems,
      shippingCost: this.quotationForm.value.shippingCost,
    };
    this.quotationService.addNewQuotation(paylod).subscribe(
      (e) => {
        this.layoutUtilsService.showActionNotification(
          'Devis créée avec succès'
        );
        setTimeout(() => this.goBack(), 1000);
      },
      () => {
        this.layoutUtilsService.showActionNotification(
          'Une erreur est survenue !'
        );
      }
    );
  }
  selectClient(event: any) {
    const client = this.suggestedClients.find((c) => (c.id = event));
    this.quotationForm
      .get('clientName')
      .setValue(client.name + ' ' + client.lastname);
    this.quotationForm.get('clientId').setValue(client.id);
    this.companyService.getById(client.companyId).subscribe((company) => {
      this.quotationForm.get('companyName').setValue(company.name);
    });
    this.clientService.getAddressBook(client.id).subscribe((value) => {
      if (value.totalElements === 1) {
        this.quotationForm
          .get('address')
          .setValue(new AddressTransPipe().transform(value.content[0]));
      }
    });
  }
  newAddress() {
    const dialogRef = this.dialog.open(AddressEditDialogComponent, {
      data: {
        bill: true,
        address: this.quotationForm.value.address
          ? new AddressTransPipe().inverse(
              this.quotationForm.value.address,
              this.countries
            )
          : '',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.quotationForm
          .get('address')
          .setValue(new AddressTransPipe().transform(res));
      }
    });
  }

  calculateTotalPrice() {
    this.quotationForm
      .get('totalPrice')
      .setValue(
        this.quotationItems
          .map((a) => a.itemTotalPrice)
          .reduce((a, b) => a + b, 0) +
          this.quotationForm.get('shippingCost').value
      );
  }
  goToOdoo() {
    window.open(`https://imtech.dev.odoo.wissal-group.com/showquotation?name=${this.quotation.number}`, '_blank');
  }
}
