import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { ClientfunctionModel } from './clientfunction.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';

@Injectable()
export class ClientfunctionsService extends AbstractService<ClientfunctionModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}client/function`;
  }

  public getAllPaged2(
    page: number = 0,
    size: number = 10
  ): Observable<Page<ClientfunctionModel>> {
    const options = {
      params: new HttpParams()
        .append('page', page.toString())
        .append('size', size.toString()),
    };
    const url = `${this.url}`;
    return this.http.get<Page<ClientfunctionModel>>(url, options);
  }
}
