import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ProductsDataSource } from '../../../../../../core/e-commerce/products/products.datasource';
import { ProductModel } from '../../../../../../core/e-commerce/products/product.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import {fromEvent, merge, Subscription} from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { QueryParamsModel } from '../../../../../../core/_base/crud';
import { ProductActions } from '../../../../../../core/e-commerce';
import {ProductModelOpt} from '../../../../../../core/e-commerce/products/productOptm.model';


@Component({
  selector: 'kt-promo-products-list',
  templateUrl: './promo-products-list.component.html',
  styleUrls: ['./promo-products-list.component.scss']
})
export class PromoProductsListComponent implements OnInit {
  viewLoading$ = null;
  promoTitle: string;

  // Datasource
  dataSource: ProductsDataSource;

  // Table columns
  displayedColumns = ['action', 'id', 'name', 'price', 'status', 'image'];
  productsResult: ProductModelOpt[] = [];

  // selectedProduct from table
  selectedProducts: ProductModelOpt[] = [];

  // Selection
  selection = new SelectionModel<ProductModelOpt>(true, []);
  subscriptions: Subscription[] = [];

  // paginator
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  // sorting
  @ViewChild('sort', {static: true}) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  constructor(public dialogRef: MatDialogRef<PromoProductsListComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private activatedRoute: ActivatedRoute,
              private store: Store<AppState>) { }

  ngOnInit(): void {
    const sortSubscription = this.sort.sortChange.subscribe(() => {
      (this.paginator.pageIndex = 0);
    });
    this.subscriptions.push(sortSubscription);

    this.paginator._changePageSize(100);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => {
        this.loadProductsList(); })
    ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap((v) => {
        this.paginator.pageIndex = 0;
        this.loadProductsList();
      })
    ).subscribe();
    this.subscriptions.push(searchSubscription);
    this.dataSource = new ProductsDataSource(this.store);
    console.log(this.dataSource);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.productsResult = res;
    });
    this.subscriptions.push(entitiesSubscription);

    this.loadProductsList();
  }
  loadProductsList(filter?: QueryParamsModel) {
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }

    this.store.dispatch(
      ProductActions.ProductsPageRequestedOpt({ queryParams })
    );
  }
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
    filter.query = searchText.trim();
    return filter;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.productsResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selectedProducts = this.selection.selected;
    } else {
      this.productsResult.forEach(row => this.selection.select(row));
      this.selectedProducts = this.selection.selected;
    }
  }
  onProductToggled(product: ProductModelOpt ) {
    this.selection.toggle(product);
    this.selectedProducts = this.selection.selected;
  }
  getItemCssClassByStatus(status): string {
    switch (status) {
      case 'OUT_OF_STOCK':
        return 'danger';

      case 'IN_STOCK':
        return 'success';

      case 'ON_COMMAND':
        return 'sun';

      case 'OBSOLETE':
        return 'metal';
    }
    return '';
  }
  onSubmit() {
    this.dialogRef.close(this.selectedProducts);
  }
  onClose() {
    this.dialogRef.close();
  }
}
