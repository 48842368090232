import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { Response } from '../../_base/crud/models/response';
import { map } from 'rxjs/operators';
import { AbstractService } from '../../services/abstract-service.service';
import { ClientQuestionModel } from './client-question.model';
import { Page } from '../../_base/crud/models/page';
import { QuestionAnswerModel } from '../questionanswer/questionanswer.model';
import { QueryParamsModel } from '../../_base/crud';
import { CategoryCharacteristicModel } from '../../e-commerce/category-characteristic/category-characteristic.model';

@Injectable()
export class ClientQuestionService extends AbstractService<ClientQuestionModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}user_question`;
    /*this.url = `http://localhost:8080/api/v1/user_question`;*/
  }

  /*findAll(): Observable<Page<ClientQuestionModel[]>> {
        const url = `${this.url}`;
        return this.http.get<Page<ClientQuestionModel[]>>(url);
    }*/
  getAllByFilter(
    filter: QueryParamsModel
  ): Observable<Page<ClientQuestionModel>> {
    const url = `${this.url}`;
    const options = {
      params: new HttpParams()
        .append('page', filter.pageNumber.toString())
        .append('size', filter.pageSize ? filter.pageSize.toString() : '25')
        .append('search', filter.filter.query)
        .append('status', filter.filter.status),
    };
    // console.log(options);
    return this.http.get<Page<ClientQuestionModel>>(url, options);
  }
  /*public updateQuestion(id : number , _question : ClientQuestionModel) {
        const url = `${this.url}/`+ id;
        const res = this.http.put(url , _question );
        return res;
    }*/
  public update(question: ClientQuestionModel): Observable<any> {
    return this.http.put<ClientQuestionModel>(
      `${this.url}/${question.id}`,
      question
    );
  }
}
