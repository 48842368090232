import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Store } from '@ngrx/store';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../_base/crud';
import { AboutModel } from './about.model';
import { AboutState } from './about.reducers';

export const getAboutState = createFeatureSelector<AboutState>('about');

export const selectAboutById = (aboutId: number) =>
  createSelector(getAboutState, (aboutState) => aboutState.entities[aboutId]);

export const selectAboutStore = createSelector(
  getAboutState,
  (aboutState: AboutState) => {
    const abouts: AboutModel[] = [];
    Object.entries(aboutState.entities).forEach(([k, v]) => abouts.push(v));
    return abouts;
  }
);

export const selectAboutsInStore = createSelector(
  getAboutState,
  (aboutState) => {
    const items: AboutModel[] = [];
    each(aboutState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: AboutModel[] = httpExtension.sortArray(
      items,
      aboutState.lastQuery.sortField,
      aboutState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, aboutState.totalCount, '');
  }
);

export const selectAboutsPageLoading = createSelector(
  getAboutState,
  (aboutsState) => aboutsState.listLoading
);

export const selectAboutsActionLoading = createSelector(
  getAboutState,
  (aboutsState) => aboutsState.actionsLoading
);

export const selectAboutsPageLastQuery = createSelector(
  getAboutState,
  (aboutState) => aboutState.lastQuery
);

export const selectLastCreatedAboutId = createSelector(
  getAboutState,
  (aboutState) => aboutState.lastCreatedAboutId
);

export const selectAboutsInitWaitingMessage = createSelector(
  getAboutState,
  (aboutState) => aboutState.showInitWaitingMessage
);

export const selectHasAboutsInStore = createSelector(
  selectAboutsInStore,
  (queryResult) => {
    return queryResult.totalCount;
  }
);
