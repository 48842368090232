import { createAction, props } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { OrderItemModel } from './OrderItem.model';
import { Update } from '@ngrx/entity';

export const OrderItemsPageRequested = createAction(
  '[OrderItem List Page] OrderItems Page Requested ',
  props<{ page: QueryParamsModel }>()
);
export const OrderItemsPageLoadedSuccessfully = createAction(
  '[OrderItem API] OrderItems Page Loaded Successfully ',
  props<{ orderItems: OrderItemModel[]; totalCount: number; page: number }>()
);
export const OrderItemsPageLoadFailed = createAction(
  '[OrderItem API] OrderItems Page Request Failed ',
  props<{ error: any }>()
);

export const OrderItemCreated = createAction(
  '[OrderItem List Page] OrderItem Created ',
  props<{ orderItem: OrderItemModel }>()
);
export const OrderItemCreatedSuccessfully = createAction(
  '[OrderItem List Page] OrderItem Created Successfully ',
  props<{ orderItem: OrderItemModel }>()
);
export const OrderItemCreationFailed = createAction(
  '[OrderItem List Page] OrderItem Creation Failed ',
  props<{ error: any }>()
);

export const OrderItemUpdated = createAction(
  '[OrderItem List Page] OrderItem Updated ',
  props<{
    orderItem: OrderItemModel;
    partialOrderItem: Update<OrderItemModel>;
  }>()
);
export const OrderItemUpdatedSuccessfully = createAction(
  '[OrderItem List Page] OrderItem Updated Successfully ',
  props<{
    orderItem: OrderItemModel;
    partialOrderItem: Update<OrderItemModel>;
  }>()
);
export const OrderItemUpdateFailed = createAction(
  '[OrderItem List Page] OrderItem Update Failed ',
  props<{ error: any }>()
);

export const OrderItemDeleted = createAction(
  '[OrderItem List Page] OrderItem Deleted ',
  props<{ orderItemId: number }>()
);
export const OrderItemDeletedSuccessfully = createAction(
  '[OrderItem List Page] OrderItem deleted Successfully ',
  props<{ orderItemId: number }>()
);
export const OrderItemDeleteFailed = createAction(
  '[OrderItem List Page] OrderItem delete Failed ',
  props<{ error: any }>()
);
