import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged, tap, delay } from 'rxjs/operators';
import {
  fromEvent,
  merge,
  BehaviorSubject,
  Subscription,
  Observable,
  of,
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ClientfunctionsDatasource } from '../../../../../../core/e-commerce/clientfunctions/clientfunctions.datasource';
import { ClientfunctionModel } from '../../../../../../core/e-commerce/clientfunctions/clientfunction.model';
import { AppState } from '../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
  TypesUtilsService,
} from '../../../../../../core/_base/crud';

import * as ClientFunctionActions from '../../../../../../core/e-commerce/clientfunctions/clientfunction.actions';
import { selectLastCreatedClientFunctionId } from '../../../../../../core/e-commerce/clientfunctions/clientfunction.selectors';

@Component({
  selector: 'kt-functions-list',
  templateUrl: './clientfunction-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientfunctionListComponent implements OnInit, OnDestroy {
  dataSource: ClientfunctionsDatasource;
  displayedColumns = ['id', 'function', 'actions'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<ClientfunctionModel>(true, []);
  clientfunctionsResult: ClientfunctionModel[] = [];

  isSwitchedToEditMode = false;
  loadingAfterSubmit = false;
  formGroup: FormGroup;
  functionForEdit: ClientfunctionModel;
  functionForAdd: ClientfunctionModel;
  // Private properties
  private componentSubscriptions: Subscription;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public typesUtilsService: TypesUtilsService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit() {
    this.loadFunctionsList();

    // Init DataSource
    this.dataSource = new ClientfunctionsDatasource(this.store);
    this.dataSource.entitySubject.subscribe(
      (res) => (this.clientfunctionsResult = res)
    );
    this.createFormGroup();
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
  }

  loadFunctionsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      {},
      this.sort.direction,
      this.sort.active
    );
    // Call request from server
    this.store.dispatch(
      ClientFunctionActions.ClientFunctionsPageRequested({
        page: queryParams,
      })
    );
  }

  createFormGroup(_item = null) {
    this.formGroup = this.fb.group({
      editFunction: ['', Validators.compose([Validators.required])],
      newFunction: ['', Validators.compose([Validators.required])],
    });
    this.clearAddForm();
    this.clearEditForm();
  }

  // ADD Client FUNCTIONS: clearAddForm | checkAddForm | addFunctionButtonOnClick | cancelAddButtonOnClick | saveNewFunction
  clearAddForm() {
    const controls = this.formGroup.controls;
    controls.newFunction.setValue('');
    controls.newFunction.markAsPristine();
    controls.newFunction.markAsUntouched();

    this.functionForAdd = new ClientfunctionModel();
    this.functionForAdd._isEditMode = false;
  }

  /**
   * Check if Add Form is Valid
   */
  checkAddForm() {
    const controls = this.formGroup.controls;
    if (controls.newFunction.invalid) {
      controls.newFunction.markAsTouched();
      return false;
    }

    return true;
  }

  /**
   * Open Function Add Form
   */
  addFunctionButtonOnClick() {
    this.clearAddForm();
    this.functionForAdd._isEditMode = true;
    this.isSwitchedToEditMode = true;
  }

  /**
   * Close Function Add Form
   */
  cancelAddButtonOnClick() {
    this.functionForAdd._isEditMode = false;
    this.isSwitchedToEditMode = false;
  }

  /**
   *  Create new function
   */
  saveNewFunction() {
    if (!this.checkAddForm()) {
      return;
    }

    const controls = this.formGroup.controls;
    this.loadingAfterSubmit = false;
    this.functionForAdd._isEditMode = false;
    this.functionForAdd.function = controls.newFunction.value;

    this.store.dispatch(
      ClientFunctionActions.ClientFunctionCreated({
        clientfunction: this.functionForAdd,
      })
    );
    this.componentSubscriptions = this.store
      .pipe(select(selectLastCreatedClientFunctionId))
      .subscribe((res) => {
        if (!res) {
          return;
        }

        const _saveMessage = `Function has been created`;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification(
          _saveMessage,
          MessageType.Create,
          10000,
          true,
          true
        );
        this.clearAddForm();
      });
  }

  // EDIT REMARK FUNCTIONS: clearEditForm | checkEditForm | editFunctionButtonOnClick | cancelEditButtonOnClick |
  clearEditForm() {
    const controls = this.formGroup.controls;
    controls.editFunction.setValue('');
    this.functionForEdit = new ClientfunctionModel();
    this.functionForEdit._isEditMode = false;
  }

  /**
   * Check is Edit Form valid
   */
  checkEditForm() {
    const controls = this.formGroup.controls;
    if (controls.editFunction.invalid) {
      return false;
    }

    return true;
  }

  /**
   * Update function
   *
   * @param _item: ClientFunctionModel
   */
  editFunctionButtonOnClick(_item: ClientfunctionModel) {
    const controls = this.formGroup.controls;
    controls.editFunction.setValue(_item.function);
    const updateClientFunction: Update<ClientfunctionModel> = {
      id: _item.id,
      changes: {
        _isEditMode: true,
      },
    };
    this.store.dispatch(
      ClientFunctionActions.ClientFunctionUpdated({
        clientfunction: _item,
        partialClientFunction: updateClientFunction,
      })
    );
    this.isSwitchedToEditMode = true;
  }

  /**
   * Cancel function
   *
   * @param _item: ClientFunctionModel
   */
  cancelEditButtonOnClick(_item: ClientfunctionModel) {
    const updateClientFunction: Update<ClientfunctionModel> = {
      id: _item.id,
      changes: {
        _isEditMode: false,
      },
    };
    this.store.dispatch(
      ClientFunctionActions.ClientFunctionUpdated({
        clientfunction: _item,
        partialClientFunction: updateClientFunction,
      })
    );
    this.isSwitchedToEditMode = false;
  }

  /**
   * Save function
   *
   * @param _item: ClientFunctionModel
   */
  saveUpdatedFunction(_item: ClientfunctionModel) {
    if (!this.checkEditForm()) {
      return;
    }

    this.loadingAfterSubmit = true;
    const controls = this.formGroup.controls;
    this.loadingAfterSubmit = false;
    const objectForUpdate = new ClientfunctionModel();
    objectForUpdate.id = _item.id;
    objectForUpdate._isEditMode = _item._isEditMode;
    objectForUpdate.function = controls.editFunction.value;
    objectForUpdate._isEditMode = false;
    const updateClientFunction: Update<ClientfunctionModel> = {
      id: _item.id,
      changes: objectForUpdate,
    };

    this.store.dispatch(
      ClientFunctionActions.ClientFunctionUpdated({
        partialClientFunction: updateClientFunction,
        clientfunction: objectForUpdate,
      })
    );
    const saveMessage = `Function has been updated`;
    this.isSwitchedToEditMode = false;
    this.layoutUtilsService.showActionNotification(
      saveMessage,
      MessageType.Update,
      10000,
      true,
      true
    );
  }

  /**
   * Check all rows are selected
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.clientfunctionsResult.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection
   */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.clientfunctionsResult.forEach((row) => this.selection.select(row));
    }
  }

  /** ACTIONS */
  /**
   * Delete function
   *
   * @param _item: ClientFunctionModel
   */
  deleteFunction(_item: ClientfunctionModel) {
    const _title = 'Function Delete';
    const _description = 'Are you sure to permanently delete this function?';
    const _waitDesciption = 'Function is deleting...';
    const _deleteMessage = `Function has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(
        ClientFunctionActions.ClientFunctionDeleted({
          clientfunctionId: _item.id,
        })
      );
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }
}
