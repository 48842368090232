import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CategoryModel } from './../category.model';
import { QueryParamsModel } from '../../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
// actions
import * as AccessoriesActions from './../accessories/accesories.actions'


export interface AccessorieState extends EntityState<CategoryModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedCategoryId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<CategoryModel> =
  createEntityAdapter<CategoryModel>();

export const initialAccessorieState: AccessorieState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedCategoryId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const accessorieReducer = createReducer(
  initialAccessorieState,

    // get list accessories
on(AccessoriesActions.accessoriesListPageRequested, (state, action) => ({
  ...state,
  actionsLoading: true,
  listLoading: true,
  showInitWaitingMessage: true,
  error: null,
  lastAction: '',
  progress: 0,
})),
on(AccessoriesActions.accessoriesListPageRequestedSuccessfully, (state, action) =>
  adapter.addAll(action.categories, {
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    lastAction: 'EDIT.ADD_MESSAGE',
    progress: 0,
  })
),
on(AccessoriesActions.accessoriesListPageRequestedFailed, (state: AccessorieState, action) => {
  let errorMessage: string;
  switch (action.error.status) {
    case 500:
      errorMessage = 'EDIT.ERROR.SERVER';
      break;
    default:
      errorMessage = 'EDIT.ERROR.UNKNOWN';
      break;
  }
  return {
    ...state,
    listLoading: false,
    actionsLoading: false,
    errorMessage,
    error: action.error,
  };
}),

// delete accessories from category
on(AccessoriesActions.AccessorieDeleted, (state, action) => ({
  ...state,
  actionsLoading: true,
  lastAction: '',
})),
on(AccessoriesActions.AccessorieDeletedSuccessfully, (state, action) =>
  adapter.removeOne(action.categoryId, state)
),
on(AccessoriesActions.AccessorieDeleteFailed, (state: AccessorieState, action) => {
  let errorMessage: string;
  switch (action.error.status) {
    case 500:
      errorMessage = 'DELETE_CATEGORY_SIMPLE.FOREIGN_KEY_ERROR';
      break;
    case 400:
      errorMessage = 'Action refusé ! Cette catégorie contient une ou plusieurs sous catégories';
      break;
    default:
      errorMessage = 'AUTH.VALIDATION.NO_CONNECTION';
      break;
  }
  return {
    ...state,
    listLoading: false,
    actionsLoading: false,
    errorMessage,
    error: action.error,
  };
}),


// save accessoir
on(AccessoriesActions.Categories3LevelSave, (state, action) => ({
  ...state,
  actionsLoading: true,
  listLoading: true,
  showInitWaitingMessage: true,
  error: null,
  lastAction: '',
  progress: 0,
})),
on(AccessoriesActions.Categories3LevelSavedSuccessfully, (state, action) =>
  adapter.addOne(action.category, {
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    lastAction: 'EDIT.ADD_MESSAGE',
    progress: 0,
  })
),
on(AccessoriesActions.Categories3LevelSaveFailed, (state: AccessorieState, action) => {
  let errorMessage: string;
  switch (action.error.status) {
    case 500:
      errorMessage = 'EDIT.ERROR.SERVER';
      break;
    default:
      errorMessage = 'EDIT.ERROR.UNKNOWN';
      break;
  }
  return {
    ...state,
    listLoading: false,
    actionsLoading: false,
    errorMessage,
    error: action.error,
  };
}),
);
export function accessoriesReducer(
  state: AccessorieState | undefined,
  action: Action
) {
  return accessorieReducer(state, action);
}
