import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  FAQCreated,
  FAQUpdated,
} from '../../../../../core/service-client/questionanswer/faq.actions';
// NGRX

import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../core/_base/crud';
import { skip } from 'rxjs/operators';
import { QuestionAnswerModel } from '../../../../../core/service-client/questionanswer/questionanswer.model';
import { QuestionAnswerDatasource } from '../../../../../core/service-client/questionanswer/questionAnswerDatasource';
import { QuestionAnswerService } from '../../../../../core/service-client/questionanswer/question-answer.service';
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
import {
  selectFAQById,
  selectFAQsActionLoading,
} from '../../../../../core/service-client/questionanswer/faq.selectors';
import { ProductModel } from '../../../../../core/e-commerce/products/product.model';

@Component({
  selector: 'kt-question-answer-edit',
  templateUrl: './question-answer-edit.component.html',
  styleUrls: ['./question-answer-edit.component.scss'],
})
export class QuestionAnswerEditComponent implements OnInit {
  faq: QuestionAnswerModel;
  faq$: Observable<QuestionAnswerModel>;
  oldFAQ: QuestionAnswerModel;
  selectedTab = 0;
  loading$: Observable<boolean>;
  faqForm: FormGroup;
  hasFormErrors = false;
  progress = new BehaviorSubject<number>(0);
  loadingSubject = new BehaviorSubject<boolean>(true);

  // Private properties
  private subscriptions: Subscription[] = [];
  private faqId: number;
  dataSource: QuestionAnswerDatasource;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private faqFB: FormBuilder,
    private store: Store<AppState>,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private questionAnswerService: QuestionAnswerService
  ) {}

  ngOnInit() {
    this.loading$ = this.store.pipe(select(selectFAQsActionLoading));

    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      if (id && id > 0) {
        this.faq$ = this.store.select(selectFAQById(id));
        this.store.pipe(select(selectFAQById(id))).subscribe((res) => {
          if (res) {
            this.faq = res;
            this.oldFAQ = Object.assign({}, this.faq);
            this.initFAQ();
          }
        });
      } else {
        this.faq = new QuestionAnswerModel();
        this.oldFAQ = Object.assign({}, this.faq);
        this.initFAQ();
      }
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  initFAQ() {
    this.createForm();
    if (!this.faq.id) {
      this.subheaderService.setBreadcrumbs([
        { title: 'Service Client', page: `/service-client` },
        { title: 'FAQ', page: `/service-client/question-answer` },
        { title: 'Create FAQ', page: `/service-client/question-answer/add` },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit FAQ');
    this.subheaderService.setBreadcrumbs([
      { title: 'Service Client', page: `/service-client` },
      { title: 'FAQ', page: `/service-client/question-answer` },
      {
        title: 'Edit FAQ',
        page: `/service-client/question-answer/edit`,
        queryParams: { id: this.faq.id },
      },
    ]);
  }
  createForm() {
    this.faqForm = this.faqFB.group({
      title: [this.faq.title, Validators.required],
      body: [this.faq.body, Validators.required],
      type: [this.faq.type, Validators.required],
    });
  }

  goBackWithoutId() {
    this.router.navigateByUrl(
      '/service-client/question-answer',
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  refreshFAQ(isNew: boolean = false, id = 0) {
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `service-client/question-answer/edit/${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  reset() {
    this.faq = Object.assign({}, this.oldFAQ);
    this.createForm();
    this.hasFormErrors = false;
    this.faqForm.markAsPristine();
    this.faqForm.markAsUntouched();
    this.faqForm.updateValueAndValidity();
  }
  onSubmit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.faqForm.controls;
    /** check form */
    if (this.faqForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    let editedFAQ = this.prepareFAQ();

    if (editedFAQ.id > 0) {
      this.updateFAQ(editedFAQ, withBack);
      return;
    }

    this.addFAQ(editedFAQ, withBack);
  }

  prepareFAQ(): QuestionAnswerModel {
    const controls = this.faqForm.controls;
    const _faq = new QuestionAnswerModel();
    _faq.id = this.faq.id;
    _faq.title = controls.title.value;
    _faq.body = controls.body.value;
    _faq.type = controls.type.value;
    return _faq;
  }
  addFAQ(_faq: QuestionAnswerModel, withBack: boolean = false) {
    _faq.id = undefined;

    this.store.dispatch(FAQCreated({ faq: _faq }));

    console.log('Entity Created ');
    const message = `Contenu ajouté avec succès.`;
    this.layoutUtilsService.showActionNotification(
      message,
      MessageType.Create,
      5000,
      true,
      true
    );
  }

  updateFAQ(_faq: QuestionAnswerModel, withBack: boolean = false) {
    const updateFAQ: Update<QuestionAnswerModel> = {
      id: _faq.id,
      changes: _faq,
    };
    this.store.dispatch(FAQUpdated({ partialFAQ: updateFAQ, faq: _faq }));
    // this.questionAnswerService.updateFAQ(_faq , id)
    const message = `Contenu modifié avec succès.`;
    this.layoutUtilsService.showActionNotification(
      message,
      MessageType.Create,
      5000,
      true,
      true
    );
  }
  getComponentTitle() {
    let result = 'Create';
    if (!this.faq || !this.faq.id) {
      return result;
    }

    result = `Edit contenu - ${this.faq.title}`;
    return result;
  }

  /**
   * Close Alert
   *
   * @param $event: Event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
