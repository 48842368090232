import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ADMIN_CONFIG } from '../../../environments/environment';
import {map} from "rxjs/operators";

@Injectable()
export class ResourcesService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}storage`;
  }

  pushFileToStorage(resource: File): Observable<any> {
    const data: FormData = new FormData();
    data.append('file', resource);
    const newRequest = new HttpRequest('POST', this.url, data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.http.request(newRequest);
  }

  addImage(resource: File, productId: number): Observable<any> {
    const data: FormData = new FormData();
    const url = `${ADMIN_CONFIG.apiEndpoint}product/${productId}/image`;
    data.append('file', resource);
    const newRequest = new HttpRequest('POST', url, data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.http.request(newRequest);
  }

  getResources(productId: number): Observable<any> {
    const url = `${ADMIN_CONFIG.apiEndpoint}product/${productId}/resource`;
    return this.http.get(url);
  }

  delete(url: string): Observable<any> {
    const options = { params: new HttpParams().set('url', url) };
    const res = this.http.delete(this.url, options);
    res.subscribe((r) => {
      console.log(JSON.stringify(r));
    });
    return res;
  }

  deleteResource(resourceId: number): Observable<any> {
    const url = `${ADMIN_CONFIG.apiEndpoint}product/resource/${resourceId}`;
    return this.http.delete(url);
  }

  addImageFromWikkeys(path: string, productId: number): Observable<any> {
    const data: FormData = new FormData();
    const url = `${ADMIN_CONFIG.apiEndpoint}product/${productId}/wikkeys/image`;
    data.append('pathUrl', path);
    const newRequest = new HttpRequest('POST', url, data, {
      reportProgress: true,
      responseType: 'text',
    });
    return this.http.request(newRequest);
  }
}
