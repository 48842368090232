// CRUD
import { select, Store } from '@ngrx/store';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import { AppState } from '../../reducers';
import {
  selectQuestionsInStore,
  selectQuestionsPageLoading,
  selectQuestionsInitWaitingMessage,
} from './client-question.selectors';

export class ClientQuestionDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectQuestionsPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectQuestionsInitWaitingMessage)
    );

    this.store
      .pipe(select(selectQuestionsInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
