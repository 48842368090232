// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, switchMap, catchError } from 'rxjs/operators';
import { Observable, defer, of, forkJoin, pipe, EMPTY } from 'rxjs';
// NGRX
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
import { AppState } from '../reducers';
import { UserService } from './user.service';
import * as UserActions from './user.actions';
// CRUD
// State

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userService: UserService
  ) {}

  allUsersRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.AllUsersRequested),
      switchMap(() =>
        this.userService.findAll().pipe(
          map((users) => UserActions.AllUsersLoaded({ users })),
          catchError((error) =>
            of(UserActions.AllUsersRequestedFailed({ error }))
          )
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UserDeleted),
      switchMap((action) =>
        this.userService.deleteUserById(action.id).pipe(
          map(UserActions.UserDeletedSuccessfully),
          catchError((error) => of(UserActions.UserDeletingFailed({ error })))
        )
      )
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UserOnServerCreated),
      switchMap((action) =>
        this.userService.addUser(action.user).pipe(
          map((user) => UserActions.UserAddedSuccessfully({ user })),
          catchError((error) => of(UserActions.AddUserFailed({ error })))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UserUpdated),
      switchMap((action) =>
        this.userService.updateUser(action.user).pipe(
          map((user) =>
            UserActions.UserUpdatedSuccessfully({
              user,
              partialUser: action.partialUser,
            })
          ),
          catchError((error) => of(UserActions.AddUserFailed({ error })))
        )
      )
    )
  );

  // @Effect()
  // loadUsersPage$ = this.actions$
  //     .pipe(
  //         ofType<UsersPageRequested>(UserActionTypes.UsersPageRequested),
  //         mergeMap(( { payload } ) => {
  //             this.store.dispatch(this.showPageLoadingDistpatcher);
  //             const requestToServer = this.auth.findUsers(payload.page);
  //             const lastQuery = of(payload.page);
  //             return forkJoin(requestToServer, lastQuery);
  //         }),
  //         map(response => {
  //             const result: QueryResultsModel = response[0];
  //             const lastQuery: QueryParamsModel = response[1];
  //             return new UsersPageLoaded({
  //                 users: result.items,
  //                 totalCount: result.totalCount,
  //                 page: lastQuery
  //             });
  //         }),
  //     );

  // @Effect()
  // deleteUser$ = this.actions$
  //     .pipe(
  //         ofType<UserDeleted>(UserActionTypes.UserDeleted),
  //         mergeMap(( { payload } ) => {
  //                 this.store.dispatch(this.showActionLoadingDistpatcher);
  //                 return this.userService.deleteUserById(payload.id);
  //             }
  //         ),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         }),
  //     );

  // @Effect()
  // updateUser$ = this.actions$
  //     .pipe(
  //         ofType<UserUpdated>(UserActionTypes.UserUpdated),
  //         mergeMap(( { payload } ) => {
  //             this.store.dispatch(this.showActionLoadingDistpatcher);
  //             return this.auth.updateUser(payload.user);
  //         }),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         }),
  //     );

  // @Effect()
  // createUser$ = this.actions$
  //     .pipe(
  //         ofType<UserOnServerCreated>(UserActionTypes.UserOnServerCreated),
  //         mergeMap(( { payload } ) => {
  //             this.store.dispatch(this.showActionLoadingDistpatcher);
  //             return this.auth.createUser(payload.user).pipe(
  //                 tap(res => {
  //                     this.store.dispatch(new UserCreated({ user: res }));
  //                 })
  //             );
  //         }),
  //         map(() => {
  //             return this.hideActionLoadingDistpatcher;
  //         }),
  //     );
}
