import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { ClaimReasonListComponent } from '../claim-reason/claim-reason-list/claim-reason-list.component';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import _ from 'lodash';
import {
  selectUsersInStore,
  UserActions,
} from '../../../../../core/user-management';
import { UserModel } from '../../../../../core/_models/user.model';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as ClaimActions from '../../../../../core/service-client/claim/claim.actions';
import { ClaimModel } from '../../../../../core/service-client/claim/claim.model';

@Component({
  selector: 'kt-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss'],
})
export class AdminListComponent implements OnInit, AfterViewInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
  };

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  admins: UserModel[] = [];
  formAdmins: FormControl = new FormControl();

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ClaimReasonListComponent>,
    private ref: ChangeDetectorRef
  ) {
    this.loadAdminList();
  }

  ngOnInit(): void {
    this.selectAdmins();
  }

  ngAfterViewInit() {
    if (this.searchInput) {
      const searchSubscription = fromEvent<KeyboardEvent>(
        this.searchInput.nativeElement,
        'keyup'
      )
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((event) => {
          this.selectAdmins();
          this.admins = this.admins.filter(
            (admin) =>
              admin.name
                .toLowerCase()
                .includes(
                  (event.target as HTMLInputElement).value.toLowerCase()
                ) ||
              admin.lastname
                .toLowerCase()
                .includes(
                  (event.target as HTMLInputElement).value.toLowerCase()
                )
          );
          this.ref.detectChanges();
        });
      this.subscriptions.push(searchSubscription);
    }
  }

  selectAdmins() {
    const selectUsersSubscription = this.store
      .select(selectUsersInStore)
      .subscribe((value) => {
        this.admins = _.cloneDeep(value.items);
      });

    this.subscriptions.push(selectUsersSubscription);
  }

  loadAdminList() {
    this.store.dispatch(UserActions.AllUsersRequested());
  }

  cancelAffectAdmin() {
    this.dialogRef.close();
  }

  applyAffectAdmin() {
    const admin: UserModel = new UserModel();
    const claim: ClaimModel = new ClaimModel();
    admin.id = this.formAdmins.value;
    claim.id = this.data;
    claim.admin = admin;

    console.log(claim);
    this.store.dispatch(ClaimActions.ClaimAdminAffectation({ claim }));
    this.cancelAffectAdmin();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
