import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductsService } from './products.service';
import { AppState } from '../../reducers';
import { createAction, props, Store } from '@ngrx/store';
import * as ProductActions from './product.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LayoutUtilsService, MessageType } from '../../_base/crud';

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private productService: ProductsService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) {}

  ProductPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.ProductsPageRequested),
      switchMap((action) =>
        this.productService.getAllPaged2(action.queryParams).pipe(
          map((productPage) =>
            ProductActions.ProductsPageLoadedSuccessfully({
              products: productPage.content,
              page: productPage.totalElements,
              totalCount: productPage.totalElements,
            })
          ),
          catchError((error) =>
            of(ProductActions.ProductsPageLoadFailed({ error }))
          )
        )
      )
    )
  );

  // GET product list optimised

    ProductPageRequestedOpt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductActions.ProductsPageRequestedOpt),
            switchMap((action) =>
                this.productService.getAllPaged2Opt(action.queryParams).pipe(
                    map((productPage) =>
                        ProductActions.ProductsPageLoadedSuccessfullyOPt({
                            products: productPage.content,
                            page: productPage.totalElements,
                            totalCount: productPage.totalElements,
                        })
                    ),
                    catchError((error) =>
                        of(ProductActions.ProductsPageLoadFailedOpt({ error }))
                    )
                )
            )
        )
    );



  ProductCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.ProductCreated),
      switchMap((action) =>
        this.productService.save(action.product).pipe(
          map((data) =>
            ProductActions.ProductCreatedSuccessfully({ product: data })
          ),
          catchError((error) =>
            of(ProductActions.ProductCreationFailed({ error }))
          )
        )
      )
    )
  );

  ProductCreationFailed = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductActions.ProductCreationFailed),
        switchMap((action) =>
          of(
            /*this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !' + action.error,
              MessageType.Update,
              3000,
              false
            )*/
          )
        )
      ),
    { dispatch: false }
  );

  ProductUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.ProductUpdated),
      switchMap((action) =>
        this.productService.update(action.product).pipe(
          map((product) =>
            ProductActions.ProductUpdatedSuccessfully({
              product,
              partialProduct: action.partialProduct,
            })
          ),
          catchError((error) =>
            of(ProductActions.ProductUpdateFailed({ error }))
          )
        )
      )
    )
  );

  ProductUpdatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductActions.ProductUpdatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Le produit a été modifié avec succès',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductUpdateFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductActions.ProductUpdateFailed),
        switchMap((action) =>
          of(
            /*this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !' + action.error,
              MessageType.Update,
              3000,
              false
            )*/
          )
        )
      ),
    { dispatch: false }
  );

  ProductDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.ProductDeleted),
      switchMap((action) =>
        this.productService.delete(action.productId).pipe(
          map(() =>
            ProductActions.ProductDeletedSuccessfully({
              productId: action.productId,
            })
          ),
          catchError((error) =>
            of(ProductActions.ProductDeleteFailed({ error }))
          )
        )
      )
    )
  );

  ProductDeletedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductActions.ProductDeletedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Le produit a été supprimé',
              MessageType.Delete,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductDeleteFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProductActions.ProductDeleteFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Delete,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductResourceDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.ProductResourceDeleted),
      switchMap((action) =>
        this.productService
          .deleteResource(action.productId, action.resourceId)
          .pipe(
            map(() =>
              ProductActions.ProductResourceDeletedSuccessfully({
                productId: action.productId,
                resourceId: action.resourceId,
              })
            ),
            catchError((error) =>
              of(ProductActions.ProductResourceDeleteFailed({ error }))
            )
          )
      )
    )
  );

  ArchiveProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.ArchiveProduct),
      switchMap((action) =>
        this.productService.archiveProduct(action.productId).pipe(
          map(() =>
            ProductActions.ProductArchivedSuccessfully({
              productId: action.productId,
            })
          ),
          catchError((error) =>
            of(ProductActions.ProductArchivingFailed({ error }))
          )
        )
      )
    )
  );

  ChangeProductVisibility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.ChangeProductVisibility),
      switchMap((action) =>
        this.productService.changeProductVisibility(action.product).pipe(
          map(() => {
            action.product.visible = !action.product.visible;
            return ProductActions.ProductVisibilityChangedSuccessfully({
              product: action.product,
            });
          }),
          catchError((error) =>
            of(ProductActions.ProductVisibilityChangeFailed({ error }))
          )
        )
      )
    )
  );
}
