// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';
import { RouterModule } from '@angular/router';
import { ServiceClientModule } from './service-client/service-client.module';
import { ArchivedProductListComponent } from './archive/archived-product-list/archived-product-list.component';
import { ArchivedClientListComponent } from './archive/archived-client-list/archived-client-list.component';
import { ArchivedOrderListComponent } from './archive/archived-order-list/archived-order-list.component';

import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatInputModule,
  MatDividerModule,
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { AboutComponent } from './a-propos/about/about.component';
import { AboutEditComponent } from './a-propos/about/about-edit/about-edit.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ArchivedQuotationListComponent } from './archive/archived-quotation-list/archived-quotation-list.component';
import { MatTableExporterModule } from 'mat-table-exporter';

@NgModule({
  declarations: [
    ArchivedProductListComponent,
    AboutComponent,
    AboutEditComponent,
    ArchivedQuotationListComponent,
    ArchivedClientListComponent,
    ArchivedOrderListComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    MailModule,
    ECommerceModule,
    UserManagementModule,
    RouterModule,
    ServiceClientModule,
    MatFormFieldModule,
    TranslateModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatButtonModule,
    MatTooltipModule,
    MatTabsModule,
    MatDividerModule,
    MatTableExporterModule,
  ],
  providers: [],
})
export class PagesModule {}
