import { OrderItemModel } from '../OrderItems/OrderItem.model';

export class OrderModel {
  id: number;
  number: string;
  clientId: number;
  createdAt: string;
  status: string;
  deliveryType: string;
  paymentType: string;
  currency: string;
  clientSecret: string;
  billingAddress: string;
  deliveryAddress: string;
  totalPrice: number;
  totalPriceWithVAT: number;
  items: OrderItemModel[];
  shippingCost: number;

  clientName?: string;
  clientNumber?: string;
  clientMail?: string;
  companyName?: string;
}
