import { createAction, props } from '@ngrx/store';
import { QuestionAnswerModel } from './questionanswer.model';
import { QueryParamsModel } from '../../_base/crud';
import { Update } from '@ngrx/entity';

export const FAQPageRequested = createAction(
  '[FAQ List] Get faq list',
  props<{ page: QueryParamsModel }>()
);
export const FAQPageLoadedSuccessfully = createAction(
  '[FAQ API] FAQ List Loaded Successfully ',
  props<{ faqs: QuestionAnswerModel[]; totalCount: number; page: number }>()
);
export const FAQPageLoadFailed = createAction(
  '[FAQ API] Faq Page Request Failed ',
  props<{ error: any }>()
);

export const FAQCreated = createAction(
  '[FAQ Create] FAQCreated ',
  props<{ faq: QuestionAnswerModel }>()
);
export const FAQCreatedSuccessfully = createAction(
  '[FAQ Create] FAQ Created Successfully ',
  props<{ faq: QuestionAnswerModel }>()
);
export const FAQCreationFailed = createAction(
  '[FAQ Create] FAQ Creation Failed ',
  props<{ error: any }>()
);

export const FAQUpdated = createAction(
  '[FAQ Update] Update FAQ ',
  props<{ faq: QuestionAnswerModel; partialFAQ: Update<QuestionAnswerModel> }>()
);
export const FAQUpdatedSuccessfully = createAction(
  '[FAQ Update] FAQ Updated Successfully ',
  props<{ faq: QuestionAnswerModel; partialFAQ: Update<QuestionAnswerModel> }>()
);
export const FAQUpdateFailed = createAction(
  '[FAQ Update] FAQ Update Failed ',
  props<{ error: any }>()
);

export const FAQDeleted = createAction(
  '[FAQ Delete] FAQ Deleted ',
  props<{ FAQId: number }>()
);
export const FAQDeletedSuccessfully = createAction(
  '[FAQ Delete] FAQ deleted Successfully ',
  props<{ FAQId: number }>()
);
export const FAQDeleteFailed = createAction(
  '[FAQ Delete] FAQ delete Failed ',
  props<{ error: any }>()
);
