import { createAction, props } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { PromoCodeModel } from './promo-code.model';
import { Update } from '@ngrx/entity';

export const PromoCodesPageRequested = createAction(
  '[PromoCode] PromoCodesPageRequested ',
  props<{ page: QueryParamsModel }>()
);
export const PromoCodesPageLoadedSuccessfully = createAction(
  '[PromoCode] PromoCodesPageLoadedSuccessfully ',
  props<{ promoCodes: PromoCodeModel[]; totalCount: number }>()
);
export const PromoCodesPageLoadFailed = createAction(
  '[PromoCode] PromoCodesPageLoadFailed ',
  props<{ error: any }>()
);

export const PromoCodeCreated = createAction(
  '[PromoCode] PromoCode Created ',
  props<{ promoCode: PromoCodeModel }>()
);
export const PromoCodeCreatedSuccessfully = createAction(
  '[PromoCode] PromoCode Created Successfully ',
  props<{ promoCode: PromoCodeModel }>()
);
export const PromoCodeCreationFailed = createAction(
  '[PromoCode] PromoCode Creation Failed ',
  props<{ error: any }>()
);

export const PromoCodeUpdated = createAction(
  '[PromoCode] PromoCodeUpdated ',
  props<{ promoCode: PromoCodeModel; partialPromoCode: Update<PromoCodeModel> }>()
);
export const PromoCodeUpdatedSuccessfully = createAction(
  '[PromoCode] PromoCode Updated Successfully ',
  props<{ promoCode: PromoCodeModel; partialPromoCode: Update<PromoCodeModel> }>()
);
export const PromoCodeUpdateFailed = createAction(
  '[PromoCode] PromoCode Update Failed ',
  props<{ error: any }>()
);

export const PromoCodeDeleted = createAction(
  '[PromoCode] PromoCode Deleted ',
  props<{ promoCodeId: number }>()
);
export const PromoCodeDeletedSuccessfully = createAction(
  '[PromoCode] PromoCode deleted Successfully ',
  props<{ promoCodeId: number }>()
);
export const PromoCodeDeleteFailed = createAction(
  '[PromoCode] PromoCode delete Failed ',
  props<{ error: any }>()
);
export const ChangePromoCodeStatus = createAction(
  '[PromoCode Status] Change PromoCode Status',
  props<{ promoCode: PromoCodeModel; partialPromoCode: Update<PromoCodeModel> }>()
);
export const PromoCodeStatusChangedSuccessfully = createAction(
  '[PromoCode Status] PromoCode Status changed Successfully ',
  props<{ promoCode: PromoCodeModel; partialPromoCode: Update<PromoCodeModel> }>()
);
export const PromoCodeStatusChangeFailed = createAction(
  '[PromoCode Status] PromoCode Status change Failed ',
  props<{ error: any }>()
);
