import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { LayoutUtilsService, MessageType } from '../../_base/crud';
import { ClaimModel } from './claim.model';
import { ClaimService } from './claim.service';
import { AppState } from '../../reducers';
import * as ClaimActions from './claim.actions';

@Injectable()
export class ClaimEffects {
  constructor(
    private actions$: Actions,
    private claimService: ClaimService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) {}

  ClaimPageRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimActions.ClaimPageRequest),
      switchMap((action) =>
        this.claimService.getClaims(action.queryParams).pipe(
          map((claimPage) =>
            ClaimActions.ClaimPageLoadedSuccessfully({
              claims: claimPage.content,
              page: claimPage.totalElements,
              totalCount: claimPage.totalElements,
            })
          ),
          catchError((error) => of(ClaimActions.ClaimPageLoadFailed({ error })))
        )
      )
    )
  );

  ClaimRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimActions.ClaimRequest),
      switchMap((action) =>
        this.claimService.getClaim(action.claimId).pipe(
          map((claim) =>
            ClaimActions.ClaimLoadedSuccessfully({
              claim,
            })
          ),
          catchError((error) => of(ClaimActions.ClaimLoadFailed({ error })))
        )
      )
    )
  );

  ClaimStatusChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimActions.ClaimStatusChange),
      switchMap((action) =>
        this.claimService.changeClaimStatus(action.claim).pipe(
          map((claim) =>
            ClaimActions.ClaimStatusChangedSuccessfully({
              claim,
            })
          ),
          catchError((error) =>
            of(ClaimActions.ClaimStatusChangeFailed({ error }))
          )
        )
      )
    )
  );

  ClaimArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimActions.ClaimArchive),
      switchMap((action) =>
        this.claimService.archiveClaim(action.claimId).pipe(
          map((claim) =>
            ClaimActions.ClaimArchivedSuccessfully({
              claim,
            })
          ),
          catchError((error) => of(ClaimActions.ClaimArchiveFailed({ error })))
        )
      )
    )
  );

  ClaimArchivedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimActions.ClaimArchivedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'La réclamation a été archivée avec succès',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ClaimAdminAffectation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimActions.ClaimAdminAffectation),
      switchMap((action) =>
        this.claimService.affectAdmin(action.claim).pipe(
          map((claim) =>
            ClaimActions.ClaimAdminAffectedSuccessfully({
              claim,
            })
          ),
          catchError((error) =>
            of(ClaimActions.ClaimAdminAffectationFailed({ error }))
          )
        )
      )
    )
  );
}
