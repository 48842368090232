import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PromoCodeModel } from './promo-code.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as PromoCodeActions from './promo-code.actions';
import { Response } from '../../_base/crud/models/response';


export interface PromoCodeState extends EntityState<PromoCodeModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedPromoCodeId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  lastAction: string;
}
const newState = (state, newData) => {
  return Object.assign({}, state, newData);
};


export const adapter: EntityAdapter<PromoCodeModel> =
  createEntityAdapter<PromoCodeModel>();

export const initialPromoCodeState: PromoCodeState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedPromoCodeId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  lastAction: '',
});

const promoCodeReducer = createReducer(
  initialPromoCodeState,
  on(PromoCodeActions.PromoCodesPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),
  on(PromoCodeActions.PromoCodesPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.promoCodes, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(PromoCodeActions.PromoCodesPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // creation
  on(PromoCodeActions.PromoCodeCreated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(PromoCodeActions.PromoCodeCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.promoCode, {
      ...state,
      actionsLoading: false,
      lastCreatedPromoCodeId: action.promoCode.id,
      lastAction: 'EDIT.ADD_MESSAGE',
    })
  ),
  on(PromoCodeActions.PromoCodeCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  // Update
  on(PromoCodeActions.PromoCodeUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(PromoCodeActions.PromoCodeUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialPromoCode, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(PromoCodeActions.PromoCodeUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),

  // Delete
  on(PromoCodeActions.PromoCodeDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(PromoCodeActions.PromoCodeDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.promoCodeId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(PromoCodeActions.PromoCodeDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  }))
);

export function promoCodesReducer(state: PromoCodeState | undefined, action: Action) {
  return promoCodeReducer(state, action);
}
