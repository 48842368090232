import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientModel } from '../../../../../../../../core/e-commerce/clients/client.model';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ClientService } from '../../../../../../../../core/e-commerce/clients/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from '../../../../../../../../core/e-commerce/companies/companies.service';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../../../core/_base/crud';
import { Observable, of } from 'rxjs';
import { ClientfunctionsService } from '../../../../../../../../core/e-commerce/clientfunctions/clientfunctions.service';
import { ClientfunctionModel } from '../../../../../../../../core/e-commerce/clientfunctions/clientfunction.model';
import { ClientOptimiserModel } from '../../../../../../../../core/e-commerce/clients/clientOptimiser.model';

@Component({
  selector: 'kt-contacts',
  templateUrl: './contacts.component.html',
})
export class ContactsComponent implements OnInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  companyId: number;
  loading$: Observable<boolean> = of(true);
  totalElements = 0;
  displayedColumns = ['id', 'mail', 'name', 'lastName', 'status', 'actions'];
  suggestedClients: ClientOptimiserModel[] = [];
  contactForm: FormGroup;
  hasFormErrors = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  private functions: ClientfunctionModel[];
  private client: Partial<ClientOptimiserModel> = new ClientModel();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private companyService: CompaniesService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private functionService: ClientfunctionsService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.getFunctions();
    this.companyId = this.activatedRoute.snapshot.params.id;
    if (this.companyId) {
      this.getContacts(this.companyId);
    } else {
      this.createForm();
    }
  }

  getContacts(companyId: number) {
    const queryParams = new QueryParamsModel('', null, null, 0, 15);
    this.companyService
      .getClientsByCompany(companyId, queryParams)
      .subscribe((value) => {
        this.dataSource.data = value.content;
        this.totalElements = value.totalElements;
        this.loading$ = of(false);
      });
  }

  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'ACTIVATED':
        return 'success';
      case 'BLOCKED':
        return 'danger';
      case 'DEACTIVATED':
      case 'PENDING':
        return 'metal';
    }
    return '';
  }

  createForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      phone: [''],
      mail: ['', Validators.required],
      title: [''],
      function: ['', Validators.required],
      searchClient: [''],
    });

    this.contactForm.get('searchClient').valueChanges.subscribe((value) => {
      this.clientService
        .searchClient(value)
        .subscribe(
          (value1) =>
            (this.suggestedClients = value1.filter(
              (value2) => !value2.companyId
            ))
        );
      if (!value) {
        this.client.id = null;
      }
    });
  }

  selectClient(value: any) {
    const client = this.suggestedClients.find((c) => c.name === value);
    this.client = client;
    this.contactForm.setValue({
      mail: client.mail,
      name: client.name,
      lastname: client.lastname,
      phone: client.phone,
      title: client.title,
      function: client.function?.function ? client.function?.function : '',
      searchClient: client.name,
    });
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getFunctions() {
    this.functionService.getAllPaged2().subscribe((response: any) => {
      this.functions = response;
    });
  }

  next() {
    if (!this.client.id) {
      this.client.name = this.contactForm.controls.name.value;
      this.client.lastname = this.contactForm.controls.lastname.value;
      this.client.mail = this.contactForm.controls.mail.value;
      this.client.title = this.contactForm.controls.title.value;
      this.client.phone = this.contactForm.controls.phone.value;
      this.client.function.id = this.contactForm.controls.function.value;
    }
    this.companyService.client = this.client;
  }

  deleteClient(id: number) {
    const _title = 'Supprimer le client';
    const _description = 'Voulez-vous vraiment supprimer ce client ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.clientService.deleteCollaboratorAdmin(id).subscribe(
        (value) => {
          this.getContacts(this.companyId);
          this.showNotification(
            'Client est supprimé de l\'entreprise avec succès !',
            MessageType.Delete
          );
          this.router.navigate(['/ecommerce/companies']);
        },
        (error) =>
          this.showNotification(
            'Oops! on peut pas supprimer ce client',
            MessageType.Delete
          )
      );
    });
  }

  showNotification(messageText: string, messageType: MessageType) {
    this.layoutUtilsService.showActionNotification(messageText, messageType);
  }

  unblockClient(client: ClientModel) {
    const _TITLE = `Unblock client ${client.lastname} ${client.name}`;
    const _DESCRIPTION = `Are you sure to unblock client ${client.lastname} ${client.name}?`;
    const _WAIT_DESCRIPTION = `Client ${client.lastname} ${client.name} is unblocking...`;
    const _UNBLOCK_MESSAGE = `Client ${client.lastname} ${client.name} has been unblocked`;

    const dialogRef = this.layoutUtilsService.unblockEntity(
      _TITLE,
      _DESCRIPTION,
      _WAIT_DESCRIPTION
    );
    dialogRef.afterClosed().subscribe((response) => {
      if (!response) {
        return;
      }
      this.clientService.unblockClient(client).subscribe(() => {
        this.ngOnInit();
        this.layoutUtilsService.showActionNotification(
          _UNBLOCK_MESSAGE,
          MessageType.Unblock
        );
      });
    });
  }

  resendConfirmationMail(mail: string) {
    this.clientService.resendConfirmationMail(mail).subscribe(() => {
      this.layoutUtilsService.showActionNotification(
        'Le mail de confirmation est envoyé avec succés',
        MessageType.Create
      );
    });
  }
}
