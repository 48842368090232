import {Action, createReducer, on} from '@ngrx/store';
import {
    getLoggedInUser,
    getLoggedInUserFailed,
    getLoggedInUserSucceed,
    loggingIn,
    login,
    loginFailure,
    loginSuccess,
    logout,
} from '../_actions/auth.actions';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserModel} from '../../_models/user.model';

export const authFeatureKey = 'auth';

export interface AuthState {
    token: string;
    isLoading: boolean;
    error: boolean;
    errorMessage: string;
    isLoggedIn: boolean;
    mail: string;
    role: string;
    user: UserModel;
}

export const initialState: AuthState = {
    token: localStorage.getItem('authToken'),
    isLoading: false,
    error: false,
    errorMessage: null,
    isLoggedIn: haveToken(),
    mail: getMail(),
    role: getUserRole(),
    user: null,
};

const reducer = createReducer(
    initialState,
    on(login, (state: AuthState) => {
        return {
            token: state.token,
            isLoading: true,
            error: false,
            errorMessage: null,
            isLoggedIn: false,
            mail: '',
            role: '',
            user: null,
        };
    }),
    on(loggingIn, (state: AuthState, action) => {
        return {
            token: action.token,
            isLoading: true,
            error: false,
            errorMessage: null,
            isLoggedIn: false,
            mail: '',
            role: '',
            user: null,
        };
    }),
    on(loginSuccess, (state: AuthState, action) => {
        return {
            token: action.token,
            isLoading: false,
            error: false,
            errorMessage: null,
            isLoggedIn: haveToken(),
            mail: getMail(),
            role: getUserRole(),
            user: null,
        };
    }),
    on(loginFailure, (state: AuthState, action) => {
        let errorMessage: string;
        switch (action.errorCode) {
            case 401:
                errorMessage = 'AUTH.VALIDATION.INVALID_LOGIN';
                break;
            case 403:
                errorMessage = 'AUTH.VALIDATION.ACCOUNT_SUSPENDED';
                break;
            case 404:
                errorMessage = 'AUTH.VALIDATION.INVALID_LOGIN';
                break;
            case 406:
                errorMessage = 'Ce compte n\'est pas encore activé';
                break;
            default:
                errorMessage = 'AUTH.VALIDATION.NO_CONNECTION';
                break;
        }
        return {
            token: state.token,
            isLoading: false,
            error: true,
            errorMessage,
            isLoggedIn: haveToken(),
            mail: '',
            role: '',
            user: null,
        };
    }),
    on(logout, (state) => ({
        ...state,
        token: null,
        isLoading: false,
        isLoggedIn: false,
        role: '',
        user: null,
    })),

    on(getLoggedInUser, (state) => ({...state, isLoading: true})),

    on(getLoggedInUserSucceed, (state, action) => ({
        ...state,
        isLoading: false,
        user: action.user,
    })),

    on(getLoggedInUserFailed, (state, action) => ({
        ...state,
        isLoading: false,
        error: true,
    }))
);

export function haveToken(): boolean {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('authToken');
    const isExpired = helper.isTokenExpired(token);
    if (token == null || token === 'null') {
        return false;
    }
    if (helper.decodeToken(token).role === ('ROLE_SUPER_ADMIN') && !isExpired) {
        return true;
    } else if (helper.decodeToken(token).role === ('ROLE_ADMIN') && !isExpired) {
        return true;
    } else {
        return false;
    }
}

export function authReducer(state: AuthState, action: Action) {
    return reducer(state, action);
}

/**
 * return the logedin user mail from token
 */
export function getMail() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('authToken');

    if (token) {
        return helper.decodeToken(token).sub;
    } else {
        return '';
    }
}

/**
 * return the logedin user mail from token
 */
export function getUserRole() {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('authToken');

    if (token) {
        return helper.decodeToken(token).role;
    } else {
        return '';
    }
}
