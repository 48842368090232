import { QueryParamsModel } from '../../_base/crud';
// State
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { MainClientModel } from './mainclient.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CompaniesService } from '../companies/companies.service';
import { catchError, finalize } from 'rxjs/operators';
import { MainClientService } from './main-client.service';
import { ClientModel } from '../clients/client.model';

export class MainClientDatasource implements DataSource<MainClientModel> {
  /**
   * Datasource that doesnt use NGRX
   * check this course https://blog.angular-university.io/angular-material-data-table/
   */

  private mainClientSubject = new BehaviorSubject<MainClientModel[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private errorSubject = new BehaviorSubject<string>(undefined);
  private listSizeSubject = new BehaviorSubject<number>(-1);
  private entitySubject = new BehaviorSubject<MainClientModel>(null);
  private paginatorTotal = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public error$ = this.errorSubject.asObservable();
  public listSize$ = this.listSizeSubject.asObservable();
  public entity$ = this.entitySubject.asObservable();
  public paginatorTotal$ = this.paginatorTotal.asObservable();

  constructor(
    private mainClientService: MainClientService,
    private companyService: CompaniesService
  ) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<MainClientModel[] | ReadonlyArray<MainClientModel>> {
    return this.mainClientSubject.asObservable();
  }

  connectEntity(id: number): Observable<ClientModel> {
    return this.entitySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.mainClientSubject.complete();
    this.loadingSubject.complete();
    this.errorSubject.complete();
    this.listSizeSubject.complete();
  }

  // affichage des clients

  getAllClients(queryParams: QueryParamsModel) {
    this.loadingSubject.next(true);
    this.mainClientService.getAllClients(queryParams).subscribe((clients) => {
      this.loadingSubject.next(false);
      this.mainClientSubject.next(clients.content);
      this.listSizeSubject.next(clients.content.length);
      this.paginatorTotal.next(clients.totalElements);
    });
  }

  deleteClient(clientId: number) {
    this.loadingSubject.next(true);

    this.mainClientService
      .delete(clientId)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of(null);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((res) => {
        this.entitySubject.next(res);
        this.mainClientSubject.next(
          this.mainClientSubject.value.filter(
            (entity) => entity.id !== clientId
          )
        );
      });
  }

  archiveClient(clientId: number, mail: string) {
    this.loadingSubject.next(true);
    this.mainClientService.archivedAllCommande(mail).subscribe();
    this.mainClientService.archivedAllDevis(mail).subscribe();
    this.mainClientService
      .archiveClient(clientId)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of(null);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((res) => {
        this.entitySubject.next(res);
        this.mainClientSubject.next(
          this.mainClientSubject.value.filter(
            (entity) => entity.id !== clientId
          )
        );
      });
  }

  unarchiveClient(clientId: number, mail: string) {
    this.loadingSubject.next(true);

    this.mainClientService.unarchivedAllCommande(mail).subscribe();
    this.mainClientService.unarchivedAllDevis(mail).subscribe();
    this.mainClientService
      .unarchiveClient(clientId)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of(null);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((res) => {
        this.entitySubject.next(res);
        this.mainClientSubject.next(
          this.mainClientSubject.value.filter(
            (entity) => entity.id !== clientId
          )
        );
      });
  }
  getArchivedClients(queryParams: QueryParamsModel) {
    this.loadingSubject.next(true);
    this.mainClientService
      .getArchivedClients(queryParams)
      .subscribe((clients) => {
        this.loadingSubject.next(false);
        this.mainClientSubject.next(clients.content);
        this.listSizeSubject.next(clients.content.length);
        this.paginatorTotal.next(clients.totalElements);
      });
  }
}
