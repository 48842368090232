import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
//Actions
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as BlogActions from './blog.action';
import { AppState } from '../reducers';
import { LayoutUtilsService } from '../_base/crud';
import { BlogService } from './blog.service';

@Injectable()
export class BlogEffects {
  // constructor(private actions$: Actions,
  //             private questionAnswerService: QuestionAnswerService,
  //             private layoutUtilsService: LayoutUtilsService,
  //             private store: Store<AppState>) {
  // }
  constructor(
    private actions$: Actions,
    private blogService: BlogService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  BlogPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.BlogsPageRequested),
      switchMap((action) =>
        this.blogService.getAllByFilter(action.page).pipe(
          map((blogPage) => {
            return BlogActions.BlogsPageLoadedSuccessfully({
              blogs: blogPage.content,
              page: blogPage.totalElements,
              totalCount: blogPage.totalElements,
            });
          }),
          catchError((error) => of(BlogActions.BlogsPageLoadFailed({ error })))
        )
      )
    )
  );

  BlogCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.BlogCreated),
      switchMap((action) =>
        this.blogService.registerBlog(action.Blog).pipe(
          map((data) => BlogActions.BlogCreatedSuccessfully({ Blog: data })),
          catchError((error) => of(BlogActions.BlogCreationFailed({ error })))
        )
      )
    )
  );

  BlogUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.BlogUpdated),
      switchMap((action) =>
        this.blogService.updateBlog(action.Blog, action.id).pipe(
          map((Blog) =>
            BlogActions.BlogUpdatedSuccessfully({
              Blog,
              id: action.id,
            })
          ),
          catchError((error) => of(BlogActions.BlogUpdateFailed({ error })))
        )
      )
    )
  );

  BlogDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.BlogDeleted),
      switchMap((action) =>
        this.blogService.delete(action.BlogId).pipe(
          map(() =>
            BlogActions.BlogDeletedSuccessfully({ BlogId: action.BlogId })
          ),
          catchError((error) => of(BlogActions.BlogDeleteFailed({ error })))
        )
      )
    )
  );
}
