import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { StockModel } from './stock.model';

@Injectable()
export class StockService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}stock`;
  }

  addStock(stock: StockModel): Observable<StockModel> {
    return this.http.post<StockModel>(this.url, stock);
  }

  getStocks(productId: number): Observable<StockModel[]> {
    return this.http.get<StockModel[]>(`${this.url}/${productId}`);
  }

  public delete(id: number): Observable<any> {
    const url = `${this.url}/${id}`;
    return this.http.delete<void>(url);
  }

  public update(stock: StockModel): Observable<StockModel> {
    const url = `${this.url}`;
    return this.http.put<StockModel>(url, stock);
  }
}
