import { createAction, props } from '@ngrx/store';
import { CarouselModel } from './carousel.model';
import { Update } from '@ngrx/entity';

export const CarouselsPageRequested = createAction(
  '[Carousel List Page] Carousels Page Requested '
);
export const CarouselsPageLoadedSuccessfully = createAction(
  '[Carousel API] Carousels Page Loaded Successfully ',
  props<{ carousels: CarouselModel[] }>()
);
export const CarouselsPageLoadFailed = createAction(
  '[Carousel API] Carousels Page Request Failed ',
  props<{ error: any }>()
);

export const CarouselCreated = createAction(
  '[Carousel List Page] Carousel Created ',
  props<{ carousel: CarouselModel }>()
);
export const CarouselCreatedSuccessfully = createAction(
  '[Carousel List Page] Carousel Created Successfully ',
  props<{ carousel: CarouselModel }>()
);
export const CarouselCreationFailed = createAction(
  '[Carousel List Page] Carousel Creation Failed ',
  props<{ error: any }>()
);

export const CarouselUpdated = createAction(
  '[Carousel List Page] Carousel Updated ',
  props<{ carousel: CarouselModel; partialCarousel: Update<CarouselModel> }>()
);
export const CarouselUpdatedSuccessfully = createAction(
  '[Carousel List Page] Carousel Updated Successfully ',
  props<{ carousel: CarouselModel; partialCarousel: Update<CarouselModel> }>()
);
export const CarouselUpdateFailed = createAction(
  '[Carousel List Page] Carousel Update Failed ',
  props<{ error: any }>()
);

export const CarouselDeleted = createAction(
  '[Carousel List Page] Carousel Deleted ',
  props<{ carouselId: number }>()
);
export const CarouselDeletedSuccessfully = createAction(
  '[Carousel List Page] Carousel deleted Successfully ',
  props<{ carouselId: number }>()
);
export const CarouselDeleteFailed = createAction(
  '[Carousel List Page] Carousel delete Failed ',
  props<{ error: any }>()
);
