import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
import {
  selectManufacturersInStore,
  selectManufacturersPageLoading,
  selectManufacturersShowInitWaitingMessage,
} from './manufacturer.selector';

export class ManufacturerDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();

    this.loading$ = this.store.pipe(select(selectManufacturersPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectManufacturersShowInitWaitingMessage)
    );

    this.store
      .pipe(select(selectManufacturersInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
