import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { fromEvent, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../core/_base/crud';
import { SelectionModel } from '@angular/cdk/collections';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ClientModel } from '../../../../core/e-commerce/clients/client.model';
import { MainClientService } from '../../../../core/e-commerce/mainclient/main-client.service';
import { MainClientDatasource } from '../../../../core/e-commerce/mainclient/mainclient.datasource';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../core/_base/layout';
import { CompaniesService } from '../../../../core/e-commerce/companies/companies.service';

@Component({
  selector: 'kt-archived-client-list',
  templateUrl: './archived-client-list.component.html',
  styleUrls: ['./archived-client-list.component.scss'],
})
export class ArchivedClientListComponent implements OnInit, OnDestroy {
  dataSource: MainClientDatasource;
  displayedColumns = ['id', 'mail', 'name', 'lastname', 'status', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  private subscriptions: Subscription[] = [];

  filterStatus = new FormControl('');

  selection = new SelectionModel<ClientModel>(true, []);

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private mainClientService: MainClientService,
    private store: Store<AppState>,
    private companyService: CompaniesService
  ) {}

  ngOnInit(): void {
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadClientsPage();
        })
      )
      .subscribe();
    this.dataSource = new MainClientDatasource(
      this.mainClientService,
      this.companyService
    );
  }
  ngAfterViewInit() {
    // server side search
    const searchInputSub = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadClientsPage();
        })
      )
      .subscribe();
    this.subscriptions.push(searchInputSub);

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const paginatorSub = this.paginator.page
      .pipe(tap((res) => this.loadClientsPage()))
      .subscribe();
    this.subscriptions.push(paginatorSub);
    this.loadClientsPage();
  }

  filterConfiguration(): any {
    const filter: any = {};

    filter.status = 'Tout';

    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  loadClientsPage() {
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.dataSource.getArchivedClients(queryParams);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  unarchiveClient(id: number, name: string, mail: string) {
    const _title = 'Désarchiver contact';
    const _description = 'Voulez-vous vraiment dérachiver ce contact ?';
    const _waitDesciption = 'Désarchivage en cours...';
    const btnTitle = 'désarchiver';

    const dialogRef = this.layoutUtilsService.archiveElement(
      _title,
      _description,
      _waitDesciption,
      btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.dataSource.unarchiveClient(id, mail);
      this.layoutUtilsService.showActionNotification(
        'Le contact est désarchivé',
        MessageType.Update
      );
    });
  }

  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'IN_STOCK':
        return 'success';
      case 'OUT_OF_STOCK':
        return 'danger';
      case 'ON_COMMAND':
        return 'metal';
    }
    return '';
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.paginator.pageSize;
    return numSelected === numRows;
  }
}
