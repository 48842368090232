import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
//Actions
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as AboutActions from '../about/about.action';
import { AppState } from '../reducers';
import { LayoutUtilsService } from '../_base/crud';
import { AboutService } from './about.service';

@Injectable()
export class AboutEffects {
  constructor(
    private actions$: Actions,
    private aboutService: AboutService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  AboutPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AboutActions.AboutsPageRequested),
      switchMap((action) =>
        this.aboutService.findAll().pipe(
          map((abouts) => {
            return AboutActions.AboutsPageLoadedSuccessfully({ abouts });
          }),
          catchError((error) =>
            of(AboutActions.AboutsPageLoadFailed({ error }))
          )
        )
      )
    )
  );

  AboutCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AboutActions.AboutCreated),
      switchMap((action) =>
        this.aboutService.registerAbout(action.About).pipe(
          map((data) => AboutActions.AboutCreatedSuccessfully({ About: data })),
          catchError((error) => of(AboutActions.AboutCreationFailed({ error })))
        )
      )
    )
  );

  AboutUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AboutActions.AboutUpdated),
      switchMap((action) =>
        this.aboutService.updateAbout(action.About, action.id).pipe(
          map((About) =>
            AboutActions.AboutUpdatedSuccessfully({
              About,
              id: action.id,
            })
          ),
          catchError((error) => of(AboutActions.AboutUpdateFailed({ error })))
        )
      )
    )
  );

  AboutDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AboutActions.AboutDeleted),
      switchMap((action) =>
        this.aboutService.delete(action.AboutId).pipe(
          map(() =>
            AboutActions.AboutDeletedSuccessfully({ AboutId: action.AboutId })
          ),
          catchError((error) => of(AboutActions.AboutDeleteFailed({ error })))
        )
      )
    )
  );
}
