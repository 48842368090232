import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as LogsActions from './logs.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {LogsService} from './logs.service';

@Injectable()
export class LogsEffects {
  constructor(
    private actions$: Actions,
    private logsService: LogsService,
    private store: Store<AppState>
  ) {}


  LogsPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LogsActions.LogsPageRequested),
      switchMap((action) =>
        this.logsService
          .getLogs(
            action.page.filter ?? {},
            action.page.pageNumber,
            action.page.pageSize
          )
          .pipe(
            map((logsPage) =>
              LogsActions.LogsPageLoadedSuccessfully({
                logs: logsPage.content,
                page: logsPage.totalElements,
                totalCount: logsPage.totalElements,
              })
            ),
            catchError((error) =>
              of(LogsActions.LogsPageLoadFailed({ error }))
            )
          )
      )
    )
  );

}
