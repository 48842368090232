import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { QueryParamsModel } from '../_base/crud';
import { BlogModel } from './blog.model';

export const BlogsPageRequested = createAction(
  '[Blog List Page] Blogs Page Requested ',
  props<{ page: QueryParamsModel }>()
);
export const BlogsPageLoadedSuccessfully = createAction(
  '[Blog API] Blogs Page Loaded Successfully ',
  props<{ blogs: BlogModel[]; totalCount: number; page: number }>()
);
export const BlogsPageLoadFailed = createAction(
  '[Blog API] Blogs Page Request Failed ',
  props<{ error: any }>()
);

export const BlogCreated = createAction(
  '[Blog List Page] Blog Created ',
  props<{ Blog: BlogModel }>()
);
export const BlogCreatedSuccessfully = createAction(
  '[Blog List Page] Blog Created Successfully ',
  props<{ Blog: BlogModel }>()
);
export const BlogCreationFailed = createAction(
  '[Blog List Page] Blog Creation Failed ',
  props<{ error: any }>()
);

export const BlogUpdated = createAction(
  '[Blog List Page] Blog Updated ',
  props<{ Blog: BlogModel; id: number }>()
);
export const BlogUpdatedSuccessfully = createAction(
  '[Blog List Page] Blog Updated Successfully ',
  props<{ Blog: BlogModel; id: number }>()
);
export const BlogUpdateFailed = createAction(
  '[Blog List Page] Blog Update Failed ',
  props<{ error: any }>()
);

export const BlogDeleted = createAction(
  '[Blog List Page] Blog Deleted ',
  props<{ BlogId: number }>()
);
export const BlogDeletedSuccessfully = createAction(
  '[Blog List Page] Blog deleted Successfully ',
  props<{ BlogId: number }>()
);
export const BlogDeleteFailed = createAction(
  '[Blog List Page] Blog delete Failed ',
  props<{ error: any }>()
);
