import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { LayoutUtilsService, MessageType } from '../../../_base/crud';
import { ClaimReasonModel } from './claim-reason.model';
import { ClaimReasonService } from './claim-reason.service';
import { AppState } from '../../../reducers';
import * as ClaimReasonActions from './claim-reason.actions';

@Injectable()
export class ClaimReasonEffects {
  constructor(
    private actions$: Actions,
    private claimReasonService: ClaimReasonService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) {}

  ClaimReasonListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimReasonActions.ClaimReasonListRequest),
      switchMap((action) =>
        this.claimReasonService.getClaimReasons(action.keyword).pipe(
          map((reasons) =>
            ClaimReasonActions.ClaimReasonListLoadedSuccessfully({
              claimReasons: reasons,
            })
          ),
          catchError((error) =>
            of(ClaimReasonActions.ClaimReasonListLoadFailed({ error }))
          )
        )
      )
    )
  );

  ClaimReasonCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimReasonActions.ClaimReasonCreation),
      switchMap((action) =>
        this.claimReasonService.addClaimReason(action.reason).pipe(
          map((reason) =>
            ClaimReasonActions.ClaimReasonCreatedSuccessfully({
              claimReason: reason,
            })
          ),
          catchError((error) =>
            of(ClaimReasonActions.ClaimReasonCreationFailed({ error }))
          )
        )
      )
    )
  );

  ClaimReasonCreatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimReasonActions.ClaimReasonCreatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Le motif a été ajouté avec succès',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ClaimReasonCreationFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimReasonActions.ClaimReasonCreationFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ClaimReasonUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimReasonActions.ClaimReasonUpdate),
      switchMap((action) =>
        this.claimReasonService.updateClaimReason(action.reason).pipe(
          map((reason) =>
            ClaimReasonActions.ClaimReasonUpdatedSuccessfully({
              claimReason: reason,
              partialClaimReason: action.partialClaimReason,
            })
          ),
          catchError((error) =>
            of(ClaimReasonActions.ClaimReasonUpdateFailed({ error }))
          )
        )
      )
    )
  );

  ClaimReasonUpdatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimReasonActions.ClaimReasonUpdatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Le motif a été mis à jour avec succès',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ClaimReasonUpdateFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimReasonActions.ClaimReasonUpdateFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ClaimReasonListStatusUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimReasonActions.ClaimReasonListStatusUpdate),
      switchMap((action) =>
        this.claimReasonService.updateClaimReasonsStatus(action.reasons).pipe(
          map((reasons) =>
            ClaimReasonActions.ClaimReasonListStatusUpdatedSuccessfully({
              claimReasons: reasons,
              partialClaimReasons: action.partialClaimReasons,
            })
          ),
          catchError((error) =>
            of(ClaimReasonActions.ClaimReasonListStatusUpdateFailed({ error }))
          )
        )
      )
    )
  );

  ClaimReasonListStatusUpdatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimReasonActions.ClaimReasonListStatusUpdatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Les motifs ont été mises à jour avec succès',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ClaimReasonListStatusUpdateFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimReasonActions.ClaimReasonListStatusUpdateFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );
}
