import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LicensesActions from './product-licenses.actions';
import { catchError, count, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ProductLicensesService } from './product-licenses.service';
import { LayoutUtilsService, MessageType } from '../../_base/crud';

@Injectable()
export class ProductLicensesEffects {
  constructor(
    private actions$: Actions,
    private licensesService: ProductLicensesService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  LicenseListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicensesActions.ProductLicensesListRequested),
      switchMap((action) =>
        this.licensesService.getByProductId(action.productId).pipe(
          map((licensesList) =>
            LicensesActions.ProductLicensesListRequestedSuccessfully({
              productLicenses: licensesList,
            })
          ),
          catchError((error) =>
            of(
              LicensesActions.ProductLicensesListRequestFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  LicensesCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicensesActions.ProductLicensesImport),
      switchMap((action) =>
        this.licensesService
          .importLicenses(action.productId, action.productLicenses)
          .pipe(
            map((productLicenses) =>
              LicensesActions.ProductLicensesImportedSuccessfully({
                productLicenses,
              })
            ),
            catchError((error) =>
              of(
                LicensesActions.ProductLicensesImportFailed({
                  error,
                })
              )
            )
          )
      )
    )
  );

  ProductLicensesImportedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LicensesActions.ProductLicensesImportedSuccessfully),
         switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              action.productLicenses.length + ' Licences importées avec succès',
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductLicensesImportFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LicensesActions.ProductLicensesImportFailed),
        switchMap(() =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Erreur lors de l\'importation des licences'
            )
          )
        )
      ),
    { dispatch: false }
  );
}
