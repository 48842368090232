import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PromoCodeState } from './promo-code.reducer';
import { PromoCodeModel } from './promo-code.model';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
// Lodash
import { each } from 'lodash';
import { selectCategoriesState } from '../categories/category.selector';

export const selectPromoCodesState = createFeatureSelector<PromoCodeState>('promoCodes');

export const selectPromoCodeById = (promoCodeId: number) =>
  createSelector(selectPromoCodesState, (promoCodesState) => {
    return promoCodesState.entities[promoCodeId];
  });

export const selectPromoCodesPageLoading = createSelector(
  selectPromoCodesState,
  (promoCodesState) => promoCodesState.listLoading
);

export const selectPromoCodesActionLoading = createSelector(
  selectPromoCodesState,
  (customersState) => customersState.actionsLoading
);

export const selectPromoCodesPageLastQuery = createSelector(
  selectPromoCodesState,
  (promoCodesState) => promoCodesState.lastQuery
);

export const selectLastCreatedPromoCodeId = createSelector(
  selectPromoCodesState,
  (promoCodesState) => promoCodesState.lastCreatedPromoCodeId
);

export const selectPromoCodesInitWaitingMessage = createSelector(
  selectPromoCodesState,
  (promoCodesState) => promoCodesState.showInitWaitingMessage
);

export const selectError = createSelector(
  selectPromoCodesState,
  (promoCodesState) => promoCodesState.error
);

export const selectPromoCodesInStore = createSelector(
  selectPromoCodesState,
  (promoCodesState) => {
    const items: PromoCodeModel[] = [];
    each(promoCodesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: PromoCodeModel[] = httpExtension.sortArray(
      items,
      promoCodesState.lastQuery.sortField,
      promoCodesState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, promoCodesState.totalCount, '');
  }
);

export const selectHasPromoCodesInStore = createSelector(
  selectPromoCodesInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  }
);
