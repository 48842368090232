import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { ClientModel } from './client.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CompaniesService } from '../companies/companies.service';
import { catchError, finalize } from 'rxjs/operators';
import { ClientService } from './client.service';
import { CompanyModel } from '../companies/company.model';
import { LayoutUtilsService, QueryParamsModel } from '../../_base/crud';
import { Page } from '../../_base/crud/models/page';

/**
 * Datasource that doesnt use NGRX
 * check this course https://blog.angular-university.io/angular-material-data-table/
 */
export class ClientDatasource implements DataSource<ClientModel> {
  private clientsSubject = new BehaviorSubject<ClientModel[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private errorSubject = new BehaviorSubject<string>(undefined);
  private listSizeSubject = new BehaviorSubject<number>(-1);
  private entitySubject = new BehaviorSubject<ClientModel>(null);
  private layoutUtilsService: LayoutUtilsService;

  public loading$ = this.loadingSubject.asObservable();
  public error$ = this.errorSubject.asObservable();
  public listSize$ = this.listSizeSubject.asObservable();
  public entity$ = this.entitySubject.asObservable();

  constructor(
    private companyService: CompaniesService,
    private clientService: ClientService,
    private company?: CompanyModel
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<ClientModel[]> {
    return this.clientsSubject.asObservable();
  }

  connectEntity(id: number): Observable<ClientModel> {
    return this.entitySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.clientsSubject.complete();
    this.loadingSubject.complete();
    this.errorSubject.complete();
    this.listSizeSubject.complete();
  }

  loadClients(companyId: number, queryParams: QueryParamsModel) {
    this.loadingSubject.next(true);

    this.companyService
      .getClientsByCompany(companyId, queryParams)
      .pipe(
        catchError((error) => {
          this.errorSubject.next(error);
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((clients: Page<ClientModel>) => {
        if (this.company) {
          clients.content.forEach((c) => {
            c.companyName = this.company.name;
            c.accountNumber = this.company.accountNumber;
          });
        }
        this.clientsSubject.next(clients.content);
        this.listSizeSubject.next(clients.totalElements);
      });
  }

  // load one client by id
  loadClientById(clientId: number) {
    this.loadingSubject.next(true);

    this.clientService
      .getById(clientId)
      .pipe(
        catchError((error) => {
          this.errorSubject.next(error);
          return of(null);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((client) => {
        if (client.id) {
          this.clientService.getAddressBook(client.id).subscribe((value) => {
            client.addresses = value.content;
          });
        }
        if (client.companyId) {
          this.companyService.getById(client.companyId).subscribe((value) => {
            client.companyName = value.name;
            this.entitySubject.next(client);
          });
        } else {
          this.entitySubject.next(client);
        }
      });
  }

  // create a client
  createClient(client: ClientModel) {
    this.loadingSubject.next(true);

    this.clientService
      .registerClient(client)
      .pipe(
        catchError((error) => {
          if (error.status === 500) {
            error = 'Client already exist with email';
            this.errorSubject.next(error);
          } else {
            error = 'Client data is not valid';
            this.errorSubject.next(error);
          }
          return of(null);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((res) => {
        this.entitySubject.next(res);
        this.clientsSubject.next(res);
      });
  }

  //delete client
  deleteClient(clientId: number) {
    this.loadingSubject.next(true);

    this.clientService
      .delete(clientId)
      .pipe(
        catchError((err) => {
          this.errorSubject.next(err);
          return of(null);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((res) => {
        this.entitySubject.next(res);
        this.clientsSubject.next(
          this.clientsSubject.value.filter((entity) => entity.id !== clientId)
        );
      });
  }
}
