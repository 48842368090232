import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ClaimReasonModel } from './claim-reason.model';
import { QueryParamsModel } from '../../../_base/crud';
import { Response } from '../../../_base/crud/models/response';
import * as ClaimReasonActions from './claim-reason.actions';

export interface ClaimReasonState extends EntityState<ClaimReasonModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedCategoryId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<ClaimReasonModel> =
  createEntityAdapter<ClaimReasonModel>();

export const initialCategoryState: ClaimReasonState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedCategoryId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const claimReasonReducer = createReducer(
  initialCategoryState,
  on(ClaimReasonActions.ClaimReasonListRequest, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ClaimReasonActions.ClaimReasonListLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.claimReasons, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(ClaimReasonActions.ClaimReasonListLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  on(ClaimReasonActions.ClaimReasonCreation, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ClaimReasonActions.ClaimReasonCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.claimReason, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(ClaimReasonActions.ClaimReasonCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  on(ClaimReasonActions.ClaimReasonUpdate, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ClaimReasonActions.ClaimReasonUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialClaimReason, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(ClaimReasonActions.ClaimReasonUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  on(ClaimReasonActions.ClaimReasonListStatusUpdate, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(
    ClaimReasonActions.ClaimReasonListStatusUpdatedSuccessfully,
    (state, action) =>
      adapter.updateMany(action.partialClaimReasons, {
        ...state,
        actionsLoading: false,
        lastAction: 'EDIT.UPDATE_MESSAGE',
      })
  ),
  on(ClaimReasonActions.ClaimReasonListStatusUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  }))
);

export function claimReasonsReducer(
  state: ClaimReasonState | undefined,
  action: Action
) {
  return claimReasonReducer(state, action);
}
