import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on, UPDATE } from '@ngrx/store';

import * as FAQActions from './faq.actions';
import { QuestionAnswerModel } from './questionanswer.model';
import { QueryParamsModel } from '../../_base/crud';

export interface FAQState extends EntityState<QuestionAnswerModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  lastCreatedFAQId: number;
  lastQuery: QueryParamsModel;
  totalCount: number;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<QuestionAnswerModel> =
  createEntityAdapter<QuestionAnswerModel>();

export const initialFaqState: FAQState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  lastCreatedFAQId: undefined,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const faqReducer = createReducer(
  initialFaqState,
  on(FAQActions.FAQPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),
  on(FAQActions.FAQPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.faqs, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(FAQActions.FAQPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //creation
  on(FAQActions.FAQCreated, (state) => ({
    ...state,
    actionsLoading: false,
    lastAction: '',
  })),
  on(FAQActions.FAQCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.faq, {
      ...state,
      actionsLoading: true,
      lastCreatedFAQId: action.faq.id,
      lastAction: 'EDIT.ADD_MESSAGE',
      progress: 0,
    })
  ),
  on(FAQActions.FAQCreationFailed, (state: FAQState, action) => {
    let errorMessage: string;
    switch (action.error.status) {
      case 500:
        errorMessage = 'EDIT.ERROR.SERVER';
        break;
      case 403:
        errorMessage = 'AUTH.VALIDATION.NOT_AUTHORIZED';
        break;
      case 404:
        errorMessage = 'EDIT.ERROR.NOT_FOUND';
        break;
      default:
        errorMessage = 'EDIT.ERROR.UNKNOWN';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage: errorMessage,
      error: action.error,
    };
  }),
  on(FAQActions.FAQUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(FAQActions.FAQUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialFAQ, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(FAQActions.FAQUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  //Delete
  on(FAQActions.FAQDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(FAQActions.FAQDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.FAQId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(FAQActions.FAQDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  }))
);

export function fAQReducer(state: FAQState | undefined, action: Action) {
  return faqReducer(state, action);
}
