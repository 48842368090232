import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { LayoutUtilsService } from '../../../../../core/_base/crud';
import { PromotionModel } from '../../../../../core/e-commerce/promotions/promotion.model';
import { PromotionsService } from '../../../../../core/e-commerce/promotions/promotions.service';
import { finalize } from 'rxjs/operators';
import { DiscountModel } from '../../../../../core/e-commerce/promotions/discount.model';
import { DiscountProductDialogComponent } from './discounts/discount-product-dialog/discount-product-dialog.component';
import { PromotionProductDialogComponent } from './promotion-product-dialog/promotion-product-dialog.component';

@Component({
  selector: 'kt-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
})
export class PromotionsComponent implements OnInit , OnDestroy {
  promotionForm: FormGroup;
  dataSource: MatTableDataSource<PromotionModel> = new MatTableDataSource([]);
  displayedPromotionColumns = [
    'select',
    'id',
    'name',
    'promoCode',
    'value',
    'startDate',
    'endDate',
    'status',
    'actions',
  ];
  editPromotionLine = -1;
  loadingAfterSubmit = false;
  loadingPromotions = false;
  show = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private promotionService: PromotionsService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.promotionForm = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      status: [null, Validators.required],
      value: [null, Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });

    this.getPromotions();
  }

  getPromotions() {
    this.loadingPromotions = true;
    this.promotionService
      .getAllPromotions()
      .pipe(finalize(() => (this.loadingPromotions = false)))
      .subscribe((rep) => {
        this.dataSource.data = rep.content;
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  createPromotion() {
    this.editPromotionLine = 0;
    this.promotionForm.reset();
  }

  saveNewPromotion() {
    this.loadingAfterSubmit = true;
    const payload: PromotionModel = {
      id: null,
      promoCode: null,
      name: this.promotionForm.get('name').value,
      description: '',
      status: 'active',
      clientGroup: 'client',
      numOfUse: 2,
      priority: 1,
      label: 'label',
      value: this.promotionForm.get('value').value,
      startDate: this.promotionForm.get('startDate').value,
      endDate: this.promotionForm.get('endDate').value,
    };

    this.promotionService
      .save(payload)
      .pipe(finalize(() => (this.loadingAfterSubmit = false)))
      .subscribe((v) => {
        this.cancelEditPromotion();
        this.getPromotions();
      });
  }

  editPromotion(item: PromotionModel) {
    this.editPromotionLine = item.id;
    this.promotionForm.get('id').setValue(item.id);
    this.promotionForm.get('name').setValue(item.name);
    this.promotionForm.get('status').setValue(item.status);
    this.promotionForm.get('value').setValue(item.value);
    this.promotionForm.get('startDate').setValue(new Date(item.startDate));
    this.promotionForm.get('endDate').setValue(new Date(item.endDate));
  }

  deletePromotion(item: PromotionModel) {
    const _title = 'Supprimer la marque';
    const _description = 'Voulez-vous vraiment supprimer la promotion ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.promotionService.delete(item.id).subscribe(() => {
        this.dataSource.data = this.dataSource.data.filter(
          (p) => p.id !== item.id
        );
      });
    });
  }

  saveEditedPromotion(item: PromotionModel) {
    this.loadingAfterSubmit = true;
    const promotion: Omit<PromotionModel, 'promoCode'> = {
      id: this.promotionForm.value.id,
      name: this.promotionForm.value.name,
      value: this.promotionForm.value.value,
      description: item.description,
      status: this.promotionForm.value.status,
      clientGroup: item.clientGroup,
      numOfUse: item.numOfUse,
      startDate: this.promotionForm.value.startDate,
      endDate: this.promotionForm.value.endDate,
      priority: item.priority,
      label: item.label,
    };
    this.promotionService
      .updatePromotion(promotion)
      .pipe(finalize(() => (this.loadingAfterSubmit = false)))
      .subscribe((rep) => {
        this.dataSource.data.forEach((p) => {
          if (p.id == item.id) {
            p.name = rep.name;
            p.value = this.promotionForm.value.value;
            p.status = this.promotionForm.value.status;
            p.startDate = this.promotionForm.value.startDate;
            p.endDate = this.promotionForm.value.endDate;
          }
        });

        this.cancelEditPromotion();
      });
  }

  cancelEditPromotion() {
    this.editPromotionLine = -1;
    this.promotionForm.reset();
  }

  showPromotionProducts(item: DiscountModel) {
    this.dialog.open(PromotionProductDialogComponent, {
      data: item,
    });
  }
}
