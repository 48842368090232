import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { CategoriesService } from '../../../../../../core/e-commerce/categories/categories.service';
import { CategoryModel } from '../../../../../../core/e-commerce/categories/category.model';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { LayoutUtilsService, QueryParamsModel, MessageType } from '../../../../../../core/_base/crud';
import { CategoryActions } from '../../../../../../core/e-commerce';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, tap, filter } from 'rxjs/operators';
import { ProductsDataSource } from '../../../../../../core/e-commerce/products/products.datasource';
import { CategoriesDatasource } from '../../../../../../core/e-commerce/categories/categories.datasource';
import {
  selectError,
  selectErrorMessage,
  selectlastAction,
} from '../../../../../../core/e-commerce/categories/category.selector';
import { TranslateService } from '@ngx-translate/core';
import {CategoryOptModel} from '../../../../../../core/e-commerce/categories/categoryOpt.model';
@Component({
  selector: 'kt-promo-categories-list',
  templateUrl: './promo-categories-list.component.html',
  styleUrls: ['./promo-categories-list.component.scss']
})
export class PromoCategoriesListComponent implements OnInit {

  dataSource: CategoriesDatasource;
  selectionOpt = new SelectionModel<CategoryOptModel>(true, []);
  selectedCategories: CategoryModel [];
  categoriesResult: CategoryModel[] = [];


  selection = new SelectionModel<CategoryModel>(true, []);
  @ViewChild('Input', {static: true}) searchInput: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort', {static: true}) sort: MatSort;
  subscriptions: Subscription[] = [];



  displayedColumns = ['action', 'id', 'label', 'image'];

  constructor(public dialogRef: MatDialogRef<PromoCategoriesListComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CategoryModel [],
              public dialog: MatDialog,
              private  categoryService: CategoriesService,
              private store: Store<AppState>,
              private layoutUtilsService: LayoutUtilsService,
              private translate: TranslateService,
  ) {
  }
  catogoriesResult: CategoryModel[] = [];
  ngOnInit() {
    this.paginator._changePageSize(50);
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadCategoryList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(700), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadCategoryList();
        })
      )
      .subscribe();
      
    this.subscriptions.push(searchSubscription);

    this.loadCategoryList();

    this.dataSource = new CategoriesDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(0), distinctUntilChanged())
      .subscribe((res) => {
        this.catogoriesResult = res;
      });
    this.subscriptions.push(entitiesSubscription);

    const error$ = this.store
      .select(selectError)
      .pipe(filter((result) => result !== null))
      .subscribe(() => {
        const errorMessageSub = this.store
          .select(selectErrorMessage)
          .subscribe((res) => {
            this.layoutUtilsService.showActionNotification(
              res,
              MessageType.Delete,
              5000,
              true,
              false
            );
          });
      });
    this.subscriptions.push(error$);
    // subscribing to the seccessOperations
    const operation$ = this.store
      .select(selectlastAction)
      .pipe(filter((result) => result !== ''))
      .subscribe((result) => {
        this.layoutUtilsService.showActionNotification(
          this.translate.instant('ECOMMERCE.CATEGORIES.' + result),
          MessageType.Delete,
          10000,
          true,
          false
        );
      });
    this.subscriptions.push(operation$);
  }
  loadCategoryList() {
    this.selectionOpt.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    this.store.dispatch(
      CategoryActions.CategoriesOptPageRequested({
        page: queryParams,
      })
    );
    this.selectionOpt.clear();
  }
  filterConfiguration(): string {
    const filter: any = {};
    filter.type = 3;
    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.categoriesResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selectedCategories = this.selection.selected;
    } else {
      this.categoriesResult.forEach(row => this.selection.select(row));
      this.selectedCategories = this.selection.selected;
    }
  }


  onCategoryToggled(category: CategoryModel ) {
    this.selection.toggle(category);
    this.selectedCategories = this.selection.selected;
  }


  onSubmit() {
    this.dialogRef.close(this.selectedCategories);
  }
  onClose() {
    this.dialogRef.close();
  }

}
