import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { QuotationModel } from '../../../../../../core/e-commerce/quotation/quotation.model';

@Component({
  selector: 'kt-quotation-detail',
  templateUrl: './quotation-detail.component.html',
  styleUrls: ['./quotation-detail.component.scss'],
})
export class QuotationDetailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<QuotationDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public quotation: QuotationModel
  ) {}

  ngOnInit(): void {}
}
