import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
import * as StockActions from './stock.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { StockService } from './stock.service';
import * as ProductActions from '../products/product.actions';
import { LayoutUtilsService, MessageType } from '../../_base/crud';

@Injectable()
export class StockEffects {
  constructor(
    private actions$: Actions,
    private stockService: StockService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>
  ) {}

  StocksPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockActions.StocksListRequested),
      switchMap((action) =>
        this.stockService.getStocks(action.productId).pipe(
            map((stocks) => {
                return StockActions.StocksPageLoadedSuccessfully({
                    productId: action.productId,
                    productStocks: stocks,
                });
            }),

            catchError((error) =>
            of(StockActions.StocksPageLoadFailed({ error }))
          )
        )
      )
    )
  );

  ProductStockCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockActions.ProductStockCreate),
      switchMap((action) =>
        this.stockService.addStock(action.stock).pipe(
          map((data) =>
            StockActions.ProductStockCreatedSuccessfully({ stock: data })
          ),
          catchError((error) =>
            of(StockActions.ProductStockCreationFailed({ error }))
          )
        )
      )
    )
  );

  ProductStockSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StockActions.ProductStockCreatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Le stock a été ajouté',
              MessageType.Create,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductStockCreationFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StockActions.ProductStockCreationFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Create,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductStockUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockActions.ProductStockUpdate),
      switchMap((action) =>
        this.stockService.update(action.stock).pipe(
          map((stock) =>
            StockActions.ProductStockUpdatedSuccessfully({ stock: stock })
          ),
          catchError((error) =>
            of(StockActions.ProductStockUpdateFailed({ error }))
          )
        )
      )
    )
  );

  ProductStockUpdatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StockActions.ProductStockUpdatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Le stock a été modifié',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductStockUpdateFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StockActions.ProductStockUpdateFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductStockDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockActions.ProductStockDelete),
      switchMap((action) =>
        this.stockService.delete(action.stockId).pipe(
          map(() =>
            StockActions.ProductStockDeletedSuccessfully({
              stockId: action.stockId,
            })
          ),
          catchError((error) =>
            of(StockActions.ProductStockDeleteFailed({ error }))
          )
        )
      )
    )
  );

  ProductStockDeletedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StockActions.ProductStockDeletedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Le stock a été supprimé',
              MessageType.Delete
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductStockDeleteFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StockActions.ProductStockDeleteFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Delete
            )
          )
        )
      ),
    { dispatch: false }
  );
}
