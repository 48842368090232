import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { ProductModel } from '../../../../../../core/e-commerce/products/product.model';
import { PromotionModel } from '../../../../../../core/e-commerce/promotions/promotion.model';
import { PromotionsService } from '../../../../../../core/e-commerce/promotions/promotions.service';
import { ProductsService } from '../../../../../../core/e-commerce/products/products.service';
import { QueryParamsModel } from '../../../../../../core/_base/crud';

@Component({
  selector: 'kt-discount-product-dialog',
  templateUrl: './promotion-product-dialog.component.html',
  styleUrls: ['./promotion-product-dialog.component.scss'],
})
export class PromotionProductDialogComponent implements OnInit {
  suggestedProducts: ProductModel[] = [];
  product = new FormControl();
  private newPromotionProduct: any;

  constructor(
    public dialogRef: MatDialogRef<PromotionProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PromotionModel,
    private promotionService: PromotionsService,
    private productService: ProductsService
  ) {}

  ngOnInit(): void {
    this.getPromotionProducts();
    this.product.valueChanges.subscribe((value) => {
      const queryParams = new QueryParamsModel(
        { query: value },
        null,
        null,
        0,
        10
      );
      this.productService
        .getAllPaged2(queryParams)
        .subscribe((products) => (this.suggestedProducts = products.content));
    });
  }

  selectProduct(event: any) {
    this.newPromotionProduct = {
      promotionId: this.data.id,
      productId: this.suggestedProducts.find((p) => p.name === event).id,
    };
  }
  saveNewDiscountProduct() {
    this.promotionService
      .linkProductToPromotion(this.newPromotionProduct)
      .subscribe((res) => {
        this.getPromotionProducts();
      });
  }

  deleteProduct(item: ProductModel) {
    this.promotionService
      .unlinkProduct(this.data.id, item.id)
      .subscribe((res) => {
        this.getPromotionProducts();
      });
  }
  getPromotionProducts() {
    this.promotionService
      .getPromotionById(this.data.id)
      .subscribe((res) => (this.data = res));
  }
}
