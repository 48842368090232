import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IDiscountCompanyModel } from '../company-discount.model';

export const loadDiscountsCopmaniesSuccess = createAction(
    '[Discount Company API] Load Companies Discounts Success',
    props<{discountCompanies: IDiscountCompanyModel[]}>()
  );

export const loadDiscountsCopmaniesFailure = createAction(
    '[Discount Company API] Load Companies Discounts Fail',
    props<{error: any}>()
  );


export const createDiscountCompanySuccess = createAction(
    '[Discount Company API] Create Discount Company Success',
    props<{discountCompany: IDiscountCompanyModel}>()
  );


export const createDiscountCompanyFailure = createAction(
    '[Discount Company API] Create Discount Company Fail',
    props<{error: any}>()
  );

export const updateDiscountCompanySuccess = createAction(
    '[Discount Company API] Update Discount Company Success',
    props<{discountCompany: Update<IDiscountCompanyModel>}>()
  );


export const updateDiscountCompanyFailure = createAction(
    '[Discount Company API] Update Discount Company Fail',
    props<{error: any}>()
  );


export const deleteDiscountCompanySuccess = createAction(
    '[Discount Company API] Delete Discount Company Success',
    props<{discountCompanyId: number}>()
  );


export const deleteDiscountCompanyFailure = createAction(
    '[Discount Company API] Delete Discount Company Fail',
    props<{error: any}>()
  );