import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as SupplierActions from './supplier.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SupplierService } from './supplier.service';
import { LayoutUtilsService, MessageType } from '../../_base/crud';

@Injectable()
export class SupplierEffects {
  constructor(
    private actions$: Actions,
    private supplierService: SupplierService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  SuppliersPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierActions.SuppliersPageRequested),
      switchMap((action) =>
        this.supplierService
          .getAll(
            action.page.filter ?? {},
            action.page.pageNumber,
            action.page.pageSize
          )
          .pipe(
            map((suppliersPage) =>
              SupplierActions.SuppliersPageLoadedSuccessfully({
                supplier: suppliersPage.content,
                page: suppliersPage.totalElements,
                totalCount: suppliersPage.totalElements,
              })
            ),
            catchError((error) =>
              of(SupplierActions.SuppliersPageLoadFailed({ error }))
            )
          )
      )
    )
  );

    SuppliersListeRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SupplierActions.SuppliersListRequested),
            switchMap((action) =>
                this.supplierService
                    .getAllList()
                    .pipe(
                        map((suppliersList) =>
                            SupplierActions.SuppliersListRequestedSuccessfully({
                                suppliers: suppliersList,
                            })
                        ),
                        catchError((error) =>
                            of(SupplierActions.SuppliersPageLoadFailed({ error }))
                        )
                    )
            )
        )
    );
  UpdateSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierActions.UpdateSupplier),
      switchMap((action) =>
        this.supplierService.updateSupplier(action.supplier).pipe(
          map((Supplier) =>
            SupplierActions.SupplierUpdatedSuccessfully({ supplier: Supplier })
          ),
          catchError((error) =>
            of(SupplierActions.SupplierUpdateFailed({ error }))
          )
        )
      )
    )
  );

  DeleteSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierActions.DeleteSupplier),
      switchMap((action) =>
        this.supplierService.deleteSupplier(action.supplierId).pipe(
          map((Supplier) =>
            SupplierActions.SupplierDeletedSuccessfully({
              supplierId: action.supplierId,
            })
          ),
          catchError((error) =>
            of(SupplierActions.SupplierDeletionFailed({ error }))
          )
        )
      )
    )
  );

  SupplierDeletedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SupplierActions.SupplierDeletedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Le fournisseur a été supprimé avec succès',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  SupplierDeletedFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SupplierActions.SupplierDeletionFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Suppression impossible, un ou plusieurs produits appartiennent à ce fournisseur! ',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  CreateSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierActions.CreateSupplier),
      switchMap((action) =>
        this.supplierService.addSupplier(action.supplier).pipe(
          map((Supplier) =>
            SupplierActions.SupplierCreatedSuccessfully({ supplier: Supplier })
          ),
          catchError((error) =>
            of(SupplierActions.SupplierCreationFailed({ error }))
          )
        )
      )
    )
  );
  ChangeSupplierStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SupplierActions.ChangeSupplierStatus),
      switchMap((action) =>
        this.supplierService.updateSupplier(action.supplier).pipe(
          map((supplier) => {
            return SupplierActions.SupplierStatusChangedSuccessfully({
              supplier,
              partialSupplier : action.partialSupplier
            });
          }),
          catchError((error) =>
            of(SupplierActions.SupplierStatusChangeFailed({ error }))
          )
        )
      )
    )
  );
}
