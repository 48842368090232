import {HttpClient} from '@angular/common/http';
import {ADMIN_CONFIG} from '../../../../environments/environment';
import {AbstractService} from '../../services/abstract-service.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OutstandingModel} from './outstanding.model';
import {AddOutstandingModel} from './addOutstanding.model';


@Injectable()
export class OutstandingService extends AbstractService<OutstandingModel> {
    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}outstanding`;
    }

    public addNewOutstanding(entity: AddOutstandingModel): Observable<OutstandingModel> {
        return this.http.post<OutstandingModel>(this.url + '/create', entity);
    }

    public updateOutstanding(payload: AddOutstandingModel): Observable<OutstandingModel> {
        const url = `${this.url}/update`;
        return this.http.put<OutstandingModel>(url, payload);
    }

    public renewOutstanding(payload: AddOutstandingModel): Observable<OutstandingModel> {
        const url = `${this.url}/renew`;
        return this.http.put<OutstandingModel>(url, payload);
    }

    getOutstandingByCompany(companyId: number) {
        const url = `${this.url}/company/${companyId}/find`;
        return this.http.get<OutstandingModel>(url);
    }

    getOutstandingByClient(clientId: number) {
        const url = `${this.url}/client/${clientId}`;
        return this.http.get<OutstandingModel>(url);
    }

    public deleteOutstanding(companyId: number, id: number): Observable<void> {
        const url = `${this.url}/${companyId}/${id}`;
        return this.http.delete<void>(url);
    }

}
