import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { LogsModel } from './logs.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { PromoCodeModel } from '../promo-code/promo-code.model';

@Injectable()
export class LogsService {
    url: string;

    constructor(protected http: HttpClient) {
        this.url = `${ADMIN_CONFIG.apiEndpoint}odoo`;
    }

    public getLogs(
        filter: any,
        page: number = 0,
        size: number = 150
    ): Observable<Page<LogsModel>> {
        let params = new HttpParams();
        params = params.append('page', page.toString());
        params = params.append('size', size.toString());
        params = filter.operation ? params.append('operation', filter.operation ) : params;
        params = filter.action ? params.append('action', filter.action  ) : params;
        params = filter.model ? params.append('model',  filter.model ) : params;
        params = filter.status ? params.append('status',  filter.status ) : params;
        params = filter.startDate ? params.append('dateMin',  filter.startDate ) : params;
        params = filter.endDate ? params.append('dateMax',  filter.endDate ) : params;
        params = params.append('search', filter.query ? filter.query : '');

        const url = `${this.url}/logs`;
        const options = {
            params,
        };
        return this.http.get<Page<LogsModel>>(url, options);
    }
    public getLogsById(id: number): Observable<LogsModel> {
        const url = `${this.url}/logs/${id}`;
        return this.http.get<LogsModel>(url);
    }
}
