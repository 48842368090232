import { AfterViewInit, AfterViewChecked } from '@angular/core';
// Angular
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar } from '@angular/material';
// RXJS
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  skip,
  take,
  delay,
} from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// LODASH
import { each, find } from 'lodash';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';

// Services
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../core/_base/crud';
// Models
import { SubheaderService } from '../../../../core/_base/layout';
//Actions
import * as QuestionActions from '../../../../core/service-client/client-question/client-question.actions';
import { ClientQuestionModel } from '../../../../core/service-client/client-question/client-question.model';
import { ClientQuestionDatasource } from '../../../../core/service-client/client-question/client-question.datasource';
import { selectProductsPageLastQuery } from '../../../../core/e-commerce/products/product.selectors';
import { MatSortable, Sort } from '@angular/material/sort';

@Component({
  selector: 'kt-client-question',
  templateUrl: './client-question.component.html',
  styleUrls: ['./client-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientQuestionComponent implements OnInit, OnDestroy {
  dataSource: ClientQuestionDatasource;
  displayedColumns = [
    'select',
    'id',
    'lastName',
    'email',
    'companyName',
    'phone',
    'object',
    'message',
    'type',
    'Actions',
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = '';
  filterCondition = '';
  lastQuery: QueryParamsModel;
  // Selection
  selection = new SelectionModel<ClientQuestionModel>(true, []);
  questionsResult: ClientQuestionModel[] = [];
  // Subscriptions
  private subscriptions: Subscription[] = [];

  /**
   *
   * @param activatedRoute: ActivatedRoute
   * @param store: Store<AppState>
   * @param router: Router
   * @param layoutUtilsService: LayoutUtilsService
   * @param subheaderService: SubheaderService
   */

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef
  ) {}
  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */

  ngOnInit() {
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    this.paginator._changePageSize(25);
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadQuestionsList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadQuestionsList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Clients Questions');

    // Init DataSource
    this.dataSource = new ClientQuestionDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.questionsResult = res;
      });
    this.subscriptions.push(entitiesSubscription);
    const lastQuerySubscription = this.store
      .pipe(select(selectProductsPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    // Load last query from store
    this.subscriptions.push(lastQuerySubscription);

    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.id) {
          this.restoreState(this.lastQuery, +params.id);
        }

        // First load
        this.loadQuestionsList();
      }
    );
    this.subscriptions.push(routeSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  /**
   * Load questions list
   */

  loadQuestionsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.store.dispatch(
      // new QuestionsPageRequested({ page: queryParams }));
      QuestionActions.QuestionsPageRequested({ page: queryParams })
    );
    this.selection.clear();
  }
  /** FILTRATION */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    if (this.filterStatus && this.filterStatus.length > 0) {
      filter.status = +this.filterStatus;
    }

    if (this.filterCondition && this.filterCondition.length > 0) {
      filter.condition = +this.filterCondition;
    }

    filter.query = searchText;
    return filter;
  }

  /**
   * Restore state
   *
   * @param queryParams: QueryParamsModel
   * @param id: number
   */
  restoreState(queryParams: QueryParamsModel, id: number) {
    if (!queryParams.filter) {
      return;
    }

    if ('condition' in queryParams.filter) {
      this.filterCondition = queryParams.filter.condition.toString();
    }

    if ('status' in queryParams.filter) {
      this.filterStatus = queryParams.filter.status.toString();
    }

    if (queryParams.filter.model) {
      this.searchInput.nativeElement.value = queryParams.filter.model;
    }
  }

  fetchQuestions() {
    // tslint:disable-next-line:prefer-const
    // let messages = [];
    // this.selection.selected.forEach(elem => {
    // 	messages.push({
    // 		text: `${elem.manufacture} ${elem.model} ${elem.modelYear}`,
    // 		id: elem.VINCode,
    // 		status: elem.status
    // 	});
    // });
    // this.layoutUtilsService.fetchElements(messages);
  }

  getItemCssClassByType(status: string = ''): string {
    switch (status) {
      case 'OPEN':
        return 'success';
      case 'CLOSED':
        return 'danger';
      case 'IN_PROGRESS':
        return 'metal';
    }
    return '';
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.questionsResult.length;
    return numSelected === numRows;
  }
  editQuestion(id) {
    this.router.navigate(['/service-client/client-question/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Toggle selection
   */
  masterToggle() {
    if (this.selection.selected.length === this.questionsResult.length) {
      this.selection.clear();
    } else {
      this.questionsResult.forEach((row) => this.selection.select(row));
    }
  }
}
