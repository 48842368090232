/* tslint:disable:no-redundant-jsdoc jsdoc-format variable-name forin */
// noinspection ES6UnusedImports

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {select, Store} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {AppState} from '../../../../../../core/reducers';
import {KtDialogService, LayoutConfigService, SubheaderService, } from '../../../../../../core/_base/layout';
import {
    LayoutUtilsService,
    MessageType,
    QueryParamsModel,
    QueryResultsModel,
    TypesUtilsService,
} from '../../../../../../core/_base/crud';
import {ProductModel} from '../../../../../../core/e-commerce/products/product.model';
import {CategoryModel} from '../../../../../../core/e-commerce/categories/category.model';
import {selectLastCreatedProductId} from '../../../../../../core/e-commerce/products/product.selectors';
import {CategoryActions, ManufacturerActions, ProductActions, } from '../../../../../../core/e-commerce';
import {
    selectCategoriesInStore,
    selectCategoriesListInStore,
    selectErrorMessage,
} from '../../../../../../core/e-commerce/categories/category.selector';
import {ProductsService} from '../../../../../../core/e-commerce/products/products.service';
import {ManufacturerModel} from '../../../../../../core/e-commerce/manufacturers/manufacturer.model';
import {selectManufacturersInStore} from '../../../../../../core/e-commerce/manufacturers/manufacturer.selector';
import * as CharacteristicActions from '../../../../../../core/e-commerce/product-characteristic/product-characteristic.actions';
import {
    ProductCharacteristicListRequested
} from '../../../../../../core/e-commerce/product-characteristic/product-characteristic.actions';
import {
    ProductCharacteristicModel
} from '../../../../../../core/e-commerce/product-characteristic/product-characteristic.model';
import {ResourcesService} from '../../../../../../core/services/resources.service';
import {Observable, Subscription, timer} from 'rxjs';
import { count, debounceTime, distinctUntilChanged, map, pluck, shareReplay, skip, startWith, take } from 'rxjs/operators';
import {ProductResource} from '../../../../../../core/e-commerce/products/product-resources/product-resource';
import {
    ProductCharacteristicsService
} from '../../../../../../core/e-commerce/product-characteristic/product-characteristics.service';
import { CategoriesListRequested } from '../../../../../../core/e-commerce/categories/category.action';
import { CategoriesService } from '../../../../../../core/e-commerce/categories/categories.service';
import {DistinctSubscriber} from 'rxjs/internal/operators/distinct';
import { CountryModel } from '../../../../../../core/_models/country.model';
import { CountryService } from '../../../../../../core/services/country.service';
import { Actions, ofType } from '@ngrx/effects';
import { ProductCreationFailed } from '../../../../../../core/e-commerce/products/product.actions';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { CategoriesDatasource } from '../../../../../../core/e-commerce/categories/categories.datasource';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-product-edit',
    templateUrl: './product-edit.component.html',
    styleUrls: ['./product-edit.component.css'],
})
export class ProductEditComponent implements OnInit, OnDestroy {
    product: ProductModel;
    productId$: Observable<number>;
    oldProduct: ProductModel;
    secondLevelCategory: CategoryModel[] = [];

    selectedTab = 0;

    productForm: FormGroup;
    hasFormErrors = false;
    disable = false;
    // data
    categories: CategoryModel[];
    manufacturers: ManufacturerModel[];
    suggestedManufacturers: ManufacturerModel[];
    suggestedCategories: CategoryModel[] = [];
    parentCategories: CategoryModel[] = [];
    selectedManufacturer: ManufacturerModel;
    selectedCategory: CategoryModel;
    // filteredBrands: Observable<BrandModel[]>;
    suggestedProducts: ProductModel[] = [];
    searchedProductCharacteristics: any[] = [];
    // availableBrands: BrandModel[];
    searchedProductImages: string[];
    resourceToAdd: ProductResource;
    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */
    disabled = true;
    private componentSubscriptions: Subscription[] = [];
    // sticky portlet header margin
    private headerMargin: number;
    thirdLevelCategory: CategoryModel[] = [];
    filteredCategory: Observable<CategoryModel[]>;
    errorMessage: Observable<string>;
    filteredManufacturer: Observable<ManufacturerModel[]>;
    chategoryFind: any;
    categoriess: CategoryModel[];

    countries: CountryModel[];
    countries$: Observable<CountryModel[]> = this.countryService
      .countries()
      .pipe(take(1));
    manufacturFind: ManufacturerModel;
    statusCode: number;

    /**
     * Component constructor
     *
     * @param store: Store<AppState>
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param typesUtilsService: TypesUtilsService
     * @param productFB: FormBuilder
     * @param dialog: MatDialog
     * @param subheaderService: SubheaderService
     * @param layoutUtilsService: SubheaderService
     * @param layoutConfigService: LayoutConfigService
     * @param ktDialogService
     * @param productService
     * @param resourcesService
     * @param cdr: ChangeDetectorRef
     * @param categoryService
     * @param productCharacteristicsService
     * @param countryService
     * @param actions$
     */
    constructor(
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private typesUtilsService: TypesUtilsService,
        private productFB: FormBuilder,
        public  dialog: MatDialog,
        private subheaderService: SubheaderService,
        private layoutUtilsService: LayoutUtilsService,
        private layoutConfigService: LayoutConfigService,
        private ktDialogService: KtDialogService,
        private productService: ProductsService,
        private resourcesService: ResourcesService,
        private cdr: ChangeDetectorRef,
        private categoryService: CategoriesService,
        private productCharacteristicsService: ProductCharacteristicsService,
        private countryService: CountryService,
        private actions$: Actions,
    ) {
        
    }

    /**
     * On init
     */
    ngOnInit() {
        this.loadManufacturers();
        this.loadCategories();
        this.ktDialogService.show();
        this.activatedRoute.params.subscribe((params) => {            const id = params.id;
            if (id && id > 0) {
                this.productService.getById(id).subscribe((product) => {
                    this.ktDialogService.hide();
                    this.product = product;
                    this.updateCategory();
                    this.updateManufacturer();
                    this.oldProduct = Object.assign({}, product);
                    this.initForm();
                    this.cdr.detectChanges();
                    this.getAllProducts();
                    this.store.dispatch(ProductCharacteristicListRequested({productId: id}));
                });
            } else {
                this.ktDialogService.hide();
                this.product = new ProductModel();
                this.initForm();
                this.cdr.detectChanges();
            }
        });

        this.getActiveTab();


        // sticky portlet header
        window.onload = () => {
            const style = getComputedStyle(document.getElementById('kt_header'));
            this.headerMargin = parseInt(style.height, 0);
        };
        this.countries$.subscribe((countries) => {
            this.countries = countries;
            this.countries$ = this.productForm.get('origin').valueChanges.pipe(
              startWith(''),
              map((value) => {
                  return this.countries.filter((country) => {
                      return (
                        country.name.toLowerCase().includes(value.toLowerCase()) &&
                        country.name !== value
                      );
                  });
              })
            );
        });
    }

    initForm() {
        const replenishmentDate = this.product?.replenishmentDate
            ? new Date(this.product?.replenishmentDate)
            : null;
        this.productForm = this.productFB.group({
            name: [
                this.product?.name,
                [Validators.required, Validators.maxLength(255)],
            ],
            category: [
                this.selectedCategory ? this.selectedCategory?.name : null,
                Validators.required,
            ],
            manufacturer: [
                this.selectedManufacturer ? this.selectedManufacturer?.label : null,
                Validators.required,
            ],
            designation: [this.product?.designation],
            shortDescription: [this.product?.shortDescription, Validators.required],
            code: [this.product?.code],
            manufacturerReference: [
                this.product?.manufacturerReference , Validators.required],
            eancode: [this.product?.eancode ? this.product?.eancode : ''],
            status: [this.product?.status],
            remainder: [this.product?.id ? this.product?.remainder : false],
            replenishmentDate: [replenishmentDate],
            description: [this.product?.description, Validators.required],
            location: [this.product?.location],
            substitutionProduct: [
                this.product?.substitutionProduct
                    ? this.product?.substitutionProduct.name
                    : '',
            ],
            physical: [
                this.product?.physical !== undefined ? this.product?.physical : true,
            ],
            link: [this.product?.link],
            customsCode: [this.product?.customsCode,  Validators.maxLength(11) ],
            origin: [this.product?.origin],
            weight: [this.product?.weight ? this.product?.weight : '', Validators.maxLength(5)],
            source: [this.product?.source]

        });
        this.productForm.get('manufacturer').valueChanges.subscribe((value) => {
            this.suggestedManufacturers = this.manufacturers.filter((m) =>
                m.label.toLowerCase().includes(value?.toLowerCase())
            );
        });
        const c1 = this.categories.filter( c => !c.parentId && c.status);
        const c3 = this.categories.filter( c => c.parentId && c.status);

        this.secondLevelCategory = this.categories.filter(cc2 => c1.some(cc1 => cc2.parentId === cc1.id));

        this.thirdLevelCategory = c3.filter(cc3 => this.secondLevelCategory.some(cc2 => cc3.parentId === cc2.id));


        this.productForm.get('category').valueChanges.subscribe((value) => {
            if (value) {
            this.suggestedCategories = this.thirdLevelCategory.filter(
                (c) => c?.name.toLowerCase().includes(value?.toLowerCase()) && c.status === true
            );
        }});

        this.store
            .pipe(select(selectCategoriesInStore))
            .subscribe((response: QueryResultsModel) => {
                this.parentCategories = response.items.filter((c) => !c.parentId);
            });
    }
    filteredCategoryExecuted() {
        this.filteredCategory = this.productForm.get('category').valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value),
            map(name => name ? this._filterCategory(name.toString()) : this.thirdLevelCategory.slice())
          );
    }
    private _filterCategory(name: any): CategoryModel[] {

        const filterValue = name.toLowerCase();

        return this.thirdLevelCategory.filter(option => option.name.toLowerCase().includes(name?.toLowerCase()) && option.status === true);
    }
    getActiveTab() {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.tab) {
                this.selectedTab = params.tab;
            }
        });
    }

    selectedTabChange() {
        this.router.navigate([], {queryParams: {tab: this.selectedTab}});
        this.ngOnInit();
    }

    /**
     * Go back to the list
     *
     * @param id: any
     */
    goBack(id) {
        this.reset();
        const url = `/ecommerce/products?id=${id}`;
        this.router.navigateByUrl(
            url,
            {}
        );
    }

    goBackWithoutId() {
        this.reset();
        this.router.navigateByUrl(
            '/ecommerce/products',
            /* Removed unsupported properties by Angular migration: relativeTo. */ {}
        );
    }
    getCategoryId(categoryName: string): number {
        const category = this.thirdLevelCategory.filter(res => res.name === categoryName);
        if (category[0]) {
            return category[0].id;
        }
    }

    /**
     * Refresh product
     *
     * @param isNew: boolean
     * @param id: number
     */
    refreshProduct(isNew: boolean = false, id = 0) {
        this.disable = false;
        this.reset();
        let url = this.router.url;
        if (!isNew) {
            this.router.navigate([url], {relativeTo: this.activatedRoute});
            return;
        }
        url = `/ecommerce/products/edit/${id}`;
        this.router.navigateByUrl(
            url,
            {}
        ).then(value => {
            setTimeout(() => {
                location.reload();
            }, 300);
        }
        );
    }

    /**
     * Reset
     */
    reset() {
        this.product = Object.assign({}, this.oldProduct);
        this.initForm();
        this.hasFormErrors = false;
        this.productForm.markAsPristine();
        this.productForm.markAsUntouched();
        this.productForm.updateValueAndValidity();
        this.searchedProductCharacteristics = null;
        this.searchedProductImages = null;
    }

    /**
     * Save data
     *
     */
    onSubmit() {
        this.hasFormErrors = false;

        const controls = this.productForm.controls;
        /** check form */
        if (this.productForm.invalid) {
            Object.keys(controls).forEach((controlName) =>
                controls[controlName].markAsTouched()
            );
            this.hasFormErrors = true;
            this.selectedTab = 0;
            return;
        }

        // tslint:disable-next-line:prefer-const
        let editedProduct = this.prepareProduct();

        if (editedProduct.id > 0) {
            this.updateProduct(editedProduct);
            return;
        }
        if (this.searchedProductCharacteristics || this.searchedProductImages.length > 0) {
            this.addProduct(editedProduct, true);
            this.disable = true;
        } else {
            this.addProduct(editedProduct);
            this.disable = true;
        }
    }

    loadCategories(){
        const queryParams = new QueryParamsModel(
            {},
            'asc',
            'parentId',
            0,
            500
          );
        this.store.dispatch(
            CategoryActions.CategoriesOptPageRequested({
              page: queryParams,
            })
          );

        let dataSource = new CategoriesDatasource(this.store);
        const entitiesSubscription = dataSource.entitySubject
        .pipe(skip(0), distinctUntilChanged())
            .subscribe((res) => {
            this.categories = res
        });
    }

    selectManufacturer(event) {
        this.selectedManufacturer = this.manufacturers.find(
            (m) => m.label === event.option.value
        );
       /* this.productForm
            .get('manufacturer')
            .setValue(this.selectedManufacturer.label);*/
        if (this.productForm.controls.manufacturer.dirty) {
            this.productForm.controls.manufacturer.enable();
            this.manufacturers.find(value => {
                if (value.id === event.option.value) {
                    this.manufacturFind = value;
                    this.selectedManufacturer = this.manufacturFind;
                    this.productForm
                      .get('manufacturer')
                      .setValue(this.selectedManufacturer.label);

                    const controls = this.productForm.controls;
                    controls.manufacturer.setValue(this.manufacturFind.label);
                }
            });
        }

    }

    selectCategory(name: string) {
        this.selectedCategory = this.thirdLevelCategory.find((c) => c.name === name);
        this.productForm.get('category').setValue(this.selectedCategory.name);
    }

    /**
     * Returns object for saving
     */
    prepareProduct(): ProductModel {
        const controls = this.productForm.controls;
        controls.weight.setValue(controls.weight.value.replace(/,/g, '.'));
        // tslint:disable-next-line:variable-name
        const _product = new ProductModel();
        _product.id = this.product.id;
        _product.manufacturerId = this.selectedManufacturer.id;
        _product.name = controls.name.value;
        _product.categoryId = this.getCategoryId(controls.category.value);
        _product.replenishmentDate = controls.replenishmentDate.value;
        _product.shortDescription = controls.shortDescription.value;
        _product.location = controls.location.value;
        _product.eancode = controls.eancode.value;
        _product.manufacturerReference = controls.manufacturerReference.value;
       // _product.code = controls.code.value;
        _product.designation = controls.designation.value ? controls.designation.value : controls.name.value;
        _product.status = controls.status.value;
        _product.remainder = controls.remainder.value;
        _product.origin = controls.origin.value;
        _product.weight = controls.weight.value;
        _product.customsCode = controls.customsCode.value;
        _product.description = controls.description.value;
        _product.physical = controls.physical.value;
        _product.link = controls.physical.value || controls.link.value === '' ? null : controls.link.value;
        _product.source = controls.source.value;
        return _product;
    }

    /**
     * Add product
     *
     * @param _product: CompanyModel
     // tslint:disable-next-line:jsdoc-format
     // tslint:disable-next-line:no-redundant-jsdoc
     * @param searchedProduct
     */
    // tslint:disable-next-line:variable-name
    addProduct(_product: ProductModel, searchedProduct: boolean = false) {
        _product.productResources = [];
        _product.status = 'OUT_OF_STOCK';
        _product.visible = false;
        _product.code = '';
        this.store.dispatch(ProductActions.ProductCreated({product: _product}));
        this.actions$.pipe(ofType(ProductActions.ProductCreationFailed)).subscribe((data) => {
            if (data) {
                let Message: string;
                switch (data.error.status) {
                    case 409:
                        Message = 'Cette référence constructeur existe déjà !';
                        this.statusCode = 409;
                        break;
                    case 404:
                        Message = 'Ce nom de produit existe déjà ! ';
                        this.statusCode = 404;
                        break;
                    default:
                        Message = 'Une erreur est survenu';
                        break;
                }
                this.layoutUtilsService.showActionNotification(
                  Message,
                  MessageType.Create,
                  5000,
                  true,
                  true
                );
            }});
        this.actions$.pipe(ofType(ProductActions.ProductCreatedSuccessfully)).subscribe((data) => {
            this.statusCode = null;
            this.ngOnInit();
        });
        const storesub$ = this.store
            .pipe(
                // delay(1000),
                select(selectLastCreatedProductId)
            )
            .subscribe(
                (newId) => {
                    if (!newId) {
                        return;
                    }
                    if (searchedProduct) {
                        this.addSearchedImages(newId);
                        this.addSearchedCharacteristics(newId).finally(() => {
                            this.layoutUtilsService.showActionNotification(
                                'La récupération des caractéristique et des images terminé avec succés',
                                MessageType.Create,
                                5000,
                                true,
                                true
                            );
                            this.refreshProduct(true, newId);

                            });
                    }
                },
                () => {
                    this.layoutUtilsService.showActionNotification(
                        'Une erreur est survenue !',
                        MessageType.Delete,
                        5000,
                        true,
                        true
                    );
                }
            );

        this.componentSubscriptions.push(storesub$);
    }

    /**
     * Update product
     *
     * @param _product: ProductModel
     */
    updateProduct(_product: ProductModel) {
        const updateProduct: Update<ProductModel> = {
            id: _product.id,
            changes: _product,
        };

        this.store.dispatch(
            ProductActions.ProductUpdated({
                partialProduct: updateProduct,
                product: _product,
            })
        );
        this.actions$.pipe(ofType(ProductActions.ProductUpdateFailed)).subscribe((data) => {
            if (data) {
            let Message: string;
            switch (data.error.status) {
                case 409:
                    Message = 'Cette référence constructeur existe déjà !';
                    this.statusCode = 409;
                    break;
                case 404:
                    Message = 'Ce nom de produit existe déjà ! ';
                    this.statusCode = 404;
                    break;
                default:
                    Message = 'Une erreur est survenu';
                    break;
            }
            this.layoutUtilsService.showActionNotification(
              Message,
              MessageType.Create,
              5000,
              true,
              true
            );
        }});
        this.actions$.pipe(ofType(ProductActions.ProductUpdatedSuccessfully)).subscribe((data) => {
            this.statusCode = null;
            this.ngOnInit();
        });
        this.disable = false;
    }

    /**
     * Returns component title
     */
    getComponentTitle() {
        let result = 'Ajouter un nouveau produit';
        if (!this.product || !this.product.id) {
            return result;
        }

        result = `${this.product.name}`;
        return result;
    }

    /**
     * Close alert
     *
     * @param $event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }



    getAllProducts() {
        this.productForm
            .get('substitutionProduct')
            .valueChanges.subscribe((value) => {
            const queryParams = new QueryParamsModel(
                {query: value},
                null,
                null,
                0,
                10
            );
            this.productService
                .getAllPaged2(queryParams)
                .subscribe((products) => (this.suggestedProducts = products.content));
        });
    }

    addSubstitutionProduct() {
        const payload = {
            productToLinkId: this.product.id,
            id: this.suggestedProducts.find(
                (p) => p.name === this.productForm.get('substitutionProduct').value
            ).id,
        };
        this.productService.linkProductToSubstitution(payload).subscribe((res) => {
            this.product.substitutionProduct = res;
            this.disabled = true;
            this.layoutUtilsService.showActionNotification(
                'Produit ajouté avec succès',
                MessageType.Update
            );
        });
        if (this.productForm.dirty) {
            const editedProduct = this.prepareProduct();
            this.updateProduct(editedProduct);
        }
    }

    deleteSubstitutionProduct() {
        this.productService
            .unlinkProductToSubstitution(this.product.id)
            .subscribe(() => {
                this.productForm.controls.substitutionProduct.setValue('');
                this.product.substitutionProduct = null;
                this.layoutUtilsService.showActionNotification(
                    'Le produit a été supprimé avec succès',
                    MessageType.Update
                );
            });
    }


    addSearchedProduct(item: any) {
        this.reset();
        const characteristics:{
            key:string,
            value:string
        }[]=[]
        const parms = Object.keys(item);

        for(var parm of parms){
            if(parm!=="title" && parm !== "description" )
            characteristics.push({key:parm,value:item[parm]})
        }
        this.product = new ProductModel();
        this.product.manufacturerReference = item.productId;
        this.product.name = item.title;
        this.product.designation = item.title;
        this.product.description = item.description;
        this.product.location = item.location;
        this.product.eancode = item.EanCode;
        const brand = this.manufacturers.find((m) =>
            m.label.toLowerCase().includes(item.fabricant)
        );
        this.product.manufacturerId = brand?.id;
        this.productForm.get('manufacturer').setValue(brand?.label);
        this.selectedManufacturer = brand;
        this.searchedProductCharacteristics = characteristics;
        this.searchedProductImages = item.images;
        this.product.source = 'WIKEYS';

        const message = `Informations récupérés avec succès, merci de remplir le reste des champs!`;

        this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            true,
            true
        );
        this.initForm();
        this.cdr.detectChanges();
    }

    async addSearchedCharacteristics(productId: number) {
        // tslint:disable-next-line:forin
        for (const group in this.searchedProductCharacteristics) {
            const caracteristics: any = this.searchedProductCharacteristics[group];
            for (const car in caracteristics) {
                const characteristic: ProductCharacteristicModel = {
                    title: group,
                    label: car,
                    value: caracteristics[car],
                    id: null,
                    additionalInfo: null,
                };
                this.productCharacteristicsService.save(characteristic,  productId ).pipe().subscribe();
                await timer(300).pipe(take(1)).toPromise();
            }
        }
    }

    async addSearchedImages(productId: number) {
        for (const i of this.searchedProductImages) {
            this.resourcesService.addImageFromWikkeys(i, productId).pipe().subscribe(value => {
            });
        }
    }

    getParentCategory(parentId: number): string {
        let parentName = '';
        const cat = this.categories.find((c) => c.id === parentId && c.status === true);
        if (cat) {
            parentName = cat.name;
            if (cat.parentId) {
                const mainCategory = this.categories.find((c) => c.id === cat.parentId && c.status === true);
                if (mainCategory) {
                    parentName = mainCategory.name + ' > ' + parentName;
                }
            }
        }
        return parentName;
    }

    updateCategory() {
        this.selectedCategory = this.categories?.find(
            (c) => c.id === this.product?.categoryId
        );
        this.productForm?.get('category').setValue(this.selectedCategory?.name);
    }

    updateManufacturer() {
        this.selectedManufacturer = this.manufacturers?.find(
            (m) => m.id === this.product?.manufacturerId
        );
        this.productForm
            ?.get('manufacturer')
            .setValue(this.selectedManufacturer?.label);
    }


    private loadManufacturers() {
        this.store.dispatch(ManufacturerActions.ManufacturersListRequested());
        const manufacturers$ = this.store
            .select(selectManufacturersInStore)
            .subscribe((results) => {
                this.manufacturers = results.items;
                if (this.manufacturers.length > 0) {
                    this.updateManufacturer();
                }
            });
        this.componentSubscriptions.push(manufacturers$);
    }

    getCategorySelected(event) {
        this.productForm.get('category').setValue('');

        if (this.productForm.controls.category.dirty) {
            this.productForm.controls.category.enable();
            this.thirdLevelCategory.find(value => {
                if (value.id === event.option.value && value.status === true) {
                    this.chategoryFind = value;

                    const controls = this.productForm.controls;
                    controls.category.setValue(this.chategoryFind.name);
                }
            });
        }
    }

    filteredManufacturerExecuted() {
        this.filteredManufacturer = this.productForm.get('manufacturer').valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value),
            map(name => name ? this.filterManufacturer(name.toString()) : this.manufacturers.slice())
          );
    }
    private filterManufacturer(name: any): ManufacturerModel[] {

        const filterValue = name.toLowerCase();

        return this.manufacturers.filter(option => option.label.toLowerCase().includes(name?.toLowerCase()));
    }
    goToOdoo() {
        window.open(`https://imtech.dev.odoo.wissal-group.com/showproduct?reference=${this.product.manufacturerReference}`, '_blank');
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
    }

}
