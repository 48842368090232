import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { QueryParamsModel } from '../../../../../core/_base/crud';
import { MatDialog } from '@angular/material';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  ClaimModel,
  ClaimStatus,
} from '../../../../../core/service-client/claim/claim.model';
import { FormControl } from '@angular/forms';
import * as ClaimActions from '../../../../../core/service-client/claim/claim.actions';
import {
  selectClaimById,
  selectHasClaimsInStore,
} from '../../../../../core/service-client/claim/claim.selectors';
import { currentUser } from '../../../../../core/auth/_selectors/auth.selectors';
import { UserModel } from '../../../../../core/_models/user.model';
import { UserActions } from '../../../../../core/user-management';
import * as OrderActions from '../../../../../core/e-commerce/orders/orders.actions';
import { selectOrdersInStore } from '../../../../../core/e-commerce/orders/orders.selectors';
import { OrderModel } from '../../../../../core/e-commerce/orders/order.model';
import { ActivatedRoute } from '@angular/router';
import { ConversationService } from '../../../../../core/service-client/claim/conversation/conversation.service';
import { AdminListComponent } from '../admin-list/admin-list.component';
import {
  AttachmentModel,
  CommercialGestureStatus,
} from '../../../../../core/service-client/claim/conversation/conversation.model';
import { Observable, of, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'kt-claim-detail',
  templateUrl: './claim-detail.component.html',
  styleUrls: ['./claim-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimDetailComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
  };

  loadingClaim$: Observable<boolean> = of(true);
  loadingAdmin$: Observable<boolean> = of(true);

  status: string[] = [];
  statusEnum = ClaimStatus;
  formStatus = new FormControl();
  formMessage = new FormControl();
  gestureEnum = CommercialGestureStatus;

  admin: UserModel;
  claim: ClaimModel;

  orders: OrderModel[] = [];
  admins: UserModel[] = [];
  attachments: File[] = [];
  subscriptions: Subscription[] = [];
  OrderId: number = 0
  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private conversationService: ConversationService,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    public dialog: MatDialog
  ) {
    this.loadOrderList();
    this.loadAdminList();
    this.loadClaimList();
  }

  ngOnInit() {
    const selectHasClaimsSubscription = this.store
      .pipe(select(selectHasClaimsInStore))
      .subscribe((count) => {
        const id: number = this.activatedRoute.snapshot.params.id;
        if (!count) {
          this.store.dispatch(ClaimActions.ClaimRequest({ claimId: id }));
        }

        const selectClaimSubscription = this.store
          .pipe(select(selectClaimById(id)))
          .subscribe((value2) => {
            if(value2){
              this.claim = value2;
              this.OrderId = value2.orderId
              this.loadingClaim$ = of(false);
              if (this.claim) {
                this.claim?.conversations.sort((a, b) => a.id - b.id);
              }

              this.status = Object.keys(ClaimStatus)
                .filter((key) => typeof ClaimStatus[key as any] === 'string')
                .map((label) => ClaimStatus[label]);
              this.formStatus.setValue(this.claim?.status);

              this.cdr.detectChanges();
            }
          });
        this.subscriptions.push(selectClaimSubscription);
      });

    const selectOrdersSubscription = this.store
      .select(selectOrdersInStore)
      .subscribe((value) => {
        this.orders = value.items;
      });

    const selectCurrentUserSubscription = this.store
      .select(currentUser)
      .subscribe((value) => {
        this.admin = value;
        this.loadingAdmin$ = of(false);
        this.cdr.detectChanges();
      });

    this.subscriptions.push(
      selectHasClaimsSubscription,
      selectOrdersSubscription,
      selectCurrentUserSubscription
    );
  }

  affectAdmin() {
    this.dialog.open(AdminListComponent, {
      width: '400px',
      data: this.activatedRoute.snapshot.params.id,
    });
  }

  uploadAttachments(files) {
    for (let i = 0; i < files.target.files.length; i++) {
      if (
        !this.attachments
          .map(({ name }) => name)
          .includes(files.target.files[i].name)
      ) {
        this.attachments.push(files.target.files[i]);
      }
    }
  }

  changeClaimStatus(status: ClaimStatus) {
    this.formStatus.setValue(status);
    this.claim.status = status;
    this.store.dispatch(ClaimActions.ClaimStatusChange({ claim: this.claim }));
  }

  loadOrderList() {
    const queryParams = new QueryParamsModel({});
    this.store.dispatch(
      OrderActions.OrdersPageRequested({ page: queryParams })
    );
  }

  loadAdminList() {
    this.store.dispatch(UserActions.AllUsersRequested());
  }

  loadClaimList() {
    const queryParams = new QueryParamsModel({});
    this.store.dispatch(ClaimActions.ClaimPageRequest({ queryParams }));
  }

  getOrder(id: number) {
    return this.orders.find((order) => order.id === id)?.number;
  }

  sharedAttachments(): AttachmentModel[] {
    return [].concat.apply(
      [],
      this.claim?.conversations.map(({ attachments }) => attachments)
    );
  }

  sendMessage(gesture: boolean) {
    const sendConversationSubscription = this.conversationService
      .sendMessage(
        this.claim.id,
        this.formMessage.value,
        gesture,
        this.attachments
      )
      .pipe()
      .subscribe((value) => {
        this.formMessage.reset();
        this.claim.conversations.push(value);
        this.cdr.detectChanges();
      });

    this.subscriptions.push(sendConversationSubscription);
  }

  changeCommercialGestureStatus(
    conversationId: number,
    status: CommercialGestureStatus
  ) {
    const changeCommercialGestureStatusSubscription = this.conversationService
      .changeCommercialGestureStatus(conversationId, status)
      .pipe()
      .subscribe((value) => {
        const id = this.claim.conversations.findIndex(
          (conversation) => conversation.id === conversationId
        );
        this.claim.conversations[id].gesture = value;
        this.cdr.detectChanges();
      });

    this.subscriptions.push(changeCommercialGestureStatusSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
