import { CountryModel } from '../../_models/country.model';

export class AddressModel {
  id: number;
  city: string;
  country: CountryModel;
  streetName: string;
  additionalInfo: string;
  postalCode: string;
  cedex: string;
  type: string;
  // For AddressBook
  contactName?: string;
  contactPhone?: string;
  AddressTitle?: string;
  isDefault?: boolean;
}
