import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  skip,
  delay,
} from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { SubheaderService } from '../../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../core/_base/crud';

import * as CompanyActions from '../../../../../../core/e-commerce/companies/company.actions';
import { CompanyModel } from '../../../../../../core/e-commerce/companies/company.model';
import { selectCompaniesPageLastQuery } from '../../../../../../core/e-commerce/companies/company.selectors';
import { CompaniesDatasource } from '../../../../../../core/e-commerce/companies/companies.datasource';
import { FormControl } from '@angular/forms';
import { CompaniesService } from '../../../../../../core/e-commerce/companies/companies.service';
import { CompanyOptModel } from '../../../../../../core/e-commerce/companies/companyOpt.model';

@Component({
  selector: 'kt-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesListComponent implements OnInit, OnDestroy {
  dataSource: CompaniesDatasource;
  displayedColumns = [
    'select',
    'id',
    'name',
    'accountNumber',
    'phone',
    'status',
    'activationStatus',
    'actions',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  filterStatus = new FormControl('');
  filterCondition = '';
  lastQuery: QueryParamsModel;

  selection = new SelectionModel<CompanyOptModel>(true, []);
  companiesResult: CompanyOptModel[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private companyService: CompaniesService
  ) {}

  ngOnInit() {



    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
		- when a pagination event occurs => this.paginator.page
		- when a sort event occurs => this.sort.sortChange
		**/
    // this.paginator._changePageSize(100);
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadCompaniesList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadCompaniesList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Companies');

    // Init DataSource
    this.dataSource = new CompaniesDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.companiesResult = res;
      });
    this.subscriptions.push(entitiesSubscription);
    const lastQuerySubscription = this.store
      .pipe(select(selectCompaniesPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    // Load last query from store
    this.subscriptions.push(lastQuerySubscription);

    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.id) {
          this.restoreState(this.lastQuery, +params.id);
        }

        // First load
        this.loadCompaniesList();
      }
    );
    this.subscriptions.push(routeSubscription);

    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  /**
   * Load Companies List
   */
  loadCompaniesList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );

    this.store.dispatch(
      CompanyActions.CompaniesPageRequested({ page: queryParams })
    );
    this.selection.clear();
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }

    // if (this.filterCondition && this.filterCondition.length > 0) {
    // 	filter.condition = +this.filterCondition;
    // }

    filter.query = searchText;
    return filter;
  }

  /**
   * Restore state
   *
   * @param queryParams: QueryParamsModel
   * @param id: number
   */
  restoreState(queryParams: QueryParamsModel, id: number) {
    if (!queryParams.filter) {
      return;
    }

    // if ('condition' in queryParams.filter) {
    // 	this.filterCondition = queryParams.filter.condition.toString();
    // }

    if ('status' in queryParams.filter) {
      this.filterStatus = queryParams.filter.status.toString();
    }

    if (queryParams.filter.model) {
      this.searchInput.nativeElement.value = queryParams.filter.model;
    }
  }

  /** ACTIONS */
  /**
   * Delete company
   *
   * @param _item: CompanyOptModel
   */
  deleteCompany(_item: CompanyModel) {
    const _title = 'Supprimer l\'entreprise';
    const _description = 'Voulez-vous vraiment supprimer cette entreprise ?';
    const _waitDesciption = 'Suppression en cours...';
    const _deleteMessage = `l'entreprise a été supprimée`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(
        CompanyActions.CompanyDeleted({ companyId: _item.id })
      );
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
    });
  }

  /**
   * Delete companies
   */
  deleteCompanies() {
    const _title = 'Companies Delete';
    const _description =
      'Are you sure to permanently delete selected companies?';
    const _waitDesciption = 'Companies are deleting...';
    const _deleteMessage = 'Selected companies have been deleted';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      const idsForDeletion: number[] = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.selection.selected.length; i++) {
        idsForDeletion.push(this.selection.selected[i].id);
      }
      // todo make this work

      // this.store.dispatch(new ManyCompaniesDeleted({ ids: idsForDeletion }));
      this.layoutUtilsService.showActionNotification(
        _deleteMessage,
        MessageType.Delete
      );
      this.selection.clear();
    });
  }

  /**
   * Fetch selected companies
   */
  fetchCompanies() {
    // tslint:disable-next-line:prefer-const
    // let messages = [];
    // this.selection.selected.forEach(elem => {
    // 	messages.push({
    // 		text: `${elem.manufacture} ${elem.model} ${elem.modelYear}`,
    // 		id: elem.VINCode,
    // 		status: elem.status
    // 	});
    // });
    // this.layoutUtilsService.fetchElements(messages);
  }

  /**
   * Update status dialog
   */
  updateStatusForCompanies() {}

  /**
   * Redirect to edit page
   *
   * @param id: any
   */
  editCompany(id) {
    this.router.navigate(['/ecommerce/companies/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }

  createCompany() {
    this.router.navigateByUrl('/ecommerce/companies/add');
  }

  /**
   * Check all rows are selected
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.companiesResult.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection
   */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.companiesResult.forEach((row) => this.selection.select(row));
    }
  }

  /* UI */
  /**
   * Returns status string
   *
   * @param status: number
   */
  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Selling';
      case 1:
        return 'Sold';
    }
    return '';
  }

  /**
   * Returns CSS Class by status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'prospect':
      case 'activate':
        return 'success';
      case 'outOfStock':
      case 'incomplete_information':
        return 'danger';
      case 'onCommand':
      case 'waiting':
        return 'metal';
    }
    return '';
  }

  /**
   * Returns CSS Class by condition
   *
   * @param condition: number
   */
  getItemCssClassByCondition(condition: number = 0): string {
    switch (condition) {
      case 0:
        return 'accent';
      case 1:
        return 'primary';
    }
    return '';
  }
}
