import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {OrderItemModel} from '../../../../../../../core/e-commerce/OrderItems/OrderItem.model';
import {AppState} from '../../../../../../../core/reducers';
import {LayoutUtilsService, MessageType, QueryParamsModel,} from '../../../../../../../core/_base/crud';
import {OrdersService} from '../../../../../../../core/e-commerce/orders/orders.service';
import {ProductResource} from '../../../../../../../core/e-commerce/products/product-resources/product-resource';
import {ProductModel} from '../../../../../../../core/e-commerce/products/product.model';
import {CurrencyMaskInputMode} from 'ngx-currency';
import {ProductsService} from '../../../../../../../core/e-commerce/products/products.service';
import {OrderModel} from '../../../../../../../core/e-commerce/orders/order.model';
import {finalize} from 'rxjs/operators';
import {StockService} from "../../../../../../../core/e-commerce/stocks/stock.service";

@Component({
    selector: 'orderlines-list',
    templateUrl: './orderlines.component.html',
})
export class OrderlinesComponent implements OnInit {
    @Input() order: OrderModel;
    @Output() orderItemsEmitter = new EventEmitter<Partial<OrderItemModel>[]>();

    @Input() orderItems: Partial<OrderItemModel>[] = [];

    dataSource: MatTableDataSource<Partial<OrderItemModel>> =
        new MatTableDataSource([]);

    displayedColumns = [
        'select',
        'id',
        'image',
        'productName',
        'quantity',
        'price',
    ];

    isSwitchedToEditMode = false;
    loadingAfterSubmit = false;

    formGroup: FormGroup;
    newOrderItem: OrderItemModel = new OrderItemModel();
    editProductLine = -1;
    newProductId: number;

    imageGallery: ProductResource[] = [];

    suggestedProducts: ProductModel[] = [];
    customCurrencyMaskConfig = {
        align: 'left',
        prefix: '',
        suffix: ' €',
        inputMode: CurrencyMaskInputMode.NATURAL,
    };
    _value = 0;
    color = '#2F4F4F';
    isDisabled = false;
    infinity = Infinity;
    valueControl: FormControl = new FormControl(1);
    private componentSubscriptions: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder,
        public dialog: MatDialog,
        private orderService: OrdersService,
        private productService: ProductsService,
        private layoutUtilsService: LayoutUtilsService,
        private stockService: StockService,
    ) {
    }

    _step = 1;

    @Input()
    set step(_step: number) {
        this._step = this.parseNumber(_step);
    }

    _min = 1;

    @Input()
    set min(_min: number) {
        this._min = this.parseNumber(_min);
    }

    _max = 0;

    @Input()
    set max(_max: number) {
        this._max = this.parseNumber(_max);
    }

    _wrap = false;

    @Input()
    set wrap(_wrap: boolean) {
        this._wrap = this.parseBoolean(_wrap);
    }

    @Input('value')
    set inputValue(_value: number) {
        this._value = this.parseNumber(_value);
    }

    setColor(color: string): void {
        this.color = color;
    }

    getColor(): string {
        return this.color;
    }

    incrementValue(step: number = 1): void {

        let inputValue = this._value + step;

        if (this._wrap) {
            inputValue = this.wrappedValue(inputValue);
        }

        this._value = inputValue;
    }

    shouldDisableDecrement(inputValue: number): boolean {
        return !this._wrap && inputValue <= this._min;
    }

    shouldDisableIncrement(inputValue: number): boolean {
        return !this._wrap && inputValue >= this._max;
    }

    ngOnInit() {
        if (this.isDisabled) {
            this.valueControl.disable();
        }

        this.valueControl.valueChanges.subscribe((value) => {
            if (value < this.min || value > this.max) {
                const clampedValue = Math.min(this.max, Math.max(this.min, value));
                this.valueControl.setValue(clampedValue);
            }
        });
        if (this.order) {
            this.dataSource.data = this.order.items;
        } else {
            this.dataSource.data = this.orderItems;
        }
        this.initFormGroup();
    }

    initFormGroup(_item = null) {
        this.formGroup = this.fb.group({
            quantity: [null, Validators.required],
            price: [null, Validators.required],
            product: [null, Validators.required],
        });

        this.formGroup.get('product').valueChanges.subscribe((value) => {
            const queryParams = new QueryParamsModel(
                {query: value},
                null,
                null,
                0,
                10
            );
            this.productService
                .getAllPaged2(queryParams)
                .subscribe((products) => (this.suggestedProducts = products.content));
        });
    }

    cancelAddProduct() {
        this.editProductLine = -1;
        this.formGroup.reset();
    }

    onEditItem(_item: OrderItemModel) {
        this.editProductLine = _item.id;
        this._value = _item.quantity;
        this.stockService.getStocks(_item.productId).subscribe(value => {
            let a = 0;
            value.forEach(value1 => {
                a = a + value1.quantity;
            });
            this._max = a + this._value;
        });
        this.formGroup.setValue({
            quantity: _item.quantity,
            price: _item.price,
            product: _item.product.id,
        });
    }

    saveUpdatedItem(_item: OrderItemModel) {
        const payload: OrderItemModel = {
            id: _item.id,
            productId: _item.productId,
            quantity: this.formGroup.get('quantity').value,
            orderId: this.order.id,
            price: null,
            itemTotalPrice: null,
        };

        this.loadingAfterSubmit = true;
        const subscription = this.orderService
            .updateOrderItem(payload)
            .pipe(
                finalize(() => {
                    this.editProductLine = -1;
                    this.formGroup.reset();
                    this.loadingAfterSubmit = false;
                })
            )
            .subscribe(
                (e) => {
                    this.dataSource.data.forEach((i) => {
                        if (i.id === e.id) {
                            i.quantity = e.quantity;
                            i.price = e.price;
                            i.itemTotalPrice = e.itemTotalPrice;
                        }
                    });
                    this.orderItems = this.dataSource.data;
                    this.layoutUtilsService.showActionNotification(
                        'Le produit a été modifié',
                        MessageType.Update
                    );
                    this.emitOrderItemsChanges();
                },
                (e) => {
                    if (e && e.status) {
                        this.layoutUtilsService.showActionNotification(
                            e.error.message,
                            MessageType.Update
                        );
                    }
                }
            );
        this.componentSubscriptions.push(subscription);
    }

    saveNewOrderItem() {
        if (this.order) {
            this.loadingAfterSubmit = true;
            const payload: OrderItemModel = {
                quantity: this.formGroup.get('quantity').value,
                productId: this.newProductId,
                orderId: this.order.id,
                id: null,
                itemTotalPrice: null,
                price: null,
            };

            const subscription = this.orderService
                .addOrderItem(payload)
                .pipe(
                    finalize(() => {
                        this.editProductLine = -1;
                        this.formGroup.reset();
                        this.loadingAfterSubmit = false;
                    })
                )
                .subscribe(
                    (e) => {
                        e.product = this.newOrderItem.product;
                        this.orderItems = this.dataSource.data.concat(e);
                        this.layoutUtilsService.showActionNotification(
                            'Produit ajouté avec succès',
                            MessageType.Create
                        );
                        this.emitOrderItemsChanges();
                    },
                    (e) => {
                        if (e && e.status) {
                            this.layoutUtilsService.showActionNotification(
                                e.error.message,
                                MessageType.Create
                            );
                        }
                    }
                );
            this.componentSubscriptions.push(subscription);
        } else {
            // on creating new order
            const item: Partial<OrderItemModel> = {
                quantity: this.formGroup.get('quantity').value,
                productId: this.newProductId,
                price: this.formGroup.get('price').value,
                product: this.newOrderItem.product,
                itemTotalPrice:
                    this.formGroup.get('quantity').value *
                    this.formGroup.get('price').value,
            };
            this.orderItems.push(item);
            this.emitOrderItemsChanges();
        }
    }

    emitOrderItemsChanges() {
        this.orderItemsEmitter.emit(this.orderItems);
        this.dataSource.data = this.orderItems;
        this.editProductLine = -1;
        this.formGroup.reset();
    }

    deleteOrderItem(_item: OrderItemModel) {
        const _title = 'Supprimer le produit';
        const _description =
            'Voulez-vous vraiment supprimer ce produit de la commande ?';
        const _waitDesciption = 'Suppression en cours...';

        const dialogRef = this.layoutUtilsService.deleteElement(
            _title,
            _description,
            _waitDesciption
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
            if (this.order) {
                const sub = this.orderService.deleteOrderItem(_item.id).subscribe(
                    () => {
                        this.orderItems = this.dataSource.data.filter(
                            (i) => i.id !== _item.id
                        );
                        this.layoutUtilsService.showActionNotification(
                            'Le produit a été supprimé avec succès',
                            MessageType.Delete
                        );
                        this.emitOrderItemsChanges();
                    },
                    () => {
                        this.layoutUtilsService.showActionNotification(
                            'Une erreur est survenue !',
                            MessageType.Delete
                        );
                    }
                );
                this.componentSubscriptions.push(sub);
            } else {
                // case new Order
                this.orderItems = this.orderItems.filter(
                    (i) => i.productId !== _item.productId
                );
                this.emitOrderItemsChanges();
            }
        });
    }

    selectProduct(event: any) {
        this.newOrderItem.product = this.suggestedProducts.find(
            (p) => p.name === event
        );
        if (
            (!this.order &&
                this.orderItems.find(
                    (value) => value.product.id === this.newOrderItem.product.id
                )) ||
            (this.order &&
                this.order.items.find(
                    (value) => value.productId === this.newOrderItem.product.id
                ))
        ) {
            this.formGroup.controls.product.setErrors({incorrect: true});
            this.layoutUtilsService.showActionNotification('Produit déja existe !');
        } else {
            this.stockService.getStocks(this.newOrderItem.product.id).subscribe(value => {
                let a = 0;
                value.forEach(value1 => {
                    a = a + value1.quantity;
                });
                this._max = a;
            });
            this.newProductId = this.newOrderItem.product.id;
            this.formGroup.get('price').setValue(this.newOrderItem.product.price);
        }
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
        }
    }

    private parseNumber(num: any): number {
        return +num;
    }

    private parseBoolean(bool: any): boolean {
        return !!bool;
    }

    private wrappedValue(inputValue): number {
        if (inputValue > this._max) {
            return this._min + inputValue - this._max;
        }

        if (inputValue < this._min) {

            if (this._max === Infinity) {
                return 0;
            }

            return this._max + inputValue;
        }

        return inputValue;
    }
}
