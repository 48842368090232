import { createAction, props } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { LogoModel } from './logo.model';

export const LogosPageRequested = createAction(
  '[Logo List Page] Logos Page Requested '
);
export const LogosPageLoadedSuccessfully = createAction(
  '[Logo API] Logos Page Loaded Successfully ',
  props<{ logos: LogoModel[] }>()
);
export const LogosPageLoadFailed = createAction(
  '[Logo API] Logos Page Request Failed ',
  props<{ error: any }>()
);

export const LogoCreated = createAction(
  '[Logo List Page] Logo Created ',
  props<{ logo: LogoModel }>()
);
export const LogoCreatedSuccessfully = createAction(
  '[Logo List Page] Logo Created Successfully ',
  props<{ logo: LogoModel }>()
);
export const LogoCreationFailed = createAction(
  '[Logo List Page] Logo Creation Failed ',
  props<{ error: any }>()
);

export const LogoDeleted = createAction(
  '[Logo List Page] Logo Deleted ',
  props<{ logoId: number }>()
);
export const LogoDeletedSuccessfully = createAction(
  '[Logo List Page] Logo deleted Successfully ',
  props<{ logoId: number }>()
);
export const LogoDeleteFailed = createAction(
  '[Logo List Page] Logo delete Failed ',
  props<{ error: any }>()
);
