import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {Store} from '@ngrx/store';

import {AppState} from '../../../../../../../../../core/reducers';
import {LayoutUtilsService, MessageType} from '../../../../../../../../../core/_base/crud';
import {ActivatedRoute} from '@angular/router';
import {OutstandingModel} from '../../../../../../../../../core/e-commerce/outstanding/outstanding.model';
import {OutstandingService} from '../../../../../../../../../core/e-commerce/outstanding/outstanding.service';
import {OutstandingEditDialogComponent} from '../oustanding-edit/outstanding-edit-dialog.component';
import {AddOutstandingModel} from '../../../../../../../../../core/e-commerce/outstanding/addOutstanding.model';
import {BehaviorSubject} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';


@Component({
    selector: 'kt-outstanding-list',
    templateUrl: './outstanding-list.component.html',
})

export class OutstandingListComponent implements OnInit, OnDestroy {

    displayedColumns = [
        'id',
        'outstandingValue',
        'outstandingLeft',
        'outstandingUsed',
        'createdAt',
        'endAt',
        'debt',
        'actions',
    ];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    companyId: number;
    dataSource$ = new BehaviorSubject<OutstandingModel[]>([]);
    outstanding: AddOutstandingModel;
    hasEncours: boolean;
    currentDate: string;
    expired: boolean;

    constructor(
        private store: Store<AppState>,
        public dialog: MatDialog,
        private layoutUtilsService: LayoutUtilsService,
        private route: ActivatedRoute,
        private outstandingService: OutstandingService,
        private snackBar: MatSnackBar,

    ) {
    }

    ngOnInit() {
        this.companyId = this.route.snapshot.params.id;
        this.currentDate = moment().format('YYYY MM DD');
        this.outstandingService.getOutstandingByCompany(this.companyId).subscribe(
            (res) => {
                if (res) {
                    const data: OutstandingModel[] = [];
                    data.push(res);
                    this.hasEncours = true;
                    const endDate = Date.parse(res?.endAt);
                    const currentDate = Date.parse(this.currentDate);
                    this.expired = endDate < currentDate;
                    return this.dataSource$.next(data);
                } else {
                    this.hasEncours = false;
                }
            }
        );
    }

    deleteOutstanding(item: OutstandingModel) {
        const title = 'Encours Delete';
        const description = 'Are you sure to permanently delete this Encours?';
        const waitDesciption = 'Encours is deleting...';
        const deleteMessage = `Encours has been deleted`;

        const dialogRef = this.layoutUtilsService.deleteElement(
            title,
            description,
            waitDesciption
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
            /*this.companiesService
              .deleteOutstanding(this.companyId, item.id)
              .subscribe((res) => {
                const afterDelete = this.encours$.value.filter(
                  (elem) => elem !== item
                );
                this.encours$.next(afterDelete);
                this.layoutUtilsService.showActionNotification(
                  deleteMessage,
                  MessageType.Delete
                );
                this.loadSpecsList();
              });*/
        });
    }

    addOutstanding() {
        /*if (this.hasEncours === true) {
            this.snackBar.open(
              ' Un seul encours actif à la fois peut être saisie! ',
              'Ok',
              {duration: 10000, panelClass: 'error-snackbar'}
            );

        } else {*/
            const newEncours = new AddOutstandingModel();
            const dialogRef = this.dialog.open(OutstandingEditDialogComponent, {
                data: { encours: null, companyId: this.companyId },
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (!res) {
                    return;
                } else {
                    newEncours.value = res.outstandingValue;
                    newEncours.companyId = this.companyId;
                    newEncours.period = res.period;
                    this.ngOnInit();
                    this.hasEncours = true;
                    this.expired = false;

                    const saveMessage = `L'Encours créé avec succès`;
                    this.layoutUtilsService.showActionNotification(
                      saveMessage,
                      MessageType.Create,
                      10000,
                      true,
                      true
                    );
                    const data: OutstandingModel[] = [];
                    data.push(res);
                    this.dataSource$.next(data);
                }
            });
        // }
    }

    /**
     * Edit add spec dialog ans save data
     *
     * @param item: OutstandingModel
     */
    editOutstanding(item: OutstandingModel) {
        const items = Object.assign({}, item);
        const add = new AddOutstandingModel();
        add.companyId = this.companyId;
        add.value = item.outstandingValue;
        add.period = 30;
        const dialogRef = this.dialog.open(OutstandingEditDialogComponent, {
            data: {outstanding: add, companyId: this.companyId},
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                const newEncoursList = this.dataSource$.value.map(
                    (obj: OutstandingModel) => [res].find((o) => o.id === obj.id) || obj
                );
                this.dataSource$.next(newEncoursList);
                const saveMessage = `Encours modifier avec succès`;
                this.layoutUtilsService.showActionNotification(
                    saveMessage,
                    MessageType.Update,
                    10000,
                    true,
                    true
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.dataSource$.next(null);
    }
}
