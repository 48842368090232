// Angular
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
  tap,
} from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
// NGRX
import { Store } from '@ngrx/store';
import { CategoryCharacteristicDatasource } from '../../../../../../../core/e-commerce/category-characteristic/category-characteristic.datasource';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../../core/_base/crud';
import { CategoryCharacteristicModel } from '../../../../../../../core/e-commerce/category-characteristic/category-characteristic.model';
import { SubheaderService } from '../../../../../../../core/_base/layout';
import { AppState } from '../../../../../../../core/reducers';
//Actions
import * as CharacteristicActions from '../../../../../../../core/e-commerce/category-characteristic/category-characteristic.actions';
import {
  selectCCharError,
  selectCCharErrorMessage,
} from '../../../../../../../core/e-commerce/category-characteristic/category-characteristic.selectors';
import {
  selectError,
  selectErrorMessage,
  selectlastAction,
} from '../../../../../../../core/e-commerce/categories/category.selector';
import { TranslateService } from '@ngx-translate/core';
// UI
// CRUD
// Services and Models

// Table with EDIT item in new page
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'kt-catergory-characteristic-list',
  templateUrl: './category-characteristic-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryCharacteristicListComponent implements OnInit, OnDestroy {
  // Table fields
  dataSource: CategoryCharacteristicDatasource;
  displayedColumns = ['select', 'id', 'label', 'valueType', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = '';
  filterCondition = '';
  lastQuery: QueryParamsModel;
  //private
  categoryId: number;
  // Selection
  selection = new SelectionModel<CategoryCharacteristicModel>(true, []);
  characteristicResults: CategoryCharacteristicModel[] = [];
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param dialog: MatDialog
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param subheaderService: SubheaderService
   * @param layoutUtilsService: LayoutUtilsService
   * @param store: Store<AppState>
   */
  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    const routesubscription = this.activatedRoute.params.subscribe((res) => {
      this.categoryId = res.id;
    });
    this.subscriptions.push(routesubscription);

    //todo change the way of getting the error message using operators
    const error$ = this.store
      .select(selectCCharError)
      .pipe(
        filter((result) => result !== null),
        skip(1)
      )
      .subscribe(() => {
        const errorMessageSub = this.store
          .select(selectCCharErrorMessage)
          .subscribe((res) => {
            this.layoutUtilsService.showActionNotification(
              this.translate.instant('ECOMMERCE.CATEGORIES.' + res),
              MessageType.Delete,
              5000,
              true,
              false
            );
          });
        this.subscriptions.push(errorMessageSub);
      });
    this.subscriptions.push(error$);
    //subscribing to the seccessOperations
    const operation$ = this.store
      .select(selectlastAction)
      .pipe(filter((result) => result !== ''))
      .subscribe((result) => {
        this.layoutUtilsService.showActionNotification(
          this.translate.instant('ECOMMERCE.CATEGORIES.' + result),
          MessageType.Delete,
          10000,
          true,
          false
        );
      });
    this.subscriptions.push(operation$);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadCharacteristicsList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Products');

    // Init DataSource
    this.dataSource = new CategoryCharacteristicDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.characteristicResults = res;
      });
    this.subscriptions.push(entitiesSubscription);

    //todo last query not sure what it does !!!!!!!!!!
    // const lastQuerySubscription = this.store.pipe(select(selectCategoryCharacteristicLa)).subscribe(res => this.lastQuery = res);
    // Load last query from store
    // this.subscriptions.push(lastQuerySubscription);

    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.id) {
          this.restoreState(this.lastQuery, +params.id);
        }

        // First load
        this.loadCharacteristicsList();
      }
    );
    this.subscriptions.push(routeSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  /**
   * Load Characteristic List
   */
  loadCharacteristicsList() {
    //todo the paging params arent used
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration()
      // this.sort.direction,
      // this.sort.active,
      // this.paginator.pageIndex,
      // this.paginator.pageSize
    );
    // Call request from server
    this.store.dispatch(
      CharacteristicActions.CategoryCharacteristicListRequested({
        categoryId: this.categoryId,
      })
    );
    this.selection.clear();
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    if (this.filterStatus && this.filterStatus.length > 0) {
      filter.status = +this.filterStatus;
    }

    if (this.filterCondition && this.filterCondition.length > 0) {
      filter.condition = +this.filterCondition;
    }

    filter.query = searchText;
    return filter;
  }

  /**
   * Restore state
   *
   * @param queryParams: QueryParamsModel
   * @param id: number
   */
  restoreState(queryParams: QueryParamsModel, id: number) {
    if (!queryParams.filter) {
      return;
    }

    if ('condition' in queryParams.filter) {
      this.filterCondition = queryParams.filter.condition.toString();
    }

    if ('status' in queryParams.filter) {
      this.filterStatus = queryParams.filter.status.toString();
    }

    if (queryParams.filter.model) {
      this.searchInput.nativeElement.value = queryParams.filter.model;
    }
  }

  /** ACTIONS */
  /**
   * Delete characteristic
   *
   * @param _item: ProductCharacteristicModel
   */
  deleteCharacteristic(_item: CategoryCharacteristicModel) {
    const _title = 'Characteristic Delete';
    const _description =
      'Are you sure to permanently delete this characteristic?';
    const _waitDesciption = 'Characteristic is deleting...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      this.store.dispatch(
        CharacteristicActions.CategoryCharacteristicDeleted({
          categoryId: this.categoryId,
          id: _item.id,
        })
      );
    });
  }

  // /**
  //  * Delete characteristic
  //  */
  // deleteProducts() {
  // 	const _title = 'Products Delete';
  // 	const _description = 'Are you sure to permanently delete selected products?';
  // 	const _waitDesciption = 'Products are deleting...';
  // 	const _deleteMessage = 'Selected products have been deleted';
  //
  // 	const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  // 	dialogRef.afterClosed().subscribe(res => {
  // 		if (!res) {
  // 			return;
  // 		}
  //
  // 		const idsForDeletion: number[] = [];
  // 		// tslint:disable-next-line:prefer-for-of
  // 		for (let i = 0; i < this.selection.selected.length; i++) {
  // 			idsForDeletion.push(this.selection.selected[i].id);
  // 		}
  // 		//todo make this work
  //
  // 		// this.store.dispatch(new ManyProductsDeleted({ ids: idsForDeletion }));
  // 		this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
  // 		this.selection.clear();
  // 	});
  // }

  /**
   * Fetch selected products
   */
  fetchProducts() {
    // tslint:disable-next-line:prefer-const
    // let messages = [];
    // this.selection.selected.forEach(elem => {
    // 	messages.push({
    // 		text: `${elem.manufacture} ${elem.model} ${elem.modelYear}`,
    // 		id: elem.VINCode,
    // 		status: elem.status
    // 	});
    // });
    // this.layoutUtilsService.fetchElements(messages);
  }

  /**
   * Redirect to edit page
   *
   * @param id: any
   */
  editCharacteristic(id) {
    this.router.navigateByUrl(
      '/ecommerce/categories/edit/' +
        this.categoryId +
        '/characteristics/edit/' +
        id
    );
  }

  createCharacteristic() {
    console.log('categoryId ' + this.categoryId);
    this.router.navigateByUrl(
      '/ecommerce/categories/edit/' + this.categoryId + '/characteristics/add'
    );
  }

  /**
   * Check all rows are selected
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.characteristicResults.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection
   */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.characteristicResults.forEach((row) => this.selection.select(row));
    }
  }

  /* UI */
  /**
   * Returns status string
   *
   * @param status: number
   */
  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Selling';
      case 1:
        return 'Sold';
    }
    return '';
  }

  /**
   * Returns CSS Class by status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'inStock':
        return 'success';
      case 'outOfStock':
        return 'danger';
      case 'onCommand':
        return 'metal';
    }
    return '';
  }

  /**
   * Returns CSS Class by condition
   *
   * @param condition: number
   */
  getItemCssClassByCondition(condition: number = 0): string {
    switch (condition) {
      case 0:
        return 'accent';
      case 1:
        return 'primary';
    }
    return '';
  }
}
