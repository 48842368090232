import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ClientfunctionModel } from './clientfunction.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
//Actions
import * as ClientFunctionActions from './clientfunction.actions';

export interface ClientFunctionState extends EntityState<ClientfunctionModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedClientFunctionId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  lastAction: string;
}

export const adapter: EntityAdapter<ClientfunctionModel> =
  createEntityAdapter<ClientfunctionModel>();

export const initialClientFunctionState: ClientFunctionState =
  adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedClientFunctionId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
  });

const clientfunctionReducer = createReducer(
  initialClientFunctionState,
  on(ClientFunctionActions.ClientFunctionsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),
  on(
    ClientFunctionActions.ClientFunctionsPageLoadedSuccessfully,
    (state, action) =>
      adapter.addAll(action.clientfunctions, {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        totalCount: action.totalCount,
      })
  ),
  on(ClientFunctionActions.ClientFunctionsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //creation
  on(ClientFunctionActions.ClientFunctionCreated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ClientFunctionActions.ClientFunctionCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.clientfunction, {
      ...state,
      actionsLoading: true,
      lastCreatedClientFunctionId: action.clientfunction.id,
      lastAction: 'EDIT.ADD_MESSAGE',
    })
  ),
  on(ClientFunctionActions.ClientFunctionCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  //Update
  on(ClientFunctionActions.ClientFunctionUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ClientFunctionActions.ClientFunctionUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialClientFunction, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(ClientFunctionActions.ClientFunctionUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  //Delete
  on(ClientFunctionActions.ClientFunctionDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ClientFunctionActions.ClientFunctionDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.clientfunctionId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(ClientFunctionActions.ClientFunctionDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  })),
  //ClientFunction Resource Delete
  on(ClientFunctionActions.ClientFunctionResourceDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(
    ClientFunctionActions.ClientFunctionResourceDeletedSuccessfully,
    (state, action) => ({
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(
    ClientFunctionActions.ClientFunctionResourceDeleteFailed,
    (state, action) => ({
      ...state,
      error: action.error,
      actionsLoading: false,
    })
  )
);

export function clientfunctionsReducer(
  state: ClientFunctionState | undefined,
  action: Action
) {
  return clientfunctionReducer(state, action);
}
