import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';

import { CategoryModel } from './category.model';
import { ActivatedRoute } from '@angular/router';
import {CategoryOptModel} from './categoryOpt.model';

@Injectable()
export class CategoriesService extends AbstractService<CategoryModel> {
  url: string;
  productId: number;
  constructor(
    protected http: HttpClient,
    private route: ActivatedRoute,
    ) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}category`;
  }

  public getCategories(
    filter: any,
    page: number = 0,
    size: number = 300
  ): Observable<Page<CategoryModel>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = params.append('search', filter.query ? filter.query : '');
    params = filter.type ? params.append('type', filter.type) : params;
    params = filter.status ? params.append('active', filter.status) : params;
    const options = { params };
    const url = `${this.url}/page`;
    return this.http.get<Page<CategoryModel>>(url, options);
  }

  public getCategoriesOpt(
      filter: any,
      page: number = 0,
      size: number = 300
  ): Observable<Page<CategoryOptModel>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = params.append('search', filter.query ? filter.query : '');
    params = filter.type ? params.append('type', filter.type) : params;
    params = filter.status ? params.append('active', filter.status) : params;
    const options = { params };
    const url = `${this.url}/page`;
    return this.http.get<Page<CategoryOptModel>>(url, options);
  }

  public allCategories(): Observable<CategoryModel[]> {
    return this.http.get<CategoryModel[]>(this.url);
  }
  public findPosition(id: number): Observable<number[]> {
    const url = `${this.url}/position/${id}`;
    return this.http.get<number[]>(url);
  }
  public updatePosition(categoryId: number , position: number ): Observable<any> {
    const url = `${this.url}/position/${categoryId}`;
    return this.http.put<any>(url, position);
  }
  public updateStatus(category: CategoryModel ): Observable<any> {
    const url = `${this.url}/Status`;
    return this.http.put<any>(url, category);
  }
  public getChildrenById(id: number): Observable<CategoryModel> {
    const url = `${this.url}/${id}/hierarchical`;
    return this.http.get<CategoryModel>(url);
  }
  public deleteCategory(categoryId: number) {
    const url = `${this.url}/delete/${categoryId}`;
    return this.http.delete<void>(url);
  }

}
