import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SupplierState } from './supplier.reducers';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { SupplierModel } from './supplier.model';

export const selectSuppliersState =
  createFeatureSelector<SupplierState>('suppliers');

export const selectSupplierById = (SupplierId: number) =>
  createSelector(
    selectSuppliersState,
    (SuppliersState) => SuppliersState.entities[SupplierId]
  );

export const selectSuppliersPageLoading = createSelector(
  selectSuppliersState,
  (SuppliersState) => SuppliersState.listLoading
);

export const selectError = createSelector(
  selectSuppliersState,
  (SuppliersState) => SuppliersState.error
);

export const selectErrorMessage = createSelector(
  selectSuppliersState,
  (SuppliersState) => SuppliersState.errorMessage
);

export const selectlastAction = createSelector(
  selectSuppliersState,
  (SuppliersState) => SuppliersState.lastAction
);

export const selectSuppliersActionLoading = createSelector(
  selectSuppliersState,
  (SuppliersState) => SuppliersState.actionsLoading
);

export const selectSuppliersShowInitWaitingMessage = createSelector(
  selectSuppliersState,
  (SuppliersState) => SuppliersState.showInitWaitingMessage
);

export const selectSupplierProgress = createSelector(
  selectSuppliersState,
  (SuppliersState) => SuppliersState.progress
);

export const selectSuppliersPageLastQuery = createSelector(
  selectSuppliersState,
  (SuppliersState) => SuppliersState.lastQuery
);

export const selectSuppliersInStore = createSelector(
  selectSuppliersState,
  (SuppliersState) => {
    const items: SupplierModel[] = [];
    each(SuppliersState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: SupplierModel[] = httpExtension.sortArray(
      items,
      SuppliersState.lastQuery.sortField,
      SuppliersState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, SuppliersState.totalCount, '');
  }
);
