import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged, tap, skip } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../core/_base/crud';

import * as ClaimActions from '../../../../../core/service-client/claim/claim.actions';
import { FormControl, FormGroup } from '@angular/forms';
import * as ClaimReasonActions from '../../../../../core/service-client/claim/claim-reason/claim-reason.actions';
import * as OrderActions from '../../../../../core/e-commerce/orders/orders.actions';
import * as CompanyActions from '../../../../../core/e-commerce/companies/company.actions';
import { ClaimReasonListComponent } from '../claim-reason/claim-reason-list/claim-reason-list.component';
import {
  ClaimModel,
  ClaimPriority,
  ClaimStatus,
} from '../../../../../core/service-client/claim/claim.model';
import { ClaimDatasource } from '../../../../../core/service-client/claim/claim.datasource';
import { Sort } from '@angular/material/sort';
import { UserModel } from '../../../../../core/_models/user.model';
import { selectOrdersInStore } from '../../../../../core/e-commerce/orders/orders.selectors';
import { OrderModel } from '../../../../../core/e-commerce/orders/order.model';
import { CompanyModel } from '../../../../../core/e-commerce/companies/company.model';
import { selectCompaniesInStore } from '../../../../../core/e-commerce/companies/company.selectors';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  selectUsersInStore,
  UserActions,
} from '../../../../../core/user-management';
import { PageEvent } from '@angular/material/paginator';
import { selectClaimPageLastQuery } from '../../../../../core/service-client/claim/claim.selectors';
import {DatePipe} from '@angular/common';
import { ClaimService } from '../../../../../core/service-client/claim/claim.service';

@Component({
  selector: 'kt-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss'],
})
export class ClaimListComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollY: true,
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sortClaimList', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  dataSource: ClaimDatasource;
  pageSizeOptions = [10, 25, 50, 100, 200];
  displayedColumns = [
    'select',
    'number',
    'order.number',
    'client.company.name',
    'reason',
    'priority',
    'createdAt',
    'status',
    'admin.name',
    'actions',
  ];

  status: string[] = [];
  statusEnum = ClaimStatus;
  priorities: string[] = [];
  prioritiesEnum = ClaimPriority;
  admins: UserModel[] = [];

  orders: OrderModel[] = [];
  companies: CompanyModel[] = [];

  filterEmpty = new FormControl('');
  filterStatus = new FormControl();
  filterPriorities = new FormControl();
  filterAdmins = new FormControl();

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  claims: ClaimModel[] = [];
  private subscriptions: Subscription[] = [];
  selection = new SelectionModel<ClaimModel>(true, []);

  lastQuery: QueryParamsModel;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private claimService: ClaimService
  ) {
    this.loadClaimReasonList();
    this.loadAdminList();
    this.loadOrderList();
    this.loadCompanyList();
  }

  ngOnInit() {
    const query: QueryParamsModel = {
      filter: {
        query: '',
        status: [],
        priorities: [],
        admins: [],
      },
      pageNumber: 0,
      pageSize: 10,
      sortField: 'id',
      sortOrder: 'asc',
    };

    this.loadClaimList(query);

    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';

    this.status = Object.keys(ClaimStatus)
      .filter((key) => typeof ClaimStatus[key as any] === 'string')
      .map((label) => ClaimStatus[label]);
    this.priorities = Object.keys(ClaimPriority)
      .filter((key) => typeof ClaimPriority[key as any] === 'string')
      .map((label) => ClaimPriority[label]);

    const lastQuerySubscription = this.store
      .pipe(select(selectClaimPageLastQuery))
      .subscribe((lastQuery) => {
        this.lastQuery = lastQuery;
      });

    const selectUsersSubscription = this.store
      .select(selectUsersInStore)
      .subscribe((value) => {
        this.admins = value.items;
        this.selectAll(this.filterAdmins, this.adminIds(this.admins));
      });

    const selectOrdersSubscription = this.store
      .select(selectOrdersInStore)
      .subscribe((value) => {
        this.orders = value.items;
      });

    const selectCompaniesSubscription = this.store
      .select(selectCompaniesInStore)
      .subscribe((value) => {
        this.companies = value.items;
      });

    this.selectAll(this.filterStatus, this.status);
    this.selectAll(this.filterPriorities, this.priorities);

    // Init DataSource
    this.dataSource = new ClaimDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((result) => {
        this.claims = result;
      });

    // Enable Search Filter
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.lastQuery.filter.query = this.searchInput.nativeElement.value;
        this.lastQuery.pageNumber = 0;
        this.loadClaimList(this.lastQuery);
      });

    // Enable Status Filter
    const filterStatusSubscription = this.filterStatus.valueChanges.subscribe(
      (value) => {
        this.lastQuery.filter.status = value;
        this.lastQuery.pageNumber = 0;
        this.loadClaimList(this.lastQuery);
      }
    );

    // Enable Priorities Filter
    const filterPropertiesSubscription =
      this.filterPriorities.valueChanges.subscribe((value) => {
        this.lastQuery.filter.priorities = value;
        this.lastQuery.pageNumber = 0;
        this.loadClaimList(this.lastQuery);
      });

    // Enable Admins Filter
    const filterAdminsSubscription = this.filterAdmins.valueChanges.subscribe(
      (value) => {
        this.lastQuery.filter.admins =
          value.length < this.admins.length ? value : [];
        this.lastQuery.pageNumber = 0;
        this.loadClaimList(this.lastQuery);
      }
    );

    // Enable Paging and Sorting Filter
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(
        tap((sort) => {
          if ((sort as Sort).direction) {
            this.lastQuery.sortOrder = (sort as Sort).direction
              ? (sort as Sort).direction
              : this.lastQuery.sortOrder;
            this.lastQuery.sortField = (sort as Sort).active
              ? (sort as Sort).active
              : this.lastQuery.sortField;
          } else {
            this.lastQuery.sortOrder = 'desc';
            this.lastQuery.sortField = 'id';
          }
          this.lastQuery.pageSize = (sort as PageEvent).pageSize
            ? (sort as PageEvent).pageSize
            : this.lastQuery.pageSize;
          this.lastQuery.pageNumber = (sort as PageEvent).pageIndex
            ? (sort as PageEvent).pageIndex
            : this.lastQuery.pageNumber;

          this.loadClaimList(this.lastQuery);
        })
      )
      .subscribe();

    this.subscriptions.push(
      lastQuerySubscription,
      selectUsersSubscription,
      selectOrdersSubscription,
      selectCompaniesSubscription,
      entitiesSubscription,
      searchSubscription,
      filterStatusSubscription,
      filterPropertiesSubscription,
      filterAdminsSubscription,
      paginatorSubscriptions
    );
  }

  loadClaimList(queryParams: QueryParamsModel) {
    this.selection.clear();
    this.store.dispatch(ClaimActions.ClaimPageRequest({ queryParams }));
  }

  getStatus(status): string {
    let s = 'En attente';
    switch (status) {
      case this.statusEnum.IN_PROCESS:
        s = 'En cours';
        break;
      case this.statusEnum.ACCEPTED:
        s = 'Acceptée';
        break;
      case this.statusEnum.REFUSED:
        s = 'Refusée';
        break;
    }
    return s;
  }

  getPriority(priority): string {
    let p = 'Indéfinie';
    switch (priority) {
      case this.prioritiesEnum.RELATIVE:
        p = 'Relative';
        break;
      case this.prioritiesEnum.ABSOLUTE:
        p = 'Absolute';
        break;
      case this.prioritiesEnum.EXTREME:
        p = 'Extrème';
        break;
    }
    return p;
  }

  getOrder(id: number) {
    return this.orders.find((order) => order.id === id)?.number;
  }

  getCompany(id: number) {
    return this.companies.find((company) => company.id === id)?.name;
  }

  detailClaim(_ID: number) {
    this.router.navigate(['/service-client/claims', _ID], {
      relativeTo: this.activatedRoute,
    });
  }

  sendClaimByMail(_ITEM: ClaimModel) {}

  downloadClaim(_ITEM: ClaimModel) {
    this.claimService.exportClaim(_ITEM.id)
    .subscribe((claim) => {
      const blob = new Blob([claim], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        (window.navigator as any).msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download =
        'ClientReclamation_' +
        new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') +
        '.xlsx';
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }

  archiveClaim(_ITEM: ClaimModel) {
    const _TITLE = 'Archivage de réclamation ==> ' + _ITEM.number;
    const _DESCRIPTION = 'Voulez-vous vraiment archiver cette réclamation ?';
    const _WAIT_DESCRIPTION = 'Archivage en cours...';
    const _BTN_TITLE = 'Archiver';

    const dialogRef = this.layoutUtilsService.archiveElement(
      _TITLE,
      _DESCRIPTION,
      _WAIT_DESCRIPTION,
      _BTN_TITLE
    );
    const archiveClaimDialogSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.store.dispatch(ClaimActions.ClaimArchive({ claimId: _ITEM.id }));
      });

    this.subscriptions.push(archiveClaimDialogSubscription);
  }

  archiveClaims() {
    const claims = this.selection.selected.map(({ id }) => id);

    const _TITLE = 'Archiver réclamations';
    const _DESCRIPTION =
      "Êtes vous sûr d'archiver " +
      (claims.length > 1 ? 'les ' + claims.length : 'la') +
      ' réclamation' +
      (claims.length > 1 ? 's' : '') +
      ' sélectionnée' +
      (claims.length > 1 ? 's' : '') +
      '?';
    const _WAIT_DESCRIPTION = 'Archivage des réclamations ...';
    const _BTN_TITLE = 'Archiver';

    const dialogRef = this.layoutUtilsService.archiveElement(
      _TITLE,
      _DESCRIPTION,
      _WAIT_DESCRIPTION,
      _BTN_TITLE
    );
    const archiveClaimsDialogSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        const _ARCHIVE_MESSAGE =
          'Les réclamations sélectionnées ont été archivées';
        this.layoutUtilsService.showActionNotification(
          _ARCHIVE_MESSAGE,
          MessageType.Update
        );
        this.selection.clear();
      });

    this.subscriptions.push(archiveClaimsDialogSubscription);
  }

  isAllSelected() {
    return this.selection.selected.length === this.claims.length;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.claims.forEach((claim) => this.selection.select(claim));
    }
  }

  selectAll(form: FormControl, items) {
    form.setValue(items.length ? items : []);
  }

  select(form: FormControl, items, item) {
    if (form.value.length === items.length - 1 && !form.value.includes(item)) {
      form.setValue([item]);
    }
  }

  adminIds(admins) {
    return admins.map(({ id }) => id);
  }

  claimReasonList() {
    this.dialog.open(ClaimReasonListComponent, {
      width: '400px',
    });
  }

  loadClaimReasonList() {
    this.store.dispatch(
      ClaimReasonActions.ClaimReasonListRequest({ keyword: '' })
    );
  }

  loadOrderList() {
    const queryParams = new QueryParamsModel({});
    this.store.dispatch(
      OrderActions.OrdersPageRequested({ page: queryParams })
    );
  }

  loadCompanyList() {
    const queryParams = new QueryParamsModel({});
    this.store.dispatch(
      CompanyActions.CompaniesPageRequested({ page: queryParams })
    );
  }

  loadAdminList() {
    this.store.dispatch(UserActions.AllUsersRequested());
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
