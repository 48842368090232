import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import * as LogoActions from './logo.action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LogosService } from './logos.service';

@Injectable()
export class LogoEffects {
  constructor(
    private actions$: Actions,
    private logoService: LogosService,
    private store: Store<AppState>
  ) {}

  LogosRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LogoActions.LogosPageRequested),
      switchMap((action) =>
        this.logoService.getAll().pipe(
          map((logos) =>
            LogoActions.LogosPageLoadedSuccessfully({
              logos: logos,
            })
          ),
          catchError((error) => of(LogoActions.LogosPageLoadFailed({ error })))
        )
      )
    )
  );

  LogoDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LogoActions.LogoDeleted),
      switchMap((action) =>
        this.logoService.delete(action.logoId).pipe(
          map(() =>
            LogoActions.LogoDeletedSuccessfully({ logoId: action.logoId })
          ),
          catchError((error) => of(LogoActions.LogoDeleteFailed({ error })))
        )
      )
    )
  );

  LogoCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LogoActions.LogoCreated),
      switchMap((action) =>
        this.logoService.save(action.logo).pipe(
          map((data) => LogoActions.LogoCreatedSuccessfully({ logo: data })),
          catchError((error) => of(LogoActions.LogoCreationFailed({ error })))
        )
      )
    )
  );
}
