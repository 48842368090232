import { createFeatureSelector, createSelector } from '@ngrx/store';

import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { ProductCharacteristicState } from './product-characteristic.reducer';
import { ProductCharacteristicModel } from './product-characteristic.model';

export const selectCharacteristicsState =
  createFeatureSelector<ProductCharacteristicState>('productCharacteristics');

export const selectProductCharacteristicById = (characteristicId: number) =>
  createSelector(
    selectCharacteristicsState,
    (categoriesState) => categoriesState.entities[characteristicId]
  );

export const selectProductCharacteristicListLoading = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.listLoading
);

export const selectPSharError = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.error
);

export const selectPSharErrorMessage = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.errorMessage
);

export const selectCategoriesActionLoading = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.actionsLoading
);

export const selectLastCreatedProductCharacteristicId = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.lastCreatedProductCharacteristicId
);

export const selectProductCharacteristicShowInitWaitingMessage = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.showInitWaitingMessage
);

export const selectProductCharacteristicInStore = createSelector(
  selectCharacteristicsState,
  (categoriesState) => {
    const items: ProductCharacteristicModel[] = [];
    each(categoriesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: ProductCharacteristicModel[] = httpExtension.sortArray(
      items,
      categoriesState.lastQuery.sortField,
      categoriesState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, categoriesState.totalCount, '');
  }
);
