import { AddressModel } from './address.model';
import { ClientModel } from '../clients/client.model';

export class CompanyModel {
  id: number;
  name: string;
  accountNumber: string;
  siret: string;
  phone: string;
  siren: string;
  globalDiscount: number;
  globalTurnover: string;
  outstanding?: number;
  status: string;
  tvanumber: number;
  nafcode: string;
  addresses: AddressModel[];
  activationStatus: string;
  client?: Partial<ClientModel>;
}
