import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OutstandingModel} from '../../../../../../../../../core/e-commerce/outstanding/outstanding.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LayoutUtilsService, MessageType} from '../../../../../../../../../core/_base/crud';
import {OutstandingService} from '../../../../../../../../../core/e-commerce/outstanding/outstanding.service';
import {AddOutstandingModel} from '../../../../../../../../../core/e-commerce/outstanding/addOutstanding.model';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'kt-outstanding-edit-dialog',
    templateUrl: './outstanding-edit-dialog.component.html',
    // styleUrls: ['./outstanding-edit-dialog.component.scss']
})
export class OutstandingEditDialogComponent implements OnInit {

    outstandingEditForm: FormGroup;
    viewLoading = true;
    loadingAfterSubmit = false;
    outstanding: OutstandingModel;
    newOutstanding: AddOutstandingModel;
    companyId: number;
    reNew: boolean;

    constructor(
        public dialogRef: MatDialogRef<OutstandingEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { outstanding: AddOutstandingModel; companyId: number },
        private fb: FormBuilder,
        private layoutUtilsService: LayoutUtilsService,
        private outstandingService: OutstandingService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.companyId = this.data.companyId;
        this.outstandingService.getOutstandingByCompany(this.companyId).subscribe(
            (res) => {
                if (res?.id) {
                    this.reNew = true;
                }
            }
        );
        this.initOutstandingForm();
    }

    initOutstandingForm() {
        this.outstandingEditForm = this.fb.group({
            outstandingValue: [this.data.outstanding ? this.data.outstanding.value : ''],
            period: [
                this.data.outstanding ? this.data.outstanding.period : '',
                [Validators.required],
            ],
        });
    }

    save() {
        this.newOutstanding = new AddOutstandingModel();
        const controls = this.outstandingEditForm.controls;
        /** check form */
        if (this.outstandingEditForm.invalid) {
            Object.keys(controls).forEach((controlName) =>
                controls[controlName].markAsTouched()
            );
            this.layoutUtilsService.showActionNotification(
                'Veuillez remplir les champs correctement!'
            );
            return;
        }

        this.loadingAfterSubmit = true;
        this.viewLoading = true;

        this.newOutstanding.value = controls.outstandingValue.value;
        this.newOutstanding.companyId = this.companyId;
        this.newOutstanding.period = controls.period.value;
        this.viewLoading = false;
        if (!this.data.outstanding) {
            if (this.reNew) {
                this.outstandingService.renewOutstanding(
                    this.newOutstanding)
                    .subscribe((res) => {
                        this.closeDialog(res);
                    });
                return;
            }
            this.outstandingService.addNewOutstanding(
                this.newOutstanding)
                .subscribe((res) => {
                    this.closeDialog(res);
                });
        } else {
            this.newOutstanding.companyId = this.data.companyId;
            this.outstandingService.updateOutstanding(this.newOutstanding).subscribe((res) => {
                    this.closeDialog(res);
                },
                () => {
                    const saveMessage = `Vous puvez pas modifier l'encour veuillez vérifier le contrat des encours `;
                    this.layoutUtilsService.showActionNotification(
                        saveMessage,
                        MessageType.Delete,
                        10000,
                        true,
                        true
                    );
                });
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(o: OutstandingModel) {
        this.dialogRef.close(o);
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.outstandingEditForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result =
            control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }
}
