import { createAction, props } from '@ngrx/store';
import { ContactModel } from './contact.model';
import { QueryParamsModel } from '../../_base/crud';

export const ContactsListRequested = createAction(
  '[Contacts list Page] Contacts Requested'
);
export const ContactsListRequestedSuccessfully = createAction(
  '[Contacts list Page] Contacts Requested Successfully',
  props<{ contacts: ContactModel[] }>()
);
export const ContactsListRequestFailed = createAction(
  '[Contacts list Page] Contacts Request Failed',
  props<{ error: any }>()
);

export const UpdateContact = createAction(
  '[Contacts API] Update Contact',
  props<{ contact: ContactModel }>()
);
export const ContactUpdatedSuccessfully = createAction(
  '[Contacts API] Contacts updated Successfully',
  props<{ contact: ContactModel }>()
);
export const ContactUpdateFailed = createAction(
  '[Contacts API] Contacts Update Failed',
  props<{ error: any }>()
);

export const DeleteContact = createAction(
  '[Contacts API] Delete Contact',
  props<{ contactId: number }>()
);
export const ContactDeletedSuccessfully = createAction(
  '[Contacts API] Contacts deleted Successfully',
  props<{ contactId: number }>()
);
export const ContactDeletionFailed = createAction(
  '[Contacts API] Contacts Deletion Failed',
  props<{ error: any }>()
);

export const CreateContact = createAction(
  '[Contacts API] Create Contact',
  props<{ contact: ContactModel }>()
);
export const ContactCreatedSuccessfully = createAction(
  '[Contacts API] Contacts created Successfully',
  props<{ contact: ContactModel }>()
);
export const ContactCreationFailed = createAction(
  '[Contacts API] Contacts Creation Failed',
  props<{ error: any }>()
);

export const ContactsPageRequested = createAction(
  '[Contacts list Page] Contacts Requested ',
  props<{ supplierId: number; page: QueryParamsModel }>()
);
export const ContactsPageLoadedSuccessfully = createAction(
  '[Contacts API] Contacts Page Loaded Successfully',
  props<{ contact: ContactModel[]; totalCount: number; page: number }>()
);
export const ContactsPageLoadFailed = createAction(
  '[Contacts API] Contacts Page Request Failed ',
  props<{ error: any }>()
);
