import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ADMIN_CONFIG } from '../../../environments/environment';
import { Response } from '../_base/crud/models/response';
import { map } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';

@Injectable()
export class UserService {
  url = 'admin/';

  constructor(private http: HttpClient) {}

  getUserByEmail(email: string): Observable<UserModel> {
    const user$ = this.http
      .get<Response<UserModel>>(
        ADMIN_CONFIG.apiEndpoint + this.url + 'email/' + email
      )
      .pipe(map(({ body }) => body));

    return user$;
  }

  findAll(): Observable<UserModel[]> {
    console.log('getting users' + ADMIN_CONFIG.apiEndpoint + this.url);
    const users$ = this.http.get<UserModel[]>(
      ADMIN_CONFIG.apiEndpoint + this.url
    );
    return users$;
  }

  addUser(user: UserModel): Observable<UserModel> {
    console.log('sending entity : ' + JSON.stringify(user));
    const user$ = this.http.post<UserModel>(
      ADMIN_CONFIG.apiEndpoint + this.url,
      user
    );
    return user$;
  }

  updateUser(user: UserModel): Observable<UserModel> {
    console.log('updating entity : ' + JSON.stringify(user));
    const user$ = this.http.put<UserModel>(
      ADMIN_CONFIG.apiEndpoint + this.url,
      user
    );
    return user$;
  }

  deleteUserById(id: number) {
    console.log('deleting user :' + ADMIN_CONFIG.apiEndpoint + this.url + id);
    return this.http.delete(ADMIN_CONFIG.apiEndpoint + this.url + id);
  }
}
