import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ClaimReasonModel } from '../../../../../../core/service-client/claim/claim-reason/claim-reason.model';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ClaimReasonListComponent } from '../claim-reason-list/claim-reason-list.component';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as ClaimReasonActions from '../../../../../../core/service-client/claim/claim-reason/claim-reason.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { selectClaimReasonById } from '../../../../../../core/service-client/claim/claim-reason/claim-reason.selectors';
import _ from 'lodash';
import { Update } from '@ngrx/entity';
import { ClaimPriority } from '../../../../../../core/service-client/claim/claim.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-claim-reason-edit',
  templateUrl: './claim-reason-edit.component.html',
  styleUrls: ['./claim-reason-edit.component.scss'],
})
export class ClaimReasonEditComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
  };

  claimReason: ClaimReasonModel;
  types: string[] = [];
  priorities: string[] = [];
  prioritiesEnum = ClaimPriority;

  editClaimReasonForm: FormGroup;
  subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ClaimReasonListComponent>,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    const selectClaimReasonSubscription = this.store
      .select(selectClaimReasonById(this.data.id))
      .subscribe((value) => {
        this.claimReason = _.cloneDeep(value);
      });

    this.subscriptions.push(selectClaimReasonSubscription);

    this.editClaimReasonForm = new FormGroup({
      title: new FormControl(this.claimReason?.title, Validators.required),
      type: new FormControl(this.claimReason?.type, Validators.required),
      priority: new FormControl(
        this.claimReason?.priority,
        Validators.required
      ),
    });

    this.types = this.data.types.filter((type) =>
      type
        .toLowerCase()
        .includes(this.claimReason ? this.claimReason?.type.toLowerCase() : '')
    );

    this.priorities = Object.keys(ClaimPriority).filter(
      (key) => typeof ClaimPriority[key as any] === 'string'
    );

    const searchSubscription = this.editClaimReasonForm
      .get('type')
      .valueChanges.subscribe(
        (value = this.editClaimReasonForm.get('type').value) => {
          this.types = this.data.types.filter((type) =>
            type.toLowerCase().includes(value.toLowerCase())
          );
        }
      );

    this.subscriptions.push(searchSubscription);
  }

  cancelClaimReason() {
    this.dialogRef.close();
    this.dialog.open(ClaimReasonListComponent, {
      width: '400px',
    });
  }

  applyAddClaimReason() {
    const controls = this.editClaimReasonForm.controls;
    if (this.editClaimReasonForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    const reason: ClaimReasonModel = this.editClaimReasonForm.value;
    this.store.dispatch(ClaimReasonActions.ClaimReasonCreation({ reason }));

    this.dialogRef.close();
    this.dialog.open(ClaimReasonListComponent, {
      width: '400px',
    });
  }

  applyEditClaimReason() {
    const controls = this.editClaimReasonForm.controls;
    if (this.editClaimReasonForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    const reason: ClaimReasonModel = this.editClaimReasonForm.value;
    reason.id = this.claimReason.id;
    reason.status = this.claimReason.status;

    const updateClaimReason: Update<ClaimReasonModel> = {
      id: reason.id,
      changes: reason,
    };

    this.store.dispatch(
      ClaimReasonActions.ClaimReasonUpdate({
        partialClaimReason: updateClaimReason,
        reason,
      })
    );

    this.dialogRef.close();
    this.dialog.open(ClaimReasonListComponent, {
      width: '400px',
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
