// NGRX
import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import * as QuestionActions from './client-question.actions';
import { QueryParamsModel } from '../../_base/crud';
import { ClientQuestionModel } from './client-question.model';
import * as ProductActions from '../../e-commerce/products/product.actions';

// tslint:disable-next-line:no-empty-interface
export interface QuestionsState extends EntityState<ClientQuestionModel> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedQuestionId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<ClientQuestionModel> =
  createEntityAdapter<ClientQuestionModel>();

export const initialQuestionsState: QuestionsState = adapter.getInitialState({
  listLoading: false,
  actionsloading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  lastCreatedQuestionId: undefined,
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const questionReducer = createReducer(
  initialQuestionsState,
  on(QuestionActions.QuestionsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(QuestionActions.QuestionsPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.questions, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),

  on(QuestionActions.QuestionsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //Update
  on(QuestionActions.QuestionUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(QuestionActions.QuestionUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialQuestion, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(QuestionActions.QuestionUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  }))
  //Delete
);

export function questionsReducer(
  state: QuestionsState | undefined,
  action: Action
) {
  return questionReducer(state, action);
}
