import { createAction, props } from '@ngrx/store';
import { AuthToken } from '../_models/AuthToken.model';
import { UserModel } from '../../_models/user.model';

export const login = createAction(
  '[Auth] Login',
  props<{ mail: string; password: string }>()
);

export const loggingIn = createAction(
  '[Auth] loging in ... ',
  props<AuthToken>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<AuthToken>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ errorCode: number }>()
);

export const logout = createAction('[Auth] Logout');

export const getLoggedInUser = createAction('[App Component] getLoggedInUser');

export const getLoggedInUserSucceed = createAction(
  '[User API] getLoggedIn User Secceed',
  props<{ user: UserModel }>()
);

export const getLoggedInUserFailed = createAction(
  '[User API] getLoggedIn User Failed',
  props<{ errorCode: number }>()
);
