import { createFeatureSelector, createSelector, on } from '@ngrx/store';
import { CategoryState } from '../category.reducers';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../../_base/crud';
import { CategoryModel } from '../category.model';


export const selectAccessoirState =
  createFeatureSelector<CategoryState>('accessories');

export const selectCategoryById = (categoryId: number) =>
  createSelector(
    selectAccessoirState,
    (AccessoirState) => AccessoirState.entities[categoryId]
  );

export const selectAccessoirPageLoading = createSelector(
    selectAccessoirState,
  (AccessoirState) => AccessoirState.listLoading
);

export const selectError = createSelector(
    selectAccessoirState,
  (AccessoirState) => AccessoirState.error
);

export const selectErrorMessage = createSelector(
    selectAccessoirState,
  (AccessoirState) => AccessoirState.errorMessage
);

export const selectlastAction = createSelector(
    selectAccessoirState,
  (AccessoirState) => AccessoirState.lastAction
);

export const selectAccessoirActionLoading = createSelector(
    selectAccessoirState,
  (AccessoirState) => AccessoirState.actionsLoading
);

export const selectLastCreatedAccessoirId = createSelector(
    selectAccessoirState,
  (AccessoirState) => AccessoirState.lastCreatedCategoryId
);

export const selectAccessoirShowInitWaitingMessage = createSelector(
    selectAccessoirState,
  (AccessoirState) => AccessoirState.showInitWaitingMessage
);

export const selectAccessoirProgress = createSelector(
    selectAccessoirState,
  (AccessoirState) => AccessoirState.progress
);

export const selectAccessoirInStore = createSelector(
    selectAccessoirState,
  (AccessoirState) => {
    const items: CategoryModel[] = [];
    each(AccessoirState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: CategoryModel[] = httpExtension.sortArray(
      items,
      AccessoirState.lastQuery.sortField,
      AccessoirState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, AccessoirState.totalCount, '');
  }
);
