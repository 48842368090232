import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProductCharacteristicModel } from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryCharacteristicModel } from '../../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.model';
import * as CategoryCharacteristicActions from '../../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.actions';
import { selectCategoryCharacteristicInStore } from '../../../../../../../../../core/e-commerce/category-characteristic/category-characteristic.selectors';
import { ProductsService } from '../../../../../../../../../core/e-commerce/products/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../../core/reducers';
import { Update } from '@ngrx/entity';
import * as CharacteristicActions from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.actions';
import {LayoutUtilsService, MessageType} from '../../../../../../../../../core/_base/crud';
import { selectLastCreatedProductCharacteristicId } from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.selectors';
import {
  ProductCharacteristicsService
} from "../../../../../../../../../core/e-commerce/product-characteristic/product-characteristics.service";

@Component({
  selector: 'kt-product-characteristics-edit-dialog',
  templateUrl: './product-characteristics-edit-dialog.component.html',
  styleUrls: ['./product-characteristics-edit-dialog.component.scss'],
})
export class ProductCharacteristicsEditDialogComponent
  implements OnInit, OnDestroy {
  viewLoading = false;

  characteristicForm: FormGroup;
  hasFormErrors = false;

  categoryCharacteristics: CategoryCharacteristicModel[];
  suggestedCharacteristics: CategoryCharacteristicModel[];

  catcharSelected: CategoryCharacteristicModel;
  productId: any;

  private componentSubscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<ProductCharacteristicsEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProductCharacteristicModel,
    private productsService: ProductsService,
    private store: Store<AppState>,
    private router: Router,
    private fb: FormBuilder,
    private productCharacteristicsService: ProductCharacteristicsService,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit(): void {
    this.characteristicForm = this.fb.group({
      label: [this.data ? this.data.label : '', Validators.required],
      additionalInfo: [this.data ? this.data.additionalInfo : ''],
      value: [this.data ? this.data.value : '', Validators.required],
      title: [this.data ? this.data.title : ''],
    });

    this.characteristicForm.get('label').valueChanges.subscribe((value) => {
      this.suggestedCharacteristics = this.categoryCharacteristics.filter((c) =>
        c.label.toLowerCase().includes(value.toLowerCase())
      );
    });

    this.getCategoryCharacteristics();
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  getCategoryCharacteristics() {
    this.productId = this.getActivatedRoute().snapshot.params['id'];

    const productSub = this.productsService
      .getById(this.productId)
      .subscribe((product) => {
        const categoryId = product.categoryId;
        this.store.dispatch(
          CategoryCharacteristicActions.CategoryCharacteristicListRequested({
            categoryId,
          })
        );
        const catCharsSub = this.store
          .select(selectCategoryCharacteristicInStore)
          .subscribe((res) => {
            this.categoryCharacteristics = res.items;
          });
        this.componentSubscriptions.push(catCharsSub);
      });
    this.componentSubscriptions.push(productSub);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onValidate() {
    if (this.characteristicForm.invalid) {
      this.hasFormErrors = true;
      return;
    }

    let editedChar = this.prepareCharacteristic();
    if (this.data) {
      this.updateProduct(editedChar);
      return;
    } else {
      this.addProduct(editedChar);
    }
  }

  // TODO plz review this method
  prepareCharacteristic(): ProductCharacteristicModel {
    const controls = this.characteristicForm.controls;
    const _characteristic = new ProductCharacteristicModel();
    if (this.data) _characteristic.id = this.data.id;
    _characteristic.label = controls.label.value;
    _characteristic.additionalInfo = controls.additionalInfo.value;
    _characteristic.title = controls.title.value;

    if (
      this.catcharSelected &&
      this.catcharSelected.valueType == 'multipleListChoice'
    ) {
      let stringvalue: string = '';
      stringvalue = stringvalue.concat(controls.value.value);
      _characteristic.value = stringvalue;
      // console.log(stringvalue);
    } else {
      _characteristic.value = controls.value.value;
    }

    return _characteristic;
  }

  updateProduct(_characteristic: ProductCharacteristicModel) {
    const updateChar: Update<ProductCharacteristicModel> = {
      id: _characteristic.id,
      changes: _characteristic,
    };

    this.store.dispatch(
      CharacteristicActions.ProductCharacteristicUpdated({
        partialCharacteristic: updateChar,
        productCharacteristic: _characteristic,
        productId: this.productId,
      })
    );
    this.productCharacteristicsService.update(_characteristic, this.productId).subscribe(value => {
          this.layoutUtilsService.showActionNotification(
              'Caractérisitiques modifé avec succès',
              MessageType.Create );
        },
        () =>
            this.layoutUtilsService.showActionNotification(
                'échec modifé caractérisitiques',
                MessageType.Create )
    );
    this.dialogRef.close(true);
  }

  addProduct(_characteristic: ProductCharacteristicModel) {
    this.store.dispatch(
      CharacteristicActions.ProductCharacteristicCreated({
        productId: this.productId,
        productCharacteristic: _characteristic,
      })
    );
    /*this.productCharacteristicsService.save(_characteristic, this.productId).subscribe(value => {
      this.layoutUtilsService.showActionNotification(
          'Caractérisitiques ajouté avec succès',
          MessageType.Create );
    },
    () =>
        this.layoutUtilsService.showActionNotification(
            'échec ajouté caractérisitiques',
            MessageType.Create )
  );*/
    this.dialogRef.close(true);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  getActivatedRoute(): ActivatedRoute {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
