import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContactModel } from './contact.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as ContactsActions from './contact.action';
import { Response } from '../../_base/crud/models/response';

export interface ContactState extends EntityState<ContactModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<ContactModel> =
  createEntityAdapter<ContactModel>();

export const initialContactState: ContactState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const ContactReducer = createReducer(
  initialContactState,
  on(ContactsActions.ContactsListRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ContactsActions.ContactsListRequestedSuccessfully, (state, action) =>
    adapter.addAll(action.contacts, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(ContactsActions.ContactsListRequestFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // Update
  on(ContactsActions.UpdateContact, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ContactsActions.ContactUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(
      { id: action.contact.id, changes: action.contact },
      {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
      }
    )
  ),
  on(ContactsActions.ContactUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  /*Deletion*/
  on(ContactsActions.DeleteContact, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ContactsActions.ContactDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.contactId, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(ContactsActions.ContactDeletionFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  /*Creation*/
  on(ContactsActions.CreateContact, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ContactsActions.ContactCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.contact, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(ContactsActions.ContactCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),

  // page
  on(ContactsActions.ContactsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
    lastQuery: action.page,
  })),
  on(ContactsActions.ContactsPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.contact, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      totalCount: action.totalCount,
      showInitWaitingMessage: false,
    })
  ),
  on(ContactsActions.ContactsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  }))
);

export function ContactsReducer(
  state: ContactState | undefined,
  action: Action
) {
  return ContactReducer(state, action);
}
