import { createFeatureSelector, createSelector } from '@ngrx/store';

import { each } from 'lodash';
import { ProductLicensesModel } from './product-licenses.model';
import { ProductLicensesState } from './product-licenses.reducer';
import { selectProductsInStore } from '../products/product.selectors';

export const selectLicensesState =
  createFeatureSelector<ProductLicensesState>('productLicenses');

export const selectProductLicensesListLoading = createSelector(
  selectLicensesState,
  (productLicensesState) => productLicensesState.listLoading
);

export const selectProductLicensesInStore = createSelector(
  selectLicensesState,
  (productLicensesState) => {
    const items: ProductLicensesModel[] = [];
    each(productLicensesState.entities, (element) => {
      items.push(element);
    });
    return items;
  }
);
export const isProductLicenseLoading = createSelector(
  selectLicensesState,
  state => state.actionsLoading
);
export const selectImportProductLicenseFailed = createSelector(
  selectLicensesState,
  state => state.error
);
