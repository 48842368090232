import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { ManufacturerModel } from './manufacturer.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';

@Injectable()
export class ManufacturerService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}manufacturer`;
  }

  public getAll(): Observable<ManufacturerModel[]> {
    return this.http.get<ManufacturerModel[]>(this.url);
  }

  public addManufacturer(
    manufacturer: ManufacturerModel
  ): Observable<ManufacturerModel> {
    return this.http.post<ManufacturerModel>(this.url, manufacturer);
  }

  public updateManufacturer(
    manufacturer: ManufacturerModel
  ): Observable<ManufacturerModel> {
    return this.http.put<ManufacturerModel>(this.url, manufacturer);
  }

  public deleteManufacturer(manufacturerId: number) {
    const url = `${this.url}/${manufacturerId}`;
    return this.http.delete<void>(url);
  }

  public getManufactures(
    filter: any,
    page: number = 0,
    size: number = 10
  ): Observable<Page<ManufacturerModel>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = params.append('search', filter.query ? filter.query : '');

    const url = `${this.url}/page`;
    const options = {
      params,
    };
    return this.http.get<Page<ManufacturerModel>>(url, options);
  }
}
