import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LogsState } from './logs.reducers';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { LogsModel } from './logs.model';

export const selectLogsState =
  createFeatureSelector<LogsState>('logs');

export const selectLogsById = (logsId: number) =>
  createSelector(
    selectLogsState,
    (logsState) => logsState.entities[logsId]
  );

export const selectLogsPageLoading = createSelector(
  selectLogsState,
  (logsState) => logsState.listLoading
);

export const selectError = createSelector(
  selectLogsState,
  (logsState) => logsState.error
);

export const selectErrorMessage = createSelector(
  selectLogsState,
  (logState) => logState.errorMessage
);

export const selectlastAction = createSelector(
  selectLogsState,
  (logsState) => logsState.lastAction
);

export const selectLogsActionLoading = createSelector(
  selectLogsState,
  (logsState) => logsState.actionsLoading
);

export const selectLogsShowInitWaitingMessage = createSelector(
  selectLogsState,
  (logsState) => logsState.showInitWaitingMessage
);

export const selectLogsProgress = createSelector(
  selectLogsState,
  (logsState) => logsState.progress
);

export const selectLogsInStore = createSelector(
  selectLogsState,
  (logsState) => {
    const items: LogsModel[] = [];
    each(logsState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: LogsModel[] = httpExtension.sortArray(
      items,
      logsState.lastQuery.sortField,
      logsState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, logsState.totalCount, '');
  }
);
export const selectLogsPageLastQuery = createSelector(
  selectLogsState,
  (logsState) => logsState.lastQuery
);
