import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CompanyModel } from './company.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
//Actions
import * as CompanyActions from './company.actions';

export interface CompanyState extends EntityState<CompanyModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedCompanyId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  lastAction: string;
}

export const adapter: EntityAdapter<CompanyModel> =
  createEntityAdapter<CompanyModel>();

export const initialCompanyState: CompanyState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedCompanyId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  lastAction: '',
});

const companyReducer = createReducer(
  initialCompanyState,
  on(CompanyActions.CompaniesPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),
  on(CompanyActions.CompaniesPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.companies, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(CompanyActions.CompaniesPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //creation
  on(CompanyActions.CompanyCreated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(CompanyActions.CompanyCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.company, {
      ...state,
      actionsLoading: false,
      lastCreatedCompanyId: action.company.id,
      lastAction: 'EDIT.ADD_MESSAGE',
    })
  ),
  on(CompanyActions.CompanyCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  //Update
  on(CompanyActions.CompanyUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(CompanyActions.CompanyUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialCompany, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(CompanyActions.CompanyUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  //Delete
  on(CompanyActions.CompanyDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(CompanyActions.CompanyDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.companyId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(CompanyActions.CompanyDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  }))
);

export function companiesReducer(
  state: CompanyState | undefined,
  action: Action
) {
  return companyReducer(state, action);
}
