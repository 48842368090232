import { ProductModel } from '../products/product.model';
import {EventModel} from './event.model';
import { CategoryModel } from '../categories/category.model';

export class PromoCodeModel {
  id: number;
  name: string;
  startingDate: string;
  endingDate: string;
  value: number;
  updater: string;
  state: boolean;
  clientUseOneTime:	boolean;
  numberUtilisation: number;
  haveMaxUseNumber: boolean;
  minValue:	number;
  promoCodeType:	string;
  appliedTo: string;
  minValueToHave: boolean;
  event: EventModel;
  products: ProductModel[];
  categoryId: number[];
  categoryDtos: CategoryModel[];
}
