import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from './company.reducers';
import { CompanyModel } from './company.model';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
// Lodash
import { each } from 'lodash';

export const selectCompaniesState =
  createFeatureSelector<CompanyState>('companies');

export const selectCompanyById = (companyId: number) =>
  createSelector(
    selectCompaniesState,
    (companiesState) => companiesState.entities[companyId]
  );

export const selectCompaniesPageLoading = createSelector(
  selectCompaniesState,
  (companiesState) => companiesState.listLoading
);

export const selectCompaniesActionLoading = createSelector(
  selectCompaniesState,
  (customersState) => customersState.actionsLoading
);

export const selectCompaniesPageLastQuery = createSelector(
  selectCompaniesState,
  (companiesState) => companiesState.lastQuery
);

export const selectLastCreatedCompanyId = createSelector(
  selectCompaniesState,
  (companiesState) => companiesState.lastCreatedCompanyId
);

export const selectCompaniesInitWaitingMessage = createSelector(
  selectCompaniesState,
  (companiesState) => companiesState.showInitWaitingMessage
);

export const selectCompaniesInStore = createSelector(
  selectCompaniesState,
  (companiesState) => {
    const items: CompanyModel[] = [];
    each(companiesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: CompanyModel[] = httpExtension.sortArray(
      items,
      companiesState.lastQuery.sortField,
      companiesState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, companiesState.totalCount, '');
  }
);

export const selectHasCompaniesInStore = createSelector(
  selectCompaniesInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  }
);
