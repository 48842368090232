export class CategoryModel {
  id: number;
  name: string;
  image: string;
  status: boolean;
  position: number;
  parentId: number;
  defaultRecommended: boolean;
  updater: string;
  file: File;
  parentName?: string;
  children?: CategoryModel[];
}
