import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PromoCodeService } from './promo-code.service';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
// Actions
import * as PromoCodeActions from './promo-code.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ClientService } from '../clients/client.service';
import { CompaniesService } from '../companies/companies.service';
import { LayoutUtilsService } from '../../_base/crud';

@Injectable()
export class PromoCodeEffects {
  constructor(
    private actions$: Actions,
    private promoCodeService: PromoCodeService,
    private clientService: ClientService,
    private companyService: CompaniesService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService
  ) {
  }

  PromoCodePageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PromoCodeActions.PromoCodesPageRequested),
      switchMap((action) =>
        this.promoCodeService
          .getAllPromoCode(
            action.page.filter ?? {},
            action.page.pageNumber,
            action.page.pageSize
          )
          .pipe(
            map((promoCodesPage) =>
              PromoCodeActions.PromoCodesPageLoadedSuccessfully({
                promoCodes: promoCodesPage.content,
                totalCount: promoCodesPage.totalElements,
              }),
            ),
            catchError((error) =>
              of(PromoCodeActions.PromoCodesPageLoadFailed({ error }))
            )
          )
      )
    )
  );

  PromoCodeCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PromoCodeActions.PromoCodeCreated),
      switchMap((action) =>
        this.promoCodeService.save(action.promoCode).pipe(
          map((data) => PromoCodeActions.PromoCodeCreatedSuccessfully({ promoCode: data })),
          catchError((error) => of(PromoCodeActions.PromoCodeCreationFailed({ error })))
        )
      )
    )
  );
  PromoCodeCreatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PromoCodeActions.PromoCodeCreatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Promo Code crée avec succès'
            )
          )
        )
      ),
    { dispatch: false }
  );
  PromoCodeCreationFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PromoCodeActions.PromoCodeCreationFailed),
        switchMap(() =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue'
            )
          )
        )
      ),
    { dispatch: false }
  );

  PromoCodeUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PromoCodeActions.PromoCodeUpdated),
      switchMap((action) =>
        this.promoCodeService.update(action.promoCode).pipe(
          map((promoCode) =>
            PromoCodeActions.PromoCodeUpdatedSuccessfully({
              promoCode,
              partialPromoCode: action.partialPromoCode,
            })
          ),
          catchError((error) => of(PromoCodeActions.PromoCodeUpdateFailed({ error })))
        )
      )
    )
  );
  PromoCodeUpdatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PromoCodeActions.PromoCodeUpdatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Promo Code modifié avec succès'
            )
          )
        )
      ),
    { dispatch: false }
  );
  PromoCodeUpdateFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PromoCodeActions.PromoCodeUpdateFailed),
        switchMap(() =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue'
            )
          )
        )
      ),
    { dispatch: false }
  );

  PromoCodeDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PromoCodeActions.PromoCodeDeleted),
      switchMap((action) =>
        this.promoCodeService.delete(action.promoCodeId).pipe(
          map(() =>
            PromoCodeActions.PromoCodeDeletedSuccessfully({ promoCodeId: action.promoCodeId })
          ),
          catchError((error) => of(PromoCodeActions.PromoCodeDeleteFailed({ error })))
        )
      )
    )
  );
  PromoCodeDeletedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PromoCodeActions.PromoCodeDeletedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Promo Code supprimé avec succès'
            )
          )
        )
      ),
    { dispatch: false }
  );
  PromoCodeDeleteFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PromoCodeActions.PromoCodeDeleteFailed),
        switchMap(() =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue'
            )
          )
        )
      ),
    { dispatch: false }
  );

  ChangePromoCodeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PromoCodeActions.ChangePromoCodeStatus),
      switchMap((action) =>
        this.promoCodeService.updatePromoCodeStatus(action.promoCode).pipe(
          map((promoCode) => {
            return PromoCodeActions.PromoCodeStatusChangedSuccessfully({
              promoCode,
              partialPromoCode : action.partialPromoCode
            });
          }),
          catchError((error) =>
            of(PromoCodeActions.PromoCodeStatusChangeFailed({ error }))
          )
        )
      )
    )
  );


  PromoCodeStatusChangedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PromoCodeActions.PromoCodeStatusChangedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Statut modifié avec succès'
            )
          )
        )
      ),
    { dispatch: false }
  );


  PromoCodeStatusChangeFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PromoCodeActions.PromoCodeStatusChangeFailed),
        switchMap(() =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue'
            )
          )
        )
      ),
    { dispatch: false }
  );

}
