import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { IDiscountCompanyModel } from '../../../../../../../../core/e-commerce/company-promotions/company-discount.model';
import { DiscountModel } from '../../../../../../../../core/e-commerce/promotions/discount.model';
import { AppState } from '../../../../../../../../core/reducers';
import { LayoutUtilsService } from '../../../../../../../../core/_base/crud';
import { DiscountCompanyApiActions, DiscountCompanyPageActions } from '../../../../../../../../core/e-commerce/company-promotions/actions';
import { selectCurrentCompanyId, 
  selectDiscountCompanyError, 
  selectDiscountCompanyErrorMessage,
  selectDiscountsForSelectedCompany } from '../../../../../../../../core/e-commerce/company-promotions/company-discount.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { CurrentPromotionComponent } from '../../../../promo-code/current-promotion/current-promotion.component';


@Component({
  selector: 'kt-discounts-companies',
  templateUrl: './discounts-companies.component.html',
  styleUrls: ['./discounts-companies.component.scss']
})
export class DiscountsCompaniesComponent implements OnInit {
  
  currentCompanyId$: Observable<number>;
  discountList$: Observable<IDiscountCompanyModel[]> | null;
  private subjectStartDateGuard = new BehaviorSubject<Date>(new Date());
  startDateGuard$ = this.subjectStartDateGuard.asObservable();
  endDateGuard$: Observable<Date>;
  discountForm: FormGroup;
  valueErrorMessage= '';
  valueErrorMessages: any = {
    required: 'Ce champ est obligatoire.',
    min: 'La valeur minimale c\'est 1',
    max: 'La valeur maximale c\'est 100'
  };
  dataSource: MatTableDataSource<IDiscountCompanyModel> = new MatTableDataSource([]);
  displayedDiscountColumns = [
    'id',
    'type',
    'value',
    'createdAt',
    'endAt',
    'discountStatus',
    'actions',
  ];
  editDiscountLine = -1;
  loadingAfterSubmit = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private actions$: Actions,
    private layoutUtilsService: LayoutUtilsService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}
  
  ngOnInit(): void {
    this.subjectStartDateGuard.next(new Date());
    
    this.discountForm = this.fb.group({
      id: [null],
      discountType: ['', Validators.required],
      value: [null, Validators.required],
      createdAt: [null, Validators.required],
      endAt: [null, Validators.required],
      discountStatus: [false, Validators.required]
    });



    /* Get The current company Id */
    this.currentCompanyId$ = this.store.select(selectCurrentCompanyId);

    /* Get Data From The store*/
    this.discountList$ = this.store.select(selectDiscountsForSelectedCompany);

    /* the endDateGuard is for preventing a promotion endDate before the startDate */
    this.endDateGuard$ = this.discountForm.get('createdAt').valueChanges
      .pipe(map(dateChanged => dateChanged))
    

    /* Apply Value Error messages at runtime */
    const valueControl = this.discountForm.get('value');
    valueControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(
      value => this.setValueErrorMessage(valueControl)
    );

    /* Listner for discountType change in order to adjust value validation*/
    this.discountForm.get('discountType').valueChanges.subscribe(
      valueType => this.setDiscountValueValidator(valueType)
    );

    /* Display Error mesages */
    this.store.select(selectDiscountCompanyErrorMessage).subscribe(
      message => {
        if(message && !message.toLocaleLowerCase().includes("un code promo"))
          this.openSnackBar(message, 'Ok')
      } 
    )

    /* Navigate to PromoCodeComponent if there is a promoCode activated */
    
    // this.store.select(selectDiscountCompanyError).subscribe(
    //   error => {
    //     if(error && error.status === 406)
    //       this.router.navigate(['ecommerce/promo-code']);
    //   } 
    // )

  }



  /* Adjusting Value Validation at Runtime */
  setDiscountValueValidator(valueType: string) {
    const discountValueControl = this.discountForm.get('value');
    if(valueType === 'PERCENTAGE')
      discountValueControl.setValidators([Validators.min(1), Validators.max(100), Validators.required]);
    else {
      discountValueControl.clearValidators();
      discountValueControl.setValidators([Validators.required, Validators.min(1)]);
    }
    discountValueControl.updateValueAndValidity();
  }



  createDiscount() {
    this.editDiscountLine = 0;
    this.discountForm.reset();
  }

  saveNewDiscount() {
    const [createdAt, endAt] = this.getDiscountDates()

    this.loadingAfterSubmit = true;
    let currentCompanyId: number;
    this.currentCompanyId$.subscribe(companyId => currentCompanyId = companyId)
    const payload: IDiscountCompanyModel = {
      type: this.discountForm.get('discountType').value,
      value: this.discountForm.get('value').value,
      createdAt: createdAt,
      endAt: endAt,
      discountStatus: this.discountForm.get('discountStatus').value,
      companyId: currentCompanyId
    };

   this.store.dispatch(DiscountCompanyPageActions.addNewCompanyDiscount({companyDiscount: payload}));
   
   this.actions$.pipe(
    ofType(DiscountCompanyApiActions.createDiscountCompanySuccess)
  ).subscribe(
    action => {
      this.loadingAfterSubmit = false;
      this.editDiscountLine = -1;
    }
  );
  this.actions$.pipe(
    ofType(DiscountCompanyApiActions.createDiscountCompanyFailure)
    ).subscribe(
      action => {
        if(action.error?.status === 406){
          this.dialog.open(CurrentPromotionComponent, {
            data: action.error.error,
            height : 'auto',
          });
        }
        this.loadingAfterSubmit = false;
        this.editDiscountLine = -1;
      }
    );

  }

  editDiscount(item: IDiscountCompanyModel) {
    this.subjectStartDateGuard.next(new Date());
    const createDate = new Date(item.createdAt);
    const endDate = new Date(item.endAt);
    this.editDiscountLine = item.id;

    this.discountForm.get('id').setValue(item.id);
    this.discountForm.get('discountType').setValue(item.type);
    this.discountForm.get('value').setValue(item.value);
    this.discountForm.get('createdAt').setValue(createDate);
    this.discountForm.get('endAt').setValue(endDate);
    this.discountForm.get('discountStatus').setValue(item.discountStatus);
 
  }

  deleteDiscount(item: DiscountModel) {
    const _title = 'Supprimer le discount';
    const _description = 'Voulez-vous vraiment supprimer la remise ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(DiscountCompanyPageActions.deleteDiscountCompany({discountCompanyId: item.id}));
    });
  }


  //  add saving logic
  saveEditedDiscount(item: DiscountModel) {
    this.loadingAfterSubmit = true;
     
    const [createdAt, endAt] = this.getDiscountDates()

    let currentCompanyId: number;
    this.currentCompanyId$.subscribe(companyId => currentCompanyId = companyId)
    const payload: IDiscountCompanyModel = {
      id: this.discountForm.get('id').value,
      type: this.discountForm.get('discountType').value,
      value: this.discountForm.get('value').value,
      createdAt: createdAt,
      endAt: endAt,
      discountStatus: this.discountForm.get('discountStatus').value,
      companyId: currentCompanyId
    };

    const update: Update<IDiscountCompanyModel> = {  
      id: payload.id,
      changes: payload
    };

    this.store.dispatch(DiscountCompanyPageActions.updateCompanyDiscount({update}));

    this.actions$.pipe(
      ofType(DiscountCompanyApiActions.updateDiscountCompanySuccess),   
    ).subscribe(action => {
      this.loadingAfterSubmit = false;
        this.editDiscountLine = -1;
    });
    this.actions$.pipe(
      ofType(DiscountCompanyApiActions.updateDiscountCompanyFailure)
    ).subscribe(
      action => {
        this.loadingAfterSubmit = false;
        this.editDiscountLine = -1;
      }
    );
  }
  
  
  cancelEditDiscount() {
    this.editDiscountLine = -1;
    this.loadingAfterSubmit = false;
    this.discountForm.reset(); 
  }


  /* Set Validation Error Messages For Value of Discount */
  setValueErrorMessage(c: AbstractControl): void {
    this.valueErrorMessage = '';
    if ((c.touched || c.dirty) && c.errors) {
      this.valueErrorMessage = Object.keys(c.errors).map(
        key => this.valueErrorMessages[key]).join(' ');
    }
  }

  // Set Color for Discount Status Status
  getItemCssClassByStatus(discountStatus: boolean = false): string {
    switch (discountStatus) {
      case true:
        return 'success';
      case false:
        return 'danger';
      default:
        return '';
    };
  }
  
  // Display notifications
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getDiscountDates():[Date, Date]{
    let createdAt: Date = this.discountForm.get('createdAt').value
    let endAt: Date = this.discountForm.get('endAt').value
    createdAt.setHours(0,0,0,0)
    endAt.setHours(23,59,59,999) 
    return [createdAt, endAt]  
  }
 }

