import { createAction, props } from '@ngrx/store';
import { ProductLicensesModel } from './product-licenses.model';

export const ProductLicensesListRequested = createAction(
  '[Product Licenses List Page] Licenses Requested',
  props<{ productId: number }>()
);
export const ProductLicensesListRequestedSuccessfully = createAction(
  '[Product Licenses List Page] Licenses Requested Successfully',
  props<{ productLicenses: ProductLicensesModel[] }>()
);
export const ProductLicensesListRequestFailed = createAction(
  '[Product Licenses List Page] Licenses Request Failed',
  props<{ error: any }>()
);

export const ProductLicensesImport = createAction(
  '[Product Licenses List Page] Licenses Import ',
  props<{
    productLicenses: File;
    productId: number;
  }>()
);
export const ProductLicensesImportedSuccessfully = createAction(
  '[Product Licenses List Page] Licenses Imported Successfully ',
  props<{ productLicenses: ProductLicensesModel[] }>()
);
export const ProductLicensesImportFailed = createAction(
  '[Product Licenses List Page] Licenses Import Failed ',
  props<{ error: any }>()
);
