import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on, UPDATE } from '@ngrx/store';

import * as StockActions from './stock.actions';
import { StockModel } from './stock.model';

export interface ProductStocksState extends EntityState<StockModel> {
  productId: number;
  totalCount: number;
  showInitWaitingMessage: boolean;
  error: any;
}

export const adapter: EntityAdapter<StockModel> =
  createEntityAdapter<StockModel>();

export const initialCProductStocksState: ProductStocksState =
  adapter.getInitialState({
    productId: null,
    totalCount: null,
    showInitWaitingMessage: false,
    error: Response,
  });

const productStockReducer = createReducer(
  initialCProductStocksState,
  on(StockActions.StocksListRequested, (state, action) => ({
    ...state,
    showInitWaitingMessage: true,
    error: null,
  })),
  on(StockActions.StocksPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.productStocks, {
      ...state,
      productId: action.productId,
      showInitWaitingMessage: false,
      totalCount: action.productStocks.length,
      error: null,
    })
  ),
  on(StockActions.StocksPageLoadFailed, (state, action) => ({
    ...state,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //creation
  on(StockActions.ProductStockCreate, (state) => ({
    ...state,
    showInitWaitingMessage: true,
    error: null,
  })),
  on(StockActions.ProductStockCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.stock, {
      ...state,
      showInitWaitingMessage: false,
      error: null,
    })
  ),
  on(StockActions.ProductStockCreationFailed, (state, action) => ({
    ...state,
    showInitWaitingMessage: true,
    error: action.error,
  })),
  // Update
  on(StockActions.ProductStockUpdate, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(StockActions.ProductStockUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(
      { id: action.stock.id, changes: action.stock },
      {
        ...state,
        showInitWaitingMessage: false,
        error: null,
      }
    )
  ),
  on(StockActions.ProductStockUpdateFailed, (state, action) => ({
    ...state,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //Delete
  on(StockActions.ProductStockDelete, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(StockActions.ProductStockDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.stockId, {
      ...state,
      showInitWaitingMessage: false,
      error: null,
    })
  ),
  on(StockActions.ProductStockDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    showInitWaitingMessage: false,
  }))
);

export function productStocksReducer(
  state: ProductStocksState | undefined,
  action: Action
) {
  return productStockReducer(state, action);
}
