import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
  QueryResultsModel,
} from '../../../../core/_base/crud';
import { SubheaderService } from '../../../../core/_base/layout';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { QuestionAnswerModel } from '../../../../core/service-client/questionanswer/questionanswer.model';
import {
  selectFAQsInitWaitingMessage,
  selectFAQsInStore,
  selectFAQsPageLastQuery,
} from '../../../../core/service-client/questionanswer/faq.selectors';
import {
  FAQDeleted,
  FAQPageRequested,
} from '../../../../core/service-client/questionanswer/faq.actions';
import { QuestionAnswerService } from '../../../../core/service-client/questionanswer/question-answer.service';
import { QuestionAnswerDatasource } from '../../../../core/service-client/questionanswer/questionAnswerDatasource';
import { SortDirection } from '@angular/material/sort/typings/sort-direction';
import { Sort } from '@angular/material/sort';
import {
  selectOrdersInitWaitingMessage,
  selectOrdersInStore,
} from '../../../../core/e-commerce/orders/orders.selectors';

@Component({
  selector: 'kt-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss'],
})
export class QuestionAnswerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;

  dataSource: QuestionAnswerDatasource;
  faqId: number;
  filterStatus = new FormControl('');
  displayedColumns = ['id', 'title', 'body', 'type', 'actions'];
  private subscriptions: Subscription[] = [];
  filterCondition = '';

  loading$: Observable<boolean> = of(true);
  selection = new SelectionModel<QuestionAnswerModel>(true, []);
  faqResult: QuestionAnswerModel[] = [];
  lastQuery: QueryParamsModel;
  direction: SortDirection;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private questionAnswerService: QuestionAnswerService
  ) {}

  ngOnInit(): void {
    this.loadFAQPage();
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadFAQPage()))
      .subscribe();

    this.filterStatus.valueChanges.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadFAQPage();
    });
    this.subheaderService.setTitle('FAQs');

    this.dataSource = new QuestionAnswerDatasource(this.store);
    // this.dataSource = new FAQDatasource( this.faqService, this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.faqResult = res;
      });
    this.subscriptions.push(entitiesSubscription);

    const lastQuerySubscription = this.store
      .pipe(select(selectFAQsPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    // Load last query from store
    this.subscriptions.push(lastQuerySubscription);
    this.loadFAQPage(this.lastQuery);

    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadFAQPage();
        })
      )
      .subscribe();

    const initWaitingMessageSubscription = this.store
      .pipe(select(selectFAQsInitWaitingMessage))
      .subscribe((v) => {
        if (!v) {
          this.loading$ = of(false);
        }
      });

    const routeSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.id) {
          this.restoreState(this.lastQuery, +params.id);
        }

        const pageSize = this.lastQuery.pageSize;
        const pageNumber = this.lastQuery.pageNumber;
        this.paginator._changePageSize(pageSize);
        this.paginator.pageIndex = pageNumber;
        this.loadFAQPage(this.lastQuery);
      }
    );

    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';
    this.subscriptions.push(
      paginatorSubscriptions,
      lastQuerySubscription,
      initWaitingMessageSubscription,
      routeSubscription,
      searchSubscription
    );
  }

  showNotification(messageText: string, messageType: MessageType) {
    this.layoutUtilsService.showActionNotification(messageText, messageType);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  loadFAQPage(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }
    // console.log(
    //     queryParams);
    this.store.dispatch(FAQPageRequested({ page: queryParams }));
    this.selection.clear();
  }
  deleteFAQ(id: number) {
    this.store.dispatch(FAQDeleted({ FAQId: id }));
    this.showNotification('Element Deleted Successfully !', MessageType.Delete);
  }

  editFAQ(id) {
    this.router.navigate(['/service-client/question-answer/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }

  createFAQ() {
    this.router.navigateByUrl('/service-client/question-answer/add');
  }

  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value != '') {
      filter.status = this.filterStatus.value;
      // filter.status.push(this.filterStatus.value);
    }

    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  /*/** FILTRATION */
  /*filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        if (this.filterStatus && this.filterStatus.length > 0) {
            filter.status = +this.filterStatus;
        }

        if (this.filterCondition && this.filterCondition.length > 0) {
            filter.condition = +this.filterCondition;
        }

        filter.query = searchText;
        return filter;
    }*/
  restoreState(queryParams: QueryParamsModel, id: number) {
    if (!queryParams.filter) {
      return;
    }

    if ('status' in queryParams.filter) {
      this.filterStatus = queryParams.filter.status;
    }
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.faqResult.forEach((row) => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.faqResult.length;
    return numSelected === numRows;
  }

  ngAfterViewInit(): void {}
}
