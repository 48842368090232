// RxJS
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  selectContactsInStore,
  selectContactsPageLoading,
  selectContactsShowInitWaitingMessage,
  selectTotalContactsLoading,
} from './contact.selector';

// Selectors

export class ContactsDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectContactsPageLoading));
    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectContactsShowInitWaitingMessage)
    );

    this.totalCount$ = this.store.pipe(select(selectTotalContactsLoading));

    this.store
      .pipe(select(selectContactsInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
