export class ClientQuestionModel {
  id: number;
  type: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  orderNumber: string;
  object: string;
  message: string;
}
