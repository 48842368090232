import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  MatDialog,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from '@angular/material';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../core/_base/crud';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  tap,
} from 'rxjs/operators';
import { QuotationService } from '../../../../../../core/e-commerce/quotation/quotation.service';
import { QuotationModel } from '../../../../../../core/e-commerce/quotation/quotation.model';
import { QuotationDetailComponent } from '../quotation-detail/quotation-detail.component';
import { ActivatedRoute, Router } from '@angular/router';
import { KtDialogService } from '../../../../../../core/_base/layout';
import { ClientService } from '../../../../../../core/e-commerce/clients/client.service';
import { CompaniesService } from '../../../../../../core/e-commerce/companies/companies.service';

@Component({
  selector: 'kt-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.scss'],
})
export class QuotationListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  dataSource: MatTableDataSource<QuotationModel> = new MatTableDataSource([]);

  totalElements: number = 0;
  displayedColumns = [
    'select',
    'id',
    'client',
    'company',
    'createdAt',
    'expiredAt',
    'status',
    'actions',
  ];

  loading$: Observable<boolean> = of(true);

  filterStatus = new FormControl('');

  selection = new SelectionModel<QuotationModel>(true, []);

  private subscriptions: Subscription[] = [];
  pageTitle = 'Liste de devis';

  constructor(
    private store: Store<AppState>,
    private quotationService: QuotationService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ktDialogService: KtDialogService,
    private cdr: ChangeDetectorRef,
    private companyService: CompaniesService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.search) {
      this.pageTitle =
        this.activatedRoute.snapshot.queryParams.search + ' | Liste de devis';
      this.searchInput.nativeElement.value =
        this.activatedRoute.snapshot.queryParams.search;
    }
    this.loadQuotationList();
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.addSearch();
          this.loadQuotationList();
        })
      )
      .subscribe();
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadQuotationList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }

  loadQuotationList(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: QueryParamsModel;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }

    this.ktDialogService.show();
    this.loading$ = of(true);

    this.quotationService
      .getAllQuotations(queryParams)
      .pipe(
        finalize(() => {
          this.loading$ = of(false);
          this.ktDialogService.hide();
        })
      )
      .subscribe((quotations) => {
        quotations.content.forEach((q) =>
          this.clientService.getById(q.clientId).subscribe((c) => {
            this.companyService.getById(c.companyId).subscribe((company) => {
              q.companyName = company.name;
              q.clientNumber = company.accountNumber;
              this.cdr.detectChanges();
            });
            q.clientName =
              c.name.toLowerCase() + ' ' + c.lastname.toLowerCase();
            q.clientMail = c.mail;
            this.cdr.detectChanges();
          })
        );
        this.dataSource.data = quotations.content;
        this.totalElements = quotations.totalElements;
      });
  }

  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value != '') {
      filter.status = this.filterStatus.value;
    }
    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  editQuotation(quotationId: number) {
    this.router.navigate(['ecommerce', 'quotations', 'edit', quotationId]);
  }

  viewQuotation(item: QuotationModel) {
    this.dialog.open(QuotationDetailComponent, {
      data: item,
      width: '900px',
    });
  }

  public sendViaEmail(quotation: QuotationModel) {
    const _title = 'Devis  ' + quotation.number;
    const _description = 'Envoyer une copie de devis en piece jointe';
    const _waitDesciption = 'Envoie en cours...';
    const _defaultMail = quotation.clientMail;
    const dialogRef = this.layoutUtilsService.sendMail(
      _title,
      _description,
      _waitDesciption,
      _defaultMail
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.quotationService.sendQuotationViaEmail(quotation.id, res).subscribe(
        () =>
          this.layoutUtilsService.showActionNotification(
            'Un mail a été envoyé à ' + res,
            MessageType.Create
          ),
        () =>
          this.layoutUtilsService.showActionNotification(
            "Oops! l'e-mail n'a pas été envoyé",
            MessageType.Delete
          )
      );
    });
  }

  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'ACCEPTED':
        return 'success';
      case 'VALIDATED':
        return 'success';
      case 'SUBMITED':
        return 'metal';
      case 'CANCELED':
        return 'danger';
        break;
      case 'EXPIRED':
        return 'danger';
        break;
      default:
        return '';
    }
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }
  addSearch() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        search: this.searchInput.nativeElement.value
          ? this.searchInput.nativeElement.value
          : null,
      },
      queryParamsHandling: 'merge',
    });
  }

  archiveQuotation(_item: QuotationModel) {
    const _title = 'Archivage de devis ==> ' + _item.number;
    const _description = 'Voulez-vous vraiment archiver ce devis ?';
    const _waitDesciption = 'Archivage en cours...';
    const btnTitle = 'Archiver';
    const dialogRef = this.layoutUtilsService.archiveElement(
      _title,
      _description,
      _waitDesciption,
      btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.quotationService.archiveQuotation(_item.id).subscribe((data) => {
        this.layoutUtilsService.showActionNotification(
          'Le devis est archivé',
          MessageType.Update
        );
        this.loadQuotationList();
      });
    });
  }
  
  downloadPDF(id: number){
    this.quotationService.downloadQuotation(id).subscribe((invoice) => {
      const blob = new Blob([invoice], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download =
        'Devis' +
        new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') +
        '.pdf';
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
}
