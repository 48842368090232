import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductState } from './product.reducers';
import { ProductModel } from './product.model';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
// Lodash
import { each } from 'lodash';
import { selectCategoriesState } from '../categories/category.selector';

export const selectProductsState =
  createFeatureSelector<ProductState>('products');

export const selectProductById = (productId: number) =>
  createSelector(
    selectProductsState,
    (productsState) => productsState.entities[productId]
  );

export const selectProductsPageLoading = createSelector(
  selectProductsState,
  (productsState) => productsState.listLoading
);

export const selectProductsActionLoading = createSelector(
  selectProductsState,
  (customersState) => customersState.actionsLoading
);

export const selectProductsPageLastQuery = createSelector(
  selectProductsState,
  (productsState) => productsState.lastQuery
);

export const selectLastCreatedProductId = createSelector(
  selectProductsState,
  (productsState) => productsState.lastCreatedProductId
);

export const selectProductsInitWaitingMessage = createSelector(
  selectProductsState,
  (productsState) => productsState.showInitWaitingMessage
);

export const selectProductsInStore = createSelector(
  selectProductsState,
  (productsState) => {
    const items: ProductModel[] = [];
    each(productsState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: ProductModel[] = httpExtension.sortArray(
      items,
      productsState.lastQuery.sortField,
      productsState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, productsState.totalCount, '');
  }
);
export const selectErrorMessage = createSelector(
  selectProductsState,
  (productState) => productState.error
);

export const selectHasProductsInStore = createSelector(
  selectProductsInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  }
);
