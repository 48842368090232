import { Injectable } from '@angular/core';
import { AbstractService } from '../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../environments/environment';
import { BlogModel } from './blog.model';
import { Observable } from 'rxjs';
import { QueryParamsModel } from '../_base/crud';
import { Page } from '../_base/crud/models/page';

@Injectable({
  providedIn: 'root',
})
export class BlogService extends AbstractService<BlogModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}blog-posts`;
  }
  public GetAllBlog(): Observable<BlogModel[]> {
    const url = `${this.url}`;
    return this.http.get<BlogModel[]>(url);
  }
  public registerBlog(entity: BlogModel): Observable<BlogModel> {
    return this.http.post<BlogModel>(this.url, entity);
  }

  public updateBlog(_blog: BlogModel, id: number): Observable<BlogModel> {
    const url = `${this.url}/` + id;
    return this.http.put<BlogModel>(url, _blog);
  }

  public getAllByFilter(filter: QueryParamsModel): Observable<Page<BlogModel>> {
    const url = `${this.url}`;

    const options = {
      params: new HttpParams()
        .append('page', filter.pageNumber.toString())
        .append('size', filter.pageSize ? filter.pageSize.toString() : '25')
        .append('search', filter.filter.query)
        .append('status', filter.filter.status),
    };
    return this.http.get<Page<BlogModel>>(url, options);
  }
}
