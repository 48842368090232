import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CategoryModel} from '../../../../../../core/e-commerce/categories/category.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutUtilsService, MessageType, QueryParamsModel, TypesUtilsService, } from '../../../../../../core/_base/crud';
import {LayoutConfigService, SubheaderService, } from '../../../../../../core/_base/layout';
import {CategoryActions} from '../../../../../../core/e-commerce';
import {
    selectCategoriesActionLoading,
    selectCategoriesInStore,
    selectCategoryById,
    selectErrorMessage,
    selectLastCreatedCategoryId,
} from '../../../../../../core/e-commerce/categories/category.selector';
import {Update} from '@ngrx/entity';
import {MatDialog} from '@angular/material/dialog';
import {CategoriesService} from '../../../../../../core/e-commerce/categories/categories.service';
import {ResourcesService} from '../../../../../../core/services/resources.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import { map, skip, startWith } from 'rxjs/operators';
import {AccessoriesService} from '../../../../../../core/e-commerce/categories/accessories/accessories.service';

// Actions

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-categories-edit-dialog',
    templateUrl: './category-edit.dialog.component.html',
    styleUrls: ['./category-edit.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryEditDialogComponent implements OnInit, OnDestroy {
    // TODO Review this component

    // Public properties
    category: CategoryModel;
    categoryId$: Observable<number>;
    oldCategory: CategoryModel;
    selectedTab = 0;
    loading$: Observable<boolean>;
    errorMessage: Observable<string>;

    // Progress
    progress = new BehaviorSubject<number>(0);
    uploading = false;

    categoryForm: FormGroup;
    hasFormErrors = false;
    private componentSubscriptions: Subscription[] = [];
    // sticky portlet header margin
    private headerMargin: number;
    sousCategories: CategoryModel;

    // data
    categories: CategoryModel[];
    suggestedCategories: Observable<CategoryModel[]>;
    filteredCategories: CategoryModel[];
    selectedCategory: CategoryModel;
    categoryPosition: number[];
    edit: boolean;
    show: boolean;
    firstCategories: CategoryModel[];
    secondCategories: CategoryModel[];
    listCategory: CategoryModel[];
    theardLevel = new Subject<boolean>();
    public details = this.theardLevel.asObservable();

    /**
     * Component constructor
     *
     * @param store: Store<AppState>
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param typesUtilsService: TypesUtilsService
     * @param fb: FormBuilder
     * @param dialog: MatDialog
     * @param subheaderService: SubheaderService
     * @param layoutUtilsService: SubheaderService
     * @param layoutConfigService: LayoutConfigService
     * @param categoriesService: CategoriesService
     * @param cdr: ChangeDetectorRef
     * @param resourcesService: ResourcesService
     * @param cdRef
     */
    constructor(
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private typesUtilsService: TypesUtilsService,
        private fb: FormBuilder,
        public dialog: MatDialog,
        private subheaderService: SubheaderService,
        private layoutUtilsService: LayoutUtilsService,
        private layoutConfigService: LayoutConfigService,
        private categoriesService: CategoriesService,
        private cdr: ChangeDetectorRef,
        private resourcesService: ResourcesService,
        private accessoriesService: AccessoriesService,
    ) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.loading$ = this.store.select(selectCategoriesActionLoading);
        this.loadParents();
        const categories$ = this.store
        .select(selectCategoriesInStore)
        .subscribe((results) => {
          this.categories = results.items;
          this.firstCategories = this.categories.filter( c => !c.parentId );
          this.secondCategories = this.categories.filter(c => c.parentId);
          this.filteredCategories = this.secondCategories.filter
          (cc2 => this.firstCategories.map(cc1 => cc1.id).includes(cc2.parentId));
          this.filteredCategories.push(...this.firstCategories);
        });
        this.activatedRoute.params.subscribe((params) => {
            const id = params.id;
            if (id) {
                if (id > 0) {
                    this.store.pipe(select(selectCategoryById(id))).subscribe((result) => {
                        if (!result) {
                            this.loadCategoryFromService(id);
                            return;
                        }
                        this.loadPosition(id);
                        this.loadCategory(result);
                        this.loadChildren(id);
                        // @ts-ignore

                        // condition to find theard level category to display accessories part in html
                        this.accessoriesService.getCategories3Niveau().subscribe(res => {
                            this.listCategory = res;
                            // tslint:disable-next-line:triple-equals
                            this.theardLevel.next(Boolean(this.listCategory.find((cat) => cat.id == id)));
                        });


                    });
                } else {
                    const newCategory = new CategoryModel();
                    this.loadCategory(newCategory);
                }
            } else {
                const newCategory = new CategoryModel();
                this.loadCategory(newCategory);
            }
        });
        this.getActiveTab();

        // sticky portlet header
        window.onload = () => {
            const style = getComputedStyle(document.getElementById('kt_header'));
            this.headerMargin = parseInt(style.height, 0);
        };

        this.errorMessage = this.store.select(selectErrorMessage);
        const errorSub = this.errorMessage.pipe(skip(1)).subscribe((res) => {
            const message = `ERROR :` + res;
            this.layoutUtilsService.showActionNotification(
                message,
                MessageType.Create,
                10000,
                true,
                false
            );
        });
        this.componentSubscriptions.push(errorSub);

        // aa
        // @ts-ignore

    }
  loadChildren(catId: number) {
    this.categoriesService.getChildrenById(catId).subscribe(res => {
      this.sousCategories = res;
    });
  }

    selectCategory(name: string) {
        this.selectedCategory = this.categories.find((c) => c.name === name);
        this.categoryForm
            .get('parentCategoryName')
            .setValue(this.selectedCategory?.name);
    }

    loadPosition(categoryId: number) {
        this.categoriesService.findPosition(categoryId).subscribe((pos) => {
            this.categoryPosition = pos;
        });
    }

    private loadParents() {
        this.store.dispatch(
            CategoryActions.CategoriesPageRequested({
                page: new QueryParamsModel('', 'asc', 'id', 0, 400),
            })
        );
    }

    // tslint:disable-next-line:variable-name
    loadCategory(_category, fromService: boolean = false) {
        if (!_category) {
            this.goBack('');
        }
        this.category = _category;
        this.categoryId$ = of(_category.id);
        this.oldCategory = Object.assign({}, _category);
        this.selectedCategory = this.categories.find(
            (c) => c.id === _category.parentId
        );
        this.initCategory();
        if (fromService) {
            this.cdr.detectChanges();
        }
    }

    // If product didn't find in store
    loadCategoryFromService(categoryId) {
        this.categoriesService.getById(categoryId).subscribe((res) => {
            this.loadCategory(res, true);
        });
    }

    getActiveTab() {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.tab) {
                this.selectedTab = params.tab;
            }
        });
    }

    initCategory() {
        this.createForm();
        if (!this.category.id) {
            this.edit = false;
            this.subheaderService.setBreadcrumbs([
                {title: 'eCommerce', page: `/ecommerce`},
                {title: 'Categories', page: `/ecommerce/categories`},
                {title: 'Create Category', page: `/ecommerce/categories/add`},
            ]);
            return;
        }
        this.edit = true;
        this.subheaderService.setTitle('Edit Category');
        this.subheaderService.setBreadcrumbs([
            {title: 'eCommerce', page: `/ecommerce`},
            {title: 'Categories', page: `/ecommerce/categories`},
            {
                title: 'Edit Category',
                page: `/ecommerce/categories/edit`,
                queryParams: {id: this.category.id},
            },
        ]);
    }

    /**
     * Create form
     */
    createForm() {
        this.categoryForm = this.fb.group({
            name: [this.category.name, Validators.required , ],
            parentCategoryName: [this.selectedCategory?.name ?? null],
            image: [this.category.image],
            catPosition: [this.category?.position]
        });

        this.suggestedCategories = this.categoryForm
            .get('parentCategoryName')
            .valueChanges.pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value),
          map(name => name ? this._filterCategory(name.toString()) : this.filteredCategories.slice())
        );
    }

    goBack(id) {
        const url = `/ecommerce/categories?id=${id}`;
        this.router.navigateByUrl(
            url,
            /* Removed unsupported properties by Angular migration: relativeTo. */ {}
        );
    }

    goBackWithoutId() {
        this.router.navigateByUrl(
            '/ecommerce/categories',
            /* Removed unsupported properties by Angular migration: relativeTo. */ {}
        );
    }
    refreshCategory(isNew: boolean, id) {
        this.createForm();
        const url = this.router.url;
        if (!isNew) {
            this.router.navigate([url], {relativeTo: this.activatedRoute});
            return;
        }
    }

    reset() {
        this.category = Object.assign({}, this.oldCategory);
        this.createForm();
        this.hasFormErrors = false;
        this.categoryForm.markAsPristine();
        this.categoryForm.markAsUntouched();
        this.categoryForm.updateValueAndValidity();
    }

    onSumbit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.categoryForm.controls;
        /** check form */
        if (this.categoryForm.invalid) {
            Object.keys(controls).forEach((controlName) =>
                controls[controlName].markAsTouched()
            );
            this.hasFormErrors = true;
            this.selectedTab = 0;
            return;
        }
        const editedCategory = this.prepareCategory();
        if (editedCategory.id > 0) {
            this.updateCategory(editedCategory, withBack);
            return;
        }
        this.addCategory(editedCategory, withBack);
    }

    prepareCategory(): CategoryModel {
        const controls = this.categoryForm.controls;
        // tslint:disable-next-line:variable-name
        const _category = new CategoryModel();
        _category.id = this.category.id;
        _category.name = controls.name.value;
        _category.parentId = this.selectedCategory?.id;
        _category.image = this.category.image;
        _category.status = false;
        _category.position = this.edit ? controls.catPosition.value : null;
        return _category;
    }

    // tslint:disable-next-line:variable-name
    addCategory(_category: CategoryModel, withBack: boolean = false) {
        this.store.dispatch(
            CategoryActions.CategoryCreated({category: _category})
        );
        /*const store$ = this.store
            .pipe(select(selectLastCreatedCategoryId))
            .subscribe((newId) => {
                if (!newId) {
                    return;
                }
                if (withBack) {
                    this.goBack(newId);
                } else {
                    const message = `Une nouvelle catégorie a été ajoutée avec succès.`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Create,
                        10000,
                        true,
                        true
                    );
                    const url = `/ecommerce/categories/edit/${newId}`;
                    this.router.navigateByUrl(
                        url,
                        {}
                    );
                }
            });*/

        // this.componentSubscriptions.push(store$);
    }

    // tslint:disable-next-line:variable-name
    updateCategory(_category: CategoryModel, withBack: boolean = false) {
        const updateCategory: Update<CategoryModel> = {
            id: _category.id,
            changes: _category,
        };
        /*this.categoriesService.updatePosition(_category.id, _category.position).subscribe((res) => {
            if (res) {
                this.category.position = res.position;
                this.categoryForm.controls.postion = res.postion;
                this.createForm();
            }
        });*/
        this.store.dispatch(
            CategoryActions.CategoryUpdated({
                partialCategory: updateCategory,
                category: _category,
            })
        );
        if (withBack) {
            this.goBack(_category.id);
        } else {
            const message = `La catégorie a été mise à jour avec succès.`;
            this.layoutUtilsService.showActionNotification(
                message,
                MessageType.Update,
                10000,
                true,
                true
            );
           /* const url = `/ecommerce/categories/edit/${_category.id}`;
            this.router.navigateByUrl(
                url,
                {}
            );*/
            // this.refreshCategory(false, _category.id);
        }
    }

    getComponentTitle() {
        let result = 'Ajouter une nouvelle catégorie';
        if (!this.category || !this.category.id) {
            return result;
        }

        result = `Modifier Categorie - ${this.category.name}`;
        return result;
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    imageInputChange(fileInputEvent: any) {
        this.category.file = fileInputEvent.target.files[0];
        const imageSub = this.resourcesService
            .pushFileToStorage(this.category.file)
            .subscribe(
                (res) => {
                    this.uploading = true;
                    // the upload finished
                    if (res instanceof HttpResponse) {
                        const body = JSON.parse(res.body);
                        // preparing the resource infos
                        this.category.image = body.url;
                        this.uploading = false;
                        this.progress.next(0);
                        // the upload is in progress
                    } else if (res.type === HttpEventType.UploadProgress) {
                        this.progress.next(Math.round((100 * res.loaded) / res.total));
                    }
                },
                (error) => {
                    this.uploading = false;
                    // this.hasErrors.next(true);
                }
            );
        this.componentSubscriptions.push(imageSub);
    }
  editCategory(id) {
    this.router.navigate(['/ecommerce/categories/edit', id]);
  }
  filteredCategoryExecuted() {
   this.suggestedCategories = this.categoryForm.get('parentCategoryName').valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value),
      map(name => name ? this._filterCategory(name.toString()) : this.filteredCategories?.slice())
    );
  }
  private _filterCategory(name: any): CategoryModel[] {
    return this.filteredCategories.filter(option => option.name.toLowerCase().includes(name?.toLowerCase()));
  }

    ngOnDestroy() {
        this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
    }
}
