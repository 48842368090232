// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { AuthActions } from '../../../../../core/auth';
import { currentUser } from '../../../../../core/auth/_selectors/auth.selectors';
import { UserModel } from '../../../../../core/_models/user.model';

@Component({
  selector: 'kt-user-profile2',
  templateUrl: './user-profile2.component.html',
  styleUrls: ['./user-profile2.component.scss'],

})
export class UserProfile2Component implements OnInit {
  // Public properties
  user$: Observable<UserModel>;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  @Input() gridNavSkin: 'light' | 'dark' = 'light';

  /**
   * Component constructor
   *
   * @param store: Store<AppState>
   */
  constructor(private store: Store<AppState>) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.store.dispatch(AuthActions.getLoggedInUser());
    this.user$ = this.store.pipe(select(currentUser));
  }

  onSVGInserted(svg) {
    svg.classList.add('kt-svg-icon', 'kt-svg-icon--success', 'kt-svg-icon--lg');
  }

  /**
   * Log out
   */
  logout() {
    this.store.dispatch(AuthActions.logout());
  }
}
