// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuthReducer from '../_reducers/auth.reducers';
import { AuthState } from '../_reducers/auth.reducers';

const getAuthState = createFeatureSelector<AuthState>(
  fromAuthReducer.authFeatureKey
);

export const currentToken = createSelector(
  getAuthState,
  (state: AuthState) => state.token
);
export const error = createSelector(
  getAuthState,
  (state: AuthState) => state.error
);
export const isLoading = createSelector(
  getAuthState,
  (state: AuthState) => state.isLoading
);
export const errorMessage = createSelector(
  getAuthState,
  (state: AuthState) => state.errorMessage
);
export const isLoggedIn = createSelector(
  getAuthState,
  (state: AuthState) => state.isLoggedIn
);
export const currentUserMail = createSelector(
  getAuthState,
  (state: AuthState) => state.mail
);
export const currentUserRole = createSelector(
  getAuthState,
  (state: AuthState) => state.role
);
export const currentUser = createSelector(
  getAuthState,
  (state: AuthState) => state.user
);
