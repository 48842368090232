import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { QueryParamsModel } from '../../_base/crud';
import { ContactModel } from './contact.model';

@Injectable()
export class ContactService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}supplier`;
  }

  public addContact(contact: ContactModel): Observable<ContactModel> {
    const url = `${this.url}/contact`;
    return this.http.post<ContactModel>(url, contact);
  }

  public updateContact(contact: ContactModel): Observable<ContactModel> {
    const url = `${this.url}/contact/update`;
    return this.http.put<ContactModel>(url, contact);
  }

  public deleteContact(contactId: number) {
    const url = `${this.url}/contact/${contactId}`;
    return this.http.delete<void>(url);
  }

  public getClientsBySupplier(
    supplierId: number,
    queryParams: QueryParamsModel
  ): Observable<Page<ContactModel>> {
    const url = `${this.url}/${supplierId}/contacts`;
    let params = new HttpParams();
    params = params.append('page', queryParams.pageNumber.toString());
    params = params.append('size', queryParams.pageSize.toString());
    params = params.append(
      'search',
      queryParams.filter.query ? queryParams.filter.query : ''
    );
    const options = { params };
    return this.http.get<Page<ContactModel>>(url, options);
  }
}
