import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers';
import * as CharacteristicActions from './product-characteristic.actions';
import {catchError, map, retry, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ProductCharacteristicsService} from './product-characteristics.service';
import {LayoutUtilsService, MessageType} from '../../_base/crud';
import * as CategoryActions from '../categories/category.action';

@Injectable()

export class ProductCharacteristicEffects {
    CharacteristicListRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.ProductCharacteristicListRequested),
            switchMap((action) =>
                this.characteristicService.getByProductId(action.productId).pipe(
                    map((productList) => {
                        return CharacteristicActions.ProductCharacteristicListRequestedSuccessfully({
                            productCharacteristics: productList,

                        });
                        console.log('Product Characteristics:', productList); // Ajoutez cette ligne

                    }),
                    catchError((error) =>
                        of(
                            CharacteristicActions.ProductCharacteristicListRequestFailed({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
    CharacteristicDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.ProductCharacteristicDeleted),
            switchMap((action) =>
                this.characteristicService.delete(action.id, action.productId).pipe(
                    map(() =>
                        CharacteristicActions.ProductCharacteristicDeletedSuccessfully({
                            id: action.id,
                        })
                    ),
                    catchError((error) =>
                        of(
                            CharacteristicActions.ProductCharacteristicDeleteFailed({error})
                        )
                    )
                )
            )
        )
    );

  ProductCharacteristicDeletedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CharacteristicActions.ProductCharacteristicDeletedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Caractéristique du produit supprimée',
              MessageType.Delete,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  ProductCharacteristicDeleteFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CharacteristicActions.ProductCharacteristicDeleteFailed),
        switchMap((action) =>
          of(
             this.layoutUtilsService.showActionNotification(
               'Une erreur est survenue !',
               MessageType.Delete,
               3000,
               false
             )
          )
        )
      ),
    { dispatch: false }
  );


    CharacteristicCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.ProductCharacteristicCreated),
            switchMap((action) =>
                this.characteristicService
                    .save(action.productCharacteristic, action.productId)
                    .pipe(
                        // retry 3 times on error
                        retry(3),
                        map((productCharacteristic) =>
                            CharacteristicActions.ProductCharacteristicCreatedSuccessfully({
                                productCharacteristic,
                            }),
                        ),
                        catchError((error) => {
                            console.log(
                                `oups ..  ${action.productCharacteristic.label}      has not been inserted !`
                            );
                            return of(
                                CharacteristicActions.ProductCharacteristicCreationFailed({
                                    error,
                                })
                            );
                        })
                    )
            )
        )
    );
    ProductCharacteristicCreatedSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CharacteristicActions.ProductCharacteristicCreatedSuccessfully),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'Caractérisitique ajoutée avec succès',
                            MessageType.Create
                        )
                    )
                )
            ),
        {dispatch: false}
    );
    ProductCharacteristicCreationFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CharacteristicActions.ProductCharacteristicCreationFailed),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'Erreur lors de la création de la caractéristique',
                            MessageType.Create
                        )
                    )
                )
            ),
        {dispatch: false}
    );
    ProductUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CharacteristicActions.ProductCharacteristicUpdated),
            switchMap((action) =>
                this.characteristicService
                    .update(action.productCharacteristic, action.productId)
                    .pipe(
                        map((productCharacteristic) =>
                            CharacteristicActions.ProductCharacteristicUpdatedSuccessfully({
                                productCharacteristic,
                                partialCharacteristic: action.partialCharacteristic,
                            })
                        ),
                        catchError((error) =>
                            of(
                                CharacteristicActions.ProductCharacteristicUpdateFailed({
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
    ProductCharacteristicUpdatedSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CharacteristicActions.ProductCharacteristicUpdatedSuccessfully),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'La caractérisitique a été modifiée avec succès',
                            MessageType.Update,
                            3000,
                            false
                        )
                    )
                )
            ),
        {dispatch: false}
    );
    ProductCharacteristicUpdateFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CharacteristicActions.ProductCharacteristicUpdateFailed),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'Une erreur est survenue lors de la mise à jour de la caractéristique!',
                            MessageType.Update,
                            3000,
                            false
                        )
                    )
                )
            ),
        {dispatch: false}
    );

    constructor(
        private actions$: Actions,
        private characteristicService: ProductCharacteristicsService,
        private layoutUtilsService: LayoutUtilsService,
        private store: Store<AppState>
    ) {
    }
}
