import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import { Response } from '../../_base/crud/models/response';
import { Action, createReducer, on } from '@ngrx/store';
import { CategoryCharacteristicModel } from './category-characteristic.model';
//Actions
import * as CharacteristicActions from './category-characteristic.actions';

export interface CategoryCharacteristicState
  extends EntityState<CategoryCharacteristicModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedCategoryCharacteristicId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
}

export const adapter: EntityAdapter<CategoryCharacteristicModel> =
  createEntityAdapter<CategoryCharacteristicModel>();

export const initialCategoryCharacteristicState: CategoryCharacteristicState =
  adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedCategoryCharacteristicId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    errorMessage: '',
  });

const categoryCharacteristicReducer = createReducer(
  initialCategoryCharacteristicState,
  on(
    CharacteristicActions.CategoryCharacteristicListRequested,
    (state, action) => ({
      ...state,
      actionsLoading: true,
      listLoading: true,
      showInitWaitingMessage: true,
      error: null,
      lastAction: '',
      progress: 0,
    })
  ),

  on(
    CharacteristicActions.CategoryCharacteristicListRequestedSuccessfully,
    (state, action) =>
      adapter.addAll(action.categoryCharacteristics, {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
      })
  ),
  on(
    CharacteristicActions.CategoryCharacteristicListRequestFailed,
    (state, action) => ({
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      error: action.error,
    })
  ),
  //creation
  on(CharacteristicActions.CategoryCharacteristicCreated, (state) => ({
    ...state,
    actionsLoading: false,
    lastAction: '',
  })),

  on(
    CharacteristicActions.CategoryCharacteristicCreatedSuccessfully,
    (state, action) =>
      adapter.addOne(action.categoryCharacteristic, {
        ...state,
        actionsLoading: true,
        lastCreatedCategoryCharacteristicId: action.categoryCharacteristic.id,
        progress: 0,
      })
  ),
  on(
    CharacteristicActions.CategoryCharacteristicCreationFailed,
    (state, action) => ({
      ...state,
      actionsLoading: false,
      error: action.error,
    })
  ),
  //Update
  on(CharacteristicActions.CategoryCharacteristicUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(
    CharacteristicActions.CategoryCharacteristicUpdatedSuccessfully,
    (state, action) =>
      adapter.updateOne(action.partialCharacteristic, {
        ...state,
        actionsLoading: false,
      })
  ),
  on(
    CharacteristicActions.CategoryCharacteristicUpdateFailed,
    (state: CategoryCharacteristicState, action) => {
      let errorMessage: string;
      switch (action.error.status) {
        case 500:
          errorMessage = 'EDIT.ERROR.SERVER';
          break;
        case 403:
          errorMessage = 'AUTH.VALIDATION.NOT_AUTHORIZED';
          break;
        case 404:
          errorMessage = 'EDIT.ERROR.NOT_FOUND';
          break;
        default:
          errorMessage = 'EDIT.ERROR.UNKNOWN';
          break;
      }
      return {
        ...state,
        listLoading: false,
        actionsLoading: false,
        errorMessage: errorMessage,
        error: action.error,
      };
    }
  ),
  //Delete
  on(CharacteristicActions.CategoryCharacteristicDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(
    CharacteristicActions.CategoryCharacteristicDeletedSuccessfully,
    (state, action) =>
      adapter.removeOne(action.id, {
        ...state,
        actionsLoading: false,
        lastAction: 'DELETE_CATEGORY_SIMPLE.MESSAGE',
      })
  ),
  on(
    CharacteristicActions.CategoryCharacteristicDeleteFailed,
    (state: CategoryCharacteristicState, action) => {
      let errorMessage: string;
      switch (action.error.status) {
        case 500:
          errorMessage = 'DELETE.ERROR.SERVER';
          break;
        case 403:
          errorMessage = 'AUTH.VALIDATION.CANT_DELETE';
          break;
        case 404:
          errorMessage = 'AUTH.VALIDATION.NOT_FOUND';
          break;
        default:
          errorMessage = 'AUTH.VALIDATION.NO_CONNECTION';
          break;
      }
      return {
        ...state,
        listLoading: false,
        actionsLoading: false,
        errorMessage: errorMessage,
        error: action.error,
      };
    }
  )
);

export function categoryCharacteristicsReducer(
  state: CategoryCharacteristicState | undefined,
  action: Action
) {
  return categoryCharacteristicReducer(state, action);
}
