import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as LogsActions from './logs.action';
import { Response } from '../../_base/crud/models/response';
import {LogsModel} from './logs.model';

export interface LogsState extends EntityState<LogsModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<LogsModel> =
  createEntityAdapter<LogsModel>();

export const initialLogsState: LogsState =
  adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    errorMessage: '',
    lastAction: '',
    progress: 0,
  });

const logReducer = createReducer(
  initialLogsState,
  on(LogsActions.LogsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),
  on(
      LogsActions.LogsPageLoadedSuccessfully,
    (state, action) =>
      adapter.addAll(action.logs, {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        totalCount: action.totalCount,
      })
  ),
  on(LogsActions.LogsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  }))
);

export function logsReducer(
  state: LogsState | undefined,
  action: Action
) {
  return logReducer(state, action);
}
