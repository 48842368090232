import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { DiscountCompanyApiActions, DiscountCompanyPageActions } from './actions';
import { CompanyDiscountService } from './company-discount.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable()
export class DiscountCompaniesEffects {

    constructor(
        private actions$: Actions,
        private discountCompaniesService: CompanyDiscountService,
        private store: Store<AppState>
      ) {}

    loadDiscountCompanies$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(DiscountCompanyPageActions.loadCompaniesDiscounts),
                mergeMap(() => this.discountCompaniesService.getAllDiscountCompanies()
                    .pipe(
                        map(discountCompanies =>
                                 DiscountCompanyApiActions.loadDiscountsCopmaniesSuccess({discountCompanies})),
                        catchError(error =>  of(DiscountCompanyApiActions.loadDiscountsCopmaniesFailure({ error })))
                        )
                    ))
        }
    );

    createDiscountCompany$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(DiscountCompanyPageActions.addNewCompanyDiscount),
                concatMap((action) => this.discountCompaniesService.createDiscountCompanies(action.companyDiscount)
                    .pipe(
                        map(response =>
                                 DiscountCompanyApiActions.createDiscountCompanySuccess({discountCompany: response })),
                        catchError(error =>  of(DiscountCompanyApiActions.createDiscountCompanyFailure({ error })))
                        )
                    ))
        }
    );


    updateDiscountCompany$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(DiscountCompanyPageActions.updateCompanyDiscount),
                concatMap((action) => this.discountCompaniesService.updateDiscountCompanies(action.update.changes)
                    .pipe(
                        map((response) => DiscountCompanyApiActions.updateDiscountCompanySuccess({discountCompany: action.update, ...response})),
                        catchError(error =>  of(DiscountCompanyApiActions.updateDiscountCompanyFailure({ error })))
                        )
                    ))
        }
    );


    deleteDiscountCompany$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(DiscountCompanyPageActions.deleteDiscountCompany),
                concatMap((action) => this.discountCompaniesService.deleteDiscountCompanies(action.discountCompanyId)
                    .pipe(
                        map(response =>
                                 DiscountCompanyApiActions.deleteDiscountCompanySuccess({discountCompanyId: action.discountCompanyId})),
                        catchError(error =>  of(DiscountCompanyApiActions.deleteDiscountCompanyFailure({ error })))
                        )
                    ))
        }
    );
        
}
