import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import {
  LayoutUtilsService,
  QueryParamsModel,
} from '../../../../../../core/_base/crud';
import {
  CreateContact,
  DeleteContact,
  UpdateContact,
} from '../../../../../../core/e-commerce/contacts-supplier/contact.action';
import { selectContactsPageLastQuery } from '../../../../../../core/e-commerce/contacts-supplier/contact.selector';
import { ContactModel } from '../../../../../../core/e-commerce/contacts-supplier/contact.model';
import { ContactsDataSource } from '../../../../../../core/e-commerce/contacts-supplier/contacts.datasource';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { ContactActions } from '../../../../../../core/e-commerce';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { SupplierService } from '../../../../../../core/e-commerce/suppliers/supplier.service';

@Component({
  selector: 'kt-suppliers',
  templateUrl: './contacts-supplier.component.html',
})
export class ContactsSupplierComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('sort2', { static: true }) sort2: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  contactForm: FormGroup;
  dataSource: ContactsDataSource;

  myControl = new FormControl();

  displayedColumns = [
    'select',
    'id',
    'name',
    'lastname',
    'mail',
    'phone',
    'actions',
  ];

  editSupplierLine = -1;

  loadingAfterSubmit = false;

  loading$: Observable<boolean>;

  suppliersResult: ContactModel[] = [];

  lastQuery: QueryParamsModel;

  supplierId: number;

  supplierName: string;

  selection = new SelectionModel<ContactModel>(true, []);

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private activatedRoute: ActivatedRoute,
    private supplierService: SupplierService
  ) {}

  ngOnInit(): void {
    this.supplierId = this.activatedRoute.snapshot.queryParams.id;

    this.contactForm = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      mail: ['', Validators.required],
      phone: ['', Validators.required],
    });

    this.paginator._changePageSize(10);

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadContactsList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    /* search*/
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadContactsList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    /**/
    this.loadContactsList();
    // Init DataSource
    this.dataSource = new ContactsDataSource(this.store);

    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.suppliersResult = res;
      });
    this.subscriptions.push(entitiesSubscription);
    /**/

    const lastQuerySubscription = this.store
      .pipe(select(selectContactsPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    this.loadContactsList(this.lastQuery);
    // Read from URL itemId, for restore previous state

    const routeSubscription = this.activatedRoute.queryParams.subscribe(() => {
      const pageSize = this.lastQuery.pageSize;
      const pageNumber = this.lastQuery.pageNumber;
      this.paginator._changePageSize(pageSize);
      this.paginator.pageIndex = pageNumber;
      this.loadContactsList(this.lastQuery);
    });
    this.supplierService.getSupplierById(this.supplierId).subscribe((res) => {
      this.supplierName = res.name;
    });
    this.subscriptions.push(
      lastQuerySubscription,
      entitiesSubscription,
      routeSubscription,
      sortSubscription,
      searchSubscription
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  createContact() {
    this.editSupplierLine = 0;
  }

  loadContactsList(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }
    queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.store.dispatch(
      ContactActions.ContactsPageRequested({
        supplierId: this.supplierId,
        page: queryParams,
      })
    );
  }

  filterConfiguration(): string {
    const filter: any = {};
    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  saveNewSupplier() {
    this.loadingAfterSubmit = true;
    const contact: ContactModel = {
      id: this.contactForm.value.id,
      name: this.contactForm.value.name,
      lastname: this.contactForm.value.lastname,
      mail: this.contactForm.value.mail,
      phone: this.contactForm.value.phone,
      supplierId: this.supplierId,
    };
    this.store.dispatch(CreateContact({ contact }));
    setTimeout(() => {
      this.loadingAfterSubmit = false;
      this.cancelEditSupplier();
    }, 1000); // TODO use Store State
    this.loadContactsList();
  }

  editSupplier(item: ContactModel) {
    this.editSupplierLine = item.id;
    this.contactForm.get('id').setValue(item.id);
    this.contactForm.get('name').setValue(item.name);
    this.contactForm.get('lastname').setValue(item.lastname);
    this.contactForm.get('mail').setValue(item.mail);
    this.contactForm.get('phone').setValue(item.phone);
  }

  deleteSupplier(item: ContactModel) {
    const _title = 'Supprimer le contact du  frounisseur';
    const _description = 'Voulez-vous vraiment supprimer le contact ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(DeleteContact({ contactId: item.id }));
    });
    this.loadContactsList();
  }

  saveEditedSupplier() {
    this.loadingAfterSubmit = true;
    const contact: ContactModel = {
      id: this.contactForm.value.id,
      name: this.contactForm.value.name,
      lastname: this.contactForm.value.lastname,
      mail: this.contactForm.value.mail,
      phone: this.contactForm.value.phone,
      supplierId: this.supplierId,
    };
    this.store.dispatch(UpdateContact({ contact }));
    setTimeout(() => {
      this.loadingAfterSubmit = false;
      this.cancelEditSupplier();
    }, 1000); // TODO use Store State
  }

  cancelEditSupplier() {
    this.editSupplierLine = -1;
    this.contactForm.reset();
  }
}
