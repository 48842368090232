import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { AccessoriesService } from '../../../../../../../core/e-commerce/categories/accessories/accessories.service';
import { CategoryModel } from '../../../../../../../core/e-commerce/categories/category.model';
import { LayoutUtilsService, QueryParamsModel } from '../../../../../../../core/_base/crud';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../core/reducers';
import { AccessoriesActions } from '../../../../../../../core/e-commerce';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription} from 'rxjs';
import { AccessoiresDatasource } from '../../../../../../../core/e-commerce/categories/accessories/accessoir.datasource';


@Component({
  selector: 'kt-accessoire-categorie',
  templateUrl: './accessoire-categorie.component.html',
  styleUrls: ['./accessoire-categorie.component.scss']
})
export class AccessoireCategorieComponent implements OnInit {
  searchText: string;
  dataSource: AccessoiresDatasource;
  products = new FormControl();
  suggestedProducts: CategoryModel[] = [];
  @Input() catId: number;
  filterStatus = new FormControl('');
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  selCat: CategoryModel;
  catogoriesResult: CategoryModel[] = [];
  private subscriptions: Subscription[] = [];
  categories: CategoryModel[];
  categoryForm: FormGroup;
  displayedColumns = [
    'id',
    'name',
    'Supprimer',
  ];
  constructor(
      private accessoriesService: AccessoriesService,
      private store: Store<AppState>,
      private actions$: Actions,
      private layoutUtilsService: LayoutUtilsService
  ) {
  }

  ngOnInit(): void {
    this.getList();
    this.categoryForm = new FormGroup({
      parentCategoryName: new FormControl()
    });
    this.dataSource = new AccessoiresDatasource(this.store);
    const catSel = this.dataSource.entitySubject.pipe(
    ).subscribe((res) => {
      this.catogoriesResult = res;
    });
    this.subscriptions.push(catSel);
    this.loadAccList();
    // get list 3rd level
    this.products.valueChanges.subscribe((value) => {
    });
  }

  getList() {
    const queryParams = new QueryParamsModel(
        {query: ''},
        null,
        null,
        0,
        10
    );
    this.accessoriesService.getCategories3Niveau(queryParams)
        .subscribe((products) => {
          (this.suggestedProducts = products.filter(item => item.id !== this.catId));
        });
  }
  loadAccList() {
    this.store.dispatch(
        AccessoriesActions.accessoriesListPageRequested({
          idOwner: this.catId
        })
    );
  }

  saveAccessoriesSelected() {
    // tslint:disable-next-line:triple-equals
    this.selCat = this.suggestedProducts.find(cat => cat.name == this.products.value);
    this.store.dispatch(
        AccessoriesActions.Categories3LevelSave({
          idOwner: this.catId,
          id: this.selCat.id
        })
    );
  }

  deletAccessoire(item: CategoryModel) {
    const _title = 'Supprimer ';
    const _description = 'Voulez-vous vraiment supprimer l\'accessoir ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
        _title,
        _description,
        _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(
          AccessoriesActions.AccessorieDeleted({categoryId: this.catId, id: item.id})
      );
 // refrech list after deleting using load list
      this.actions$.pipe(ofType(AccessoriesActions.AccessorieDeletedSuccessfully)).subscribe(() => {
          this.loadAccList();
      });
    });
    }
}

