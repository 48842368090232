import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on, Store } from '@ngrx/store';
import { QueryParamsModel } from '../_base/crud';
// actions
import { AboutModel } from './about.model';
import { AboutCreationFailed } from './about.action';
import * as AboutActions from './about.action';

export interface AboutState extends EntityState<AboutModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  lastCreatedAboutId: number;
  lastQuery: QueryParamsModel;
  totalCount: number;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<AboutModel> =
  createEntityAdapter<AboutModel>();

export const initialAboutState: AboutState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  lastCreatedAboutId: undefined,
  totalCount: 0,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const AboutReducer = createReducer(
  initialAboutState,
  on(AboutActions.AboutsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),

  on(AboutActions.AboutsPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.abouts, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(AboutActions.AboutsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // creation
  on(AboutActions.AboutCreated, (state) => ({
    ...state,
    actionsLoading: false,
    lastAction: '',
  })),
  on(AboutActions.AboutCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.About, {
      ...state,
      actionsLoading: true,
      lastCreatedAboutId: action.About.id,
      lastAction: 'EDIT.ADD_MESSAGE',
      progress: 0,
    })
  ),
  on(AboutCreationFailed, (state: AboutState, action) => {
    let errorMessage: string;
    switch (action.error.status) {
      case 500:
        errorMessage = 'EDIT.ERROR.SERVER';
        break;
      case 403:
        errorMessage = 'AUTH.VALIDATION.NOT_AUTHORIZED';
        break;
      case 404:
        errorMessage = 'EDIT.ERROR.NOT_FOUND';
        break;
      default:
        errorMessage = 'EDIT.ERROR.UNKNOWN';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage: errorMessage,
      error: action.error,
    };
  }),
  //Update

  //Delete
  on(AboutActions.AboutDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(AboutActions.AboutDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.AboutId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(AboutActions.AboutDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  })),
  //About Resource Delete
  on(AboutActions.AboutDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(AboutActions.AboutDeletedSuccessfully, (state, action) => ({
    ...state,
    actionsLoading: false,
    lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS',
  })),
  on(AboutActions.AboutDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  }))
);

export function AboutsReducer(state: AboutState | undefined, action: Action) {
  return AboutReducer(state, action);
}
