// Angular
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'kt-search-dropdown',
  templateUrl: './search-dropdown.component.html',
})
export class SearchDropdownComponent implements OnInit {
  // Public properties

  // Set icon class name
  @Input() icon = 'flaticon2-search-1';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  @Input() type: 'brand' | 'success' | 'warning' = 'success';

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  data: any[];
  result: any[];
  loading: boolean;

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * On init
   */
  ngOnInit(): void {
    // simulate result from API
    // type 0|1 as separator or item
    this.result = [
      {
        text: 'Aucun résultat trouvé',
        type: 1,
      },
    ];
  }

  /**
   * Search
   * @param e: Event
   */
  search(e) {
    this.data = null;
    if (e.target.value.length > 2) {
      this.loading = true;
      // simulate getting search result
      setTimeout(() => {
        this.data = this.result;
        this.loading = false;
        this.cdr.markForCheck();
      }, 500);
    }
  }

  /**
   * Clear search
   *
   * @param e: Event
   */
  clear(e) {
    this.data = null;
    this.searchInput.nativeElement.value = '';
  }

  openChange() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }
}
