import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ADMIN_CONFIG } from '../../../environments/environment';
import { Response } from '../_base/crud/models/response';
import { map } from 'rxjs/operators';
import { AbstractService } from '../services/abstract-service.service';
import { Page } from '../_base/crud/models/page';
import { QueryParamsModel } from '../_base/crud';
import { AboutModel } from './about.model';

@Injectable()
export class AboutService extends AbstractService<AboutModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}user-conditions`;
  }

  findAll(): Observable<AboutModel[]> {
    const url = `${this.url}`;
    return this.http.get<AboutModel[]>(url);
  }
  public registerAbout(entity: AboutModel): Observable<AboutModel> {
    return this.http.post<AboutModel>(this.url, entity);
  }

  public updateAbout(_about: AboutModel, id: number): Observable<AboutModel> {
    const url = `${this.url}/` + id;
    return this.http.put<AboutModel>(url, _about);
  }

  public getAllByFilter(
    filter: QueryParamsModel
  ): Observable<Page<AboutModel>> {
    const url = `${this.url}`;

    const options = {
      params: new HttpParams()
        .append('page', filter.pageNumber.toString())
        .append('size', filter.pageSize ? filter.pageSize.toString() : '25')
        .append('status', filter.filter.status),
    };
    return this.http.get<Page<AboutModel>>(url, options);
  }
}
