import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { CompanyModel } from './company.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { ClientModel } from '../clients/client.model';
import { QueryParamsModel } from '../../_base/crud';
import { AddressModel } from './address.model';

@Injectable()
export class CompaniesService extends AbstractService<CompanyModel> {
  url: string;
  client: Partial<ClientModel>;
  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}company`;
  }

  public getAllPaged2(
    page: number = 0,
    size: number = 10
  ): Observable<Page<CompanyModel>> {
    const options = {
      params: new HttpParams()
        .append('page', page.toString())
        .append('size', size.toString()),
    };
    const url = `${this.url}`;
    return this.http.get<Page<CompanyModel>>(url, options);
  }

  public getAllByFilter(
    filter: QueryParamsModel
  ): Observable<Page<CompanyModel>> {
    const url = `${this.url}`;

    let params = new HttpParams();
    params = params.append(
      'page',
      filter.pageNumber ? filter.pageNumber.toString() : '0'
    );
    params = params.append(
      'size',
      filter.pageSize ? filter.pageSize.toString() : '100'
    );
    params = params.append(
      'sort',
      filter.sortField && filter.sortOrder
        ? [filter.sortField, filter.sortOrder].join(',')
        : 'id,asc'
    );
    params = filter.filter.query
      ? params.append('search', filter.filter.query)
      : params;
    params = filter.filter.status
      ? params.append('status', filter.filter.status)
      : params;

    const options = {
      params,
    };

    return this.http.get<Page<CompanyModel>>(url, options);
  }

  public getAllCompanies(): Observable<Page<CompanyModel>> {
    const url = `${this.url}`;
    return this.http.get<Page<CompanyModel>>(url);
  }

  public getClientsByCompany(
    companyId: number,
    queryParams: QueryParamsModel
  ): Observable<Page<ClientModel>> {
    const url = `${this.url}/${companyId}/clients`;
    let params = new HttpParams();
    params = params.append('page', queryParams.pageNumber.toString());
    params = params.append('size', queryParams.pageSize.toString());
    params = params.append(
      'search',
      queryParams.filter.query ? queryParams.filter.query : ''
    );
    params = queryParams.filter.status
      ? params.append('status', queryParams.filter.status)
      : params;
    const options = { params };
    return this.http.get<Page<ClientModel>>(url, options);
  }
  public addNewAddress(
    companyId: number,
    address: AddressModel
  ): Observable<AddressModel> {
    const url = `${this.url}/${companyId}/address`;
    return this.http.post<AddressModel>(url, address);
  }

  public updateAddress(address: AddressModel): Observable<AddressModel> {
    const url = `${this.url}/address`;
    return this.http.put<AddressModel>(url, address);
  }

  public deleteAddress(companyId: number, id: number): Observable<void> {
    const url = `${this.url}/${companyId}/addresses/${id}`;
    return this.http.delete<void>(url);
  }
}
