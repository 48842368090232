import { ClaimReasonModel } from './claim-reason/claim-reason.model';
import { ClientModel } from '../../e-commerce/clients/client.model';
import { UserModel } from '../../_models/user.model';
import { ConversationModel } from './conversation/conversation.model';

export class ClaimModel {
  id: number;
  number: string;
  priority: ClaimPriority;
  orderId: number;
  reason: ClaimReasonModel;
  clientReason: string;
  client: ClientModel;
  admin: UserModel;
  status: ClaimStatus;
  createdAt: string;
  conversations: ConversationModel[];
}

export enum ClaimStatus {
  PENDING = 'PENDING',
  IN_PROCESS = 'IN_PROCESS',
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
}

export enum ClaimPriority {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
  EXTREME = 'EXTREME',
  UNDEFINED = 'UNDEFINED',
}
