import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { ClientModel } from './client.model';
import { Observable } from 'rxjs';
import { AddressModel } from '../companies/address.model';
import { Page } from '../../_base/crud/models/page';

@Injectable()
export class ClientService extends AbstractService<ClientModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}client`;
  }

  public registerClient(entity: ClientModel): Observable<ClientModel> {
    return this.http.post<ClientModel>(this.url + '/add', entity);
  }

  public unblockClient(client: ClientModel): Observable<ClientModel> {
    return this.http.patch<ClientModel>(this.url + '/unblock', client.id);
  }

  public searchClient(search: string): Observable<ClientModel[]> {
    const params = new HttpParams().append('search', search);

    return this.http.get<ClientModel[]>(this.url + '/search', { params });
  }

  public updateClient(client: ClientModel): Observable<ClientModel> {
    return this.http.put<ClientModel>(this.url + '/collaborator', client);
  }

  public resendConfirmationMail(mail: string): Observable<void> {
    return this.http.post<void>(this.url + '/resend', { mail });
  }

  getAddressBook(clientId: number, page: number = 0, size: number = 10) {
    const url = `${this.url}/${clientId}/addressbook`;
    const options = {
      params: new HttpParams()
        .append('page', page.toString())
        .append('size', size.toString()),
    };
    return this.http.get<Page<AddressModel>>(url, options);
  }

  addAddressBook(clientId: number, address: AddressModel) {
    const url = `${this.url}/${clientId}/addressbook`;
    return this.http.post<AddressModel>(url, address);
  }

  updateAddressBook(clientId: number, address: AddressModel) {
    const url = `${this.url}/${clientId}/addressbook`;
    return this.http.put<AddressModel>(url, address);
  }

  deleteAddressBook(clientId: number, addressId) {
    const url = `${this.url}/${clientId}/addressbook/${addressId}`;
    return this.http.delete<void>(url);
  }
}
