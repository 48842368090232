import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimReasonState } from './claim-reason.reducer';
import { each } from 'lodash';
import { QueryResultsModel } from '../../../_base/crud';
import { ClaimReasonModel } from './claim-reason.model';

export const selectClaimReasonsState =
  createFeatureSelector<ClaimReasonState>('claimReasons');

export const selectClaimReasonById = (reasonId: number) =>
  createSelector(
    selectClaimReasonsState,
    (reasonsState) => reasonsState.entities[reasonId]
  );

export const selectClaimReasonsInStore = createSelector(
  selectClaimReasonsState,
  (ClaimReasonsState) => {
    const items: ClaimReasonModel[] = [];
    each(ClaimReasonsState.entities, (element) => {
      items.push(element);
    });
    return new QueryResultsModel(items, ClaimReasonsState.totalCount, '');
  }
);
