import { ClientModel } from '../../../e-commerce/clients/client.model';
import { UserModel } from '../../../_models/user.model';

export class ConversationModel {
  id: number;
  message: string;
  sentAt: Date;
  admin: UserModel;
  client: ClientModel;
  gesture: CommercialGestureModel;
  attachments: AttachmentModel[];
}

export class CommercialGestureModel {
  id: number;
  status: CommercialGestureStatus;
  admin: UserModel;
}

export class AttachmentModel {
  id: number;
  link: string;
}

export enum CommercialGestureStatus {
  'PENDING' = 'PENDING',
  'ACCEPTED' = 'ACCEPTED',
  'REFUSED' = 'REFUSED',
}
