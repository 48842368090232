import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ADMIN_CONFIG } from '../../../environments/environment';
import { CountryModel } from '../_models/country.model';

@Injectable()
export class CountryService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}countries`;
  }

  countries(): Observable<CountryModel[]> {
    return this.http.get<CountryModel[]>(this.url);
  }
}
