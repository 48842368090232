// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective,
} from './_base/layout';

import { AuthService } from './auth/_services/auth.service';
import { JwtInterceptor } from './_base/crud/utils/JwtInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CategoriesService } from './e-commerce/categories/categories.service';
import { ProductsService } from './e-commerce/products/products.service';
import { CategoryCharacteristicsService } from './e-commerce/category-characteristic/category-characteristics.service';
import { ProductCharacteristicsService } from './e-commerce/product-characteristic/product-characteristics.service';
import { ResourcesService } from './services/resources.service';
import { CarouselsService } from './home-page/carousel/carousels.service';
import { LogosService } from './home-page/logos/logos.service';
import { CompaniesService } from './e-commerce/companies/companies.service';
import { UserService } from './user-management/user.service';
import { ClientfunctionsService } from './e-commerce/clientfunctions/clientfunctions.service';
import { ManufacturerService } from './e-commerce/manufacturers/manufacturer.service';
import { StockService } from './e-commerce/stocks/stock.service';
import { OrdersService } from './e-commerce/orders/orders.service';
import { OrderItemsService } from './e-commerce/OrderItems/orderItems.service';
import { ClientService } from './e-commerce/clients/client.service';
import { MainClientService } from './e-commerce/mainclient/main-client.service';
import { SupplierService } from './e-commerce/suppliers/supplier.service';
import { SupplierAddressesService } from './e-commerce/suppliers/addresses.service';
import { ContactService } from './e-commerce/contacts-supplier/contact.service';
import { ClaimReasonService } from './service-client/claim/claim-reason/claim-reason.service';
import { ClaimService } from './service-client/claim/claim.service';
import { ConversationService } from './service-client/claim/conversation/conversation.service';
import { ProductLicensesService } from './e-commerce/product-licenses/product-licenses.service';
import { CountryService } from './services/country.service';
import {LogsService} from './e-commerce/odoo/logs.service';
import { AccessoriesService } from './e-commerce/categories/accessories/accessories.service';

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
  ],
  providers: [
    AuthService,
    AccessoriesService,
    CategoriesService,
    ManufacturerService,
    StockService,
    ProductsService,
    CategoryCharacteristicsService,
    ProductCharacteristicsService,
    ResourcesService,
    CarouselsService,
    LogosService,
    CompaniesService,
    UserService,
    ClientfunctionsService,
    OrdersService,
    OrderItemsService,
    ClientService,
    MainClientService,
    SupplierService,
    SupplierAddressesService,
    ContactService,
    ClaimReasonService,
    ClaimService,
    ConversationService,
    ProductLicensesService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    CountryService,
    LogsService
  ],
})
export class CoreModule {}
