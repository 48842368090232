import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { LayoutUtilsService, QueryParamsModel, QueryResultsModel } from '../../../../../core/_base/crud';
import { MatDialog } from '@angular/material/dialog';
import { KtDialogService } from '../../../../../core/_base/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { PromoCodeModel } from '../../../../../core/e-commerce/promo-code/promo-code.model';
import {
  selectPromoCodesInitWaitingMessage, selectPromoCodesInStore,
  selectPromoCodesPageLastQuery
} from '../../../../../core/e-commerce/promo-code/promo-code.selectors';
import { PromoCodeService } from '../../../../../core/e-commerce/promo-code/promo-code.service';
import { PromoCodesPageRequested } from '../../../../../core/e-commerce/promo-code/promo-code.actions';
import * as PromoCodeActions from '../../../../../core/e-commerce/promo-code/promo-code.actions';
import { Update } from '@ngrx/entity';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { CategoryActions } from '../../../../../core/e-commerce';
import { PromoCodeDatasource } from '../../../../../core/e-commerce/promo-code/promocode.datasource';



@Component({
  selector: 'kt-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit, OnDestroy {



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  dataSource: PromoCodeDatasource;

  totalElements = 0;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  showDatePicker = true;
  filterByDateActivated = false;
  hoveredDate: NgbDate | null = null;
  loadingData = false;
  startDate = '';
  endDate = '';



  displayedColumns = [
    'select',
    'id',
    'name',
    'event',
    'promoCodeType',
    'startDate',
    'endDate',
    'appliedTo',
    'actions',
  ];

  loading$: Observable<boolean> = of(true);

  filterStatus = new FormControl('');
  filterType = new FormControl('');

  selection = new SelectionModel<PromoCodeModel>(true, []);

  lastQuery: QueryParamsModel;

  pageTitle = 'Listes des codes promos';

  private subscriptions: Subscription[] = [];

  initWaitingMessageSubscription = this.store
    .pipe(select(selectPromoCodesInitWaitingMessage))
    .subscribe((v) => {
      if (!v) {
        this.loading$ = of(false);
        this.ktDialogService.hide();
      }
    });
  constructor(
    public dialog: MatDialog,
    private ktDialogService: KtDialogService,
    private promoCodeService: PromoCodeService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private actions$: Actions,
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.search) {
      this.pageTitle =
        this.activatedRoute.snapshot.queryParams.search +
        ' | Liste de commandes';
      this.searchInput.nativeElement.value =
        this.activatedRoute.snapshot.queryParams.search;
    }
    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';

    const lastQuerySubscription = this.store
      .pipe(select(selectPromoCodesPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    this.loadPromoCodeList(this.lastQuery);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadPromoCodeList()))
      .subscribe();

    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadPromoCodeList();
        })
      )
      .subscribe();

    this.ktDialogService.show();
    const selectPromoCodesInStoreSubscription = this.store
      .pipe(select(selectPromoCodesInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginator.length = response.totalCount;
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 1000);
      });

    const routeSubscription = this.activatedRoute.queryParams.subscribe(() => {
      const pageSize = this.lastQuery.pageSize;
      const pageNumber = this.lastQuery.pageNumber;
      this.paginator._changePageSize(pageSize);
      this.paginator.pageIndex = pageNumber;
      this.loadPromoCodeList(this.lastQuery);
    });

    this.subscriptions.push(
      paginatorSubscriptions,
      lastQuerySubscription,
      this.initWaitingMessageSubscription,
      selectPromoCodesInStoreSubscription,
      routeSubscription,
      searchSubscription
    );
    this.dataSource = new PromoCodeDatasource(this.store);
  }

  loadPromoCodeList(filter?: QueryParamsModel) {
    let queryParams: QueryParamsModel;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }
    this.loading$ = of(true);
    this.store.dispatch(PromoCodesPageRequested({ page: queryParams }));
    this.selection.clear();
  }
  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }
    if (this.filterType.value && this.filterType.value !== '') {
      filter.type = this.filterType.value;
    }
    filter.query = this.searchInput.nativeElement.value;
    if (this.startDate || this.endDate) {
      filter.startDate = this.startDate;
      filter.endDate = this.endDate;
    }
    return filter;
  }
  /*masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      //this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
   // const numRows = this.dataSource.data.length;
   // return numSelected === numRows;
  }*/



  deleteCodePromo(codePromo) {
    const title = 'Supprimer Code Promo';
    const description = 'Voulez-vous supprimer définitivement ce Code Promo?';
    const waitDesciption = ' Suppression en cours';

    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(
        PromoCodeActions.PromoCodeDeleted({ promoCodeId: codePromo.id })
      );
    });
  }

  editPromoCode(codePromoId) {
    this.router.navigate(['ecommerce', 'promo-code', 'edit', codePromoId]);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  changePromoCodeState(value, codePromo) {
    const updatedPromoCode = Object.assign({}, codePromo);
    updatedPromoCode.state = value.checked;
    const updatePromoCode: Update<PromoCodeModel> = {
      id: codePromo.id,
      changes: updatedPromoCode,
    };
    this.store.dispatch(
      PromoCodeActions.ChangePromoCodeStatus(
        {
          partialPromoCode: updatePromoCode,
          promoCode: updatedPromoCode
        }
      )
    );
    this.actions$.pipe(ofType(PromoCodeActions.PromoCodeStatusChangeFailed)).subscribe((data) => {
      if (data) {
        this.ngOnInit();
      }
    });
    this.actions$.pipe(ofType(PromoCodeActions.PromoCodeStatusChangedSuccessfully)).subscribe((data) => {
      if (data) {
        this.ngOnInit();
      }
    });
  }
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
  formatDate(date: NgbDate) {
    let month = '' + (date.month);
    let day = '' + date.day;
    const year = date.year;
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('/');
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.startDate = this.formatDate(this.fromDate);
      this.endDate = this.formatDate(this.toDate);
      this.showDatePicker = false;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.loadPromoCodeList();
  }
  disableFilterByDate(event) {
    if (event.checked === false) {
      this.filterByDateActivated = false;
      this.loadPromoCodeList();
    }
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}

