import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { LogoModel } from './logo.model';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class LogosService extends AbstractService<LogoModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}logo`;
  }

  public getAll(): Observable<LogoModel[]> {
    const options = {
      params: new HttpParams().append('page', '0').append('size', '999'),
    };
    const url = `${this.url}`;
    const result = this.http.get<Page<LogoModel>>(url, options);
    return result.pipe(map((p) => p.content));
  }

  //todo just why we need to send a list why ? why ????????????????????????
  public save(entity: LogoModel): Observable<LogoModel> {
    console.log('saving ... ' + JSON.stringify(entity));
    const res = this.http.post<LogoModel>(this.url, [entity]);
    res.pipe(tap((val) => console.log(JSON.stringify(val))));
    return res.pipe(map((result) => result[0]));
  }

  public update(entity: LogoModel): Observable<LogoModel> {
    console.log('updating ... ' + JSON.stringify(entity));
    const res = this.http.put<LogoModel>(this.url, entity);
    res.pipe(tap((val) => console.log(JSON.stringify(val))));
    return res;
  }
}
