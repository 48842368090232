import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../../../core/_base/crud';
import {
  QuotationItem,
  QuotationModel,
} from '../../../../../../../../core/e-commerce/quotation/quotation.model';
import { ProductResource } from '../../../../../../../../core/e-commerce/products/product-resources/product-resource';
import { QuotationService } from '../../../../../../../../core/e-commerce/quotation/quotation.service';
import { finalize } from 'rxjs/operators';
import { ProductModel } from '../../../../../../../../core/e-commerce/products/product.model';
import { ProductsService } from '../../../../../../../../core/e-commerce/products/products.service';
import { CurrencyMaskInputMode } from 'ngx-currency';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'kt-quotation-item-list',
  templateUrl: './quotation-item-list.component.html',
  styleUrls: ['./quotation-item-list.component.scss'],
})
export class QuotationItemListComponent implements OnInit {
  @Input() quotation: QuotationModel;
  @Output() quotationItemsEmitter = new EventEmitter<
    Partial<QuotationItem>[]
  >();

  @Input() quotationItems: Partial<QuotationItem>[] = [];

  dataSource: MatTableDataSource<Partial<QuotationItem>> =
    new MatTableDataSource([]);

  displayedColumns = [
    'select',
    'id',
    'image',
    'productName',
    'quantity',
    'price',
  ];

  isSwitchedToEditMode = false;
  loadingAfterSubmit = false;

  formGroup: FormGroup;
  newQuotationItem: QuotationItem;
  newProductId: number;
  editProductLine = -1;

  imageGallery: ProductResource[] = [];

  suggestedProducts: ProductModel[] = [];

  private componentSubscriptions: Subscription[] = [];

  customCurrencyMaskConfig = {
    align: 'left',
    prefix: '',
    suffix: ' €',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private quotationService: QuotationService,
    private productService: ProductsService,
    private layoutUtilsService: LayoutUtilsService,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {
     this.quotationService.getById(this.route.snapshot.params.id)
         .subscribe(value=>{
           this.dataSource.data = value.items;
           console.log(value ,'vvvaakkk');

         })

    this.initFormGroup();
    console.log(this.displayedColumns,'tables' );
  }

  initFormGroup(_item = null) {
    this.formGroup = this.fb.group({
      quantity: [
        this.newQuotationItem ? this.newQuotationItem.quantity : null,
        Validators.required,
      ],
      price: [
        this.newQuotationItem ? this.newQuotationItem.price : null,
        Validators.required,
      ],
      product: [
        this.newQuotationItem ? this.newQuotationItem.product.name : null,
        Validators.required,
      ],
    });
    this.formGroup.get('product').valueChanges.subscribe((value) => {
      const queryParams = new QueryParamsModel(
        { query: value },
        null,
        null,
        0,
        10
      );
      this.productService
        .getAllPaged2(queryParams)
        .subscribe((products) => (this.suggestedProducts = products.content));
    });
  }

  cancelAddProduct() {
    this.editProductLine = -1;
    this.formGroup.reset();
  }

  onEditItem(_item: QuotationItem) {
    if (_item.id === undefined) {
      this.dataSource.data.forEach((i) => {
        if (i.product.id === _item.product.id) {
          this.editProductLine = _item.product.id;
          this.formGroup.setValue({
            quantity: _item.quantity,
            price: _item.price,
            product: _item.product.name,
          });
        }
      });
      return;
    }
    this.editProductLine = _item.id;
    this.formGroup.setValue({
      quantity: _item.quantity,
      price: _item.price,
      product: _item.product.name,
    });
  }

  saveUpdatedItem(_item: QuotationItem) {
    if (_item.id === undefined) {
      this.loadingAfterSubmit = false;
      this.editProductLine = -1;
      this.dataSource.data.forEach((i) => {
        if (i.product.id === _item.product.id) {
          i.price = this.formGroup.get('price').value;
          i.quantity = this.formGroup.get('quantity').value;
          i.itemTotalPrice =
            this.formGroup.get('quantity').value *
            this.formGroup.get('price').value;
        }
      });
      this.quotationItems = this.dataSource.data;
      this.layoutUtilsService.showActionNotification(
        'modification réussie',
        MessageType.Update
      );
      this.emitQuotationItemsChanges();
      this.formGroup.reset();
      return;
    }
    this.loadingAfterSubmit = true;
    const payload = {
      price: this.formGroup.get('price').value,
      quantity: this.formGroup.get('quantity').value,
    };

    const subs = this.quotationService
      .updateQuotationItem(_item.id, payload)
      .pipe(
        finalize(() => {
          this.editProductLine = -1;
          this.formGroup.reset();
          this.loadingAfterSubmit = false;
        })
      )
      .subscribe(
        (e) => {
          this.dataSource.data.forEach((i) => {
            if (i.id === e.id) {
              i.price = payload.price;
              i.quantity = payload.quantity;
            }
          });
          this.layoutUtilsService.showActionNotification(
            'modification réussie',
            MessageType.Update
          );
        },
        () => {
          this.layoutUtilsService.showActionNotification(
            'Une erreur est survenue !',
            MessageType.Update
          );
        }
      );
    this.componentSubscriptions.push(subs);
  }

  saveNewQuotationItem() {
    if (this.quotation) {
      this.loadingAfterSubmit = true;
      const payload = {
        price: this.formGroup.get('price').value,
        quantity: this.formGroup.get('quantity').value,
        productId: this.newProductId,
      };

      const subscription = this.quotationService
        .addQuotationItem(this.quotation.id, payload)
        .pipe(
          finalize(() => {
            this.editProductLine = -1;
            this.formGroup.reset();
            this.loadingAfterSubmit = false;
          })
        )
        .subscribe(
          (e) => {
            this.quotationItems = this.dataSource.data.concat(e);
            this.layoutUtilsService.showActionNotification(
              'Produit ajouté avec succès',
              MessageType.Update
            );
            this.emitQuotationItemsChanges();
          },
          (e) => {
            if (e.status === 409) {
              this.layoutUtilsService.showActionNotification(
                'Le produit existe déjà !',
                MessageType.Update
              );
            } else {
              this.layoutUtilsService.showActionNotification(
                'Une erreur est survenue !',
                MessageType.Update
              );
            }
          }
        );
      this.componentSubscriptions.push(subscription);
    } else {
      const item: Partial<QuotationItem> = {
        quantity: this.formGroup.get('quantity').value,
        price: this.formGroup.get('price').value,
        product: this.newQuotationItem.product,
        itemTotalPrice:
          this.formGroup.get('quantity').value *
          this.formGroup.get('price').value,
      };
      this.quotationItems.push(item);
      this.emitQuotationItemsChanges();
    }
  }

  deleteQuotationItem(_item: QuotationItem) {
    const _title = 'Supprimer le produit';
    const _description = 'Voulez-vous vraiment supprimer ce produit du devis ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      if (this.quotation) {
        const sub = this.quotationService
          .deleteQuotationItemById(_item.id)
          .subscribe(
            () => {
              this.quotationItems = this.dataSource.data.filter(
                (i) => i.id !== _item.id
              );
              this.quotation.items = this.quotation.items.filter(
                (i) => i.product !== _item.product
              );
              this.layoutUtilsService.showActionNotification(
                'Le produit a été supprimé avec succès',
                MessageType.Delete
              );
              this.emitQuotationItemsChanges();
            },
            () => {
              this.layoutUtilsService.showActionNotification(
                'Une erreur est survenue !',
                MessageType.Delete
              );
            }
          );
        this.componentSubscriptions.push(sub);
      } else {
        this.quotationItems = this.quotationItems.filter(
          (i) => i.product.id !== _item.product.id
        );
        this.emitQuotationItemsChanges();
      }
    });
   // this.ngOnInit();
  }

  selectProduct(event: any) {
    this.newQuotationItem = {
      id: null,
      quantity: null,
      price: null,
      product: this.suggestedProducts.find((p) => p.name === event),
      itemTotalPrice: null,
    };
    if (
      (!this.quotation &&
        this.quotationItems.find(
          (value) => value.product.id === this.newQuotationItem.product.id
        )) ||
      (this.quotation &&
        this.quotation.items.find(
          (value) => value.product.id === this.newQuotationItem.product.id
        ))
    ) {
      this.formGroup.controls.product.setErrors({ incorrect: true });
      this.layoutUtilsService.showActionNotification('Produit déja existe!');
    } else {
      this.newProductId = this.newQuotationItem.product.id;
      this.formGroup.get('price').setValue(this.newQuotationItem.product.price);
    }
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
    }
  }

  private emitQuotationItemsChanges() {
    this.quotationItemsEmitter.emit(this.quotationItems);
    this.dataSource.data = this.quotationItems;
    this.editProductLine = -1;
    this.formGroup.reset();
  }
}
