import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ManufacturerState } from './manufacturer.reducers';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { ManufacturerModel } from './manufacturer.model';
import { selectProductsState } from '../products/product.selectors';

export const selectManufacturersState =
  createFeatureSelector<ManufacturerState>('manufacturers');

export const selectManufacturerById = (manufacturerId: number) =>
  createSelector(
    selectManufacturersState,
    (manufacturersState) => manufacturersState.entities[manufacturerId]
  );

export const selectManufacturersPageLoading = createSelector(
  selectManufacturersState,
  (manufacturersState) => manufacturersState.listLoading
);

export const selectError = createSelector(
  selectManufacturersState,
  (manufacturersState) => manufacturersState.error
);

export const selectErrorMessage = createSelector(
  selectManufacturersState,
  (manufacturersState) => manufacturersState.errorMessage
);

export const selectlastAction = createSelector(
  selectManufacturersState,
  (manufacturersState) => manufacturersState.lastAction
);

export const selectManufacturersActionLoading = createSelector(
  selectManufacturersState,
  (manufacturersState) => manufacturersState.actionsLoading
);

/*export const selectLastCreatedManufacturerId = createSelector(
    selectManufacturersState,
    manufacturersState => manufacturersState.lastCreatedManufacturerId
);*/

export const selectManufacturersShowInitWaitingMessage = createSelector(
  selectManufacturersState,
  (manufacturersState) => manufacturersState.showInitWaitingMessage
);

export const selectManufacturerProgress = createSelector(
  selectManufacturersState,
  (manufacturersState) => manufacturersState.progress
);

export const selectManufacturersInStore = createSelector(
  selectManufacturersState,
  (manufacturersState) => {
    const items: ManufacturerModel[] = [];
    each(manufacturersState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: ManufacturerModel[] = httpExtension.sortArray(
      items,
      manufacturersState.lastQuery.sortField,
      manufacturersState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, manufacturersState.totalCount, '');
  }
);
export const selectManufacturesPageLastQuery = createSelector(
  selectManufacturersState,
  (manufacturersState) => manufacturersState.lastQuery
);
