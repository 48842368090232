import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { ManufacturerModel } from '../../../../../core/e-commerce/manufacturers/manufacturer.model';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import {
  CreateManufacturer,
  DeleteManufacturer,
  UpdateManufacturer,
} from '../../../../../core/e-commerce/manufacturers/manufacturer.action';
import {
  LayoutUtilsService,
  QueryParamsModel,
} from '../../../../../core/_base/crud';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { ManufacturerActions } from '../../../../../core/e-commerce';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoryModel } from '../../../../../core/e-commerce/categories/category.model';
import { ManufacturerDatasource } from '../../../../../core/e-commerce/manufacturers/manufacturer.datasource';
import { ActivatedRoute } from '@angular/router';
import { selectProductsPageLastQuery } from '../../../../../core/e-commerce/products/product.selectors';

@Component({
  selector: 'kt-manufacturers',
  templateUrl: './manufacturers.component.html',
  styleUrls: ['./manufacturers.component.scss'],
})
export class ManufacturersComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('sort2', { static: true }) sort2: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  manufacturerForm: FormGroup;
  dataSource: ManufacturerDatasource;
  displayedColumns = ['select', 'id', 'label', 'actions'];
  manufacturesResult: ManufacturerModel[] = [];

  editManufacturerLine = -1;
  loadingAfterSubmit = false;
  loading$: Observable<boolean>;
  lastQuery: QueryParamsModel;

  private subscriptions: Subscription[] = [];

  selection = new SelectionModel<CategoryModel>(true, []);
  filterCategories = new FormControl(0);

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.manufacturerForm = this.fb.group({
      id: [null],
      label: ['', Validators.required],
    });

    this.paginator._changePageSize(10);

    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadManufacturesList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    /* search*/
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadManufacturesList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    /**/
    this.loadManufacturesList();
    // Init DataSource
    this.dataSource = new ManufacturerDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.manufacturesResult = res;
      });
    this.subscriptions.push(entitiesSubscription);
    /**/
    const lastQuerySubscription = this.store
      .pipe(select(selectProductsPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    this.loadManufacturesList(this.lastQuery);
    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(() => {
      const pageSize = this.lastQuery.pageSize;
      const pageNumber = this.lastQuery.pageNumber;
      this.paginator._changePageSize(pageSize);
      this.paginator.pageIndex = pageNumber;
      this.loadManufacturesList(this.lastQuery);
    });

    this.subscriptions.push(
      lastQuerySubscription,
      entitiesSubscription,
      routeSubscription,
      sortSubscription,
      searchSubscription
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  createManufacturer() {
    this.editManufacturerLine = 0;
  }

  saveNewManufacturer() {
    this.loadingAfterSubmit = true;
    const manufacturer: ManufacturerModel = {
      id: this.manufacturerForm.value.id,
      label: this.manufacturerForm.value.label,
    };
    this.store.dispatch(CreateManufacturer({ manufacturer }));
    setTimeout(() => {
      this.loadingAfterSubmit = false;
      this.cancelEditManufacturer();
    }, 1000); // TODO use Store State
  }

  editManufacturer(item: ManufacturerModel) {
    this.editManufacturerLine = item.id;
    this.manufacturerForm.get('id').setValue(item.id);
    this.manufacturerForm.get('label').setValue(item.label);
  }

  deleteManufacturer(item: ManufacturerModel) {
    const _title = 'Supprimer la marque';
    const _description =
      'Voulez-vous vraiment supprimer la marque <b>' + item.label + '</b>?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(DeleteManufacturer({ manufacturerId: item.id }));
    });
  }

  saveEditedManufacturer() {
    this.loadingAfterSubmit = true;
    const manufacturer: ManufacturerModel = {
      id: this.manufacturerForm.value.id,
      label: this.manufacturerForm.value.label,
    };
    this.store.dispatch(UpdateManufacturer({ manufacturer }));
    setTimeout(() => {
      this.loadingAfterSubmit = false;
      this.cancelEditManufacturer();
    }, 1000); // TODO use Store State
  }

  cancelEditManufacturer() {
    this.editManufacturerLine = -1;
    this.manufacturerForm.reset();
  }

  loadManufacturesList(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }
    queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.store.dispatch(
      ManufacturerActions.ManufacturersPageRequested({
        page: queryParams,
      })
    );
  }

  filterConfiguration(): string {
    const filter: any = {};
    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }
}
