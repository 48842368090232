import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../../../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchedProductService {
  endpoint = `${ADMIN_CONFIG.wikeysEndpoint}`;
  headers = new HttpHeaders().append(
    'Authorization',
    '641ca9971e0368e34250f4847d3b7bc4'
  );

  constructor(private httpClient: HttpClient) {}

  getProductByFAB(fab: string) {
    const params = new HttpParams().append('query', fab);

    return this.httpClient.get<any[]>(this.endpoint, {
      params,
      headers: this.headers,
    });
  }
}
