import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { AboutDatasource } from '../../../../core/about/about.datasource';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../core/_base/crud';
import { SubheaderService } from '../../../../core/_base/layout';
import { SelectionModel } from '@angular/cdk/collections';
import { AboutModel } from '../../../../core/about/about.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import {
  AboutDeleted,
  AboutsPageRequested,
} from '../../../../core/about/about.action';
import { selectAboutsPageLastQuery } from '../../../../core/about/about.selector';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kt-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  dataSource: AboutDatasource;
  filterAbouts = new FormControl(0);
  aboutId: number;
  displayedColumns = ['id', 'title', 'body', 'type', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;

  filterStatus: boolean = false;
  filterCondition = '';
  selection = new SelectionModel<AboutModel>(true, []);
  aboutResult: AboutModel[] = [];
  lastQuery: QueryParamsModel;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.loadAboutPage();
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadAboutPage()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    this.filterAbouts.valueChanges.subscribe((v) => console.log(v));
    this.subheaderService.setTitle('Articles');

    this.dataSource = new AboutDatasource(this.store);
    // this.dataSource = new AboutDatasource( this.aboutService, this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.aboutResult = res;
      });
    this.subscriptions.push(entitiesSubscription);

    const lastQuerySubscription = this.store
      .pipe(select(selectAboutsPageLastQuery))
      .subscribe((res) => (this.lastQuery = res));
    // Load last query from store
    this.subscriptions.push(lastQuerySubscription);

    this.loadAboutPage(this.lastQuery);

    const routeSubscription = this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params.id) {
          this.restoreState(this.lastQuery, +params.id);
        }

        const pageSize = this.lastQuery.pageSize;
        const pageNumber = this.lastQuery.pageNumber;
        this.paginator._changePageSize(pageSize);
        this.paginator.pageIndex = pageNumber;
        this.loadAboutPage(this.lastQuery);
      }
    );
    this.subscriptions.push(routeSubscription);

    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';
  }

  showNotification(messageText: string, messageType: MessageType) {
    this.layoutUtilsService.showActionNotification(messageText, messageType);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }
  loadAboutPage(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }
    // console.log(
    //     queryParams);
    this.store.dispatch(AboutsPageRequested({ page: queryParams }));
    this.selection.clear();
  }

  deleteAbout(id: number) {
    this.store.dispatch(AboutDeleted({ AboutId: id }));
    this.showNotification('Element Deleted Successfully !', MessageType.Delete);
  }

  editAbout(id) {
    this.router.navigate(['/a-propos/about/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }

  createAbout() {
    this.router.navigateByUrl('a-propos/about/add');
  }

  getItemCssClassByType(status: string = ''): string {
    switch (status) {
      case 'cookies':
        return 'success';
      case 'legal':
        return 'danger';
      case 'general_condition':
        return 'primary';
      case 'security':
        return 'info';
    }
    return '';
  }

  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterAbouts.value) {
      filter.abouts = [];
      filter.abouts.push(this.filterAbouts.value);
    }

    //filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  restoreState(queryParams: QueryParamsModel, id: number) {
    if (!queryParams.filter) {
      return;
    }

    if ('status' in queryParams.filter) {
      this.filterStatus = queryParams.filter.status;
    }
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.aboutResult.forEach((row) => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.aboutResult.length;
    return numSelected === numRows;
  }
}
