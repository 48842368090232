// NGRX
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';

// Models

import { ClientQuestionModel } from './client-question.model';

export const QuestionsPageRequested = createAction(
  '[Questions List Page] Questions Page Requested ',
  props<{ page: QueryParamsModel }>()
);
export const QuestionsPageLoadedSuccessfully = createAction(
  '[Questions API] Questions Page Loaded Successfully ',
  props<{
    questions: ClientQuestionModel[];
    totalCount: number;
    page: number;
  }>()
);
export const QuestionsPageLoadFailed = createAction(
  '[Questions API] Blogs Questions Request Failed ',
  props<{ error: any }>()
);

export const QuestionUpdated = createAction(
  '[Question List Page] Question Updated ',
  props<{
    question: ClientQuestionModel;
    partialQuestion: Update<ClientQuestionModel>;
    id: number;
  }>()
);
export const QuestionUpdatedSuccessfully = createAction(
  '[Question List Page] Question Updated Successfully ',
  props<{
    question: ClientQuestionModel;
    partialQuestion: Update<ClientQuestionModel>;
  }>()
);
export const QuestionUpdateFailed = createAction(
  '[Question List Page] Question Update Failed ',
  props<{ error: any }>()
);
