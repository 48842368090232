// RxJS
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  selectClientFunctionsInitWaitingMessage,
  selectClientFunctionsInStore,
  selectClientFunctionsPageLoading,
} from './clientfunction.selectors';

// Selectors

export class ClientfunctionsDatasource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectClientFunctionsPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectClientFunctionsInitWaitingMessage)
    );

    this.store
      .pipe(select(selectClientFunctionsInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
