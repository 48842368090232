import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DiscountModel } from '../../../../../../core/e-commerce/promotions/discount.model';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-current-promotion',
  templateUrl: './current-promotion.component.html',
  styleUrls: ['./current-promotion.component.scss']
})
export class CurrentPromotionComponent implements OnInit {
  DisplayedColumns = [
    'id',
    'type',
    'value',
    'startDate',
    'endDate'];

  constructor(public dialogRef: MatDialogRef<CurrentPromotionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private router: Router) { }
  discountDataSource = new MatTableDataSource<DiscountModel>();

  ngOnInit(): void {
    this.discountDataSource.data = this.data[0];
  }

  getDiscountUrl(discount:any){
    if(discount.promoCodeType){
      return "/ecommerce/promo-code"
    }
    if(discount.companyId){
      return `/ecommerce/companies/edit/${discount.companyId}`
    }
    return "/ecommerce/products/promotions"
  }

  goToDiscount(discount: any) {
    const discountUrl : any = this.getDiscountUrl(discount);
    if(discountUrl){
      window.open(discountUrl, '_blank');
    }
  }
}
