import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompaniesService } from './companies.service';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
//Actions
import * as CompanyActions from './company.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompaniesService,
    private store: Store<AppState>
  ) {}

  CompanyPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.CompaniesPageRequested),
      switchMap((action) =>
        this.companyService.getAllByFilter(action.page).pipe(
          map((companyPage) =>
            CompanyActions.CompaniesPageLoadedSuccessfully({
              companies: companyPage.content,
              page: companyPage.totalElements,
              totalCount: companyPage.totalElements,
            })
          ),
          catchError((error) =>
            of(CompanyActions.CompaniesPageLoadFailed({ error }))
          )
        )
      )
    )
  );

  CompanyCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.CompanyCreated),
      switchMap((action) =>
        this.companyService.save(action.company).pipe(
          map((data) =>
            CompanyActions.CompanyCreatedSuccessfully({ company: data })
          ),
          catchError((error) =>
            of(CompanyActions.CompanyCreationFailed({ error }))
          )
        )
      )
    )
  );

  CompanyUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.CompanyUpdated),
      switchMap((action) =>
        this.companyService.update(action.company).pipe(
          map((company) =>
            CompanyActions.CompanyUpdatedSuccessfully({
              company,
              partialCompany: action.partialCompany,
            })
          ),
          catchError((error) =>
            of(CompanyActions.CompanyUpdateFailed({ error }))
          )
        )
      )
    )
  );

  CompanyDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActions.CompanyDeleted),
      switchMap((action) =>
        this.companyService.delete(action.companyId).pipe(
          map(() =>
            CompanyActions.CompanyDeletedSuccessfully({
              companyId: action.companyId,
            })
          ),
          catchError((error) =>
            of(CompanyActions.CompanyDeleteFailed({ error }))
          )
        )
      )
    )
  );
}
