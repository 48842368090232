import { createAction, props } from '@ngrx/store';
import { QueryParamsModel } from '../../_base/crud';
import { CompanyModel } from './company.model';
import { Update } from '@ngrx/entity';

export const CompaniesPageRequested = createAction(
  '[Company List Page] Companies Page Requested ',
  props<{ page: QueryParamsModel }>()
);
export const CompaniesPageLoadedSuccessfully = createAction(
  '[Company API] Companies Page Loaded Successfully ',
  props<{ companies: CompanyModel[]; totalCount: number; page: number }>()
);
export const CompaniesPageLoadFailed = createAction(
  '[Company API] Companies Page Request Failed ',
  props<{ error: any }>()
);

export const CompanyCreated = createAction(
  '[Company List Page] Company Created ',
  props<{ company: CompanyModel }>()
);
export const CompanyCreatedSuccessfully = createAction(
  '[Company List Page] Company Created Successfully ',
  props<{ company: CompanyModel }>()
);
export const CompanyCreationFailed = createAction(
  '[Company List Page] Company Creation Failed ',
  props<{ error: any }>()
);

export const CompanyUpdated = createAction(
  '[Company List Page] Company Updated ',
  props<{ company: CompanyModel; partialCompany: Update<CompanyModel> }>()
);
export const CompanyUpdatedSuccessfully = createAction(
  '[Company List Page] Company Updated Successfully ',
  props<{ company: CompanyModel; partialCompany: Update<CompanyModel> }>()
);
export const CompanyUpdateFailed = createAction(
  '[Company List Page] Company Update Failed ',
  props<{ error: any }>()
);

export const CompanyDeleted = createAction(
  '[Company List Page] Company Deleted ',
  props<{ companyId: number }>()
);
export const CompanyDeletedSuccessfully = createAction(
  '[Company List Page] Company deleted Successfully ',
  props<{ companyId: number }>()
);
export const CompanyDeleteFailed = createAction(
  '[Company List Page] Company delete Failed ',
  props<{ error: any }>()
);
