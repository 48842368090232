import { createFeatureSelector, createSelector } from '@ngrx/store';

import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { CategoryCharacteristicState } from './category-characteristic.reducer';
import { CategoryCharacteristicModel } from './category-characteristic.model';

export const selectCharacteristicsState =
  createFeatureSelector<CategoryCharacteristicState>('categoryCharacteristics');

export const selectCategoryCharacteristicById = (characteristicId: number) =>
  createSelector(
    selectCharacteristicsState,
    (categoriesState) => categoriesState.entities[characteristicId]
  );

export const selectCategoryCharacteristicListLoading = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.listLoading
);

export const selectCCharError = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.error
);

export const selectCCharErrorMessage = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.errorMessage
);

export const selectCategoriesActionLoading = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.actionsLoading
);

export const selectLastCreatedCategoryCharacteristicId = createSelector(
  selectCharacteristicsState,
  (categoriesState) => categoriesState.lastCreatedCategoryCharacteristicId
);

export const selectCategoryCharacteristicShowInitWaitingMessage =
  createSelector(
    selectCharacteristicsState,
    (categoriesState) => categoriesState.showInitWaitingMessage
  );

export const selectCategoryCharacteristicInStore = createSelector(
  selectCharacteristicsState,
  (categoriesState) => {
    const items: CategoryCharacteristicModel[] = [];
    each(categoriesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: CategoryCharacteristicModel[] = httpExtension.sortArray(
      items,
      categoriesState.lastQuery.sortField,
      categoriesState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, categoriesState.totalCount, '');
  }
);
