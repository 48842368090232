export class CategoryCharacteristicModel {
  id: number;
  label: string;
  valueType:
    | 'textfield'
    | 'textbox'
    | 'toggle'
    | 'singleListChoice'
    | 'multipleListChoice';
  listValue: string[] = [];
}
