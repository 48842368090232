import { createAction, props } from '@ngrx/store';
import { ProductCharacteristicModel } from './product-characteristic.model';
import { Update } from '@ngrx/entity';

export const ProductCharacteristicListRequested = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Requested',
  props<{ productId: number }>()
);
export const ProductCharacteristicListRequestedSuccessfully = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Requested Successfully',
  props<{ productCharacteristics: ProductCharacteristicModel[] }>()
);
export const ProductCharacteristicListRequestFailed = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Request Failed',
  props<{ error: any }>()
);

export const ProductCharacteristicCreated = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Created ',
  props<{
    productCharacteristic: ProductCharacteristicModel;
    productId: number;
  }>()
);
export const ProductCharacteristicCreatedSuccessfully = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Created Successfully ',
  props<{ productCharacteristic: ProductCharacteristicModel }>()
);
export const ProductCharacteristicCreationFailed = createAction(
  '[Product List Page] ProductCharacteristic Creation Failed ',
  props<{ error: any }>()
);

export const ProductCharacteristicUpdated = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Updated ',
  props<{
    productCharacteristic: ProductCharacteristicModel;
    partialCharacteristic: Update<ProductCharacteristicModel>;
    productId: number;
  }>()
);
export const ProductCharacteristicUpdatedSuccessfully = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Updated Successfully ',
  props<{
    productCharacteristic: ProductCharacteristicModel;
    partialCharacteristic: Update<ProductCharacteristicModel>;
  }>()
);
export const ProductCharacteristicUpdateFailed = createAction(
  '[Product List Page] ProductCharacteristic Update Failed ',
  props<{ error: any }>()
);

export const ProductCharacteristicDeleted = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Deleted ',
  props<{ id: number; productId: number }>()
);
export const ProductCharacteristicDeletedSuccessfully = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Deleted Successfully ',
  props<{ id: number }>()
);
export const ProductCharacteristicDeleteFailed = createAction(
  '[ProductCharacteristic List Page] ProductCharacteristic Delete Failed ',
  props<{ error: any }>()
);
