import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge, Subscription } from 'rxjs';
import { AppState } from '../../../../../../core/reducers';
import { Store } from '@ngrx/store';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
  tap,
} from 'rxjs/operators';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../core/_base/crud';
import { SelectionModel } from '@angular/cdk/collections';
// Actions
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoriesDatasource } from '../../../../../../core/e-commerce/categories/categories.datasource';
import { CategoryModel } from '../../../../../../core/e-commerce/categories/category.model';
import {
  selectError,
  selectErrorMessage,
  selectlastAction,
} from '../../../../../../core/e-commerce/categories/category.selector';
import { CategoryActions } from '../../../../../../core/e-commerce';
import { ActivatedRoute, Router } from '@angular/router';
import { Update } from '@ngrx/entity';
import { FormControl } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import {CategoryOptModel} from '../../../../../../core/e-commerce/categories/categoryOpt.model';

@Component({
  selector: 'kt-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],

})
export class CategoriesComponent implements OnInit, OnDestroy {
  // Table fields
  dataSource: CategoriesDatasource;
  displayedColumns = [
    'select',
    'id',
    'name',
    'parentId',
    'status',
    'updater',
    'edit',
  ];
  // Table Data
  catogoriesResult: CategoryModel[] = [];

  // Paginator
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // Sort
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = new FormControl('');
  filterType = new FormControl('');
  checked: boolean;

  // Selection
  selection = new SelectionModel<CategoryModel>(true, []);
  selectionOpt = new SelectionModel<CategoryOptModel>(true, []);


  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions,

  ) {}

  ngOnInit() {
    /* Data load will be triggered in two cases:
            - when a pagination event occurs => this.paginator.page
            - when a sort event occurs => this.sort.sortChange
            **/
    this.paginator._changePageSize(50);
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0)
    );
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadCategoryList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(700), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadCategoryList();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    this.loadCategoryList();

    this.dataSource = new CategoriesDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(0), distinctUntilChanged())
      .subscribe((res) => {
        this.catogoriesResult = res;
      });
    this.subscriptions.push(entitiesSubscription);

    // subscribing to the error observable
    // todo change the way of getting the error message using operators
    const error$ = this.store
      .select(selectError)
      .pipe(filter((result) => result !== null))
      .subscribe(() => {
        const errorMessageSub = this.store
          .select(selectErrorMessage)
          .subscribe((res) => {
            this.layoutUtilsService.showActionNotification(
              res,
              MessageType.Delete,
              5000,
              true,
              false
            );
          });
      });
    this.subscriptions.push(error$);
    // subscribing to the seccessOperations
    const operation$ = this.store
      .select(selectlastAction)
      .pipe(filter((result) => result !== ''))
      .subscribe((result) => {
        this.layoutUtilsService.showActionNotification(
          this.translate.instant('ECOMMERCE.CATEGORIES.' + result),
          MessageType.Delete,
          10000,
          true,
          false
        );
      });
    this.subscriptions.push(operation$);
  }

  toggleDefault(value, category: CategoryModel) {
    const updatedCat = Object.assign({}, category);
    updatedCat.defaultRecommended = value.checked;
    const updateCategory: Update<CategoryModel> = {
      id: category.id,
      changes: updatedCat,
    };

    this.store.dispatch(
      CategoryActions.CategoryUpdated({
        partialCategory: updateCategory,
        category: updatedCat,
      })
    );
  }

  loadCategoryList() {
    this.selectionOpt.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    this.store.dispatch(
      CategoryActions.CategoriesOptPageRequested({
        page: queryParams,
      })
    );
    this.selectionOpt.clear();
  }

  /**
   * Returns object for filter
   */
  /** FILTRATION */
  filterConfiguration(): string {
    // tslint:disable-next-line:no-shadowed-variable
    const filter: any = {};
    if (this.filterType.value && this.filterType.value !== '') {
      filter.type = this.filterType.value;
    }
    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }
    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  masterToggle() {
    if (this.selection.selected.length === this.catogoriesResult.length) {
      this.selection.clear();
    } else {
      this.catogoriesResult.forEach((row) => this.selection.select(row));
    }
  }

  /**
   * Check all rows are selected
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.catogoriesResult.length;
    return numSelected === numRows;
  }

  // editCategory(category: CategoryModel) {
  //     const dialogRef = this.dialog.open(ProductCharacteristicEditComponent, {
  //         data: {
  //             category,
  //         }
  //     });
  //     dialogRef.afterClosed().subscribe(res => {
  //         if (!res) {
  //             return;
  //         }
  //         this.loadCategoryList();
  //     });
  // }
  editCategory(id) {
    this.router.navigate(['/ecommerce/categories/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Show add category dialog
   */
  // addCategory() {
  //     const newCategory = new CategoryModel();
  //     this.editCategory(newCategory);
  // }

  createCategory() {
    this.router.navigateByUrl('/ecommerce/categories/add');
  }

  deleteCategory(category: CategoryModel) {
    // tslint:disable-next-line:variable-name
    const _title: string = this.translate.instant(
      'ECOMMERCE.CATEGORIES.DELETE_CATEGORY_SIMPLE.TITLE'
    );
    // tslint:disable-next-line:variable-name
    const _description: string = this.translate.instant(
      'ECOMMERCE.CATEGORIES.DELETE_CATEGORY_SIMPLE.DESCRIPTION'
    );
    // tslint:disable-next-line:variable-name
    const _waitDesciption: string = this.translate.instant(
      'ECOMMERCE.CATEGORIES.DELETE_CATEGORY_SIMPLE.WAIT_DESCRIPTION'
    );

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(
        CategoryActions.CategoryDeleted({ categoryId: category.id })
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  /** UI */
  /**
   * Retursn CSS Class Name by status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'DRAFT':
        return '';
      case 'PUBLIC':
        return 'success';
    }
    return 'danger';
  }

  changeCategoryStatus(value, category) {
    const updatedCat = Object.assign({}, category);
    updatedCat.status = value.checked;
    const updateCategory: Update<CategoryModel> = {
      id: category.id,
      changes: updatedCat,
    };
    this.store.dispatch(
      CategoryActions.ChangeCategoryStatus(
        {
          partialCategory: updateCategory,
          category: updatedCat,
        }
      )
    );
    this.actions$.pipe(ofType(CategoryActions.CategoryStatusChangeFailed)).subscribe((data) => {
      if (data) {
      this.ngOnInit();
      }
    });
    this.actions$.pipe(ofType(CategoryActions.CategoryStatusChangedSuccessfully)).subscribe((data) => {
      if (data) {
        this.ngOnInit();
      }
    });
  }
}
