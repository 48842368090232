import { createAction, props } from '@ngrx/store';
import { CategoryModel } from './category.model';
import { Update } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import {CategoryOptModel} from './categoryOpt.model';

export const CategoriesPageRequested = createAction(
  '[Category List Page] Categories Page Requested ',
  props<{ page: QueryParamsModel }>()
);
export const CategoriesPageLoadedSuccessfully = createAction(
  '[Category API] Categories Page Loaded Successfully ',
  props<{ categories: CategoryModel[]; totalCount: number; page: number }>()
);
export const CategoriesPageLoadFailed = createAction(
  '[Category API] Categories Page Request Failed ',
  props<{ error: any }>()
);

// categories list optimiser
export const CategoriesOptPageRequested = createAction(
    '[Category opt List Page] Categories opt Page Requested ',
    props<{ page: QueryParamsModel }>()
);
export const CategoriesOptPageLoadedSuccessfully = createAction(
    '[Category opt API] Categories opt Page Loaded Successfully ',
    props<{ categories: CategoryOptModel[]; totalCount: number; page: number }>()
);
export const CategoriesOptPageLoadFailed = createAction(
    '[Category opt API] Categories opt Page Request Failed ',
    props<{ error: any }>()
);

export const CategoryCreated = createAction(
  '[Category List Page] Category Created ',
  props<{ category: CategoryModel }>()
);
export const CategoryCreatedSuccessfully = createAction(
  '[Category List Page] Category Created Successfully ',
  props<{ category: CategoryModel }>()
);
export const CategoryCreationFailed = createAction(
  '[Category List Page] Category Creation Failed ',
  props<{ error: any }>()
);

export const CategoryUpdated = createAction(
  '[Category List Page] Category Updated ',
  props<{ category: CategoryModel; partialCategory: Update<CategoryModel> }>()
);
export const CategoryUpdatedSuccessfully = createAction(
  '[Category List Page] Category Updated Successfully ',
  props<{ category: CategoryModel; partialCategory: Update<CategoryModel> }>()
);
export const CategoryUpdateFailed = createAction(
  '[Category List Page] Category Update Failed ',
  props<{ error: any }>()
);

export const CategoryDeleted = createAction(
  '[Category List Page] Category Deleted ',
  props<{ categoryId: number }>()
);
export const CategoryDeletedSuccessfully = createAction(
  '[Category List Page] Category deleted Successfully ',
  props<{ categoryId: number }>()
);
export const CategoryDeleteFailed = createAction(
  '[Category List Page] Category delete Failed ',
  props<{ error: any }>()
);

export const CategoryUploadProgressed = createAction(
  '[Category API] Category Upload Progressed',
  props<{ progress: number }>()
);

export const CategoriesListRequested = createAction(
  '[Category list Page] Categories Requested'
);
export const CategoriesListRequestedSuccessfully = createAction(
  '[Category API] Categories Requested Successfully',
  props<{ category: CategoryModel[] }>()
);
export const CategoriesListRequestFailed = createAction(
  '[Category API] Categories Page Request Failed',
  props<{ error: any }>()
);
export const ChangeCategoryStatus = createAction(
  '[Category Status] Change Category Status',
  props<{ category: CategoryModel; partialCategory: Update<CategoryModel> }>()
);
export const CategoryStatusChangedSuccessfully = createAction(
  '[Category Status] Category Status changed Successfully ',
  props<{ category: CategoryModel; partialCategory: Update<CategoryModel> }>()
);
export const CategoryStatusChangeFailed = createAction(
  '[Category Status] Category Status change Failed ',
  props<{ error: any }>()
);
