import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ProductsService} from '../../../../../../../../core/e-commerce/products/products.service';
import {SearchedProductService} from '../searched-product.service';
import {QueryParamsModel} from '../../../../../../../../core/_base/crud';

@Component({
    selector: 'kt-product-search',
    templateUrl: './product-search.component.html',
    styleUrls: ['./product-search.component.scss'],
})

export class ProductSearchComponent {
    public config: PerfectScrollbarConfigInterface = {
        wheelPropagation: false,
        suppressScrollX: true,
    };

    searchControl = new FormControl('');
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    @Output() searchedProduct = new EventEmitter<any>(); // TODO don't use any, must type the exchanged entity

    @Input() icon = 'flaticon2-search-1';

    // Set true to icon as SVG or false as icon class
    @Input() useSVG: boolean;

    @Input() type: 'brand' | 'success' | 'warning' = 'success';
    data: any[];
    loading: boolean;
    finalize: boolean;

    constructor(
        private cdr: ChangeDetectorRef,
        private productService: ProductsService,
        private searchProductService: SearchedProductService
    ) {
    }

    ngOnInit(): void {
    }

    search(e) {
        this.data = null;
        this.loading = true;
        this.finalize = true;
        this.searchProductService
            .getProductByFAB(e.target.value.trim())
            .pipe(finalize(() => (this.loading = false)))
            .subscribe(p => {
                this.data = p;
                console.log(this.data[0])
                this.checkIfProductAlreadyExist();
                this.cdr.markForCheck();
            });
    }

    clear(e) {
        this.data = null;
        this.loading = false;
        this.finalize = false;
        this.searchInput.nativeElement.value = '';
    }

    openChange() {
        setTimeout(() => this.searchInput.nativeElement.focus());
    }

    checkIfProductAlreadyExist() {
        this.data.forEach((e) => {
            let queryParamsModel = new QueryParamsModel(null, null, null, null, null);
            queryParamsModel = {
                ...queryParamsModel,
                filter: {
                    query: this.searchInput.nativeElement.value,
                },
            };
            this.productService.getAllPaged2(queryParamsModel).subscribe((s) => {
                if (s.content.length) {
                    e.exists = true;
                    this.finalize = false;
                    this.cdr.markForCheck();
                }
            });
        });
    }

}
