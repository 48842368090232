import { Component, OnDestroy, OnInit } from '@angular/core';
import { PromoCodeModel } from '../../../../../../core/e-commerce/promo-code/promo-code.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../../../../../../core/reducers';
import {select, Store} from '@ngrx/store';
import {
  selectLastCreatedPromoCodeId,
  selectPromoCodeById,
  selectPromoCodesPageLoading
} from '../../../../../../core/e-commerce/promo-code/promo-code.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { CategoryModel } from '../../../../../../core/e-commerce/categories/category.model';
import { MatRadioChange } from '@angular/material/radio';
import { PromoProductsListComponent } from '../promo-products-list/promo-products-list.component';
import { MatDialog } from '@angular/material/dialog';
import { PromoCodeService } from '../../../../../../core/e-commerce/promo-code/promo-code.service';
import { PromoCategoriesListComponent } from '../promo-categories-list/promo-categories-list.component';
import { Update } from '@ngrx/entity';
import { PromoCodeActions } from '../../../../../../core/e-commerce';
import { EventModel } from '../../../../../../core/e-commerce/promo-code/event.model';
import { Actions, ofType } from '@ngrx/effects';
import { DatePipe } from '@angular/common';
import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
import { CurrentPromotionComponent } from '../current-promotion/current-promotion.component';



@Component({
  selector: 'kt-promo-code-edit',
  templateUrl: './promo-code-edit.component.html',
  styleUrls: ['./promo-code-edit.component.scss']
})
export class PromoCodeEditComponent implements OnInit , OnDestroy {

  promotionCode: PromoCodeModel;
  hasFormErrors = false;
  promoCodeForm: FormGroup;
  loading$: Observable<boolean>;
  generatedRef = '';
  minValueToHave = false;
  promoCodeId: number;
  isLimited: boolean;
  isLimitedToOnce: boolean;
  selectCategory = false;
  applicatedAtData: any;
  loading = false;
  productDataSource = new MatTableDataSource<PromoCodeModel>();
  categoryDataSource = new MatTableDataSource<CategoryModel>();
  categoryDisplayedColumns = ['id', 'label', 'delete'];
  productDisplayedColumns = ['image_', 'name_', 'price_', 'status_', 'delete_'];
  appliedTo = 'ALL_THE_ORDER';
  subscription: Subscription;


  constructor(private store: Store<AppState>,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private promoCodeService: PromoCodeService,
              private actions$: Actions,
              private datePipe: DatePipe,
              private layoutUtilsService: LayoutUtilsService,
  ) { }

  ngOnInit(): void {
    this.promoCodeId = this.activatedRoute.snapshot.params.id;
    this.loading$ = this.store.pipe(select(selectPromoCodesPageLoading));
    this.getPromoCode();
  }
  getPromoCode() {
    if (this.promoCodeId) {
      this.subscription = this.store.pipe(select(selectPromoCodeById(this.promoCodeId))).subscribe((pc) => {
        if (pc) {
          this.loading = false;
          this.promotionCode = pc;
          this.initFormGroup(pc);
          // @ts-ignore
          this.productDataSource.data = this.promotionCode?.products;
          this.categoryDataSource.data = this.promotionCode?.categoryDtos;
        } else {
          this.loading = true;
          this.promoCodeService.getById(this.promoCodeId).subscribe((res) => {
            this.loading = false;
            this.promotionCode = res;
            this.initFormGroup(res);
            // @ts-ignore
            this.productDataSource.data = this.promotionCode?.products;
            this.categoryDataSource.data = this.promotionCode?.categoryDtos;
          });
          this.initFormGroup();
        }
      });
    } else {
      this.initFormGroup();
    }
  }
  minValueOnChange(e) {
    this.minValueToHave = !!e.checked;
    if (this.minValueToHave) {
        this.promoCodeForm.controls.minValue.setValidators([Validators.required]);
      } else {
        this.promoCodeForm.controls.minValue.clearValidators();
      }
    this.promoCodeForm.controls.minValue.updateValueAndValidity();
  }
  initFormGroup(promotionCode?) {
    this.minValueToHave = this.promotionCode?.minValueToHave;
    this.isLimited = this.promotionCode?.haveMaxUseNumber;
    this.isLimitedToOnce = this.promotionCode?.clientUseOneTime;
    this.appliedTo = this.promotionCode?.appliedTo;
    this.promoCodeForm  = this.fb.group({
      title: [this.promotionCode?.event?.name ?? '', [Validators.required]],
      promoCode: [this.promotionCode?.name ?? '', [Validators.required]],
      description: [this.promotionCode?.event?.description ?? ''],
      startingDate: [this.promotionCode?.startingDate ? new Date(this.promotionCode?.startingDate.toString()) : '', [Validators.required]],
      endingDate: [this.promotionCode?.endingDate ? new Date(this.promotionCode?.endingDate .toString()) : '', [Validators.required]],
      value: [this.promotionCode?.value ? this.promotionCode?.promoCodeType === 'PERCENTAGE' ? this.promotionCode?.value * 100 : this.promotionCode?.value : null,
        [Validators.required , Validators.maxLength(99) ]],
      numberUtilisation: [this.promotionCode?.numberUtilisation ?? ''],
      minValue:	[this.promotionCode?.minValue ?? null, this.minValueToHave ? [Validators.required] : []],
      promoCodeType:	[this.promotionCode?.promoCodeType ?? '', [Validators.required]],
    }); }

  Limited(e) {
    this.isLimited = !!e.checked;
    if (this.isLimited) {
      this.promoCodeForm.controls.numberUtilisation.setValidators([Validators.required]);
    } else {
      this.promoCodeForm.controls.numberUtilisation.clearValidators();
    }
    this.promoCodeForm.controls.numberUtilisation.updateValueAndValidity();
  }
  isNotLimited(e) {
    this.isLimitedToOnce = !!e.checked;
  }


  getComponentTitle() {
    if (!this.promotionCode || !this.promotionCode.id) {
      return  'Créer un Code Promo';
    } else {
      return  'Modifier un Code Promo';
    }
  }
  generateReference() {
    if (!this.promoCodeForm.get('startingDate').value) {
      this.layoutUtilsService.showActionNotification(
        'Vous devez d\'abord spécifier une date de début pour généré le code promo',
        MessageType.Delete
      );
      this.promoCodeForm.get('startingDate').markAsTouched();
      this.promoCodeForm.get('promoCode').markAsTouched();
    } else {
    this.generatedRef = 'TK' + this.datePipe.transform(this.promoCodeForm.get('startingDate').value,  'ddMMyyyy');
    this.promoCodeForm.get('promoCode').setValue(this.generatedRef);
    }
  }
  getItemCssClassByStatus(status): string {
    switch (status) {
      case 'OUT_OF_STOCK':
        return 'danger';

      case 'IN_STOCK':
        return 'success';

      case 'ON_COMMAND':
        return 'sun';

      case 'OBSOLETE':
        return 'metal';
    }
    return '';
  }
  goBack() {
    this.router.navigate(['ecommerce', 'promo-code']);
  }
  setType(promoCodeType: string) {
    switch (promoCodeType) {
      case 'ALL_THE_ORDER':
        return 'toute la Commande';
      case 'SELECTED_CATEGORY':
        return 'une Catégorie sélctionnée';
      case 'SELECTED_PRODUCTS':
        return 'des Produits sélctionnés';
    }
  }
  onChangeRadio(radio: MatRadioChange) {
    this.appliedTo = radio.value.toString();
    switch (this.appliedTo) {
      case 'ALL_THE_ORDER':
        this.applicatedAtData = [];
        break;
      case 'SELECTED_CATEGORY':
        break;
      case 'SELECTED_PRODUCTS':
        this.applicatedAtData = this.productDataSource.data;
        break;
    }
  }
  deleteCategory(catId) {this.categoryDataSource.data = this.categoryDataSource.data.filter(item => item.id !== catId); }
  deleteProduct(productId) {this.productDataSource.data = this.productDataSource.data.filter(item => item.id !== productId); }

  showCategoriesList() {
    const dialogRef = this.dialog.open(PromoCategoriesListComponent, {
      data: this.categoryDataSource.data,
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.categoryDataSource.data = res;
      }
    });
  }

  showProductsList() {
    if (this.productDataSource.data === undefined) {
      console.log('wow');
    }
    const dialogRef = this.dialog.open(PromoProductsListComponent, {
      data: this.productDataSource.data,
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.productDataSource.data = res ;
  }
    });
  }
  preparePromoCode() {
    const controls = this.promoCodeForm.controls;
    const promoCode = new PromoCodeModel();
    const promoCodEvent = new EventModel();
    promoCodEvent.name = controls.title.value;
    promoCodEvent.description = controls.description.value;
    promoCode.id = this.promoCodeId;
    promoCode.event = promoCodEvent;
    promoCode.name = controls.promoCode.value;
    promoCode.promoCodeType = controls.promoCodeType.value;
    promoCode.value = controls.promoCodeType.value === 'PERCENTAGE' ? controls.value.value / 100 : controls.value.value;
    promoCode.minValue = controls.minValue.value;
    promoCode.numberUtilisation = this.isLimited ? controls.numberUtilisation.value : null;
    promoCode.haveMaxUseNumber = this.isLimited ? this.isLimited : false;
    promoCode.minValueToHave = this.minValueToHave ? this.minValueToHave : false;
    promoCode.clientUseOneTime = this.isLimitedToOnce ? this.isLimitedToOnce : false;
    promoCode.startingDate = controls.startingDate.value;
    promoCode.endingDate = controls.endingDate.value;
    promoCode.appliedTo = this.appliedTo;
    promoCode.state = this.promoCodeId ? this.promotionCode.state : true;
    promoCode.categoryId = this.categoryDataSource.data.length > 0 ? this.categoryDataSource.data.map((c) => c.id) : null;
    // @ts-ignore
    promoCode.products = this.productDataSource.data;
    return promoCode;
  }
  onSubmit() {
    this.hasFormErrors = false;
    const controls = this.promoCodeForm.controls;
    if (this.promoCodeForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }
    const editedPromoCode = this.preparePromoCode();
    if (editedPromoCode.id > 0) {
      this.updatePromoCode(editedPromoCode);
    } else {
      this.addPromoCode(editedPromoCode);
    }
  }

  updatePromoCode(editedPromoCode: PromoCodeModel) {
    const updatePromoCode: Update<PromoCodeModel> = {
      id: editedPromoCode.id,
      changes: editedPromoCode,
    };
    this.store.dispatch(
      PromoCodeActions.PromoCodeUpdated({
        partialPromoCode: updatePromoCode,
        promoCode: editedPromoCode,
      })
    );
    this.actions$.pipe(ofType(PromoCodeActions.PromoCodeUpdateFailed)).subscribe((data) => {
      const discount = data.error.error[0];
      if (discount) {
        this.dialog.open(CurrentPromotionComponent, {
          data: discount,
          height : 'auto',
        });
      }
    });
    this.actions$.pipe(ofType(PromoCodeActions.PromoCodeUpdatedSuccessfully)).subscribe((data) => {
      if (data) {
        const url = `/ecommerce/promo-code/edit/${data.promoCode.id}`;
        this.router.navigateByUrl(
          url,
          {}
        );
      }
    });
  }

  addPromoCode(promoCode: PromoCodeModel) {
    this.store.dispatch(PromoCodeActions.PromoCodeCreated({promoCode}));
    this.store
      .pipe(select(selectLastCreatedPromoCodeId))
      .subscribe((newId) => {
        if (!newId) {
          this.actions$.pipe(ofType(PromoCodeActions.PromoCodeCreationFailed)).subscribe((data) => {
            if (data) {
              this.dialog.open(CurrentPromotionComponent, {
                data: data.error.error,
                height : 'auto',
              });
            }
          });
        } else {
          const url = `/ecommerce/promo-code/edit/${newId}`;
          this.router.navigateByUrl(
            url,
            {}
          );
        }
      });
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  ngOnDestroy(): void {
  // this.subscription.unsubscribe();
  }
}
