import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ADMIN_CONFIG} from '../../../../environments/environment';
import {ProductCharacteristicModel} from './product-characteristic.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ProductCharacteristicsService {
    url: string;

    constructor(protected http: HttpClient) {
        this.url = `${ADMIN_CONFIG.apiEndpoint}product`;
    }

    public getByProductId(id: number): Observable<any> {
        const url = `${this.url}/${id}/productcharacterstic`;
        return this.http.get<ProductCharacteristicModel[]>(url);
    }

    public save(
        entity: ProductCharacteristicModel,
        productId: number
    ): Observable<ProductCharacteristicModel> {
        const list: ProductCharacteristicModel[] = [];
        list.push(entity);
        const res = this.http.post<ProductCharacteristicModel[]>(
            `${this.url}/${productId}/productcharacterstic`,
            list
        );
        return res.pipe(map((result) => result[0]));
    }

    public update(
        entity: ProductCharacteristicModel,
        productId: number
    ): Observable<ProductCharacteristicModel> {
        return this.http.put<ProductCharacteristicModel>(
            `${this.url}/${productId}/productcharacterstic`,
            entity
        );
    }

    public delete(id: number, productId: number): Observable<any> {
        const url = `${this.url}/${productId}/productcharacterstic/${id}`;
        return this.http.delete<void>(url);
    }
}
