import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as LicensesActions from '../../../../../../../../../core/e-commerce/product-licenses/product-licenses.actions';

import {TranslateService} from '@ngx-translate/core';
import { LayoutUtilsService, QueryParamsModel } from '../../../../../../../../../core/_base/crud';
import {SubheaderService} from '../../../../../../../../..//core/_base/layout';
import {AppState} from '../../../../../../../../..//core/reducers';
import {ProductLicensesService} from '../../../../../../../../../core/e-commerce/product-licenses/product-licenses.service';
import {DatePipe} from '@angular/common';
import {ProductLicensesStatusModel} from '../../../../../../../../../core/e-commerce/product-licenses/product-licneses-status.model';
import {ProductLicensesAllModel} from '../../../../../../../../../core/e-commerce/product-licenses/product-licenses-all.model';
import { debounceTime, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import {
  isProductLicenseLoading, selectImportProductLicenseFailed
} from '../../../../../../../../../core/e-commerce/product-licenses/product-licenses.selectors';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { SortDirection } from '@angular/material/sort/typings/sort-direction';
import { ProductsService } from '../../../../../../../../../core/e-commerce/products/products.service';
import { ProductModel } from '../../../../../../../../../core/e-commerce/products/product.model';


@Component({
    selector: 'kt-licenses-list',
    templateUrl: './product-licenses-list.component.html',
    styleUrls: ['./product-licenses-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductLicensesListComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<ProductLicensesAllModel> = new MatTableDataSource(
    []
  );
  myObservableObject$: Observable<any>;


  myDate: string;
  displayedColumns =  [
    'select',
    'id',
    'activationKey',
    'referenceKey',
    'createdAt',
    'client',
    'dateImport',
    'dateCommand',
    'status',
    'vu'
  ];
  fileInput: ElementRef;


  productId: number;
  loading = true;
  over: boolean[];
  startDate = '';
  endDate = '';
  filterStatus = new FormControl('false');
  filterReserved = new FormControl('');
  filterVu = new FormControl('');
  dateFilter = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });



  selection = new SelectionModel<ProductLicensesAllModel>(true, []);
  licensesResults: ProductLicensesAllModel[] = [];
  licensesStatus: ProductLicensesStatusModel[] = [];
  licenses: ProductLicensesAllModel[] = [];
  private subscriptions: Subscription[] = [];
  loadingKeys: Observable<boolean>;
  countM: Observable<number>;
  file: File;
  arrayBuffer: any;
  data: any[] = [];
  importError: Observable<any>;
  error: any = null;
  direction: SortDirection;
  product: ProductModel;
  totalCount: number;



  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private productLicensesService: ProductLicensesService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private ref: ChangeDetectorRef,
    private productService: ProductsService,
  ) {
  }

  ngOnInit() {
    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      this.productId = params.id;
    });
    this.productService.getById(this.productId).subscribe((product) => {
      this.product = product;
    });
    this.paginator._changePageSize(this.paginator.pageSize);



    const paginatorSubscriptions = merge(
      this.paginator.page
    )
      .pipe(tap(() => this.loadProductLicenses()))
      .subscribe();
    this.loadProductLicenses();
    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';
    this.subscriptions.push(routeSubscription , paginatorSubscriptions);
    this.selection.clear();
    this.over = new Array(this.dataSource.data.length);
    this.over.fill(false);
    this.loadingKeys = this.store.pipe(select(isProductLicenseLoading));
    this.importError = this.store.pipe(select(selectImportProductLicenseFailed));
    this.importError.subscribe((c) => {
      this.error = c;
    });

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.loadProductLicenses();
        })
      )
      .subscribe();
  }
  loadProductLicenses() {
    const queryParams =  this.filterConfiguration();
    this.productLicensesService.getLicensesStatusByProductId(this.productId, queryParams).pipe(
      distinctUntilChanged()).subscribe(
      (va) => {
        // @ts-ignore
        this.dataSource.data = va?.licensesList;
        // @ts-ignore
        this.totalCount = va.listSize;
        this.loading = false ;
        this.ref.detectChanges();
  }
    );
    this.selection.clear();
  }
  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value) {
      filter.status = this.filterStatus.value;
    }
    if (this.filterReserved.value) {
      filter.reserved = this.filterReserved.value;
    }
    if (this.filterVu.value) {
      filter.seen = this.filterVu.value;
    }
    filter.query = this.searchInput.nativeElement.value;
    filter.startDate = this.startDate;
    filter.endDate = this.endDate;
    filter.size = this.paginator.pageSize;
    filter.page = this.paginator.pageIndex;

    return filter;
  }
  filterByDate() {
    if (this.dateFilter.get('endDate').value) {
      this.startDate = new DatePipe('fr-FR').transform(
        new Date(this.dateFilter.get('startDate').value),
        'YYYY/MM/dd'
      );
      this.endDate = new DatePipe('fr-FR').transform(
        new Date(this.dateFilter.get('endDate').value),
        'YYYY/MM/dd'
      );
    } else {
      this.startDate = '';
      this.endDate = '';
    }
    this.loadProductLicenses();
  }


  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  importLicenses(files: FileList , event: any) {
    this.store.dispatch(
      LicensesActions.ProductLicensesImport({
        productLicenses: files.item(0),
        productId: this.productId,
      }),
    );
    const target: DataTransfer = (event.target) as DataTransfer;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      this.data = XLSX.utils.sheet_to_json(ws);
    };

    this.fileInput.nativeElement.setValue('');
  }

  exportLicenses() {
    this.productLicensesService
      .exportLicenses(this.productId)
      .subscribe((licenses) => {
        const blob = new Blob([licenses], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download =
          'ProductLicenses_' +
          new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') +
          '.xlsx';
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.licensesResults.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.licensesResults.forEach((row) => this.selection.select(row));
    }
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}

