import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ClaimModel } from './claim.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as ClaimActions from './claim.actions';

export interface ClaimState extends EntityState<ClaimModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedClaimId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  lastAction: string;
}

export const adapter: EntityAdapter<ClaimModel> =
  createEntityAdapter<ClaimModel>();

export const initialClaimState: ClaimState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedClaimId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  lastAction: '',
});

const claimReducer = createReducer(
  initialClaimState,
  on(ClaimActions.ClaimPageRequest, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.queryParams,
  })),
  on(ClaimActions.ClaimPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.claims, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(ClaimActions.ClaimPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  on(ClaimActions.ClaimRequest, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ClaimActions.ClaimLoadedSuccessfully, (state, action) =>
    adapter.addOne(action.claim, {
      ...state,
      actionsLoading: false,
    })
  ),
  on(ClaimActions.ClaimLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  on(ClaimActions.ClaimArchive, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ClaimActions.ClaimArchivedSuccessfully, (state, action) =>
    adapter.removeOne(action.claim.id, {
      ...state,
      actionsLoading: false,
    })
  ),
  on(ClaimActions.ClaimArchiveFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  on(ClaimActions.ClaimStatusChange, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ClaimActions.ClaimStatusChangedSuccessfully, (state, action) =>
    adapter.updateOne(
      { id: action.claim.id, changes: action.claim },
      {
        ...state,
        actionsLoading: false,
      }
    )
  ),
  on(ClaimActions.ClaimStatusChangeFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  on(ClaimActions.ClaimAdminAffectation, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(ClaimActions.ClaimAdminAffectedSuccessfully, (state, action) =>
    adapter.updateOne(
      { id: action.claim.id, changes: action.claim },
      {
        ...state,
        actionsLoading: false,
      }
    )
  ),
  on(ClaimActions.ClaimAdminAffectationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  }))
);

export function claimsReducer(state: ClaimState | undefined, action: Action) {
  return claimReducer(state, action);
}
