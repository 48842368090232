import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '../../../../../../core/reducers';
import { selectLogsById, selectLogsPageLoading } from '../../../../../../core/e-commerce/odoo/logs.selector';
import { LogsModel } from '../../../../../../core/e-commerce/odoo/logs.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { LogsService } from '../../../../../../core/e-commerce/odoo/logs.service';

@Component({
  selector: 'kt-logs-edit',
  templateUrl: './logs-edit.component.html',
  styleUrls: ['./logs-edit.component.scss']
})
export class LogsEditComponent implements OnInit {
  logId: number;
  loading$: Observable<boolean>;
  subscription: Subscription;
  log: LogsModel;
  hasFormErrors = false;
  logForm: FormGroup;


  constructor(private store: Store<AppState>,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private fb: FormBuilder,
              private logsService: LogsService) { }

  ngOnInit(): void {
    this.logId = this.activatedRoute.snapshot.params.id;
    this.loading$ = this.store.pipe(select(selectLogsPageLoading));
    this.getLog();
  }
  getLog() {
    if (this.logId) {
      this.subscription = this.store.pipe(select(selectLogsById(this.logId))).subscribe((log) => {
        if (log) {
        this.log = log;
        this.initLog(log);
        } else {
          this.logsService.getLogsById(this.logId).subscribe((logs) => {
            this.log = logs;
            this.initLog(logs);
          });
        }
      });
      }
  }
  initLog(log: LogsModel) {
    this.createForm(log);
  }
  createForm(log: LogsModel) {
    this.logForm = this.fb.group({
      operation: [log.operation],
      status: [log.status],
      actionType: [log.actionType],
      modelName: [log.modelName],
      queueName: [log.queueName],
      message: [log.message],
      createdAt: [new DatePipe('fr-FR').transform(log.createdAt, 'dd/MM/YYYY')],
    });
  }
  goBackWithoutId() {
    this.location.back();
  }

}
