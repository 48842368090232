import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { DiscountModel } from './discount.model';
import { ProductModel } from '../products/product.model';

@Injectable({
  providedIn: 'root',
})
export class DiscountsService extends AbstractService<DiscountModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}discount`;
  }

  public getAllDiscounts(
    page: number = 0,
    size: number = 100
  ): Observable<Page<DiscountModel>> {
    const options = {
      params: new HttpParams()
        .append('page', page.toString())
        .append('size', size.toString()),
    };
    const url = `${this.url}`;
    return this.http.get<Page<DiscountModel>>(url, options);
  }

  public updateDiscount(entity: any): Observable<any> {
    return this.http.put<any>(this.url, entity);
  }

  public linkProductToDiscount(entity: any): Observable<any> {
    const url = this.url + '/product_discount';
    return this.http.patch<any>(url, entity);
  }

  public unlinkProduct(id: number, productId: number): Observable<any> {
    const url = this.url + `/product_discount/${id}?productId=${productId}`;
    return this.http.delete<any>(url);
  }

  public getDiscountById(id: number): Observable<DiscountModel> {
    const url = this.url + `/${id}`;
    return this.http.get<any>(url);
  }

  public saveDescounts( id: number,
                        discountType: 'PERCENTAGE' | 'FIX',
                        startDate: Date,
                        endDate: Date,
                        value: number,
                        products?: ProductModel): Observable<DiscountModel> {
   const url = `${this.url}`;
   const body = {
          id: id,
          discountType: discountType,
          startDate: startDate,
          endDate: endDate,
          value: value,
          products: products,
   }    
    return this.http.post<DiscountModel>(url, body);
  }
}
