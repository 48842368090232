import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  tap,
} from 'rxjs/operators';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../core/_base/crud';
import { ProductModel } from '../../../../core/e-commerce/products/product.model';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { QuotationModel } from '../../../../core/e-commerce/quotation/quotation.model';
import { QuotationService } from '../../../../core/e-commerce/quotation/quotation.service';
import { ClientService } from '../../../../core/e-commerce/clients/client.service';
import { CompaniesService } from '../../../../core/e-commerce/companies/companies.service';

@Component({
  selector: 'kt-archived-quotation-list',
  templateUrl: './archived-quotation-list.component.html',
  styleUrls: ['./archived-quotation-list.component.scss'],
})
export class ArchivedQuotationListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  dataSource: MatTableDataSource<QuotationModel> = new MatTableDataSource([]);
  totalElements = 0;
  displayedColumns = [
    'select',
    'id',
    'number',
    'client',
    'company',
    'createdAt',
    'expiredAt',
    'status',
    'actions',
  ];

  loading$: Observable<boolean> = of(false);
  isPreloadTextViewed$: Observable<boolean> = of(true);

  filterStatus = new FormControl('');

  selection = new SelectionModel<QuotationModel>(true, []);

  private subscriptions: Subscription[] = [];
  constructor(
    private quotationService: QuotationService,
    private layoutUtilsService: LayoutUtilsService,
    private clientService: ClientService,
    private companyService: CompaniesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadQuotationsList();
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadQuotationsList();
        })
      )
      .subscribe();
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page
    )
      .pipe(tap(() => this.loadQuotationsList()))
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }

  loadQuotationsList(filter?: QueryParamsModel) {
    this.selection.clear();
    let queryParams: any;
    if (filter) {
      queryParams = filter;
    } else {
      queryParams = new QueryParamsModel(
        this.filterConfiguration(),
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize
      );
    }

    this.isPreloadTextViewed$ = of(true);

    this.quotationService
      .getArchivedQuotations(queryParams)
      .pipe(
        finalize(() => {
          this.isPreloadTextViewed$ = of(false);
        })
      )
      .subscribe((quotations) => {
        quotations.content.forEach((q) =>
          this.clientService.getById(q.clientId).subscribe((c) => {
            this.companyService.getById(c.companyId).subscribe((company) => {
              q.companyName = company.name;
              q.clientNumber = company.accountNumber;
              this.cdr.detectChanges();
            });
            q.clientName =
              c.name.toLowerCase() + ' ' + c.lastname.toLowerCase();
            q.clientMail = c.mail;
            this.cdr.detectChanges();
          })
        );
        this.dataSource.data = quotations.content;
        this.totalElements = quotations.totalElements;
      });
  }

  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value != '') {
      filter.status = this.filterStatus.value;
    }
    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  unarchiveQuotation(_item: ProductModel) {
    const _title = 'Désarchiver le devis';
    const _description = 'Voulez-vous vraiment dérachiver ce devis ?';
    const _waitDesciption = 'Désarchivage en cours...';
    const btnTitle = 'Désarchiver';

    const dialogRef = this.layoutUtilsService.archiveElement(
      _title,
      _description,
      _waitDesciption,
      btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.quotationService.unarchiveQuotation(_item.id).subscribe(() => {
        this.dataSource.data = this.dataSource.data.filter(
          (p) => p.id !== _item.id
        );
        this.layoutUtilsService.showActionNotification(
          'Le devis est désarchivé',
          MessageType.Update
        );
      });
    });
  }

  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'VALIDATED':
        return 'success';
      case 'CANCELED':
      case 'EXPIRED':
        return 'danger';
      case 'SUBMITTED':
        return 'metal';
    }
    return '';
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.paginator.pageSize;
    return numSelected === numRows;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((e) => e.unsubscribe());
  }
}
