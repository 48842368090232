import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OrderModel } from '../../../../../../core/e-commerce/orders/order.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LayoutUtilsService,
  MessageType,
  TypesUtilsService,
} from '../../../../../../core/_base/crud';

import { KtDialogService } from '../../../../../../core/_base/layout';
import { OrdersService } from '../../../../../../core/e-commerce/orders/orders.service';
import { finalize } from 'rxjs/operators';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { ClientService } from '../../../../../../core/e-commerce/clients/client.service';
import { CompaniesService } from '../../../../../../core/e-commerce/companies/companies.service';
import { DatePipe } from '@angular/common';
import { ProductsService } from '../../../../../../core/e-commerce/products/products.service';
import { ClientModel } from '../../../../../../core/e-commerce/clients/client.model';
import { OrderItemModel } from '../../../../../../core/e-commerce/OrderItems/OrderItem.model';
import { MatDialog } from '@angular/material';
import { AddressEditDialogComponent } from '../../companies/companies-list/_subs/addresses/address-edit/address-edit-dialog.component';
import { AddressTransPipe } from '../../../../../pipes/address-trans.pipe';
import { AddressModel } from '../../../../../../core/e-commerce/companies/address.model';
import { CountryModel } from '../../../../../../core/_models/country.model';
import { CountryService } from '../../../../../../core/services/country.service';

@Component({
  selector: 'kt-order-edit',
  templateUrl: './order-edit.component.html',
  styles: [
    `.odoo-message{
                font-size: 15px ;
                background-color: red ;
                color: white;
                width: 100%;
                margin-right: 206px;
            }
        `,
  ],
})
export class OrderEditComponent implements OnInit {
  title = '';
  selectedTab = 0;
  order: OrderModel;
  orderId: number;

  orderForm: FormGroup;
  newOrderForm: FormGroup;
  loading = false;
  hasFormErrors = false;

  suggestedClients: ClientModel[];
  orderItems: Partial<OrderItemModel>[] = [];

  customCurrencyMaskConfig = {
    align: 'left',
    prefix: '',
    suffix: ' €',
    inputMode: CurrencyMaskInputMode.NATURAL,
  };
  private billingAddress: AddressModel;
  private status: string = null;

  private countries: CountryModel[] = [];

  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ktDialogService: KtDialogService,
    private typesUtilsService: TypesUtilsService,
    private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private orderService: OrdersService,
    private clientService: ClientService,
    private companyService: CompaniesService,
    private productService: ProductsService,
    private countryService: CountryService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  /**
   * On init
   */
  ngOnInit() {
    this.orderId = this.activatedRoute.snapshot.params['id'];

    this.activatedRoute.queryParamMap.subscribe(() => {
      this.loading = true;
      this.getOrder();
    });

    this.countryService
      .countries()
      .subscribe((countries) => (this.countries = countries));
  }

  getOrder() {
    if (this.orderId) {
      this.ktDialogService.show();
      this.loading = true;
      this.orderService
        .findById(this.orderId)
        .pipe(finalize(() => this.ktDialogService.hide()))
        .subscribe((o) => {
          this.order = o;
          this.orderItems = o.items;
          this.getClientInfo(o.clientId);
          this.getOrderItemsProduct();
          this.updateComponentTitle();
          this.initFormGroup();
          this.loading = false;
        });
    } else {
      this.initFormGroup();
      this.loading = false;
      setTimeout(() => {
        this.updateComponentTitle();
      }, 100);
    }
  }

  getClientInfo(clientId: number) {
    this.clientService.getById(clientId).subscribe((c) => {
      this.order.clientName = c.name + ' ' + c.lastname;
      this.companyService.getById(c.companyId).subscribe(
        (company) => {
          this.billingAddress = company.addresses[0];
          this.order.companyName = company.name;
          this.initFormGroup();
        },
        () => {
          this.initFormGroup();
          this.orderForm.get('companyName').markAsTouched();
        }
      );
    });
  }

  getOrderItemsProduct() {
    this.order.items.forEach((i) => {
      this.productService
        .getById(i.productId)
        .subscribe((p) => (i.product = p));
    });
  }

  initFormGroup() {
    this.orderForm = this.fb.group({
      id: [this.order ? this.order.id : null],
      number: [this.order ? this.order.number : null],
      createdAt: [
        this.order
          ? new DatePipe('fr-FR').transform(this.order.createdAt, 'dd/MM/YYYY')
          : null,
      ],
      address: [this.order ? this.order.deliveryAddress : null],
      clientName: [
        this.order ? this.order.clientName : null,
        Validators.required,
      ],
      companyName: [
        this.order ? this.order.companyName : null,
        Validators.required,
      ],
      clientId: [this.order ? this.order.id : null],
      totalPrice: [this.order ? this.order.totalPrice : 15],
      shippingCost: [
        this.order ? this.order.shippingCost : 15,
        Validators.required,
      ],
      status: [this.order ? this.order.status : null, Validators.required],
    });

    if (!this.orderId) {
      this.orderForm.get('clientName').valueChanges.subscribe((v) => {
        this.clientService
          .searchClient(v)
          .subscribe((clients) => (this.suggestedClients = clients));
      });
    }
    this.orderForm.get('status').valueChanges.subscribe((selectedValue) => {
      this.status = selectedValue;
    });
    this.orderForm
      .get('shippingCost')
      .valueChanges.subscribe((_) => this.calculateTotalPrice());
  }

  goBack() {
    this.router.navigate(['ecommerce', 'orders']);
  }

  reset() {}

  onSumbit() {
    if (this.orderId) {
      const payload = {
        id: this.orderId,
        status: this.status,
        deliveryAddress: this.orderForm.get('address').value,
        shippingCost: this.orderForm.get('shippingCost').value,
      };
      this.orderService.updateOrder(payload).subscribe(
        () => {
          this.layoutUtilsService.showActionNotification(
            'Mise à jour avec succès'
          );
        },
        () => {
          this.layoutUtilsService.showActionNotification(
            'Une erreur est survenue !'
          );
        }
      );
    } else {
      this.createNewOrder();
    }
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  public sendViaEmail(order: OrderModel) {
    const _title = 'Commande  ' + order.number;
    const _description = 'Envoyer une copie de commande en piece jointe';
    const _waitDesciption = 'Envoie en cours...';
    let _defaultMail = '';
    this.clientService.getById(order.clientId).subscribe((c: ClientModel) => {
      _defaultMail = c.mail;
      const dialogRef = this.layoutUtilsService.sendMail(
        _title,
        _description,
        _waitDesciption,
        _defaultMail
      );
      dialogRef.afterClosed().subscribe((res) => {
        if (!res) {
          return;
        }
        this.orderService.sendInvoiceViaEmail(order.id, res).subscribe(
          () =>
            this.layoutUtilsService.showActionNotification(
              'Un mail a été envoyé à ' + res,
              MessageType.Create
            ),
          () =>
            this.layoutUtilsService.showActionNotification(
              'Oops! l\'e-mail n\'a pas été envoyé',
              MessageType.Delete
            )
        );
      });
    });
  }

  updateComponentTitle() {
    if (!this.order) {
      this.title = 'Créer une nouvelle commande';
    } else {
      this.title = 'Commande N° ' + this.order.number;
    }
  }

  updateOrderItems(items) {
    this.orderItems = items;
    this.calculateTotalPrice();
  }

  createNewOrder() {
    if (this.orderForm.invalid) {
      this.layoutUtilsService.showActionNotification(
        'Veuillez remplir les champs'
      );
      this.orderForm.markAllAsTouched();
      return;
    }
    if (!this.orderItems.length) {
      this.selectedTab = 1;
      this.layoutUtilsService.showActionNotification(
        'Veuillez rajouter des produits'
      );
      return;
    }
    if (this.billingAddress === undefined) {
      this.layoutUtilsService.showActionNotification(
        'Veuillez ajouter une adresse pour votre entreprise'
      );
      return;
    }
    const paylod = {
      clientId: this.orderForm.value.clientId,
      status: this.orderForm.value.status,
      deliveryType: 'FULL',
      paymentType: 'WIRE_TRANSFER',
      currency: 'EUR',
      billingAddress: new AddressTransPipe().transform(this.billingAddress),
      deliveryAddress: this.orderForm.value.address,
      items: this.orderItems,
      shippingCost: this.orderForm.value.shippingCost,
    };
    this.orderService.addNewOrder(paylod).subscribe({
      next: (data) => {
        this.layoutUtilsService.showActionNotification(
          'Commande N° ' + data.number + ' créée avec succès'
        );
      },
      error: () => {
        this.layoutUtilsService.showActionNotification(
          'Une erreur est survenue! Veuillez réessayer '
        );
      },
      complete: () => {
        setTimeout(() => this.goBack(), 700);
      },
    });
  }

  selectClient(event: any) {
    const client = this.suggestedClients.find((c) => (c.id = event));
    this.orderForm
      .get('clientName')
      .setValue(client.name + ' ' + client.lastname);
    this.orderForm.get('clientId').setValue(client.id);
    this.companyService.getById(client.companyId).subscribe(
      (company) => {
        this.billingAddress = company.addresses[0];
        this.orderForm.get('companyName').setValue(company.name);
      },
      (error) => {
        this.layoutUtilsService.showActionNotification(
          'erreur: ' + error.message + '  !'
        );
      }
    );
    this.clientService.getAddressBook(client.id).subscribe(
      (value) => {
        if (value.totalElements === 1) {
          this.orderForm
            .get('address')
            .setValue(new AddressTransPipe().transform(value.content[0]));
        }
      },
      (error) => {
        this.layoutUtilsService.showActionNotification(
          'erreur: ' + error.message + '  !'
        );
      }
    );
  }

  newAddress() {
    const dialogRef = this.dialog.open(AddressEditDialogComponent, {
      data: {
        bill: true,
        address: this.orderForm.value.address
          ? new AddressTransPipe().inverse(
              this.orderForm.value.address,
              this.countries
            )
          : '',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.orderForm
          .get('address')
          .setValue(new AddressTransPipe().transform(res));
      }
    });
  }

  calculateTotalPrice() {
    this.orderForm
      .get('totalPrice')
      .setValue(
        this.orderItems
          .map((a) => a.itemTotalPrice)
          .reduce((a, b) => a + b, 0) + this.orderForm.get('shippingCost').value
      );
  }
  goToOdoo() {
    window.open(`https://imtech.dev.odoo.wissal-group.com/showquotation?name=${this.order.number}`, '_blank');
  }
}
