import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {filter, finalize, skip} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';

import {TranslateService} from '@ngx-translate/core';
import {LayoutUtilsService, MessageType, QueryResultsModel, } from '../../../../../../../../../core/_base/crud';
import {
    ProductCharacteristicModel
} from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.model';
import {SubheaderService} from '../../../../../../../../../core/_base/layout';
import {AppState} from '../../../../../../../../../core/reducers';
import {
    selectProductCharacteristicInStore,
    selectPSharError,
    selectPSharErrorMessage,
} from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.selectors';
import {
    ProductCharacteristicsEditDialogComponent
} from '../product-characteristics-edit-dialog/product-characteristics-edit-dialog.component';
import {
    ProductCharacteristicsService
} from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristics.service';
import { ProductActions } from '../../../../../../../../../core/e-commerce';
import * as CharacteristicActions
    from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.actions';
import { ProductCharacteristicDeleted } from '../../../../../../../../../core/e-commerce/product-characteristic/product-characteristic.actions';

@Component({
    selector: 'kt-product-characteristic-list',
    templateUrl: './product-characteristic-list.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class ProductCharacteristicListComponent implements OnInit, AfterViewInit , OnDestroy {
    dataSource: MatTableDataSource<ProductCharacteristicModel> =
      new MatTableDataSource([]);

    displayedColumns = [
        'select',
        'id',
        'title',
        'label',
        'value',
        'additionalInfo',
        'actions',
    ];
    @ViewChild('sort', {static: true}) sort: MatSort;

    productId: number;
    loading = false;

    selection = new SelectionModel<ProductCharacteristicModel>(true, []);
    characteristicResults: ProductCharacteristicModel[] = [];
    private subscriptions: Subscription[] = [];

    constructor(
      public dialog: MatDialog,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private subheaderService: SubheaderService,
      private layoutUtilsService: LayoutUtilsService,
      private translate: TranslateService,
      private store: Store<AppState>,
      private productCharacteristicsService: ProductCharacteristicsService,
    ) {
    }

    /**
     * On init
     */
    ngOnInit() {
        const routesubscription = this.activatedRoute?.params.subscribe((res) => {
            this.productId = res?.id;
        });
        if (routesubscription) {
            this.subscriptions.push(routesubscription);
        }
        // todo change the way of getting the error message using operators
        const error$ = this.store
          .select(selectPSharError)
          .pipe(
            filter((result) => result !== null),
            skip(1)
          )
          .subscribe(() => {
              const errorMessageSub = this.store
                .select(selectPSharErrorMessage)
                .subscribe((res) => {
                    this.layoutUtilsService.showActionNotification(
                      this.translate.instant('ECOMMERCE.PRODUCTS.' + res),
                      MessageType.Delete,
                      5000,
                      true,
                      false
                    );
                });
              this.subscriptions.push(errorMessageSub);
          });
        this.subscriptions.push(error$);
        this.store.pipe(select(selectProductCharacteristicInStore)).subscribe((response) => {
              if (response) {
                  this.dataSource.data = response.items;
                  this.dataSource.sort = this.sort;
                  this.loading = true;
                  this.selection.clear();
              }
          });
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach((el) => el.unsubscribe());
    }

    /**
     * Load Characteristic List
     */

    /** ACTIONS */
    /**
     * Delete characteristic
     *
     * @param _item: ProductCharacteristicModel
     */
    deleteCharacteristic(_item: ProductCharacteristicModel) {
        const _title = 'Characteristic Delete';
        const _description =
          'Are you sure to permanently delete this characteristic?';
        const _waitDesciption = 'Characteristic is deleting...';

        const dialogRef = this.layoutUtilsService.deleteElement(
          _title,
          _description,
          _waitDesciption
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
            this.store.dispatch(
              CharacteristicActions.ProductCharacteristicDeleted({ id : _item.id, productId: this.productId})
            );
            /*this.productCharacteristicsService.delete(_item.id, this.productId).pipe(
              finalize(() => this.ngOnInit())
            ).subscribe(
              () => { this.layoutUtilsService.showActionNotification(
                'suppression avec succès',
                MessageType.Delete
              );
              },
              () => this.layoutUtilsService.showActionNotification(
                'échec de la suppression',
                MessageType.Delete
              ));*/
        });

    }

    /**
     * Redirect to edit page
     *
     * @param spec
     */
    editCharacteristic(spec: ProductCharacteristicModel) {
        const dialogRef = this.dialog.open(
          ProductCharacteristicsEditDialogComponent,
          {
              data: spec,
          }
        ).afterClosed().subscribe(() => {
            this.ngOnInit();

        });
    }

    createCharacteristic() {
        this.dialog.open(ProductCharacteristicsEditDialogComponent, {
            data: null,
        }).afterClosed().subscribe(() => {
            this.ngOnInit();
        });
    }
    ngAfterViewInit() {
        this.ngOnInit();
    }

    /**
     * Check all rows are selected
     */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.characteristicResults.length;
        return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection
     */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.characteristicResults.forEach((row) => this.selection.select(row));
        }
    }
}
