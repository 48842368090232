import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClientfunctionsService } from './clientfunctions.service';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
//Actions
import * as ClientFunctionActions from './clientfunction.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ClientfunctionEffects {
  constructor(
    private actions$: Actions,
    private clientfunctionService: ClientfunctionsService,
    private store: Store<AppState>
  ) {}

  ClientFunctionPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientFunctionActions.ClientFunctionsPageRequested),
      switchMap((action) =>
        this.clientfunctionService.getAll().pipe(
          map((clientfunctionPage) =>
            ClientFunctionActions.ClientFunctionsPageLoadedSuccessfully({
              clientfunctions: clientfunctionPage,
              page: 0,
              totalCount: 0,
            })
          ),
          catchError((error) =>
            of(ClientFunctionActions.ClientFunctionsPageLoadFailed({ error }))
          )
        )
      )
    )
  );

  ClientFunctionCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientFunctionActions.ClientFunctionCreated),
      switchMap((action) =>
        this.clientfunctionService.save(action.clientfunction).pipe(
          map((data) =>
            ClientFunctionActions.ClientFunctionCreatedSuccessfully({
              clientfunction: data,
            })
          ),
          catchError((error) =>
            of(ClientFunctionActions.ClientFunctionCreationFailed({ error }))
          )
        )
      )
    )
  );

  ClientFunctionUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientFunctionActions.ClientFunctionUpdated),
      switchMap((action) =>
        this.clientfunctionService.update(action.clientfunction).pipe(
          map((clientfunction) =>
            ClientFunctionActions.ClientFunctionUpdatedSuccessfully({
              clientfunction,
              partialClientFunction: action.partialClientFunction,
            })
          ),
          catchError((error) =>
            of(ClientFunctionActions.ClientFunctionUpdateFailed({ error }))
          )
        )
      )
    )
  );

  ClientFunctionDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientFunctionActions.ClientFunctionDeleted),
      switchMap((action) =>
        this.clientfunctionService.delete(action.clientfunctionId).pipe(
          map(() =>
            ClientFunctionActions.ClientFunctionDeletedSuccessfully({
              clientfunctionId: action.clientfunctionId,
            })
          ),
          catchError((error) =>
            of(ClientFunctionActions.ClientFunctionDeleteFailed({ error }))
          )
        )
      )
    )
  );
}
