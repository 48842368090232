import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { SupplierModel } from './supplier.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';

@Injectable()
export class SupplierService {
  url: string;

  constructor(protected http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}supplier`;
  }

  public getAll(
    filter: any,
    page: number = 0,
    size: number = 10
  ): Observable<Page<SupplierModel>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = params.append('search', filter.query ? filter.query : '');

    const url = `${this.url}/page`;
    const options = {
      params,
    };
    return this.http.get<Page<SupplierModel>>(url, options);
  }

  public addSupplier(supplier: SupplierModel): Observable<SupplierModel> {
    const url = `${this.url}/add`;
    return this.http.post<SupplierModel>(url, supplier);
  }

  public getAllList(): Observable<any> {
    const url = `${this.url}/List`;
    return this.http.get<any>(url);
  }
  public updateSupplier(supplier: SupplierModel): Observable<SupplierModel> {
    return this.http.put<SupplierModel>(this.url, supplier);
  }

  public deleteSupplier(supplierId: number) {
    const url = `${this.url}/${supplierId}`;
    return this.http.delete<void>(url);
  }

  public getSupplierById(supplierId: number): Observable<SupplierModel> {
    const url = `${this.url}/${supplierId}`;
    return this.http.get<SupplierModel>(url);
  }
  /*public changeSupplierStatus(supplier: SupplierModel): Observable<any> {
    const url = `${this.url}/`;
    return this.http.patch<void>(url, null);
  }*/
}
