import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ADMIN_CONFIG} from '../../../../environments/environment';
import {ProductLicensesModel} from './product-licenses.model';
import {Observable} from 'rxjs';
import {ProductLicensesAllModel} from './product-licenses-all.model';
import { QueryParamsModel } from '../../_base/crud';
import { Page } from '../../_base/crud/models/page';

@Injectable()
export class ProductLicensesService {
    url: string;
    urlOrder: string;

    constructor(protected http: HttpClient) {
        this.url = `${ADMIN_CONFIG.apiEndpoint}product`,
        this.urlOrder = `${ADMIN_CONFIG.apiEndpoint}order`;
    }

    public getByProductId(id: number): Observable<ProductLicensesModel[]> {
        const url = `${this.url}/${id}/licenses`;
        return this.http.get<ProductLicensesModel[]>(url);
    }

    public getLicensesStatusByProductId(id: number , queryParams: any): Observable<ProductLicensesAllModel[]> {
        const url = `${this.urlOrder}/product/${id}/licensesStatus`;
        let params = new HttpParams()
          .append(
          'page', queryParams.page.toString())
          .append(
            'size',
            queryParams.size ? queryParams.size.toString() : '50'
          );
        params = queryParams.status
          ? params.append('ordered', queryParams.status)
          : params;
        params = queryParams.reserved
          ? params.append('paid', queryParams.reserved)
          : params;
        params = queryParams.seen
          ? params.append('seen', queryParams.seen)
          : params;
        params = queryParams.query
          ? params.append('clientMail', queryParams.query)
          : params;
        params = queryParams.startDate
          ? params.append('dateMin', queryParams.startDate)
          : params;
        params = queryParams.endDate
          ? params.append('dateMax', queryParams.endDate)
          : params;
        const options = { params };
        return this.http.get<ProductLicensesAllModel[]>(url , options);
    }

    public importLicenses(
        id: number,
        file: File
    ): Observable<ProductLicensesModel[]> {
        const url = `${this.url}/${id}/licenses/import`;
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<ProductLicensesModel[]>(url, formData);
    }

    public exportLicenses(id: number): Observable<Blob> {
        const url = `${this.url}/${id}/licenses/export`;
        return this.http.get(url, {responseType: 'blob'});
    }
}
