import { Injectable } from '@angular/core';
import { AbstractService } from '../../../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

import { ClaimReasonModel } from './claim-reason.model';

@Injectable()
export class ClaimReasonService extends AbstractService<ClaimReasonModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}complaint/reason`;
  }

  public getClaimReasons(search: string): Observable<ClaimReasonModel[]> {
    let params = new HttpParams();
    params = params.append('keyword', search);

    const url = `${this.url}`;
    return this.http.get<ClaimReasonModel[]>(url, { params });
  }

  public addClaimReason(
    reason: ClaimReasonModel
  ): Observable<ClaimReasonModel> {
    const url = `${this.url}`;
    return this.http.post<ClaimReasonModel>(url, reason);
  }

  public updateClaimReason(
    reason: ClaimReasonModel
  ): Observable<ClaimReasonModel> {
    const url = `${this.url}`;
    return this.http.put<ClaimReasonModel>(url, reason);
  }

  public updateClaimReasonsStatus(
    reasons: ClaimReasonModel[]
  ): Observable<ClaimReasonModel[]> {
    const url = `${this.url}/status`;
    return this.http.patch<ClaimReasonModel[]>(url, reasons);
  }
}
