import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
// actions
import * as CategoryActions from './category.action';
import { Response } from '../../_base/crud/models/response';
import {CategoryOptModel} from './categoryOpt.model';

export interface CategoryOptState extends EntityState<CategoryOptModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedCategoryId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    errorMessage: string;
    lastAction: string;
    progress: number;
}

export const adapter: EntityAdapter<CategoryOptModel> =
    createEntityAdapter<CategoryOptModel>();

export const initialCategoryState: CategoryOptState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedCategoryId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    errorMessage: '',
    lastAction: '',
    progress: 0,
});

// @ts-ignore
// @ts-ignore
const categoryOptReducer = createReducer(
    initialCategoryState,
    // get opt list categories
    on(CategoryActions.CategoriesOptPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        progress: 0,
        lastQuery: action.page,
    })),

    on(CategoryActions.CategoriesOptPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.categories, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(CategoryActions.CategoriesOptPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
);

export function categoriesOptReducer(
    state: CategoryOptState | undefined,
    action: Action
) {
    return categoryOptReducer(state, action);
}
