// RxJS
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import {
  selectProductsInitWaitingMessage,
  selectProductsInStore,
  selectProductsPageLoading,
} from './product.selectors';

// Selectors

export class ProductsDataSource extends BaseDataSource {
  constructor(private store: Store<AppState>) {
    super();
    this.loading$ = this.store.pipe(select(selectProductsPageLoading));

    this.isPreloadTextViewed$ = this.store.pipe(
      select(selectProductsInitWaitingMessage)
    );

    this.store
      .pipe(select(selectProductsInStore))
      .subscribe((response: QueryResultsModel) => {
        this.paginatorTotalSubject.next(response.totalCount);
        this.entitySubject.next(response.items);
      });
  }
}
