import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';

import { PromotionModel } from './promotion.model';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService extends AbstractService<PromotionModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}promotion`;
  }

  public getAllPaged(
    page: number = 0,
    size: number = 100
  ): Observable<Page<PromotionModel>> {
    return super.getAllPaged(page, size);
  }

  public getAllPromotions(
    page: number = 0,
    size: number = 100
  ): Observable<Page<PromotionModel>> {
    const options = {
      params: new HttpParams()
        .append('page', page.toString())
        .append('size', size.toString()),
    };
    const url = `${this.url}`;
    return this.http.get<Page<PromotionModel>>(url, options);
  }

  public updatePromotion(entity: any): Observable<any> {
    return this.http.put<any>(this.url, entity);
  }

  public linkProductToPromotion(entity: any): Observable<any> {
    const url = this.url + '/product_promotion';
    return this.http.patch<any>(url, entity);
  }

  public unlinkProduct(id: number, productId: number): Observable<any> {
    //TODO
    const url = this.url + `/product_promotion/${id}?productId=${productId}`;
    return this.http.delete<any>(url);
  }
  public getPromotionById(id: number): Observable<PromotionModel> {
    const url = this.url + `/${id}`;
    return this.http.get<PromotionModel>(url);
  }
}
