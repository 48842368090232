import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { debounceTime, distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { SubheaderService } from '../../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../core/_base/crud';
import { CompaniesService } from '../../../../../../core/e-commerce/companies/companies.service';
import { ClientService } from '../../../../../../core/e-commerce/clients/client.service';
import { MainClientService } from '../../../../../../core/e-commerce/mainclient/main-client.service';
import { MainClientDatasource } from '../../../../../../core/e-commerce/mainclient/mainclient.datasource';
import { ClientModel } from '../../../../../../core/e-commerce/clients/client.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kt-main-client-list',
  templateUrl: './main-client-list.component.html',
  styles: [
    `
      mat-row {
        cursor: pointer;
      }

      mat-row:hover {
        background-color: #f8f7f7;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainClientListComponent
  implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private mainClientService: MainClientService,
    private store: Store<AppState>,
    private companyService: CompaniesService,
    private clientService: ClientService
  ) {}

  // Table fields
  dataSource: MainClientDatasource;
  displayedColumns = ['id', 'mail', 'name', 'lastname', 'status', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  private subscriptions: Subscription[] = [];

  // show a notification
  filterStatus = new FormControl('');

  ListIds: string[] = [];

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadClientsPage();
        })
      )
      .subscribe();
    this.dataSource = new MainClientDatasource(
      this.mainClientService,
      this.companyService
    );
    const errorSub = this.dataSource.error$.pipe(skip(1)).subscribe((res) => {
      this.showNotification(
        'Error : ' + res['error']['error'],
        MessageType.Read
      );
    });

    this.mainClientService.findClientCommands().subscribe((e) => {
      if (e !== null) {
        e.forEach((j) => {
          this.ListIds = this.ListIds.concat(j.id.toString());
        });
      }
    });

    this.mainClientService.findClientDevis().subscribe((j) => {
      if (j !== null) {
        j.forEach((e) => {
          this.ListIds = this.ListIds.concat(e.id.toString());
        });
      }
    });
  }

  // we use ngAfterViewInit to wait for the view to complete so we can use paginator.page
  ngAfterViewInit() {
    // server side search
    const searchInputSub = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadClientsPage();
        })
      )
      .subscribe();
    this.subscriptions.push(searchInputSub);

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const paginatorSub = this.paginator.page
      .pipe(tap((res) => this.loadClientsPage()))
      .subscribe();
    this.subscriptions.push(paginatorSub);
    this.loadClientsPage();
  }

  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }

    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }

  loadClientsPage() {
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.dataSource.getAllClients(queryParams);
  }

  showNotification(messageText: string, messageType: MessageType) {
    this.layoutUtilsService.showActionNotification(messageText, messageType);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'ACTIVATED':
        return 'success';
      case 'BLOCKED':
        return 'danger';
      case 'DEACTIVATED':
      case 'PENDING':
        return 'metal';
    }
    return '';
  }

  unblockClient(client: ClientModel) {
    const _TITLE = `Unblock client ${client.lastname} ${client.name}`;
    const _DESCRIPTION = `Are you sure to unblock client ${client.lastname} ${client.name}?`;
    const _WAIT_DESCRIPTION = `Client ${client.lastname} ${client.name} is unblocking...`;
    const _UNBLOCK_MESSAGE = `Client ${client.lastname} ${client.name} has been unblocked`;

    const dialogRef = this.layoutUtilsService.unblockEntity(
      _TITLE,
      _DESCRIPTION,
      _WAIT_DESCRIPTION
    );
    dialogRef.afterClosed().subscribe((response) => {
      if (!response) {
        return;
      }
      this.clientService.unblockClient(client).subscribe(() => {
        this.loadClientsPage();
        this.layoutUtilsService.showActionNotification(
          _UNBLOCK_MESSAGE,
          MessageType.Unblock
        );
      });
    });
  }

  deleteClient(id: number) {
    const _title = 'Supprimer le client';
    const _description = 'Voulez-vous vraiment supprimer ce client ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.dataSource.deleteClient(id);
      this.showNotification(
        'Client est supprimé avec succès !',
        MessageType.Delete
      );
    });
  }

  archiveClient(id: number, name: string, mail: string) {
    const _title = 'Archivage de Contact ==> ' + name;
    const _description = 'Voulez-vous vraiment archiver ce contact ?';
    const _waitDesciption = 'Archivage en cours...';
    const btnTitle = 'Archiver';

    const dialogRef = this.layoutUtilsService.archiveElement(
      _title,
      _description,
      _waitDesciption,
      btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.dataSource.archiveClient(id, mail);
      this.showNotification(
        'Contact est archivé avec succès !',
        MessageType.Update
      );
    });
  }

  resendConfirmationMail(mail: string) {
    this.clientService.resendConfirmationMail(mail).subscribe(() => {
      this.layoutUtilsService.showActionNotification(
        'Le mail de confirmation est envoyé avec succés',
        MessageType.Create
      );
    });
  }

  editClient(id) {
    this.router.navigate(['/ecommerce/mainclient/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }
}
