import { ClientfunctionModel } from '../clientfunctions/clientfunction.model';
import { AddressModel } from '../companies/address.model';

export class ClientModel {
  id: number;
  mail: string;
  password: string;
  name: string;
  lastname: string;
  phone: string;
  status: string;
  role: string;
  title: string;
  companyId: number;
  isMain: boolean;
  function: ClientfunctionModel;
  conditionsValidated: boolean;

  companyName?: string;
  accountNumber?: string;
  functionId?: number;
  addresses?: AddressModel[];

  constructor() {
    this.function = new ClientfunctionModel();
  }
}
