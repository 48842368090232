import { createFeatureSelector, createSelector } from '@ngrx/store';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { ContactState } from './contact.reducers';
import { ContactModel } from './contact.model';

export const selectContactsState =
  createFeatureSelector<ContactState>('contacts');

export const selectContactById = (ContactId: number) =>
  createSelector(
    selectContactsState,
    (ContactsState) => ContactsState.entities[ContactId]
  );

export const selectContactsPageLoading = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.listLoading
);

export const selectTotalContactsLoading = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.totalCount
);

export const selectError = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.error
);

export const selectErrorMessage = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.errorMessage
);

export const selectlastAction = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.lastAction
);

export const selectContactsActionLoading = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.actionsLoading
);

export const selectContactsShowInitWaitingMessage = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.showInitWaitingMessage
);

export const selectContactProgress = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.progress
);

export const selectContactsPageLastQuery = createSelector(
  selectContactsState,
  (ContactsState) => ContactsState.lastQuery
);

export const selectContactsInStore = createSelector(
  selectContactsState,
  (ContactsState) => {
    const items: ContactModel[] = [];
    each(ContactsState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: ContactModel[] = httpExtension.sortArray(
      items,
      ContactsState.lastQuery.sortField,
      ContactsState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, ContactsState.totalCount, '');
  }
);
