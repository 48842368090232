import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { IDiscountCompanyModel } from "./company-discount.model";
import { DiscountCompanyApiActions, DiscountCompanyPageActions } from './actions';


export interface DiscountCompanyState extends EntityState<IDiscountCompanyModel> {
    currentCompanyId: number | null;
    error: Response | null;
    errorMessage: string | null;
}

export const adapter: EntityAdapter<IDiscountCompanyModel> =
  createEntityAdapter<IDiscountCompanyModel>();

export const initialDiscountCompaniesState: DiscountCompanyState = adapter.getInitialState({
    currentCompanyId:null,
    error: null,
    errorMessage: null
});

export const discountCompaniesReducer = createReducer(
    initialDiscountCompaniesState,
    
    on(DiscountCompanyApiActions.loadDiscountsCopmaniesSuccess, (state, action) => 
        adapter.addAll(
            action.discountCompanies, {
              ...state,
               errorMessage:'',
               error:null
              }
        )),
  
    on(DiscountCompanyApiActions.loadDiscountsCopmaniesFailure, (state, action) =>{
      let errorMessage =  displayErrorMessage(action.error.status);
        return {
        ...state,
        error: action.error,
        errorMessage: errorMessage
      }
    }),
    on(DiscountCompanyApiActions.createDiscountCompanySuccess, (state, action) => 
        adapter.addOne(action.discountCompany, {
          ...state,
           errorMessage:'',
           error:null
          })
    ),
    
    on(DiscountCompanyApiActions.createDiscountCompanyFailure, (state, action) => {
      let errorMessage =  displayErrorMessage(action.error.status);
      return {
        ... state,
        error: action.error,
        errorMessage: errorMessage
      }
    }),
    on(DiscountCompanyApiActions.updateDiscountCompanySuccess, (state, action) =>
      adapter.updateOne(action.discountCompany, {
        ...state,
         errorMessage:'',
         error:null
        })
    ),
    on(DiscountCompanyApiActions.updateDiscountCompanyFailure, (state, action) => {
      let errorMessage =  displayErrorMessage(action.error.status);
      return {
        ...state,
        errorMessage: errorMessage,
        error: action.error,
      };
    }),
    on(DiscountCompanyApiActions.deleteDiscountCompanySuccess, (state, action) => 
        adapter.removeOne(action.discountCompanyId, {
          ...state,
           errorMessage:'',
           error:null
          })
    ),
    on(DiscountCompanyApiActions.deleteDiscountCompanyFailure, (state, action) =>{
        let errorMessage =  displayErrorMessage(action.error.status);
        return {
          ...state,
          errorMessage: errorMessage,
          error: action.error,
        };
      }
    ),
    on(DiscountCompanyPageActions.getDiscountsForSingleCompany, (state, action) => {
        return {
            ... state,
            currentCompanyId: action.companyId
        }
    }
    ),
  );

  // This function is used to generate the error message
  function displayErrorMessage(errorStatus) {
    switch (errorStatus) {
      case 500:
        return 'Erreur Interne Du Serveur';
        break;
      case 403:
        return 'Authentification Non Autorisée';
        break;
      case 404:
        return 'Requête Demandée Est Introuvable';
        break;
      case 406:
        return 'Un Code Promo Est Activé déja';
        break;
      case 409:
        return 'Vous Pouvez Avoir Un Seule Remise Entreprise Pour Le Moment';
        break;
      default:
        return 'Erreur Non Reconnue';
        break;
    }
  }
  

  const {
    selectIds,
    selectAll,
    selectEntities,
    selectTotal
} = adapter.getSelectors();

// select the array of Companies Discounst Ids
export const selectCompanniesDiscounstIds = selectIds;

// select the dictionnary of Companies Discounts Entities
export const selectCompaniesDiscountsEntities = selectEntities;

// select the array of Companies discounts
export const selectAllCompaniesDiscounts  = selectAll;

// select the total of Companies Discounts
export const selectCompaniesDiscountsTotal = selectTotal;
