import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductStocksState } from './stock.reducers';
import { StockModel } from './stock.model';

export const getProductStocksState =
  createFeatureSelector<ProductStocksState>('productStocks');

export const selectProductStockInStore = createSelector(
  getProductStocksState,
  (productStocksState: ProductStocksState) => {
    const productStocks: StockModel[] = [];
    Object.entries(productStocksState.entities).forEach(([k, v]) =>
      productStocks.push(v)
    );
    return productStocks;
  }
);
