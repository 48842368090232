import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientFunctionState } from './clientfunction.reducers';
import { ClientfunctionModel } from './clientfunction.model';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
// Lodash
import { each } from 'lodash';

export const selectClientFunctionsState =
  createFeatureSelector<ClientFunctionState>('clientfunctions');

export const selectClientFunctionById = (clientfunctionId: number) =>
  createSelector(
    selectClientFunctionsState,
    (clientfunctionsState) => clientfunctionsState.entities[clientfunctionId]
  );

export const selectClientFunctionsPageLoading = createSelector(
  selectClientFunctionsState,
  (clientfunctionsState) => clientfunctionsState.listLoading
);

export const selectClientFunctionsActionLoading = createSelector(
  selectClientFunctionsState,
  (customersState) => customersState.actionsLoading
);

export const selectClientFunctionsPageLastQuery = createSelector(
  selectClientFunctionsState,
  (clientfunctionsState) => clientfunctionsState.lastQuery
);

export const selectLastCreatedClientFunctionId = createSelector(
  selectClientFunctionsState,
  (clientfunctionsState) => clientfunctionsState.lastCreatedClientFunctionId
);

export const selectClientFunctionsInitWaitingMessage = createSelector(
  selectClientFunctionsState,
  (clientfunctionsState) => clientfunctionsState.showInitWaitingMessage
);

export const selectClientFunctionsInStore = createSelector(
  selectClientFunctionsState,
  (clientfunctionsState) => {
    const items: ClientfunctionModel[] = [];
    each(clientfunctionsState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: ClientfunctionModel[] = httpExtension.sortArray(
      items,
      clientfunctionsState.lastQuery.sortField,
      clientfunctionsState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, clientfunctionsState.totalCount, '');
  }
);

export const selectHasClientFunctionsInStore = createSelector(
  selectClientFunctionsInStore,
  (queryResult) => {
    if (!queryResult.totalCount) {
      return false;
    }

    return true;
  }
);
