import { createFeatureSelector, createSelector, on } from '@ngrx/store';
import { CategoryState } from './category.reducers';
import { each } from 'lodash';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { CategoryModel } from './category.model';


export const selectCategoriesState =
  createFeatureSelector<CategoryState>('categories');

export const selectCategoryById = (categoryId: number) =>
  createSelector(
    selectCategoriesState,
    (categoriesState) => categoriesState.entities[categoryId]
  );

export const selectCategoriesPageLoading = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.listLoading
);

export const selectError = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.error
);

export const selectErrorMessage = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.errorMessage
);

export const selectlastAction = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.lastAction
);

export const selectCategoriesActionLoading = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.actionsLoading
);

export const selectLastCreatedCategoryId = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.lastCreatedCategoryId
);

export const selectCategoriesShowInitWaitingMessage = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.showInitWaitingMessage
);

export const selectCategoryProgress = createSelector(
  selectCategoriesState,
  (categoriesState) => categoriesState.progress
);

export const selectCategoriesInStore = createSelector(
  selectCategoriesState,
  (categoriesState) => {
    const items: CategoryModel[] = [];
    each(categoriesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: CategoryModel[] = httpExtension.sortArray(
      items,
      categoriesState.lastQuery.sortField,
      categoriesState.lastQuery.sortOrder
    );
    return new QueryResultsModel(result, categoriesState.totalCount, '');
  }
);

export const selectCategoriesListInStore = createSelector(
  selectCategoriesState,
  (categoriesState) => {
    const items: CategoryModel[] = [];
    each(categoriesState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: CategoryModel[] = httpExtension.sortArray(items);
    return result;
  }
);
