import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractEntity } from '../_base/crud/models/abstract-entity';
import { Page } from '../_base/crud/models/page';
import { Response } from '../_base/crud/models/response';
import { map, tap } from 'rxjs/operators';

export abstract class AbstractService<E extends AbstractEntity> {
  protected abstract url: string;

  constructor(protected http: HttpClient) {}

  public getById(id: number): Observable<E> {
    const url = `${this.url}/${id}`;
    return this.http.get<E>(url);
  }

  public getAll(): Observable<E[]> {
    return this.http.get<E[]>(this.url);
  }

  public getAllPaged(page: number = 0, size: number = 10): Observable<Page<E>> {
    const options = {
      params: new HttpParams()
        .append('page', page.toString())
        .append('size', size.toString()),
    };
    const url = `${this.url}/page`;
    return this.http.get<Page<E>>(url, options);
  }

  public save(entity: E): Observable<E> {
    const res = this.http.post<E>(this.url, entity);
    res.pipe(tap((val) => console.log(JSON.stringify(val))));
    return res;
  }
  
  public saveAll(entities: E[]): Observable<E[]> {
    const url = `${this.url}/all`;
    return this.http
      .post<Response<E[]>>(url, entities)
      .pipe(map(({ body }) => body));
  }

  public update(entity: E): Observable<E> {
    return this.http.put<E>(this.url, entity);
  }

  public delete(id: number): Observable<any> {
    const url = `${this.url}/${id}`;
    const res = this.http.delete<void>(url);
    return res;
  }

  public deleteCollaboratorAdmin(id: number): Observable<any> {
    const url = `${this.url}/collaborator/${id}`;
    const res = this.http.delete<void>(url);
    return res;
  }
}
