import { ClaimPriority } from '../claim.model';

export class ClaimReasonModel {
  id: number;
  title: string;
  status: ClaimReasonStatus;
  type: string;
  priority: ClaimPriority;
}

export enum ClaimReasonStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
}
