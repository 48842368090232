import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ManufacturerModel } from './manufacturer.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
import * as ManufacturersActions from './manufacturer.action';
import { Response } from '../../_base/crud/models/response';

export interface ManufacturerState extends EntityState<ManufacturerModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<ManufacturerModel> =
  createEntityAdapter<ManufacturerModel>();

export const initialManufacturerState: ManufacturerState =
  adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    errorMessage: '',
    lastAction: '',
    progress: 0,
  });

const manufacturerReducer = createReducer(
  initialManufacturerState,
  on(ManufacturersActions.ManufacturersListRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(
    ManufacturersActions.ManufacturersListRequestedSuccessfully,
    (state, action) =>
      adapter.addAll(action.manufacturers, {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
      })
  ),
  on(ManufacturersActions.ManufacturersListRequestFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // Update
  on(ManufacturersActions.UpdateManufacturer, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ManufacturersActions.ManufacturerUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(
      { id: action.manufacturer.id, changes: action.manufacturer },
      {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
      }
    )
  ),
  on(ManufacturersActions.ManufacturerUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // Deletion
  on(ManufacturersActions.DeleteManufacturer, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ManufacturersActions.ManufacturerDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.manufacturerId, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(ManufacturersActions.ManufacturerDeletionFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  // Creation
  on(ManufacturersActions.CreateManufacturer, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),
  on(ManufacturersActions.ManufacturerCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.manufacturer, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(ManufacturersActions.ManufacturerCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  on(ManufacturersActions.ManufacturersPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),
  on(
    ManufacturersActions.ManufacturersPageLoadedSuccessfully,
    (state, action) =>
      adapter.addAll(action.manufacturer, {
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        totalCount: action.totalCount,
      })
  ),
  on(ManufacturersActions.ManufacturersPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  }))
);

export function manufacturersReducer(
  state: ManufacturerState | undefined,
  action: Action
) {
  return manufacturerReducer(state, action);
}
