import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {Gallery} from '@ngx-gallery/core';
import {select, Store} from '@ngrx/store';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Subscription} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {ProductModel} from '../../../../../../../../core/e-commerce/products/product.model';
import {ProductResource} from '../../../../../../../../core/e-commerce/products/product-resources/product-resource';
import {AppState} from '../../../../../../../../core/reducers';
import {ResourcesService} from '../../../../../../../../core/services/resources.service';
import {LayoutUtilsService, MessageType} from '../../../../../../../../core/_base/crud';
import {selectProductById} from '../../../../../../../../core/e-commerce/products/product.selectors';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ProductsService} from '../../../../../../../../core/e-commerce/products/products.service';

@Component({
    selector: 'kt-resources-edit',
    templateUrl: './resources-edit.component.html',
    styleUrls: ['./resources-edit.component.scss'],
})
export class ResourcesEditComponent implements OnInit, OnDestroy {
    @Input() product: ProductModel;

    productId: number;
    loading = false;
    resources = new BehaviorSubject<ProductResource[]>([]);
    // imagesUrl : ImageItem[]= [];
    galleryId: 'myLightbox';
    resourceToAdd: ProductResource;
    componentSubscriptions$: Subscription[] = [];
    hasErrors = new BehaviorSubject(false);

    progress = new BehaviorSubject<number>(0);
    uploading = false;

    constructor(
        public gallery: Gallery,
        private translate: TranslateService,
        private store: Store<AppState>,
        private resourcesService: ResourcesService,
        private productService: ProductsService,
        private activatedRoute: ActivatedRoute,
        private layoutUtilsService: LayoutUtilsService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        const routerSub = this.activatedRoute.params.subscribe((params) => {
            this.productId = params.id;
            const confSub$ = this.store
                .pipe(select(selectProductById(this.productId)))
                .subscribe(
                    (res) => {
                        setTimeout(() => {
                            this.resourcesService
                                .getResources(this.productId)
                                .subscribe((resources: ProductResource[]) => {
                                    this.loadGallery(resources);
                                });
                        }, 100);
                        this.loading = true;
                    },
                    (error) => console.log(JSON.stringify(error))
                );
            this.prepareImageToAdd();
            this.componentSubscriptions$.push(confSub$);
        });
        this.componentSubscriptions$.push(routerSub);
    }

    ngOnDestroy(): void {
        this.componentSubscriptions$.forEach((sub) => sub.unsubscribe());
    }

    loadGallery(resources: ProductResource[]) {
        // retrieve defautl image
        const defaultImage = resources.find((r) => r.isDefault);
        if (defaultImage) {
            resources.splice(resources.indexOf(defaultImage), 1);
            resources = [defaultImage].concat(resources);
        }
        this.resources.next(resources);
        const galleryRef = this.gallery.ref(this.galleryId);
    }

    prepareImageToAdd() {
        this.resourceToAdd = new ProductResource();
    }

    onSelectedFile(files: FileList) {
        this.resourceToAdd.file = files.item(0);
        this.resourcesService
            .addImage(this.resourceToAdd.file, this.productId)
            .subscribe(
                (res) => {
                    this.uploading = true;
                    // the upload finished
                    if (res instanceof HttpResponse) {
                        const body = JSON.parse(res.body);
                        this.resources.next([...this.resources.value, body]);
                        this.uploading = false;
                        const message = `Image ajoutée avec succès`;
                        this.layoutUtilsService.showActionNotification(
                            message,
                            MessageType.Update,
                            3000,
                            true,
                            false
                        );
                        this.progress.next(0);
                    } else if (res.type === HttpEventType.UploadProgress) {
                        this.progress.next(Math.round((100 * res.loaded) / res.total));
                    }
                },
                (error) => {
                    this.uploading = false;
                    this.hasErrors.next(true);
                }
            );
    }

    onDelete(resourceId: number) {
        const title = 'Suppression d\'une image';
        const description = 'Voulez vous supprimer cette image';
        const waitDesciption = 'L\'image est en cours de supprission';

        const dialogRef = this.layoutUtilsService.deleteElement(
            title,
            description,
            waitDesciption
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
            this.resourcesService.deleteResource(resourceId).subscribe((__) => {
                const resources = this.resources.value.filter(
                    (img) => img.id !== resourceId
                );
                this.resources.next(resources);
                const message = `Image has been deleted Successfully.`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    false
                );
            });
        });
    }

    onAlertClose($event) {
        this.hasErrors.next(false);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.resources.value,
            event.previousIndex,
            event.currentIndex
        );
        this.productService
            .setResourceAsDefault(this.productId, this.resources.value[0].id)
            .subscribe();
    }

    search() {
        window.open('https://www.google.com/search?tbm=isch&q=' + this.product.manufacturerReference, '_blank');
    }
}
