import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AuthToken } from '../_models/AuthToken.model';
import { UserModel } from '../../_models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}admin`;
  }

  public login(mail: string, password: string): Observable<AuthToken> {
    const url = `${this.url}/login`;
    return this.http.put<AuthToken>(url, { mail, password });
  }

  getLoggedinUser(): Observable<UserModel> {
    return this.http.get<UserModel>(this.url + '/me');
  }
}
