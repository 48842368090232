import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimState } from './claim.reducer';
import { ClaimModel } from './claim.model';
import { HttpExtenstionsModel, QueryResultsModel } from '../../_base/crud';
import { each } from 'lodash';

export const selectClaimsState = createFeatureSelector<ClaimState>('claims');

export const selectClaimById = (claimId: number) =>
  createSelector(
    selectClaimsState,
    (claimsState) => claimsState.entities[claimId]
  );

export const selectClaimPageLoading = createSelector(
  selectClaimsState,
  (claimsState) => claimsState.listLoading
);

export const selectClaimsInitWaitingMessage = createSelector(
  selectClaimsState,
  (claimsState) => claimsState.showInitWaitingMessage
);

export const selectClaimsInStore = createSelector(
  selectClaimsState,
  (claimsState) => {
    const items: ClaimModel[] = [];
    each(claimsState.entities, (element) => {
      items.push(element);
    });
    const httpExtension = new HttpExtenstionsModel();
    const result: ClaimModel[] = httpExtension.sortArray(
      items,
      claimsState.lastQuery?.sortField,
      claimsState.lastQuery?.sortOrder
    );
    return new QueryResultsModel(result, claimsState.totalCount, '');
  }
);

export const selectHasClaimsInStore = createSelector(
  selectClaimsInStore,
  (queryResult) => {
    return queryResult.totalCount;
  }
);

export const selectClaimPageLastQuery = createSelector(
  selectClaimsState,
  (claimsState) => claimsState.lastQuery
);
