import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrdersService } from './orders.service';
import { AppState } from '../../reducers';
import { Store } from '@ngrx/store';
//Actions
import * as OrderActions from './orders.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ClientService } from '../clients/client.service';
import { CompaniesService } from '../companies/companies.service';
import { OrderModel } from './order.model';
import { selectOrderById } from './orders.selectors';

@Injectable()
export class OrdersEffects {
  constructor(
    private actions$: Actions,
    private orderService: OrdersService,
    private clientService: ClientService,
    private companyService: CompaniesService,
    private store: Store<AppState>
  ) {}

  OrderPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.OrdersPageRequested),
      switchMap((action) =>
        this.orderService.getAllOrderByFilter(action.page).pipe(
          map((orderPage) => {
            // TODO retrieve company and client from backend
            orderPage.content.forEach((o) =>
              this.clientService.getById(o.clientId).subscribe((c) => {
                o.clientName =
                  c.name.toLowerCase() + ' ' + c.lastname.toLowerCase();
                this.companyService
                  .getById(c.companyId)
                  .subscribe((company) => {
                    o.companyName = company.name;
                    o.clientNumber = company.accountNumber;
                  });
              })
            );

            return OrderActions.OrdersPageLoadedSuccessfully({
              orders: orderPage.content,
              totalCount: orderPage.totalElements,
            });
          }),
          catchError((error) =>
            of(OrderActions.OrdersPageLoadFailed({ error }))
          )
        )
      )
    )
  );

  OrderCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.OrderCreated),
      switchMap((action) =>
        this.orderService.save(action.order).pipe(
          map((data) => OrderActions.OrderCreatedSuccessfully({ order: data })),
          catchError((error) => of(OrderActions.OrderCreationFailed({ error })))
        )
      )
    )
  );

  OrderUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.OrderUpdated),
      switchMap((action) =>
        this.orderService.update(action.order).pipe(
          map((order) =>
            OrderActions.OrderUpdatedSuccessfully({
              order,
              partialOrder: action.partialOrder,
            })
          ),
          catchError((error) => of(OrderActions.OrderUpdateFailed({ error })))
        )
      )
    )
  );

  OrderDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderActions.OrderDeleted),
      switchMap((action) =>
        this.orderService.delete(action.orderId).pipe(
          map(() =>
            OrderActions.OrderDeletedSuccessfully({ orderId: action.orderId })
          ),
          catchError((error) => of(OrderActions.OrderDeleteFailed({ error })))
        )
      )
    )
  );

  // OrderItemUpdated$ = createEffect(() =>
  //     this.actions$.pipe(
  //         ofType(OrderActions.OrderItemUpdated),
  //         switchMap((action) =>
  //             this.orderService.updateOrderItem(action.orderItem)
  //                 .pipe(
  //                     map(orderItem => {
  //                         let order: OrderModel = new OrderModel();
  //                         this.store.select(selectOrderById(orderItem.orderId)).subscribe( o => {
  //                             order = o;
  //                             console.log(o)
  //                             order.items.map(item => {
  //                                 if (item.id = orderItem.id) {
  //                                     return orderItem
  //                                 } else return item
  //                             });
  //                         });
  //                         return OrderActions.OrderItemUpdatedSuccessfully({order})
  //                     }),
  //                     catchError(error => of(OrderActions.OrderItemUpdateFailed({error})))
  //                 ))));
}
