import { createAction, props } from '@ngrx/store';
import { LogsModel } from './logs.model';
import { QueryParamsModel } from '../../_base/crud';


export const LogsPageRequested = createAction(
  '[Logs list Page] Logs Requested ',
  props<{ page: QueryParamsModel }>()
);
export const LogsPageLoadedSuccessfully = createAction(
  '[Logs API] Logs Page Loaded Successfully',
  props<{
    logs: LogsModel[];
    totalCount: number;
    page: number;
  }>()
);
export const LogsPageLoadFailed = createAction(
  '[Logs API] Logs Page Request Failed ',
  props<{ error: any }>()
);
