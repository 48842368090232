import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { delay, skip, startWith } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../core/_base/layout';
// CRUD
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
  TypesUtilsService,
} from '../../../../../core/_base/crud';
import {
  selectLastCreatedProductId,
  selectProductById,
} from '../../../../../core/e-commerce/products/product.selectors';
import {
  CategoryActions,
  ProductActions,
  ManufacturerActions,
} from '../../../../../core/e-commerce';
import * as QuestionActions from '../../../../../core/service-client/client-question/client-question.actions';
import {
  selectCategoriesActionLoading,
  selectCategoriesInStore,
  selectCategoryById,
  selectErrorMessage,
  selectLastCreatedCategoryId,
} from '../../../../../core/e-commerce/categories/category.selector';
import { ProductsService } from '../../../../../core/e-commerce/products/products.service';
import { ManufacturerModel } from '../../../../../core/e-commerce/manufacturers/manufacturer.model';
import { selectManufacturersInStore } from '../../../../../core/e-commerce/manufacturers/manufacturer.selector';
import { ClientQuestionModel } from '../../../../../core/service-client/client-question/client-question.model';
import {
  selectCompaniesActionLoading,
  selectCompanyById,
} from '../../../../../core/e-commerce/companies/company.selectors';
import { CompanyModel } from '../../../../../core/e-commerce/companies/company.model';
import {
  selectQuestionById,
  selectQuestionsActionLoading,
  selectQuestionsInStore,
} from '../../../../../core/service-client/client-question/client-question.selectors';
import { ClientQuestionService } from '../../../../../core/service-client/client-question/client-question.service';
import { CategoryModel } from '../../../../../core/e-commerce/categories/category.model';

@Component({
  selector: 'kt-client-question-edit',
  templateUrl: './client-question-edit.component.html',
  styleUrls: ['./client-question-edit.component.scss'],
})
export class ClientQuestionEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // Public properties
  question: ClientQuestionModel;
  questionId$: Observable<number>;
  oldQuestion: ClientQuestionModel;
  question$: Observable<ClientQuestionModel>;
  addNewPorduct: any;
  selectedTab = 0;
  loadingSubject = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean>;
  questionForm: FormGroup;
  hasFormErrors = false;
  errorMessage: Observable<string>;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  private subscriptions: Subscription[] = [];
  parents: ClientQuestionModel[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private clientQuestionService: ClientQuestionService,
    private layoutConfigService: LayoutConfigService
  ) {}

  ngOnInit() {
    this.loading$ = this.store.select(selectQuestionsActionLoading);

    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      if (id && id > 0) {
        this.store.pipe(select(selectQuestionById(id))).subscribe((result) => {
          if (!result) {
            this.loadQuestionFromService(id);
            return;
          }

          this.loadQuestion(result);
        });
      } else {
        const newQuestion = new ClientQuestionModel();
        this.loadQuestion(newQuestion);
      }
    });
    this.getActiveTab();

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById('kt_header'));
      this.headerMargin = parseInt(style.height, 0);
    };

    this.errorMessage = this.store.select(selectErrorMessage);
    const errorSub = this.errorMessage.pipe(skip(1)).subscribe((res) => {
      const message = `ERROR :` + res;
      this.layoutUtilsService.showActionNotification(
        message,
        MessageType.Create,
        10000,
        true,
        false
      );
    });
    this.componentSubscriptions.push(errorSub);
  }
  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.componentSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  private loadParents() {
    this.store.dispatch(
      QuestionActions.QuestionsPageRequested({
        page: new QueryParamsModel('', 'asc', 'id', 0, 200),
      })
    );
    const questions$ = this.store
      .select(selectQuestionsInStore)
      .subscribe((results) => {
        this.parents = results.items;
      });
  }

  loadQuestion(_question, fromService: boolean = false) {
    if (!_question) {
      this.goBack('');
    }
    this.question = _question;
    this.questionId$ = of(_question.id);
    this.oldQuestion = Object.assign({}, _question);
    this.initQuestion();
    if (fromService) {
      this.cdr.detectChanges();
    }
  }

  // If product didn't find in store
  loadQuestionFromService(questionId) {
    this.clientQuestionService.getById(questionId).subscribe((res) => {
      this.loadQuestion(res, true);
    });
  }

  getActiveTab() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.tab) {
        this.selectedTab = params.tab;
      }
    });
  }

  /**
   * Init category
   */
  initQuestion() {
    this.createForm();
    if (!this.question.id) {
      this.subheaderService.setBreadcrumbs([
        { title: 'Service Client', page: `/service-client` },
        {
          title: 'Question des Clients',
          page: `/service-client/client-question`,
        },
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit CategoryQuestion');
    this.subheaderService.setBreadcrumbs([
      { title: 'Service Client', page: `/service-client` },
      {
        title: 'Question des Clients',
        page: `/service-client/client-question`,
      },
      {
        title: 'Edit question',
        page: `/service-client/client-question/edit`,
        queryParams: { id: this.question.id },
      },
    ]);
  }
  createForm() {
    this.questionForm = this.fb.group({
      firstName: [this.question.firstName],
      lastName: [this.question.lastName],
      companyName: [this.question.companyName],
      email: [this.question.email],
      phone: [this.question.phone],
      orderNumber: [this.question.orderNumber],
      object: [this.question.object],
      message: [this.question.message],
      type: [this.question.type],
    });
  }
  goBack(id) {
    const url = `/service-client/client-question?id=${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }
  goBackWithoutId() {
    this.router.navigateByUrl(
      '/service-client/client-question',
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }

  saveandgoback(){
    this.onSumbit()
    delay(1000)
   
    this.goBackWithoutId()
  }
  /**
   * Returns object for saving
   */
  prepareQuestion(): ClientQuestionModel {
    const controls = this.questionForm.controls;
    const _question = new ClientQuestionModel();
    _question.id = this.question.id;
    _question.firstName = controls.firstName.value;
    _question.lastName = controls.lastName.value;
    _question.type = controls.type.value;
    _question.companyName = controls.companyName.value;
    _question.email = controls.email.value;
    _question.phone = controls.phone.value;
    _question.orderNumber = controls.orderNumber.value;
    _question.object = controls.object.value;
    _question.message = controls.message.value;

    return _question;
  }

  /**
   * Update product
   *
   * @param _category: CategoryModel
   * @param withBack: boolean
   */
  updateQuestion(_question: ClientQuestionModel, withBack: boolean = false) {
    const updateQuestion: Update<ClientQuestionModel> = {
      id: _question.id,
      changes: _question,
    };

    this.store.dispatch(
      QuestionActions.QuestionUpdated({
        partialQuestion: updateQuestion,
        question: _question,
        id: _question.id,
      })
    );
    if (withBack) {
      this.goBack(_question.id);
    } else {
      const message = `question successfully has been saved.`;
      this.layoutUtilsService.showActionNotification(
        message,
        MessageType.Update,
        10000,
        true,
        true
      );
      this.refreshQuestion(false);
    }
  }

  /**
   * Returns component title
   */
  getComponentTitle() {
    let result = 'Ajouter une nouvelle question';
    if (!this.question || !this.question.id) {
      return result;
    }

    result = `Modifier question - ${this.question.object}`;
    return result;
  }

  refreshQuestion(isNew: boolean = false, id = 0) {
    let url = this.router.url;
    if (!isNew) {
      this.router.navigate([url], { relativeTo: this.activatedRoute });
      return;
    }

    url = `/service-client/client-question/${id}`;
    this.router.navigateByUrl(
      url,
      /* Removed unsupported properties by Angular migration: relativeTo. */ {}
    );
  }
  /**
   * Save data
   *
   * @param withBack: boolean
   */
  onSumbit(withBack: boolean = false) {
    this.hasFormErrors = false;
    const controls = this.questionForm.controls;
    /** check form */
    if (this.questionForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.hasFormErrors = true;
      this.selectedTab = 0;
      return;
    }

    // tslint:disable-next-line:prefer-const
    let editedQuestion = this.prepareQuestion();

    if (editedQuestion.id > 0) {
      this.updateQuestion(editedQuestion, withBack);
      return;
    }

    /*this.addQuestion(editedQuestion, withBack);*/
  }
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
  /**
   * Add product
   *
   * @param _category: CategoryModel
   * @param withBack: boolean
   */
  /*addQuestion(_question: CategoryModel, withBack: boolean = false) {
    this.store.dispatch(QuestionActions.CategoryCreated({category: _question}));
    const storesub$ = this.store.pipe(
        select(selectLastCreatedCategoryId)
    ).subscribe(newId => {
      if (!newId) {
        return;
      }
      if (withBack) {
        this.goBack(newId);
      } else {
        const message = `New category successfully has been added.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
        this.refreshCategory(true, newId);
      }
    });

    this.componentSubscriptions.push(storesub$);
  }*/
}
