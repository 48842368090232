import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract-service.service';
import { HttpClient } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { CarouselModel } from './carousel.model';

@Injectable()
export class CarouselsService extends AbstractService<CarouselModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}carousel`;
  }
}
