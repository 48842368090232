import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract-service.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ADMIN_CONFIG } from '../../../../environments/environment';
import { QuestionAnswerModel } from './questionanswer.model';
import { Observable } from 'rxjs';
import { Page } from '../../_base/crud/models/page';
import { QueryParamsModel } from '../../_base/crud';
import { AboutModel } from '../../about/about.model';

@Injectable()
export class QuestionAnswerService extends AbstractService<QuestionAnswerModel> {
  url: string;

  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}user_question/faq`;
  }
  public GetAllFAQ(
    queryParams: QueryParamsModel
  ): Observable<Page<QuestionAnswerModel>> {
    const url = `${this.url}`;
    const options = {
      params: new HttpParams()
        .append(
          'page',
          queryParams.pageNumber ? queryParams.pageNumber.toString() : '0'
        )
        .append(
          'size',
          queryParams.pageSize ? queryParams.pageSize.toString() : '50'
        )
        .append(
          'search',
          queryParams.filter.query ? queryParams.filter.query : ''
        )
        .append('status', queryParams.filter.status),
    };

    return this.http.get<Page<QuestionAnswerModel>>(url, options);
  }

  /*public getAllByFilter(filter: QueryParamsModel): Observable<Page<QuestionAnswerModel>> {
        const url = `${this.url}`;

        const options = {
            params: new HttpParams()
                .append('page', filter.pageNumber.toString())
                .append('size', filter.pageSize? filter.pageSize.toString() : "30")
                .append('search', filter.filter.query)
                .append('status', filter.filter.status)
        };
        return this.http.get<Page<QuestionAnswerModel>>(url, options);
    }*/

  public registerFaq(
    entity: QuestionAnswerModel
  ): Observable<QuestionAnswerModel> {
    console.log('entity to save' + JSON.stringify(entity));
    console.log(this.url);
    const res = this.http.post<QuestionAnswerModel>(this.url, entity);
    return res;
  }
  public updateFaq(
    _faq: QuestionAnswerModel,
    id: number
  ): Observable<QuestionAnswerModel> {
    const url = `${this.url}/` + id;
    return this.http.put<QuestionAnswerModel>(url, _faq);
  }
}
