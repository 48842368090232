import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { fromEvent, Subscription } from 'rxjs';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../../../../core/_base/crud';
import { SubheaderService } from '../../../../../../../../core/_base/layout';
import { ClientDatasource } from '../../../../../../../../core/e-commerce/clients/client.datasource';
import { CompaniesService } from '../../../../../../../../core/e-commerce/companies/companies.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
  tap,
} from 'rxjs/operators';
import { ClientService } from '../../../../../../../../core/e-commerce/clients/client.service';
import { CompanyModel } from '../../../../../../../../core/e-commerce/companies/company.model';
import { ClientModel } from '../../../../../../../../core/e-commerce/clients/client.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kt-clients-list',
  templateUrl: './client-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private companyService: CompaniesService,
    private clientService: ClientService
  ) {}
  @Input() mainClient = false;
  dataSource: ClientDatasource;
  displayedColumns = [
    'id',
    'accountNumber',
    'companyName',
    'mail',
    'name',
    'status',
    'actions',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  private subscriptions: Subscription[] = [];
  private companyId: number;
  company: CompanyModel;
  filterStatus = new FormControl('');

  ngOnInit() {
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    )
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadClientsPage();
        })
      )
      .subscribe();
    // get the companyId from the route
    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      this.companyId = params.id;
      if (this.companyId) {
        this.companyService.getById(this.companyId).subscribe((company) => {
          this.company = company;
          this.dataSource = new ClientDatasource(
            this.companyService,
            this.clientService,
            company
          );
          this.loadClientsPage();

          setTimeout(() => this.cdr.detectChanges(), 1000);
          const errorSub = this.dataSource.error$
            .pipe(skip(1))
            .subscribe((res) => {
              this.showNotification('Error : ' + res, MessageType.Read);
            });
          this.subscriptions.push(errorSub);
        });
      }
    });
    this.subscriptions.push(routeSubscription);
  }

  // we use ngAfterViewInit to wait for the view to complete so we can use paginator.page
  ngAfterViewInit() {
    // server side search
    const searchInputSub = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadClientsPage();
        })
      )
      .subscribe();
    this.subscriptions.push(searchInputSub);

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    const paginatorSub = this.paginator.page
      .pipe(tap((res) => this.loadClientsPage()))
      .subscribe();
    this.subscriptions.push(paginatorSub);
  }
  filterConfiguration(): any {
    const filter: any = {};
    if (this.filterStatus.value && this.filterStatus.value !== '') {
      filter.status = this.filterStatus.value;
    }

    filter.query = this.searchInput.nativeElement.value;
    return filter;
  }
  loadClientsPage() {
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.dataSource.loadClients(this.companyId, queryParams);
  }
  showNotification(messageText: string, messageType: MessageType) {
    this.layoutUtilsService.showActionNotification(messageText, messageType);
  }

  deleteClient(id: number) {
    const _title = 'Supprimer le client';
    const _description = 'Voulez-vous vraiment supprimer ce client ?';
    const _waitDesciption = 'Suppression en cours...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.dataSource.deleteClient(id);
      this.showNotification(
        'Client est supprimé avec succès !',
        MessageType.Delete
      );
    });
  }

  /**
   * On Destroy
   */

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  unblockClient(client: ClientModel) {
    const _TITLE = `Unblock client ${client.lastname} ${client.name}`;
    const _DESCRIPTION = `Are you sure to unblock client ${client.lastname} ${client.name}?`;
    const _WAIT_DESCRIPTION = `Client ${client.lastname} ${client.name} is unblocking...`;
    const _UNBLOCK_MESSAGE = `Client ${client.lastname} ${client.name} has been unblocked`;

    const dialogRef = this.layoutUtilsService.unblockEntity(
      _TITLE,
      _DESCRIPTION,
      _WAIT_DESCRIPTION
    );
    dialogRef.afterClosed().subscribe((response) => {
      if (!response) {
        return;
      }
      this.clientService.unblockClient(client).subscribe(() => {
        this.loadClientsPage();
        this.layoutUtilsService.showActionNotification(
          _UNBLOCK_MESSAGE,
          MessageType.Unblock
        );
      });
    });
  }
  getItemCssClassByStatus(status: string = ''): string {
    switch (status) {
      case 'ACTIVATED':
        return 'success';
      case 'BLOCKED':
        return 'danger';
      case 'DEACTIVATED':
      case 'PENDING':
        return 'metal';
    }
    return '';
  }
}
