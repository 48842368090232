// Angular
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthActions, AuthNoticeService } from '../../../../core/auth';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import {
  currentToken,
  errorMessage,
  isLoading,
} from '../../../../core/auth/_selectors/auth.selectors';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
  EMAIL: 'admin@tekkeys.com',
  PASSWORD: 'Azerty@1234',
};

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup;
  isLoggedIn$: Observable<boolean>;
  private unsubscribe: Subject<any>;

  private returnUrl: any;

  error$: Observable<any>;
  errorMessage$: Observable<string>;
  isLoading$: Observable<boolean>;
  token$: Observable<string>;

  // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param router: Router
   * @param auth: AuthService
   * @param authNoticeService: AuthNoticeService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   * @param fb: FormBuilder
   * @param cdr
   * @param route
   */
  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.unsubscribe = new Subject();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */
  show: boolean = false;

  /**
   * On init
   */
  ngOnInit(): void {
    this.initLoginForm();

    this.isLoading$ = this.store.pipe(select(isLoading));
    this.token$ = this.store.pipe(select(currentToken));
    this.error$ = this.store.pipe(select(Error));
    this.errorMessage$ = this.store.pipe(select(errorMessage));

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || '/';
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initLoginForm() {
    // demo message to show
    if (!this.authNoticeService.onNoticeChanged$.getValue()) {
      const initialNotice = `Utilise le compte
			<strong>${DEMO_PARAMS.EMAIL}</strong> avec le mot de passe
			<strong>${DEMO_PARAMS.PASSWORD}</strong> pour continuer.`;
      this.authNoticeService.setNotice(initialNotice, 'info');
    }

    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.loginForm.controls;
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    const authData = {
      email: controls.email.value,
      password: controls.password.value,
    };

    this.store.dispatch(
      AuthActions.login({ mail: authData.email, password: authData.password })
    );
    this.errorMessage$.subscribe((response) => {});
    this.errorMessage$.subscribe((response) => {
      if (response) {
        this.authNoticeService.setNotice(
          this.translate.instant(response),
          'danger'
        );
      }
    });
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  password() {
    this.show = !this.show;
  }
}
