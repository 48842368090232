import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AddressModel } from '../../../../../../../core/e-commerce/suppliers/address.model';
import { SupplierService } from '../../../../../../../core/e-commerce/suppliers/supplier.service';
import { LayoutUtilsService } from '../../../../../../../core/_base/crud';
import { SupplierAddressesService } from '../../../../../../../core/e-commerce/suppliers/addresses.service';

@Component({
  selector: 'kt-address-supplier-edit-dialog',
  templateUrl: './address-edit-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSupplierEditDialogComponent implements OnInit {
  addressEditForm: FormGroup;
  viewLoading = true;
  loadingAfterSubmit = false;
  address: AddressModel;
  newAddress: AddressModel;

  constructor(
    public dialogRef: MatDialogRef<AddressSupplierEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { address: AddressModel; supplierId: number; bill: boolean },
    private fb: FormBuilder,
    private supplierService: SupplierService,
    private supplierAddressesService: SupplierAddressesService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit() {
    this.initAddressForm();
  }

  initAddressForm() {
    this.addressEditForm = this.fb.group({
      country: [this.data.address ? this.data.address.country : ''],
      city: [
        this.data.address ? this.data.address.city : '',
        [Validators.required],
      ],
      streetName: [
        this.data.address ? this.data.address.streetName : '',
        [Validators.required],
      ],
      additionalInfo: [
        this.data.address ? this.data.address.additionalInfo : '',
      ],
      cedex: [this.data.address ? this.data.address.cedex : '', []],
      postalCode: [
        this.data.address ? this.data.address.postalCode : '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(6),
        ]),
      ],
    });
  }

  /**
   * Close dialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Save address
   */
  save() {
    this.newAddress = new AddressModel();
    const controls = this.addressEditForm.controls;
    /** check form */
    if (this.addressEditForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.layoutUtilsService.showActionNotification(
        'Veuillez remplir les champs correctement!'
      );
      return;
    }

    this.loadingAfterSubmit = true;
    this.viewLoading = true;

    this.newAddress.country = controls.country.value;
    this.newAddress.city = controls.city.value;
    this.newAddress.streetName = controls.streetName.value;
    this.newAddress.postalCode = controls.postalCode.value;
    this.newAddress.additionalInfo = controls.additionalInfo.value;
    this.newAddress.cedex = controls.cedex.value;
    this.viewLoading = false;
    if (this.data.bill || !this.data.supplierId) {
      this.closeDialog(this.newAddress);
      return;
    }
    if (!this.data.address) {
      this.supplierAddressesService
        .addNewAddress(this.data.supplierId, this.newAddress)
        .subscribe((res) => {
          this.closeDialog(res);
        });
    } else {
      this.newAddress.id = this.data.address.id;
      this.supplierAddressesService
        .updateAddress(this.newAddress)
        .subscribe((res) => {
          this.closeDialog(res);
        });
    }
  }

  /**
   * Close dialog
   *
   * @param specId: any
   */
  closeDialog(address: AddressModel) {
    this.dialogRef.close(address);
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addressEditForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
