import { ProductModel } from '../products/product.model';

export class OrderItemModel {
  id: number;
  itemTotalPrice: number;
  price: number;
  quantity: number;
  orderId: number;
  productId: number;

  product?: ProductModel;
}
