import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OrderItemModel } from './OrderItem.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
//Actions
import * as OrderItemActions from './orderItems.actions';

export interface OrderItemState extends EntityState<OrderItemModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedOrderItemId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  lastAction: string;
}

export const adapter: EntityAdapter<OrderItemModel> =
  createEntityAdapter<OrderItemModel>();

export const initialOrderItemState: OrderItemState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedOrderItemId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  lastAction: '',
});

const orderItemReducer = createReducer(
  initialOrderItemState,
  on(OrderItemActions.OrderItemsPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    lastQuery: action.page,
  })),
  on(OrderItemActions.OrderItemsPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.orderItems, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
      totalCount: action.totalCount,
    })
  ),
  on(OrderItemActions.OrderItemsPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //creation
  on(OrderItemActions.OrderItemCreated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(OrderItemActions.OrderItemCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.orderItem, {
      ...state,
      actionsLoading: false,
      lastCreatedOrderItemId: action.orderItem.id,
      lastAction: 'EDIT.ADD_MESSAGE',
    })
  ),
  on(OrderItemActions.OrderItemCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  //Update
  on(OrderItemActions.OrderItemUpdated, (state) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(OrderItemActions.OrderItemUpdatedSuccessfully, (state, action) =>
    adapter.updateOne(action.partialOrderItem, {
      ...state,
      actionsLoading: false,
      lastAction: 'EDIT.UPDATE_MESSAGE',
    })
  ),
  on(OrderItemActions.OrderItemUpdateFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
  })),
  //Delete
  on(OrderItemActions.OrderItemDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(OrderItemActions.OrderItemDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.orderItemId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE.DELETE_ORDERITEM_SIMPLE.DELETE_SUCCESS',
    })
  ),
  on(OrderItemActions.OrderItemDeleteFailed, (state, action) => ({
    ...state,
    error: action.error,
    actionsLoading: false,
  }))
);

export function orderItemsReducer(
  state: OrderItemState | undefined,
  action: Action
) {
  return orderItemReducer(state, action);
}
