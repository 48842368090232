import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
import { ResourcesService } from '../../../../../../core/services/resources.service';
import {
  selectCarouselError,
  selectCarousels,
} from '../../../../../../core/home-page/carousel/carousel.selectors';
import { CarouselModel } from '../../../../../../core/home-page/carousel/carousel.model';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
//Actions
import * as CarouselActions from '../../../../../../core/home-page/carousel/carousel.action';
import { Update } from '@ngrx/entity';
import {map, skip} from 'rxjs/operators';

@Component({
  selector: 'kt-carousel-images',
  templateUrl: './carousel-images.component.html',
  styleUrls: ['./carousel-images.component.scss'],
})
export class CarouselImagesComponent implements OnInit, OnDestroy {
  urlToAdd;
  //Subscriptions
  private subscriptions: Subscription[] = [];
  private subscription: Subscription;

  private error$: Observable<string>;
  private positions: number ;

  private carouselToAdd = new CarouselModel();
  uploading = false;
  //Progress
  progress = new BehaviorSubject<number>(0);
  carousels$: Observable<CarouselModel[]>;
  numbers: number[] = [];

  constructor(
    private store: Store<AppState>,
    private resourcesService: ResourcesService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.store.dispatch(CarouselActions.CarouselsPageRequested());
    this.carousels$ = this.store.select(selectCarousels).pipe(
        map(carousels => this.sortSlidesByPosition(carousels))
    );

    this.error$ = this.store.select(selectCarouselError);
    this.subscription = this.carousels$.subscribe(count => {
     this.positions = count.length;
     this.generateNumbers();
    });
    // to manage the errors in the page
    const errorSub = this.error$.pipe(skip(1)).subscribe((res) => {
      const message = `Action Failed .` + res['message'];
      this.layoutUtilsService.showActionNotification(
        message,
        MessageType.Update,
        5000,
        true,
        false
      );
    });
    this.subscriptions.push(errorSub);
  }

  sortSlidesByPosition(slides: CarouselModel[]): CarouselModel[] {
    return slides.sort((a, b) => a.position - b.position);
  }

  generateNumbers() {
    this.numbers = [];
    for (let i = 1; i <= this.positions; i++) {
      this.numbers.push(i);
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onSelectedFile(files: FileList) {
    this.carouselToAdd.file = files.item(0);
    const resourceSub = this.resourcesService
      .pushFileToStorage(this.carouselToAdd.file)
      .subscribe(
        (res) => {
          this.uploading = true;

          // the upload finished
          if (res instanceof HttpResponse) {
            // let newResource = new ProductResource();
            const body = JSON.parse(res.body);
            //preparing the resource infos
            this.carouselToAdd.image = body.url;
            //todo make it dynamic
            this.carouselToAdd.enabled = true;
            this.carouselToAdd.name = this.carouselToAdd.file.name;
            this.carouselToAdd.link = this.carouselToAdd.image;
            this.store.dispatch(
              CarouselActions.CarouselCreated({
                carousel: this.carouselToAdd,
              }
          )
            );
            console.log(this.carouselToAdd.image,'tttttt');

            this.uploading = false;
            const message = `Carousel has been added Successfully.`;
            this.layoutUtilsService.showActionNotification(
              message,
              MessageType.Update,
              5000,
              true,
              false
            );
            this.progress.next(0);
            //the upload is in progress
          } else if (res.type === HttpEventType.UploadProgress) {
            this.progress.next(Math.round((100 * res.loaded) / res.total));
          }
        },
        (error) => {
          this.uploading = false;
        }
      );

    this.subscriptions.push(resourceSub);
  }

  onUpdateUrl(carousel: CarouselModel, url , position: number, visible: boolean ) {
    const updated = {
      id: carousel.id,
      link: url,
      image: carousel.image,
      enabled: visible,
      name: carousel.name,
      file: carousel.file,
      position,
    };
    const updateCarousel: Update<CarouselModel> = {
      id: carousel.id,
      changes: updated,
    };
    this.store.dispatch(
      CarouselActions.CarouselUpdated({
        carousel: updated,
        partialCarousel: updateCarousel,
      })
    );
    const message = `Image has been aded Successfully.`;
    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, false);

  }

  onDelete(carousel: CarouselModel) {
    const _title: string = this.translate.instant(
      'ECOMMERCE.HOME_PAGE.DELETE.IMAGE.TITLE'
    );
    const _description: string = this.translate.instant(
      'ECOMMERCE.HOME_PAGE.DELETE.IMAGE.DESCRIPTION'
    );
    const _waitDesciption: string = this.translate.instant(
      'ECOMMERCE.HOME_PAGE.DELETE.IMAGE.WAIT_DESCRIPTION'
    );

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption
    );
    const subdialog = dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.resourcesService.delete(carousel.image).subscribe((res) => {
        this.store.dispatch(
          CarouselActions.CarouselDeleted({
            carouselId: carousel.id,
          })
        );
        const message = `Image has been deleted Successfully.`;
        this.layoutUtilsService.showActionNotification(
          message,
          MessageType.Update,
          5000,
          true,
          false
        );
      });
    });
    this.subscriptions.push(subdialog);
  }
}
