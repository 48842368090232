export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Tableau de Bord',
          root: true,
          alignment: 'left',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',
        },

        {
          title: 'Services',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            // the horizontal menu items
         /*   {
              title: 'Catalogue',
              bullet: 'dot',
              icon: 'flaticon2-tag',
              // permission: 'ROLE_SUPER_ADMIN',
              submenu: [
                {
                  title: 'Catégories',
                  translate: 'MENU.CATEGORIES',
                  page: '/ecommerce/categories',
                },
                {
                  title: 'Marques',
                  page: '/ecommerce/products/manufacturers',
                },
                {
                  title: 'Produits',
                  translate: 'MENU.PRODUCTS',
                  page: '/ecommerce/products',
                },
              ],
            },
            {
              title: 'Clients',
              bullet: 'dot',
              icon: 'flaticon-users',
              root: true,
              submenu: [
                {
                  title: 'Sociétés',
                  translate: 'MENU.COMPANIES',
                  page: '/ecommerce/companies',
                },
                {
                  title: 'Contacts',
                  translate: 'MENU.CLIENTS',
                  page: '/ecommerce/mainclient',
                },
                {
                  title: 'Fonctions',
                  translate: 'MENU.FUNCTIONS',
                  page: '/ecommerce/client-functions',
                },
              ],
            },*/
            {
              title: 'Commandes',
              bullet: 'dot',
              icon: 'flaticon2-list-2',
              root: true,
              submenu: [
                {
                  title: 'Commandes',
                  translate: 'MENU.ORDERS',
                  page: '/ecommerce/orders',
                },
                {
                  title: 'Devis',
                  page: '/ecommerce/quotations',
                },
              ],
            },
          ],
        },
      ],
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Tableau de Bord',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot',
        },
        // {separator: 'true'},
        { section: 'Services' },
        {
        //  title: 'Catalogue',
         // bullet: 'dot',
          //icon: 'flaticon2-tag',
          //root: true,
          //submenu: [
           /* {
              title: 'Catégories',
              translate: 'MENU.CATEGORIES',
              page: '/ecommerce/categories',
            },
            {
              title: 'Marques',
              page: '/ecommerce/manufacturers',
            },
            {
              title: 'Produits',
              translate: 'MENU.PRODUCTS',
              page: '/ecommerce/products',
            },
            /*{
              title: 'Fournisseurs',
              page: '/ecommerce/products/suppliers',
            },
            {
              title: 'Promotions & Remises',
              page: '/ecommerce/promotions',
            },
            {
              title: 'Code Promo',
              page: '/ecommerce/promo-code',
            },*/
         // ],
        },
        /*   {
          title: 'Clients',
          bullet: 'dot',
          icon: 'flaticon-users',
          root: true,
          submenu: [
            {
              title: 'Sociétés',
              translate: 'MENU.COMPANIES',
              page: '/ecommerce/companies',
            },
            {
              title: 'Contacts',
              translate: 'MENU.CLIENTS',
              page: '/ecommerce/mainclient',
            },
            {
              title: 'Fonctions',
              translate: 'MENU.FUNCTIONS',
              page: '/ecommerce/client-functions',
            },
          ],
        },
        /*   {
             title: 'Devis',
             bullet: 'dot',
             icon: 'flaticon2-list-2',
             root: true,
             page: '/ecommerce/quotations',
           },
           {
             title: 'Commandes',
             bullet: 'dot',
             icon: 'flaticon-clipboard',
             root: true,
             page: '/ecommerce/orders',
           },
           {
             title: 'Journal Odoo',
             bullet: 'dot',
             icon: 'flaticon2-list-2',
             root: true,
             submenu: [
               {
                 title: 'Messages du log',
                 page: 'ecommerce/odoo/logs',
               },
             ],
           },*/
        {
          title: 'Page d\'accueil',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-buildings',
          translate: 'MENU.HOME_PAGE',
          // permission: 'ROLE_SUPER_ADMIN',
          submenu: [
            {
              title: 'Carousel',
              translate: 'MENU.CAROUSEL',
              page: '/ecommerce/carousel',
            },
            {
              title: 'Logos Partnaires ',
              translate: 'MENU.LOGOS',
              page: '/ecommerce/logo',
            },
            {
              title: 'Gestion de  gestion des article',
              translate: 'BLOG.ARTICLE_MANAGEMENT',
              page: '/blog/articles',
            },
          ],
        },
        {
          title: 'Service Client',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-questions-circular-button',
          translate: 'MENU.SERVICE_CLIENT',
          // permission: 'ROLE_SUPER_ADMIN',
          submenu: [
           /* {
              title: 'Reclamation',
              page: '/service-client/claims',
            },*/
            {
              title: 'FAQ',
              page: '/service-client/question-answer',
            },
            {
              title: 'Questions des Clients',
              page: '/service-client/client-question',
            },
          ],
        },
      /*  {
          title: 'Gestion de  gestion des article',
          bullet: 'dot',
          icon: 'flaticon2-file-2',
          root: true,
          // permission: 'ROLE_SUPER_ADMIN',
          submenu: [
            {
              title: 'Gestion des articles',
              translate: 'BLOG.ARTICLE_MANAGEMENT',
              page: '/blog/articles',
            },
          ],
        },
        /*{
          title: 'Archive',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-delete-2',
          // permission: 'ROLE_SUPER_ADMIN',
          submenu: [
            {
              title: 'Produits archivés',
              page: '/archive/products',
            },
            {
              title: 'Devis archivés',
              page: '/archive/quotations',
            },
            {
              title: 'Commandes archivés',
              page: '/archive/orders',
            },
            {
              title: 'Contacts archivés',
              page: '/archive/clients',
            },
          ],
        },*/
        { section: 'Autre' },
        {
          title: 'A propos',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-information',
          submenu: [
            {
              title: 'Liens Utiles',
              page: '/a-propos/about',
            },
            /*{
              title: 'Qui somme-nous',
              // page: '/a-propos/about'
            },
            {
              title: 'Pourquoi choisir Imtech',
              // page: '/a-propos/about'
            },*/
          ],
        },
        {
          title: 'Comptes Utilisateurs',
          root: true,
          bullet: 'dot',
          icon: 'flaticon-user',
          translate: 'MENU.USER_MANAGEMENT',
          permission: 'ROLE_SUPER_ADMIN',
          submenu: [
            {
              title: 'Administrateurs',
              translate: 'MENU.USERS',
              page: '/user-management/users',
            },
          ],
        },
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
