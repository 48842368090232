import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { LogoModel } from './logo.model';
import { QueryParamsModel } from '../../_base/crud';
import { Action, createReducer, on } from '@ngrx/store';
// actions
import * as LogoActions from './logo.action';
import { Response } from '../../_base/crud/models/response';

export interface LogoState extends EntityState<LogoModel> {
  listLoading: boolean;
  actionsLoading: boolean;
  totalCount: number;
  lastCreatedLogoId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
  error: any;
  errorMessage: string;
  lastAction: string;
  progress: number;
}

export const adapter: EntityAdapter<LogoModel> =
  createEntityAdapter<LogoModel>();

export const initialLogoState: LogoState = adapter.getInitialState({
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  lastCreatedLogoId: undefined,
  lastQuery: new QueryParamsModel({}),
  showInitWaitingMessage: true,
  error: Response,
  errorMessage: '',
  lastAction: '',
  progress: 0,
});

const logoReducer = createReducer(
  initialLogoState,
  on(LogoActions.LogosPageRequested, (state, action) => ({
    ...state,
    actionsLoading: true,
    listLoading: true,
    showInitWaitingMessage: true,
    error: null,
    lastAction: '',
    progress: 0,
  })),

  on(LogoActions.LogosPageLoadedSuccessfully, (state, action) =>
    adapter.addAll(action.logos, {
      ...state,
      actionsLoading: false,
      listLoading: false,
      showInitWaitingMessage: false,
    })
  ),
  on(LogoActions.LogosPageLoadFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    listLoading: false,
    showInitWaitingMessage: false,
    error: action.error,
  })),
  //creation
  on(LogoActions.LogoCreated, (state) => ({
    ...state,
    actionsLoading: false,
    lastAction: '',
  })),
  on(LogoActions.LogoCreatedSuccessfully, (state, action) =>
    adapter.addOne(action.logo, {
      ...state,
      actionsLoading: true,
      lastCreatedLogoId: action.logo.id,
      lastAction: 'EDIT.ADD_MESSAGE',
      progress: 0,
    })
  ),
  on(LogoActions.LogoCreationFailed, (state, action) => ({
    ...state,
    actionsLoading: false,
    error: action.error,
    progress: 0,
  })),
  //Update

  //Delete
  on(LogoActions.LogoDeleted, (state, action) => ({
    ...state,
    actionsLoading: true,
    lastAction: '',
  })),
  on(LogoActions.LogoDeletedSuccessfully, (state, action) =>
    adapter.removeOne(action.logoId, {
      ...state,
      actionsLoading: false,
      lastAction: 'DELETE_CATEGORY_SIMPLE.MESSAGE',
    })
  ),
  on(LogoActions.LogoDeleteFailed, (state: LogoState, action) => {
    let errorMessage: string;
    switch (action.error.status) {
      case 500:
        errorMessage = 'DELETE_CATEGORY_SIMPLE.FOREIGN_KEY_ERROR';
        break;
      case 403:
        errorMessage = 'AUTH.VALIDATION.CANT_DELETE';
        break;
      case 404:
        errorMessage = 'AUTH.VALIDATION.NOT_FOUND';
        break;
      default:
        errorMessage = 'AUTH.VALIDATION.NO_CONNECTION';
        break;
    }
    return {
      ...state,
      listLoading: false,
      actionsLoading: false,
      errorMessage: errorMessage,
      error: action.error,
    };
  })
);

export function logosReducer(state: LogoState | undefined, action: Action) {
  return logoReducer(state, action);
}
