// Angular
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// Material
import {MatDialog, MatPaginator, MatTableDataSource} from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import {LayoutUtilsService} from '../../../../../../../../../core/_base/crud';
import {SubheaderService} from '../../../../../../../../../core/_base/layout';
import {StockModel} from '../../../../../../../../../core/e-commerce/stocks/stock.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import {AppState} from '../../../../../../../../../core/reducers';
import {selectProductStockInStore} from '../../../../../../../../../core/e-commerce/stocks/stock.selectors';
import {
    ProductStockCreate,
    ProductStockDelete,
    ProductStockUpdate,
    StocksListRequested,
} from '../../../../../../../../../core/e-commerce/stocks/stock.actions';
import {MatSort} from '@angular/material/sort';
import {CurrencyMaskInputMode} from 'ngx-currency';
import {SupplierModel} from '../../../../../../../../../core/e-commerce/suppliers/supplier.model';
import {SupplierActions} from '../../../../../../../../../core/e-commerce';
import {selectSuppliersInStore} from '../../../../../../../../../core/e-commerce/suppliers/supplier.selector';
import {ProductsService} from '../../../../../../../../../core/e-commerce/products/products.service';
import * as LicensesActions from '../../../../../../../../../core/e-commerce/product-licenses/product-licenses.actions';
import * as XLSX from 'xlsx';
import {
    isProductLicenseLoading,
    selectImportProductLicenseFailed
} from '../../../../../../../../../core/e-commerce/product-licenses/product-licenses.selectors';
import { ProductModel } from '../../../../../../../../../core/e-commerce/products/product.model';

@Component({
    selector: 'kt-stock-list',
    templateUrl: './stock-list.component.html',
    styles: [
        `   row{
            justify-content: flex-end;
        }
            mat-cell,
            mat-header-cell {
                max-width: 25%;
            }
            .odoo-message{
                font-size: 15px ;
                background-color: red ;
                color: white;
                width: 100%;
                margin-left: 120px;
            }
        `,
    ],
})
export class StockListComponent implements OnInit, OnDestroy {
    dataSource: MatTableDataSource<StockModel> = new MatTableDataSource([]);

    displayedColumns = [
        'select',
        'id',
        'status',
        'quantity',
        'orderedQuantity',
        'sellingPrice',
    ];

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    filterStatus = '';
    error: any = null;


    productId: number;
    stockForm: FormGroup;

    editStockLine = -1; // 0 if new stock
    loadingAfterSubmit = false;
    productPhysique = true;

    customCurrencyMaskConfig = {
        align: 'left',
        prefix: '',
        suffix: ' €',
        inputMode: CurrencyMaskInputMode.NATURAL,
    };
    data: any[] = [];
    fileInput: ElementRef;



    selectedSupplier: SupplierModel;
    suppliers: SupplierModel[];
    suggestedSuppliers: SupplierModel[] = [];
    importError: Observable<any>;
    loadingKeys: Observable<boolean>;
    product: ProductModel;



    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private subheaderService: SubheaderService,
        private layoutUtilsService: LayoutUtilsService,
        private store: Store<AppState>,
        private productService: ProductsService,
    ) {
        this.loadSuppliers(); // TODO loadSuppliers only when trying to add new stock
    }

    ngOnInit() {
        const routesubscription = this.activatedRoute.params.subscribe((res) => {
            this.productId = res.id;
            this.store.dispatch(StocksListRequested({productId: this.productId}));
            this.store.select(selectProductStockInStore).subscribe((stocks) => {
                if(stocks){
                    this.dataSource.data = stocks;
                    this.dataSource.sort = this.sort;}

            });
        });

        this.productService.getById(this.productId).subscribe(value => {
            if (!value.physical) {
                this.productPhysique = false;
            }
            this.product = value;
        });
        this.subscriptions.push(routesubscription);

        this.stockForm = this.fb.group({
            id: [null],
            supplier: [null, Validators.required],
            quantity: [null, Validators.required],
            price: [null, Validators.required],
        });

        this.stockForm.get('supplier').valueChanges.subscribe((value) => {
            this.suggestedSuppliers = this.suppliers.filter((c) =>
                c.name.toLowerCase().includes(value?.toLowerCase())
            );
        });
        this.importError = this.store.pipe(select(selectImportProductLicenseFailed));
        this.importError.subscribe((c) => {
            this.error = c;
        });
        this.loadingKeys = this.store.pipe(select(isProductLicenseLoading));

    }

    ngOnDestroy() {
        this.subscriptions.forEach((el) => el.unsubscribe());
    }

    deleteStock(_item: StockModel) {
        const _title = 'Supprimer le stock';
        const _description = 'Voulez-vous vraiment supprimer ce stock?';
        const _waitDesciption = 'Suppression en cours...';

        const dialogRef = this.layoutUtilsService.deleteElement(
            _title,
            _description,
            _waitDesciption
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (!res) {
                return;
            }
            this.store.dispatch(ProductStockDelete({stockId: _item.id}));
        });
    }

    createStock() {
        this.editStockLine = 0;
    }

    saveNewStock() {
        this.loadingAfterSubmit = true;
        const stock: StockModel = {
            id: null,
            productId: this.productId,
            price: this.stockForm.value.price,
            quantity: this.stockForm.value.quantity,
            supplierId: this.selectedSupplier.id,
        };
        this.store.dispatch(ProductStockCreate({stock}));
        setTimeout(() => {
            this.loadingAfterSubmit = false;
            this.cancelAddNewStock();
        }, 1000); // TODO use Store State
    }

    cancelAddNewStock() {
        this.stockForm.reset();
        this.editStockLine = -1;
    }

    editStock(item: StockModel) {
        this.editStockLine = item.id;
        this.selectedSupplier = item.supplier;
        this.stockForm.get('id').setValue(item.id);
        this.stockForm.get('supplier').setValue(item.supplier.name);
        this.stockForm.get('quantity').setValue(item.quantity);
        this.stockForm.get('price').setValue(item.price);
    }

    saveEditedStock() {
        this.loadingAfterSubmit = true;
        const stock: StockModel = {
            id: this.stockForm.value.id,
            productId: this.productId,
            price: this.stockForm.value.price,
            quantity: this.stockForm.value.quantity,
            supplierId: this.selectedSupplier.id,
        };
        this.store.dispatch(ProductStockUpdate({stock}));
        setTimeout(() => {
            this.ngOnInit();
            this.loadingAfterSubmit = false;
            this.cancelEditStock();
        }, 1000); // TODO use Store State
    }

    cancelEditStock() {
        this.editStockLine = -1;
        this.stockForm.reset();
    }

    selectSupplier(name: string) {
        this.selectedSupplier = this.suppliers.find((m) => m.name === name);
        this.stockForm.get('supplier').setValue(this.selectedSupplier.name);
    }

    private loadSuppliers() {
        this.store.dispatch(SupplierActions.SuppliersListRequested());
        const manufacturers$ = this.store
            .select(selectSuppliersInStore)
            .subscribe((results) => {
                this.suppliers = results.items;
            });
        this.subscriptions.push(manufacturers$);
    }

  plus() {

  }
    importLicenses(files: FileList , event: any) {
        this.store.dispatch(
          LicensesActions.ProductLicensesImport({
              productLicenses: files.item(0),
              productId: this.productId,
          }),
        );
    }
    getItemCssClassByStatus(status: string = ''): string {
        switch (status) {
            case 'IN_STOCK':
                return 'success';
            case 'OUT_OF_STOCK':
                return 'danger';
            case 'ON_COMMAND':
                return 'metal';
        }
        return '';
    }
}
