// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, switchMap, catchError } from 'rxjs/operators';
import { Observable, defer, of, forkJoin, pipe, EMPTY } from 'rxjs';
// NGRX
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
import { AppState } from '../../reducers';

import * as QuestionActions from './client-question.actions';
import { ClientQuestionService } from './client-question.service';
import { LayoutUtilsService, MessageType } from '../../_base/crud';

// CRUD
// State

@Injectable()
export class ClientQuestionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private clientQuestionService: ClientQuestionService
  ) {}

  BlogPageRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionActions.QuestionsPageRequested),
      switchMap((action) =>
        this.clientQuestionService.getAllByFilter(action.page).pipe(
          map((questionPage) => {
            return QuestionActions.QuestionsPageLoadedSuccessfully({
              questions: questionPage.content,
              page: questionPage.totalElements,
              totalCount: questionPage.totalElements,
            });
          }),
          catchError((error) =>
            of(QuestionActions.QuestionsPageLoadFailed({ error }))
          )
        )
      )
    )
  );

  QuestionUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuestionActions.QuestionUpdated),
      switchMap((action) =>
        this.clientQuestionService.update(action.question).pipe(
          map((question) =>
            QuestionActions.QuestionUpdatedSuccessfully({
              question,
              partialQuestion: action.partialQuestion,
            })
          ),
          catchError((error) =>
            of(QuestionActions.QuestionUpdateFailed({ error }))
          )
        )
      )
    )
  );

  QuestionUpdatedSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuestionActions.QuestionUpdatedSuccessfully),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'La Question a été modifié avec succès',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );

  QuestionUpdateFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuestionActions.QuestionUpdateFailed),
        switchMap((action) =>
          of(
            this.layoutUtilsService.showActionNotification(
              'Une erreur est survenue !',
              MessageType.Update,
              3000,
              false
            )
          )
        )
      ),
    { dispatch: false }
  );
}
